import { AxiosInstance } from "axios";

interface Variables {
	acct_id: string;
	productName?: string;
	[propName: string]: any;
}

async function query({ query, variables, url, token, ax, type }: { query: string; variables: Variables; url: string; token: string; ax: AxiosInstance; type: string; }) {
	let response: any;
	try {
		response = await ax.post(
			url, 
			{
				query,
				variables
			},
			{
				headers: token
					? { Authorization: `Bearer ${token}` }
					: undefined
			}
		);
	} catch (e: any) {
		if (e.response && e.response.data && e.response.data.errors !== undefined) {
			throw new Error(e.response.data.errors.map((val: any) => val.message).join(", "));
		}

		throw e;
	}

	if (response.data && response.data.errors && response.data.errors.length) {
		response.data.data.crm
		if (type) {
			response.data.data.crm = {
				[type]: {
					success: false,
					message: response.data.errors[0].message
				}
			}
		} else {
			response.data.data.crm = {
				success: false,
				message: response.data.errors[0].message
			}
		}
	}

	return response.data.data;
};

export default query;
