import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Hidden } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SUBSCRIPTION_TYPES } from "../../../common/consts";
import classNames from "classnames";
import Row from "./Row";
import Column from "./Column";
import { Creators } from "../actions";
const { ON_LOAD } = SUBSCRIPTION_TYPES;

const Section = React.forwardRef(({ name, label, rows, columns, classes, hidden, fireSubscriptionsByEventName }, ref) => {
	useEffect(() => {
		fireSubscriptionsByEventName({ eventName: ON_LOAD, fieldName: name });
	}, [name]);

	const section = (
		<ExpansionPanel ref={ref} defaultExpanded className={classes.section}>
			<ExpansionPanelSummary className={classNames(classes.sectionHeader, "CyFormSectionHeader")} expandIcon={<ExpandMoreIcon />}>
				{label}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classNames(classes.gridContainer)}>
				<Grid
					container
					direction="row"
				>
					{
						columns && columns.map((column, i) => {
							const columnName = `${name}-column-${i}`;
							return <Column classes={classes} key={columnName} {...column} name={columnName} />;
						})
					}
					{
						rows && rows.map((row, i) => {
							const rowName = `${name}-row-${i}`;
							return <Row classes={classes} key={rowName} {...row} name={rowName} />;
						})
					}
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
	if (hidden) {
		return <Hidden xsUp={true}>{section}</Hidden>;
	} else {
		return section;
	}
});

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	fireSubscriptionsByEventName: Creators.fireSubscriptionsByEventName
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Section);
