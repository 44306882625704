import { theme as mosaicTheme } from "@simpleview/sv-mosaic";

const styles = theme => ({
	backgrounddefault: "#fff",
	backgroundpaper: "#fff",
	errorBackground: "#f4f4f4",
	errordark: "#7b0000",
	errorlight: "#ec492c",
	errormain: mosaicTheme.colors.red,
	primarydark: mosaicTheme.colors.blueHover,
	primarylight: "#e2e2e2",
	primarymain: mosaicTheme.colors.blue,
	secondarycontrasttext: "#fff",
	secondarydark: "#E8EAED",
	secondarylight: "#E8EAED",
	secondarymain: "#F0F2F5",
	type: "light"
});

export default styles;
