/**
 * Flattens fieldName into string with composite info and data index when applicable
 * 	example 1
 * 	fieldNameToDataKey({ fieldName: "Address1", compField: "Address", compIndex: 0 }) >> "$comp_Address_Address1_0"
 *
 * 	example 2
 * 	fieldNameToDataKey({ fieldName: "companyName", compField: null, compIndex: null }) >> "companyName"
 */
const fieldNameToDataKey = ({ fieldName, compField, compIndex }) => (compField && Number.isInteger(compIndex) ? `$comp_${compField}_${fieldName}_${compIndex}` : fieldName);

export default fieldNameToDataKey;
