import React from "react";
import DropdownFilter from "./dropdownFilter";

const allDateOptions = [
	{ value: null, label: "Any" },
	{ value: 1, label: "Yes" },
	{ value: 0, label: "No" }
];


const BooleanFilter = props => {
	return <DropdownFilter options={allDateOptions} {...props} />;
};

export default BooleanFilter;
