const listingCategories = crm => ({ fieldName, dispatchWrapper, getState, eventName }) => {
	const { data } = getState();
	if (data[fieldName]) {
		crm.get({
			type: "listingCategory",
			filter: {
				ListingTypeID: data[fieldName].value,
				IsActive: true
			},
			fields: "Title ListingCategoryID"
		}).then(res => {
			if (res.success && res.count) {
				const options = res.recs.map(category => ({ label: category.Title, value: category.ListingCategoryID }));
				dispatchWrapper("updateField", ["CategoryID", { options }]);
				//Only clear if switching listing type (not onload)
				if (eventName !== "onLoad" && data.CategoryID && options.findIndex(cat => cat.value === data.CategoryID.value) === -1) {
					dispatchWrapper("handleFormChange", ["CategoryID", "Int", []]);
				}
			} else if (eventName !== "onLoad") {
				dispatchWrapper("updateField", ["CategoryID", { options: [] }]);
				dispatchWrapper("handleFormChange", ["CategoryID", "Int", []]);
			}
		});
	}
};

export default listingCategories;
