const DefaultTaskDescription = crm => ({ eventName, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (data.TaskTypeID) {
		crm.get({
			type: "taskType",
			filter: {
				recId: data.TaskTypeID.value
			},
			fields: `
				DefaultDesc
			`
		}).then(response => {
			let { recs: taskTypes } = response;
			if (taskTypes.length) {
				taskTypes = taskTypes[0];
				if (taskTypes.DefaultDesc && taskTypes.DefaultDesc.length) {
					dispatchWrapper("handleFormChange", ["TaskDesc", "String", taskTypes.DefaultDesc]);
				}
			}
		});
	}
};

export default DefaultTaskDescription;
