import { GridFilterText, GridFilterMultiselect, FilterDate } from "@simpleview/sv-mosaic";
import isEmpty from "../../../common/isEmpty";
import BooleanFilter from "./booleanFilter";
import YesNoNAFilter from "./yesnonaFilter";
import RangeFilter from "./rangeFilter";
import queryString from "query-string";
import BooleanYesNoFilter from "./booleanYesNoFilter";

const filterComponentMap = {
	GridFilterText,
	GridFilterMultiselect,
	text: GridFilterText,
	fullText: GridFilterText,
	date: FilterDate,
	boolean: BooleanFilter,
	range: RangeFilter,
	yesnona: YesNoNAFilter,
	int: GridFilterText,
	yesno: BooleanYesNoFilter
};

const convertDatePair = {
	last7days: {
		rangeStart: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
	},
	last30days: {
		rangeStart: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
	},
	last60days: {
		rangeStart: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)
	},
	last90days: {
		rangeStart: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)
	},
	last180days: {
		rangeStart: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)
	},
	last365days: {
		rangeStart: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
	},
	last3months: {
		rangeStart: new Date(new Date().setMonth(-3))
	},
	last6months: {
		rangeStart: new Date(new Date().setMonth(-6))
	},
	last12months: {
		rangeStart: new Date(new Date().setMonth(-12))
	},
	next7days: {
		rangeStart: new Date(),
		rangeEnd: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
	},
	next30days: {
		rangeStart: new Date(),
		rangeEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
	},
	next3months: {
		rangeStart: new Date(),
		rangeEnd: new Date(new Date().setMonth(3))
	},
	next6months: {
		rangeStart: new Date(),
		rangeEnd: new Date(new Date().setMonth(6))
	},
	next12months: {
		rangeStart: new Date(),
		rangeEnd: new Date(new Date().setMonth(12))
	}
};

const defaultToFilter = (filter) => {
	const formattedFilter = {
		operation: filter.comparison,
		value: filter.value
	};
	if (filter.label) {
		formattedFilter.label = filter.label;
	}
	return formattedFilter;
};

const toFilters = {
	text: filter => {
		return {
			operation: "contains",
			value: filter.value
		};
	},
	date: filter => {
		if (filter.value && typeof filter.value === "string") {
			filter = { ...filter, ...convertDatePair[filter.value] };
			if (filter.rangeStart) {
				filter.rangeStart.setHours(0);
				filter.rangeStart.setMinutes(0);
				filter.rangeStart.setSeconds(0);
			}
			if (filter.rangeEnd) {
				filter.rangeEnd.setHours(23);
				filter.rangeEnd.setMinutes(59);
				filter.rangeEnd.setSeconds(59);
			}
		} 

		return {
			operation: "between",
			value: {
				rangeStart: filter.rangeStart,
				rangeEnd: filter.rangeEnd
			},
			dataType: "Date"
		};
	},
	processIntArray: filter => {
		return {
			operation: filter.comparison,
			value: filter.value.map(value => Number(value))
		};
	},
	boolean: filter => {
		return {
			operation: filter.comparison,
			label: filter.label,
			value: filter.value == "true" || filter.value == true
		};
	},
	range: filter => {
		return {
			operation: filter.comparison,
			value: {
				rangeStart: filter.rangeStart,
				rangeEnd: filter.rangeEnd
			}
		};
	},
	yesnona: filter => {
		return {
			operation: filter.comparison,
			label: filter.label,
			value: filter.value
		};
	},
	int: filter => {
		let value = null;

		try {
			value = filter.value && typeof filter.value === "string" ? parseInt(filter.value.replace(/\D/g,"")) : parseInt(filter.value);
		} catch(e) {
			//do nothing
		}

		return {
			operation: "equals",
			value
		};
	},
	yesno: filter => {
		return {
			operation: filter.comparison,
			label: filter.label,
			value: filter.value == "true" || filter.value == true
		};
	}
};

/**
 * Return the filter component, defaults to GridFilterText if the component could not be found
 * @param {string} component - name of the component
 */
export const getFilterComponent = component => {
	return filterComponentMap[component] || GridFilterText;
};

const getConvertFilter = ({ component, toFilter }) => {
	return toFilters[toFilter] || toFilters[component] || defaultToFilter;
};


export const getDefaultFilter = filters => {
	const result = {};
	filters.forEach(filter => {
		result[filter.name] = {
			comparison: filter.args?.comparisons?.[0]
		};
	});
	return result;
};

/**
 * Process Mosaic filter state and translate that to filter input for graph
 * @param {*} gridFilter
 */
export const gridFilterToFilters = ({ gridFilter, filters = [] }) => {
	const result = [];
	let searchValues = queryString.parse(location.search);

	for (const filterConfig of filters) {
		const { name, component, toFilter, where } = filterConfig;

		if (searchValues[name] && ((gridFilter[name] && gridFilter[name].value === undefined) || !gridFilter[name])) {
			const label = ["1", "0", "true", "false"].includes(searchValues[name]) ? (["1", "true"].includes(searchValues[name]) ? "Yes" : "No") : "";

			gridFilter[name] = {
				...gridFilter[name],
				value: searchValues[name], 
				comparison: "equals", 
				label
			};
		}

		const filter = gridFilter[name];
		if (filter && (!isEmpty(filter.value))) {
			result.push({
				name,
				where,
				...getConvertFilter({ component, toFilter })(filter) });
		} else if (component === "date" && filter && (filter.rangeStart || filter.rangeEnd || filter.start || filter.end)) {
			result.push({
				name,
				...getConvertFilter({ component, toFilter })(filter) });
		}
	}
	return result;
};

// these two function could be move elsewhere
export function isPrimitive(test) {
	return (test !== Object(test));
}

export function primitiveAndArrayValueToString(value) {
	if (value === undefined || value === null) {
		return value;
	}

	if (Array.isArray(value)) {
		value = value.filter(val => val !== undefined && val !== null).map(val => isPrimitive(val) ? val.toString() : val);
	} else {
		value = isPrimitive(value) ? value.toString() : value;
	}

	return value;
}

/**
 * Process view.filters to Mosaic filter state.
 * @param {*} filters
 */
export const filtersToGridFilter = filters => {
	const result = {};

	filters.forEach(filter => {
		const value = primitiveAndArrayValueToString(filter.value);
		result[filter.name] = {
			value,
			comparison: filter.operation
		};
		if(filter.label) {
			result[filter.name].label = filter.label;
		}
		//So Mosaic properly renders on refresh (they don't have a value object on the filter just range)
		if(filter.dataType === "Date") {
			if (filter?.value?.rangeStart) {
				result[filter.name].rangeStart = new Date(filter.value.rangeStart);
			} 
			if (filter?.value?.rangeEnd) {
				result[filter.name].rangeEnd = new Date(filter.value.rangeEnd);
			}
		}
	});
	return result;
};
