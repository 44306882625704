import debounce from "lodash/debounce";

const geocode = crm => debounce(({ subscriber, dispatchWrapper, getState, staticArgs, eventName }) => {
	dispatchWrapper("enableSubmit", [false]);
	const {
		formType,
		data: {
			Address: { compositeFields = [] },
			AutoGeocode
		},
		data
	} = getState();
	const typeArray = formType.split(".");
	const actualFormType = typeArray.length === 1 ? formType : typeArray[1];
	
	const addresses = Array.from(compositeFields);
	// If there are additional Address composite fields to check, then add them into the array.
	if(staticArgs) {
		staticArgs.forEach(field => {
			if(data[field].compositeFields) {
				addresses.push(data[field].compositeFields[0]);
			}
		});	
	}

	const singleAddressTypes = ["eventCalendar"];
	const address = addresses
		.filter(addr => (
			(
				addr.AddressTypeID && addr.AddressTypeID.label === "Physical") 
				|| (singleAddressTypes.includes(actualFormType) && addr.AddressLine1)
		) && addr.CountryCode && addr.StateCode && addr.PostalCode
		)
		.map(addr => ({
			AddressLine1: addr.AddressLine1,
			AddressLine2: addr.AddressLine2,
			AddressLine3: addr.AddressLine3,
			City: addr.City,
			StateCode: addr.StateCode && addr.StateCode.value,
			PostalCode: addr.PostalCode,
			CountryCode: addr.CountryCode && addr.CountryCode.value
		}));
	if (address[0] && (eventName === "onClick" || AutoGeocode)) {
		crm.get({
			type: "geolocation_byaddress",
			filter: {
				...address[0]
			},
			fields: "Latitude Longitude"
		}).then(res => {
			const [location] = res.recs;
			const { Latitude = 0, Longitude = 0 } = location;
			dispatchWrapper("handleFormChange", [subscriber, "String", `${Latitude},${Longitude}`]);
		});
	}
	dispatchWrapper("enableSubmit", [true]);
}, 1000);

export default geocode;
