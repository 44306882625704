import defaultAx from "axios";
import AdminPrefix from "./prefixes/Admin";
import FormPrefix from "./prefixes/Form";
import GeneralPrefix from "./prefixes/GeneralPrefix";
import ListingPrefix from "./prefixes/Listing";
import EventCalendarPrefix from "./prefixes/EventCalendar";
import ReferralPrefix from "./prefixes/Referral";
import OfferPrefix from "./prefixes/Offer";
import ContactPrefix from "./prefixes/Contact";
import GridPrefix from "./prefixes/Grid";
import { ServiceAccount } from "./Service";

const GraphServer = function ({ graphUrl, prefixes, context = {}, ax = defaultAx }) {
	this.context = context;

	prefixes.forEach(Prefix => {
		const prefix = new Prefix({
			graphUrl,
			graphServer: this,
			ax
		});

		this[prefix.name] = prefix;
	});
};

export {
	AdminPrefix,
	ContactPrefix,
	FormPrefix,
	GeneralPrefix,
	GraphServer,
	ListingPrefix,
	EventCalendarPrefix,
	ReferralPrefix,
	OfferPrefix,
	ServiceAccount,
	GridPrefix
};
