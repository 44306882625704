import { CrmFormCallback } from "./typeDef";
import { handleSubmit } from "../form";


const sendComm: CrmFormCallback = crm => async (info): Promise<any> => {
	const { dispatchWrapper } = info;
	const { getState } = info;
	const { data } = getState();
	const newData = {
		...data,
		shouldSend: true,
		EmailStatus: "Sent"
	};

	const res = await crm.get({
		type: "contact",
		fields: `
		ContactID
		FullName
		SendEmail
		Email {
			recs {
				EmailAddress
			}
		}`,
		filter: {
			recId: data.ContactID.value
		}
	}).then(res => {
		if (res.count === 1 && res.recs[0].SendEmail) {
			handleSubmit({ crm, ...info, data: newData });
		} else {
			dispatchWrapper("setDialog", [true, "", "The Send Email preference for this contact is set to No.  No communication was sent.  To change this, edit the contact and set Send Email to Yes."]);
		}
	});
};

export default sendComm;
