import { extractInfo } from "../form";

const setDefaultVenueAccount = crm => async info => {
	const { getState } = info;
	const { formType, refId } = getState();
	const { updateField, updateValue } = extractInfo(info);
	let ListingID;
	let AccountID;

	if (formType === "listing.eventCalendar") {
		ListingID = refId
	} else if (formType === "account.eventCalendar") {
		AccountID = refId
	}

	if (AccountID) {
		crm.get({
			type: "account",
			filter: {
				recId: AccountID,
			},
			fields: `
				CompanyName
				AccountID
				IsActive
				IsDeleted
			` 
		}).then(res => {
			if (res.success && res.count) {
				const { recs: accounts } = res;
				const options = accounts.filter(rec => rec.IsActive && !rec.IsDeleted).map(rec => {
					return { label: rec.CompanyName, value: rec.AccountID };
				});
				updateValue(options[0]);
			} else {
				updateValue(undefined);
				updateField({ options: [] });
			}
		});
	}

	if (ListingID) {
		const res = await crm.get({
			type: "listing",
			filter: {
				recId: ListingID,
			},
			fields: `
				ListingID
				AccountID
			` 
		});
	
		if (res.success && res.count) {
			AccountID = res.recs[0].AccountID;
		}

		crm.get({
			type: "account",
			filter: {
				recId: AccountID,
			},
			fields: `
				CompanyName
				AccountID
				IsActive
				IsDeleted
			` 
		}).then(res => {
			if (res.success && res.count) {
				const { recs: accounts } = res;
				const options = accounts.filter(rec => rec.IsActive && !rec.IsDeleted).map(rec => {
					return { label: rec.CompanyName, value: rec.AccountID };
				});
				updateValue(options[0]);
				updateField({ readOnly: true });
			} else {
				updateValue(undefined);
				updateField({ options: [] });
			}
		});
	}
};

export default setDefaultVenueAccount;
