import React from "react";
import DropdownFilter from "./dropdownFilter";

const options = [
	{ value: null, label: "Any" },
	{ value: "Yes", label: "Yes" },
	{ value: "No", label: "No" },
	{ value: "N/A", label: "N/A" }
];

const YesNoNAFilter = props => {
	return <DropdownFilter options={options} {...props} />;
};

export default YesNoNAFilter;
