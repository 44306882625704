const birthMonths = crm => ({ dispatchWrapper, getState }) => {
	const { data } = getState();

	if (data.BirthMonth && data.BirthMonth.length === 0 && data.BirthDay && data.BirthDay.length === 0) {
		return;
	}

	crm.get({
		type: "days_by_month",
		filter: {
			index: data.BirthMonth ? data.BirthMonth.value : 0
		},
		fields: "day"
	}).then(response => {
		const { recs: days } = response;
		const options = days.map(opt => ({ label: opt.day, value: opt.day }));
		dispatchWrapper("updateField", ["BirthDay", { options }]);
		const birthDayIndex = options.findIndex(option => option.value === data.BirthDay.value);
		if (birthDayIndex === -1) {
			dispatchWrapper("handleFormChange", ["BirthDay", "Int", []]);
		}
	});
};

export default birthMonths;
