import { extractInfo } from "../form";

const confirmDeactivation = () => info => {
	const { dispatchWrapper } = info;
	const { ownValue, updateValue, staticArgs } = extractInfo(info);

	if (!ownValue) {
		const onConfirm = value => {
			if (!value) {
				updateValue(true);
			}
		};

		dispatchWrapper("setDialog", [true, "", staticArgs[0], onConfirm]);
	}	
};

export default confirmDeactivation;
