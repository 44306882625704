import store from "../../store";

const addTemplateHtml = crm => ({ fieldName, subscriber, dispatchWrapper, getState }) => {
	const { data } = getState();
	const promises = [];
	const loginState = store.getState().login;

	if (data.EmailStatus === "Sent" && (data.recId || data.TemplateID === ""|| (data.TemplateID.label.indexOf("Referral") > -1 && !data.ReferralID))) {
		//If already sent, don't overwrite the template.
		return;
	}

	const userSignature = crm.get({
		type: "myProfile",
		filter: {
			UserID: loginState.userId
		},
		fields: "UserSignature"
	}).then(res => {
		if (res.success && res.count) {
			const heading = "";
			return res.recs[0].UserSignature ? heading.concat(res.recs[0].UserSignature) : res.recs[0].UserSignature;
		}
	});

	promises.push(userSignature);

	if (data[fieldName]) {
		const filter = {
			recId: data[fieldName].value,
			Preview: true
		};

		if (data.ReferralID) {
			filter.ReferralID = data.ReferralID;
		}

		if (data?.ContactID?.value) {
			filter.ContactID = data.ContactID.value;
		} else if (data.ContactID && Array.isArray(data.ContactID) && data.ContactID.length) {
			filter.ContactID = data.ContactID[0].value;
		}

		const selectedTemplate = crm.get({
			type: "template",
			filter,
			fields: "TemplateBody"
		}).then(res => {
			if (res.success && res.count) {
				return res.recs[0].TemplateBody;
			}
		});

		promises.push(selectedTemplate);
	}

	Promise.all(promises).then(values => {
		const [signature, template] = values;
		const formChange = signature && template ? template.concat(signature) : template || signature;
		dispatchWrapper("handleFormChange", [subscriber, "String", formChange]);
	});
};

export default addTemplateHtml;
