import { AxiosInstance } from "axios";

import query from "../query";
import all from "./fields";

class ListingPrefix {
	name: string;
	private _graphUrl: string;
	private _graphServer: any;
	private _ax: AxiosInstance;
	constructor({ graphUrl, graphServer, ax }: { graphUrl: string, graphServer: object, ax: AxiosInstance }) {
		this.name = "listing";
		this._graphUrl = graphUrl;
		this._graphServer = graphServer;
		this._ax = ax;
	}

	async listings_v2(fields: string, filter: { RecordID: string }, options: { limit: number, page: number, orderBy?: string, orderDir?: string }, context: any) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			filter,
			options,
			productName: context.productNameNoEnv || context.productName
		};

		const response = await query({
			ax: this._ax,
			query: `
				query listings_v2($acct_id: String!, $filter: crm_listing_filter_v2, $options: crm_options, $productName: String) {
					crm(acct_id: $acct_id, productName: $productName) {
						listings_v2(filter: $filter, options: $options) {
							${fields}
						}
					}
				}
			`,
			token: context.token,
			type: "listings_v2",
			url: this._graphUrl,
			variables
		});

		return response.crm.listings_v2;
	}

	async listingFull({ recId, ReferralID, context }: { recId: number, ReferralID: number, context: any }) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			filter: {
				recId,
				ReferralID
			},
			productName: context.productNameNoEnv || context.productName
		};

		const response = await query({
			ax: this._ax,
			query: `
				query listings($acct_id: String!, $filter: crm_listing_filter, $productName: String) {
					crm(acct_id: $acct_id, productName: $productName) {
						listings(
							filter: $filter
						) {
							success
							message
							recs {
								${all}
							}
						}
					}
				}
			`,
			token: context.token,
			type: "listings",
			url: this._graphUrl,
			variables
		});

		return response.crm.listings;
	}
}

export default ListingPrefix;
