import React, { useState, useCallback } from "react";
import AsyncSelect, { LoadOptionsCallback, Option, Options } from "./AsyncSelect";

interface Props {
	isMulti?: boolean;
	fetchOptions: FetchOptions;
	formType: string;
	field: Record<string, any>;
	value: Option | Option[] | "";
	variant?: string;
	error: boolean;
	errorMsg?: string;
	onChange: (value: any) => void;
}

interface Result {
	recs: OptionResult[];
	count: number;
	succss: boolean;
	message: string;
}

interface OptionResult extends Option {
	valueString?: string;
}

type FetchOptions = (moduleName: string, column: string, page: number, search?: string) => Promise<Result>;


/**
 * Wrapper component for the form
 * 
 * TODO: the following features are in the old select field, implement if nessasary.
 * - limit use options
 * - creatable
 */
const SelectField: React.FC<Props> = ({ isMulti, fetchOptions, formType, variant = "outlined", field: { name, label }, error, errorMsg, value, onChange }) => {

	const [options, setOptions] = useState<Option[]>([]);

	const loadOptions = useCallback<LoadOptionsCallback>(async (search, loadedOptions, { page }): Promise<Options> => {
		const res = await fetchOptions(formType, name, page, search);
		const hasMore = Math.ceil(res.count / 25) > page;
		const newOptions: Option[] = res.recs.map((option: OptionResult): Option => ({
			label: option.label,
			value: option.valueString || option.value,
			sortOrder: option.sortOrder
		}));
		setOptions([...loadedOptions, ...newOptions]);
		return {
			options: newOptions,
			hasMore,
			additional: {
				page: page + 1
			}
		};
	}, [fetchOptions, name]);

	return <AsyncSelect
		label={label}
		options={options}
		value={value === "" ? undefined: value}
		isMulti={isMulti}
		variant={variant}
		error={error}
		errorMsg={errorMsg}
		loadOptions={loadOptions}
		onChange={onChange}
	>

	</AsyncSelect>;

};

export default SelectField;

export const AsyncSelectField: React.FC<Props> = props => <SelectField {...props} />;
export const AsyncMultiSelectField: React.FC<Props> = props => <SelectField {...props} isMulti />;
