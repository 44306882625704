import moment from "moment";
import { extractInfo } from "../form";

const modifiers = {
	"!": (value) => !value,
	"!!": (value) => !!value,
	"-": (value) => -value
};

const compares = {
	">=": (value, arg) => value >= arg,
	">": (value, arg) => value > arg,
	"<": (value, arg) => value = arg,
	"<=": (value, arg) => value <= arg,
	"==": (value, arg) => value == arg,
	"===": (value, arg) => value === arg,
	"!=": (value, arg) => value != arg,
	"!==": (value, arg) => value !== arg,
	"includes": (value, arg) => arg.includes(value),
	"!includes": (value, arg) => !arg.includes(value)
};


/**
 * setFieldPropsWithValue
 *
 * staticArgs:
 * 0 - fieldPropname: String
 *     defaults to fieldName of the subscribed field.
 * 1 - operator: String
 *     Specify the operator of the action, if arg length is 2,
 *     this should be a modifier, if length is more than 2, this
 *     should be a comparision operator.
 * 2 - compValue: any
 *     This is the value that the subValue is compared with.
 *     If arg length is 3, then the setField will set the field to
 *     comparison result (true or false).
 *     (FUTURE: might want to have $value_{fieldName}[{index}] or
 *     $field_{fieldName}[{index}]_${fieldPropName} to grab
 *     the value from another field)
 * 3 - valueToSet: any
 *     the first two args would function like a condition check,
 *     so if (subValue op compValue) { setField(setValue) }
 * 4 - elseValueToSet: any
 *     set to this value if condition fail.
 */
const setFieldPropsWithValue = crm => info => {
	const { updateField, subFieldName, subValue, staticArgs = [] } = extractInfo(info);
	const [attrName = subFieldName, operator, compValue, valueToSet, elseValueToSet] = staticArgs;
	const update = (value) => {
		updateField({
			[attrName]: value
		});
	};

	let value;
	if (subValue instanceof Array) {
		value = subValue.length;
	} else if (moment.isMoment(subValue)) {
		value = subValue.format();
	} else if (subValue instanceof Object) {
		value = subValue.value;
	} else {
		value = subValue;
	}

	if (elseValueToSet !== undefined) {
		update(compares[operator](value, compValue) ? valueToSet: elseValueToSet);
	} else if (valueToSet !== undefined) {
		if (compares[operator](value, compValue)) {
			update(valueToSet);
		}
	} else if (compValue !== undefined) {
		let parsedCompValue = compValue;
		try {
			parsedCompValue = JSON.parse(compValue);
		} catch (e) {
			//JSON is already parsed
		}
		update(compares[operator](value, parsedCompValue));
	} else if (operator !== undefined) {
		if (operator in modifiers) {
			update(modifiers[operator](value));
		} else {
			// apply value directly
			update(operator);
		}
	} else {
		update(value);
	}
};
//
export default setFieldPropsWithValue;
