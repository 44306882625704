const styles = theme => ({
	appContainer: {
		flex: "1 1 0",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignItems: "stretch",
		height: "100%"
	},
	headerContainer: {
		...theme.mixins.toolbar,
		zIndex: 1
	},
	mainContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		height: "calc(100% - 64px)",
		alignItems: "stretch"
	},
	mainContent: {
		flex: "1 1 0",
		overflowY: "auto"
	},
	embedForm: {
		padding: 24
	}
});

export default styles;
