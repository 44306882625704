import { extractInfo } from "../form";

const usersInGroups = crm => async info => {
	const { dispatchWrapper, eventName } = info;
	const { subValue, updateField, ownValue, updateValue, staticArgs, data, fields } = extractInfo(info);
	if (eventName === "onChange" && !staticArgs && ownValue.compositeFields.length && Object.keys(ownValue.compositeFields[0]).length) {
		dispatchWrapper("setDialog", [true, "", "Changing Groups affects the assigned users"]);
	}

	let groups = [];
	if (staticArgs && staticArgs.length) {
		groups = data[staticArgs[0]].map(group => group.value);
	} else if (subValue && subValue.length) {
		groups = subValue.map(group => group.value);
	}
	crm.get_old({
		queryString: `allUsers(filter: { Permissions: { ids: [${groups}], module: "account" }, IsActiveOnly: true}) {
			success
			recs {
				UserID
				FullNameEmail
			}
		}`
	}).then(res => {
		if (res.allUsers.success && res.allUsers.recs.length) {
			res.allUsers.recs.sort((a, b) => (a.FullNameEmail.toLowerCase() > b.FullNameEmail.toLowerCase()) ? 1 : -1);
		}
		if (staticArgs && staticArgs.length) {
			const options = res.allUsers.recs.map(rec => {
				return { label: rec.FullNameEmail, value: rec.UserID };
			});
			for (let i = 0; i < fields[staticArgs[1]].index.length; i++) {
				fields[staticArgs[1]].index[i] = { ...fields[staticArgs[1]].index[i], options };
			}
			dispatchWrapper("updateField", [staticArgs[1], {
				index: fields[staticArgs[1]].index
			}]);
		} else {
			if (res.allUsers.success) {
				const options = res.allUsers.recs.map(rec => {
					return { label: rec.FullNameEmail, value: rec.UserID };
				});
				updateField({ options }, true);
				for (let i = 0; i < ownValue.compositeFields.length; i++) {
					const val = ownValue.compositeFields[i];
					if (val?.UserID?.value && !options.some(option => option.value === val.UserID.value)) {
						val.UserID = undefined;
						updateValue(undefined, i);
					} else if(val?.UserID?.value && val?.UserID?.label && val?.UserID?.value === val?.UserID?.label) {
						const index = options.findIndex(option => option.value === val.UserID.value);
						val.UserID = options[index];
						updateValue(val.UserID, i);
					}
				}
			} else {
				updateField({ options: [] }, true);
			}
		}
	});
};

export default usersInGroups;
