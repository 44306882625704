const fetchGridField = crm => async ({ subscriber, dispatchWrapper, getState }) => {
	const { data, fields } = getState();
	const field = fields[subscriber];
	const recId = data["recId"];

	if (!recId) {
		return [];
	}

	let qryFields = field.gridOptions.listColumns.map(column => column.name).join(" ");

	if (field.gridOptions.childObject) {
		qryFields = `
			recId
			${field.gridOptions.childObject} {
				recs {
					${field.gridOptions.listColumns.map(column => column.name).join(" ")}
				}
			}
		`;
	}	
	
	crm.get({
		type: `${field.gridOptions.form}`,
		filter: {
			recId: recId
		},
		fields: qryFields
	}).then(recs => {
		if (recs.success && recs.count) {
			const gridRecs = recs.recs[0][field.gridOptions.childObject].recs;
			if (gridRecs.length) {
				dispatchWrapper("handleFormChange", [subscriber, "Object", gridRecs]);
			}
		}
	});
};

export default fetchGridField;
