import React, { useState, useRef, useMemo, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";

interface Props {
	content: string;
	lines?: number;
	defaultExpanded?: boolean;
	variant?: "body1" | "body2";
}

const buttonStyle = {
	padding: "0.5em 0 0 0"
};

const ExpandableText: React.FC<Props> = ({ content, lines = 3, defaultExpanded = false, variant = "body1" }) => {
	const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
	const [overflow, setOverflow] = useState<boolean>(false);
	const ref = useRef<HTMLElement>(null);
	const style: React.CSSProperties = useMemo<React.CSSProperties>(() => {
		return expanded ? {
			overflow: "auto"
		} : {
			display: "-webkit-box",
			WebkitLineClamp: lines,
			WebkitBoxOrient: "vertical",
			overflow: "hidden"
		};
	}, [lines, expanded]);
	useEffect(() => {
		setOverflow( (ref && ref.current) ? (ref.current.offsetHeight < ref.current.scrollHeight) : false);
	});
	return <div>
		<Typography variant={variant} style={style} ref={ref}>
			{content}
		</Typography>
		{ !expanded && overflow && <Button style={buttonStyle} onClick={(): void => setExpanded(true)}>show more</Button> }
		{ expanded && <Button style={buttonStyle} onClick={(): void => setExpanded(false)}>show less</Button>}
	</div>;
};

export default ExpandableText;
