const defaultAccountAddress = crm => ({ dispatchWrapper, getState, eventName }) => {

	const { formType, refId, recId } = getState();

	const getDefault = () => {
		return {
			address: {
				AddressTypeID: null,
				AddressLine1: "",
				AddressLine2: "",
				AddressLine3: "",
				PostalCode: "",
				PostalCode4: "",
				City: "",
				StateCode: null,
				CountryCode: null,
				AddressID: 0,
			},
			map: {
				Latitude: 0,
				Longitude: 0,
				AutoGeocode: true,
			}
		};
	};

    const getAddress = async(id) => {
        const { recs } = await crm.get({
            type: "account",
            filter: {
                recId: refId,
            },
            fields: `
            CompanyName
            PhysicalAddressLine1
            PhysicalAddressLine2
            PhysicalAddressLine3
            PhysicalCity
            PhysicalStateCode
            PhysicalPostalCode
            PhysicalPostalCode4
            PhysicalCountryCode
            Latitude
            Longitude
            AutoGeocode
        `,
        });
    
        const toPush = getDefault();
        const address = recs[0];
    
        const countryRes = await crm.get({
            type: "state_code",
            filter: {
                CountryCode: address.PhysicalCountryCode || "US",
            },
            fields: "StateCode StateDesc",
        });
        
        if (countryRes.success) {
            const r = countryRes.recs.filter(rec => rec.StateCode === address.PhysicalStateCode)[0];
            address.PhysicalStateCode = { label: r.StateDesc, value: r.StateCode };
        } else {
            address.PhysicalStateCode = null;
        }
    
        toPush.address.AddressLine1 = address.PhysicalAddressLine1 || "";
        toPush.address.AddressLine2 = address.PhysicalAddressLine2 || "";
        toPush.address.AddressLine3 = address.PhysicalAddressLine3 || "";
        toPush.address.City = address.PhysicalCity || "";
        toPush.address.PostalCode = address.PhysicalPostalCode || "";
        toPush.address.PostalCode4 = address.PhysicalPostalCode4 || "";
        toPush.address.StateCode = address.PhysicalStateCode;
        toPush.address.AddressTypeID = { label: "Physical", value: 1 };
        toPush.address.CountryCode = address.PhysicalCountryCode 
            ? { label: (address.PhysicalCountryCode === "US" ? "United States" : address.PhysicalCountryCode), value: address.PhysicalCountryCode } 
            : null;
        toPush.map.Latitude = JSON.parse(address.Latitude) || 0;
        toPush.map.Longitude = JSON.parse(address.Longitude) || 0;
        toPush.map.AutoGeocode =
            "AutoGeocode" in address ? address.AutoGeocode : true;
    
        dispatchWrapper("handleFormChange", [
            "Address_v2",
            "String",
            { map: toPush.map, address: [toPush.address] },
        ]);
    };

    if (eventName === "onLoad" && formType === "account.contact" && refId && !recId) {
        getAddress(refId);
    }
};

export default defaultAccountAddress;
