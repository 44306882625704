
const createTag = crm => async ({ module, fieldName, value, dispatchWrapper }) => {
	const resolver = `set_${module}_tag`;
	const escapedValue = value.trim().replace(/["\\]/g, function(match) {
		return "\\".concat(match);
	});

	if (value) {
		crm.set_old({
			type: resolver,
			queryString: `
				${resolver}(tag: "${escapedValue}") {
					success
					message
					snackbarMessage
					snackbarDisplayType
					recId
				}
			`
		}).then(res => {
			const data = res[resolver];
			if (data && data.success) {
				const option = { label: value, value: data.recId, sortOrder: 0 };
				dispatchWrapper("handleNewOption", [fieldName, option]);
				return option;
			}
		});
	}
};

export default createTag;
