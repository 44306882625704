import React, { useRef, useEffect, useState } from "react";
import MuiChip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const tooltipWrapper = (component, title) => {
	return title ? <Tooltip
		disableFocusListener={true}
		title={title}
	>
		<span>{component}</span>
	</Tooltip> : component;
};

const Chip = (props) => {
	const { label } = props;
	const chipEl = useRef(null);
	const [overflowing, setOverflowing] = useState(false);
	useEffect(() => {
		const labelEl = chipEl.current.querySelector("span");
		setOverflowing(labelEl.offsetHeight < labelEl.scrollHeight || labelEl.offsetWidth < labelEl.scrollWidth);
	}, [label]);

	return tooltipWrapper(<MuiChip innerRef={chipEl} {...props} />, overflowing ? label : null);
};

export default withStyles(styles)(Chip);
