const countryToState = crm => ({ compField, compIndex, dispatchWrapper, getState }) => {
	const { data, fields } = getState();
	const stateCode = data[compField].compositeFields[compIndex].StateCode;
	const countryCode = data[compField].compositeFields[compIndex].CountryCode;
	const stateField = fields[`$comp_${compField}_StateCode`];
	const index = [...stateField.index];

	crm.get({
		type: "state_code",
		filter: {
			CountryCode: countryCode ? countryCode.value : "US"
		},
		fields: "StateCode StateDesc"
	}).then(res => {
		if (res.success && res.count) {
			const options = res.recs.map(opt => ({ label: opt.StateDesc, value: opt.StateCode }));

			if (index[compIndex]) {
				index[compIndex].options = options;
			}
			dispatchWrapper("updateField", [`$comp_${compField}_StateCode`, { index }]);

			if (!stateCode) {
				return;
			}

			const stateIndex = res.recs.findIndex(state => state.StateCode === stateCode.value);
			if (stateIndex > -1) {
				dispatchWrapper("handleFormChange", ["StateCode", "String", options[stateIndex], compIndex, compField]);
			} else {
				//if can't find by value check by label
				const stateLabelIndex = res.recs.findIndex(state => state.StateDesc === stateCode.label);
				if (stateLabelIndex > -1) {
					dispatchWrapper("handleFormChange", ["StateCode", "String", options[stateLabelIndex], compIndex, compField]);
				} else {
					dispatchWrapper("handleFormChange", ["StateCode", "String", "", compIndex, compField]);
				}
			}
		} else {
			if (index[compIndex]) {
				index[compIndex].options = [];
			}
			dispatchWrapper("updateField", [`$comp_${compField}_StateCode`, { index }]);
			dispatchWrapper("handleFormChange", ["StateCode", "String", "", compIndex, compField]);
		}
	});
};

export default countryToState;
