import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Drawer from "@material-ui/core/Drawer";
import DeleteIcon from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment-timezone";
import GridDrawerGrid from "./gridDrawerGrid";
import GridDrawerForm from "./gridDrawerForm";
import Chip from "@react-ui/components/Chip";
import FormFrame from "../../FormFrame";
import { EDIT } from "../../../../common/consts";
import isEmpty from "../../../../common/isEmpty";

import styles from "./styles";
import useWindowSize from "@react-ui/hooks/useWindowSize";
import classNames from "classnames";

function Grid({
	classes,
	field: {
		gridOptions: {
			form,
			moduleName,
			parentModule,
			listColumns,
			listTitle,
			registry,
			modifyRecords = false,
			removeRecords = true,
			filterId,
			activeRecords = false,
			formFields = [],
			showAddLink = true,
			modifyRecordsType = "GridDrawerForm",
			formProps,
			dataMap
		},
		subscriptions = []
	},
	value = [],
	error,
	errorMsg,
	onChange,
	refId,
	formType,
	server,
	gridHook,
	localeSettings,
	viewOnly
}) {
	const formParent = formType.split(".")[0];
	parentModule = formParent === parentModule ? formParent : undefined;
	const showAsrequired = subscriptions.filter(subscription => (subscription.name === "isRequired" && !subscription.args) || subscription.name === "isRequiredCompare").length > 0;

	const windowSize = useWindowSize();
	const [drawerState, setDrawerState] = useState({
		open: false
	});
	
	const [gridParams, setGridParams] = useState({
		moduleName,
		form,
		registry,
		server,
		parentModule,
		refId: parentModule !== undefined ? refId : undefined,
		systemFilters: []
	});

	useEffect(() => {
		const systemFilters = [];
		if (value && value.length) {
			systemFilters.push({
				name: filterId,
				value: value.map(val => val[filterId]),
				operation: "not_in"
			});
		}

		if (activeRecords) {
			systemFilters.push({
				name: "IsActive",
				value: true,
				operation: "equals"
			});
		}

		setGridParams({
			...gridParams,
			systemFilters
		});	

	}, [value]);

	const actionMap = {
		handleApplyToForm: function({ data }) {
			// sanitize data
			const newData = [];
			data.forEach(rec => {
				const addToGrid = {};
				for (const key of listColumns.map(column => column.name)) {
					if (rec[key] && rec[key].props) {
						rec[key] = rec[key].props.children;
					}
					addToGrid[key] = rec[key] ? rec[key] : null;
				}
				newData.push(addToGrid);
			});
			const newValue = (value || []).concat(newData);
			onChange(newValue);
			setDrawerState({
				open: false
			});
			// set grid page back to 1
			this.updateSettings({ page: 1 });
		}
	};

	const wrapSubmit = (cbs, dataIndex) => info => {
		const data = cbs(info);

		if (value[dataIndex]) {
			Object.keys(data).forEach((dataKey) => {
				if (Array.isArray(data[dataKey])) {
					//If array grab values
					if (!isEmpty(data[dataKey])) {
						value[dataIndex][dataKey] = data[dataKey].map(d => d.value);
						value[dataIndex][dataMap[dataKey].label] = data[dataKey].map(d => d.label);
					} else {
						value[dataIndex][dataKey] = [];
						value[dataIndex][dataMap[dataKey].label] = [];
					}
				} else if (typeof data[dataKey] === "object") {
					//If object grab value
					if (!isEmpty(data[dataKey])) {
						value[dataIndex][dataKey] = data[dataKey].value;
						value[dataIndex][dataMap[dataKey].label] = data[dataKey].label;
					} else {
						value[dataIndex][dataKey] = undefined;
						value[dataIndex][dataMap[dataKey].label] = undefined;
					}
				} else {
					//Else grab self
					if (typeof dataMap[dataKey] === "object" && dataMap[dataKey].onlyOne) {
						const onlyOneKey = dataMap[dataKey].onlyOne;
						//set the value from the form
						value[dataIndex][onlyOneKey] = data[dataKey];
						//if value is true then all others should be false
						if (data[dataKey]) {
							value.forEach((val, idx) => {
								if (idx !== dataIndex) {
									value[idx][onlyOneKey] = false;
								}
							});
						}
					} else {
						value[dataIndex][dataMap[dataKey]] = data[dataKey];
					}
				}							
			});
		}
		onChange(value);
		setDrawerState({
			open: false
		});
	};

	const mapDefaultData = (data) => {
		const defaultData = {};
		Object.keys(dataMap).forEach((key) => {
			if (typeof dataMap[key] === "object") {
				if (!isEmpty(data[key])) {
					if (dataMap[key].chip) {
						//If chip data should be [{value,label},...]
						defaultData[key] = [];
						//loop data key
						data[key].forEach((rec, index) => {
							const record = {
								value: rec,
								label: data[dataMap[key].label][index]
							};
							defaultData[key].push(record);
						});
					} else if (dataMap[key].onlyOne) {
						defaultData[key] = data[dataMap[key].onlyOne];
					} else {
						//If not data should be {value,label}
						defaultData[key] = {};
						defaultData[key].value = data[dataMap[key].value];
						defaultData[key].label = data[dataMap[key].label];
					}
				}				
			} else {
				defaultData[key] = data[dataMap[key]];
			}
		});
		return defaultData;
	};

	const toggleDrawer = (open, child, props, dataIndex) => event => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		let children = null;

		if (child === "GridDrawerGrid") {
			children = <GridDrawerGrid gridParams={gridParams} gridHook={gridHook} actionMap={actionMap} />;
		} else if (child === "GridDrawerForm") {
			children = <GridDrawerForm locale={localeSettings} formData={props} formFields={formFields} setDrawerState={setDrawerState} />;
		} else if (child === "Form") {
			//default data
			const defaultData = mapDefaultData(props);
			children = <FormFrame 
				defaultData={defaultData}
				type={formProps.formType.toLowerCase()}
				action={EDIT}
				recId={0}
				refId={0}
				custom={true}
				customSetDrawerOpen={setDrawerState}
				wrapSubmit={(cbs, type) => wrapSubmit(cbs, dataIndex)}
				setDrawerAction={() => null}
			/>;
		}

		setDrawerState({ open, children, child });
	};

	const removeFromList = recId => () => {
		const updatedValue = (value || []).filter(val => val.recId !== recId);
		onChange(updatedValue);
	};	

	const endText = <React.Fragment>
		{ !viewOnly && showAddLink ? 
			<p>Please browse to select {listTitle}.</p>
			: null
		}
	</React.Fragment>;

	return ( 
		<div>
			{ !viewOnly && showAddLink ?
				<Button className={classes.browseButton} onClick={toggleDrawer(true, "GridDrawerGrid")}>Browse {listTitle}{showAsrequired ? "*" : null}</Button>
				: null
			}
			<Drawer
				classes={{
					paper: classNames(classes.drawerPaper, "lg")
				}}
				className={classes.drawer}
				anchor="right"
				open={drawerState.open}
				onClose={toggleDrawer(false)}
				disableEnforceFocus
				variant="temporary"
			>
				<Paper
					className={classNames(drawerState.child === "Form" ? "drawerData" : classes.gridComponent)} 
					style={{
						// make room for margin/tab and navbar
						height: (windowSize.height || 300) - 180 }}
					elevation={drawerState.child === "Form" ? 0 : 1}
				>
					{ drawerState.children }
				</Paper>
			</Drawer>
			{value && value.length ?
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableHead} align="left" style={{ width: 12 }}></TableCell>
							{listColumns.filter(column => !column.hidden).map((column, i) => (
								<TableCell
									key={`${column.name}_${i}`}
									align="left"
									style={{ minWidth: column.minWidth }}
									className={classes.tableHead}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{(value || []).map((rec, index) => {
							const modifierButtons = <React.Fragment>
								{modifyRecords ?
									<Button className={classes.tableButton} onClick={toggleDrawer(true, modifyRecordsType, rec, index)}>
										<EditIcon />
									</Button>
									: null
								}
								{removeRecords ?
									<Button className={classes.tableButton} onClick={removeFromList(rec.recId)}>
										<DeleteIcon />
									</Button>
									: null
								}
							</React.Fragment>;
							return (
								<TableRow key={rec.recId}>
									<TableCell key={`buttonRow_${rec.recId}`} align="left">
										<div className={classes.tableButtonCell}>
											{
												!viewOnly ? modifierButtons : null
											}
										</div>
									</TableCell>
									{listColumns.filter(column => !column.hidden).map(column => {
										return (
											<TableCell key={column.name} align="left">
												{prepareDataForDisplay(rec[column.name], localeSettings, classes, viewOnly)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				: endText
			}
			<FormHelperText
				error={error}
				className={classes.helperText}
			>
				{errorMsg}
			</FormHelperText>
		</div>
	);
}

const prepareDataForDisplay = (input, localeSettings, classes, viewOnly) => {
	if (typeof input === "boolean") {
		return input ? <Done displayvalue="Yes"/> : <Clear displayvalue="No"/>;
	} else if (Array.isArray(input)) {
		return <> {
			input.filter(val => val !== null && val !== undefined)
				.map((elem, index) => <Chip key={`${elem}_${index}`} disabled={viewOnly === true} label={elem} className={classNames("chip", classes.chipMargin)} component="span" />)
		} </>;
	} else if (moment(input, moment.ISO_8601, true).isValid() && localeSettings.timeZone) {
		return moment(input).tz(localeSettings.timeZone).format("L");
	}
	return input;
};

const mapStateToProps = state => {
	return {
		refId: state.refId,
		server: state.server,
		gridHook: state.gridHook,
		formType: state.formType,
		localeSettings: state.localeSettings
	};
};

const mapDispatchToProps = {};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Grid)
);
