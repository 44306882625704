import { extractInfo } from "../form";
import isLimitExceeded from "./isLimitExceeded";

const updateFilesSelected = crm => async info => {
	const { selected, update, dispatchWrapper, field, getState, multiFile } = info;
	const state = getState();
	const { updateField } = extractInfo(info);

	const files = state.fields[field.name].files || [];

	//We don't want to add duplicates, so we check and see if it already exists in the field.files field. If it does and it was deleted, just undelete it,
	// otherwise just ignore.
	selected.forEach(f => {
		let exists = files.filter(file => file.damId === f.AssetID);
		if (exists.length) {
			exists = exists[0];
			if (exists.toBeDeleted) {
				exists.toBeDeleted = false;
			}
		} else {
			const sortOrder = f.AccountSortOrder || f.SortOrder || 0;
			files.push({ name: f.Title, damId: f.AssetID, url: f.AssetPath, MediaID: f.MediaID, MediaType: f.MediaType, AccountSortOrder: sortOrder });
		}
	});

	if (files.length) {
		files.sort((a, b) => {
			const aSort = a.AccountSortOrder || a.SortOrder || 0;
			const bSort = b.AccountSortOrder || b.SortOrder || 0;

			if (aSort < bSort) {
				return -1;
			}
			return 1;
		});
	}

	updateField({
		files
	});

	if (field?.subscriptions.some(subscription => subscription.name === "isLimitExceeded")) {
		info.subscriber = field.name;
		info.fileCount = files.filter(asset => asset.toBeDeleted !== true).length;
		info.fileLimit = field.limit || 0;
		isLimitExceeded(crm)(info);
	}

	//handleFormChange for updating the selected values, given the options updated above in the field.files object.
	dispatchWrapper("handleFormChange", [field.name, `File${multiFile ? "s" : ""}`, multiFile ?
		[...state.data[field.name], ...selected.map(f => ({ name: f.Title, damId: f.AssetID, url: f.AssetPath, MediaID: f.MediaID, AccountSortOrder: f.AccountSortOrder || 0 }))]
		: [...selected.map(f => ({ name: f.Title, damId: f.AssetID, url: f.AssetPath, MediaID: f.MediaID, AccountSortOrder: f.AccountSortOrder || 0 }))]]);
};

export default updateFilesSelected;
