import { extractInfo } from "../form";

const getAccountRelation = crm => async info => {
	const { getState } = info;
	const { data } = getState();
	const { updateField } = extractInfo(info);

	const { ParentAccountID } = data;
	const res = await crm.get({
		type: "accountRelation",
		filter: {
			ParentAccountID
		},
		fields: `
			AccountID
			CompanyName
			
		`
	});
	if (res.success && res.count) {
		const options = res.recs.map(rec => ({ label: rec.CompanyName, value: rec.AccountID }));
		updateField({ options });
	} else {
		updateField({ options: [] });
	}

};

export default getAccountRelation;
