import { AxiosInstance } from "axios";

import query from "../query";

class FormPrefix {
	name: string;
	private _graphUrl: string;
	private _graphServer: any;
	private _ax: AxiosInstance;
	constructor({ graphUrl, graphServer, ax }: { graphUrl: string, graphServer: object, ax: AxiosInstance }) {
		this.name = "form";
		this._graphUrl = graphUrl;
		this._graphServer = graphServer;
		this._ax = ax;
	}

	async fetchCoreFormWithData(module: string, action: string, id: number) {
		const context = this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			productName: context.productNameNoEnv,
			id,
			action
		};

		const resolver: string = `${module}_form`;
		const response = await query({
			ax: this._ax,
			query: `
				query ${resolver}(
					$acct_id: String!,
					$productName: String,
					$action: String,
					$id: Int
				) {
					crm(acct_id: $acct_id, productName: $productName) {
						${resolver}(action: $action, id: $id)
					}
				}
			`,
			token: context.token,
			type: resolver,
			url: this._graphUrl,
			variables
		});

		return response.crm[resolver];
		return {};
	}
}

export default FormPrefix;
