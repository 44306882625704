import { AxiosInstance } from "axios";
import query from "../query";
import { settingString, buildDataFilters, buildDataString } from "./strings";
import { columdnDef, filterDef } from "./sharedDefs";

interface parentDef {
	type: string;
	ID: number;
};

interface pagingInfoDef {
	limit: number;
	page: number;
	orderBy: string;
	direction: "ASC" | "DESC";
	skip: boolean;
};

interface variableSettingDef {
	acct_id: string;
	productName: string;
	type: string;
	parent?: parentDef;
	pagingInfo?: pagingInfoDef;
	view?: settingViewDef
};

interface variableDataDef {
	acct_id: string;
	productName: string;
	type: string;
	parent?: parentDef;
	pagingInfo?: pagingInfoDef;
	filterInfo?: any;
};

interface viewDef {
	columns: columdnDef[];
	filters: filterDef[];
};

interface sortInfo {
	field: string;
	order: string;
};

interface primaryFiltersInfo {
	name: string;
	operation: string;
	dataType: string;
	value: any;
};

interface settingViewDef {
	activeFilters: any[];
	filters: any[];
	id?: string;
	limit: number;
	name: string;
	primaryFilters?: primaryFiltersInfo[];
	sortedColumns: sortInfo[];
	visibleColumns: string[];
};

export default class GridPrefix {
	name: string;
	private _graphUrl: string;
	private _graphServer: any;
	private _ax: AxiosInstance;
	constructor({ graphUrl, graphServer, ax }: { graphUrl: string, graphServer: object, ax: AxiosInstance }) {
		this.name = "get_grid";
		this._graphUrl = graphUrl;
		this._graphServer = graphServer;
		this._ax = ax;
	}

	async fetchGridSettings(context: any, type: string, parent: parentDef | undefined, view: settingViewDef | undefined ) {
		context = context || this._graphServer.context;
		const variables: variableSettingDef = {
			acct_id: context.acct_id,
			productName: context.productName,
			type: type
		};
		if (parent && parent.type) {
			variables.parent = {
				type: parent.type,
				ID: parent.ID
			};
		}
		if (view) {
			variables.view = view;
			variables.view.filters = variables.view.filters.map((f:any) => {
				return JSON.stringify(f);
			});

		}
		const queryStr = `
			query get_grid(
				$acct_id: String!,
				$type: crm_type_enum!,
				$parent: crm_grid_parent,
				$productName: String
				$view: crm_grid_incoming_view
			) {
				crm(acct_id: $acct_id, productName: $productName) {
					get_grid(
						type: $type,
						parent: $parent,
						view: $view
					) {
						${settingString}
					}
				}
			}
		`;
	
		const response = await query({
			ax: this._ax,
			variables,
			query: queryStr,
			url: this._graphUrl,
			token: context.token,
			type: "get_grid"
		});

		return response.crm.get_grid;
	}

	async fetchGridData(context: any, type: string, parent: parentDef | undefined, view: viewDef, pagingOptions: pagingInfoDef ) {
		context = context || this._graphServer.context;
		const variables: variableDataDef = {
			acct_id: context.acct_id,
			productName: context.productName,
			type: type
		};
		if (parent && parent.type) {
			variables.parent = {
				type: parent.type,
				ID: parent.ID
			};
		}
		if (pagingOptions) {
			variables.pagingInfo = {
				limit: pagingOptions.limit, 
				page: pagingOptions.page,
				orderBy: pagingOptions.orderBy,
				direction: pagingOptions.direction,
				skip: pagingOptions.skip
			};
		}
		if (view && view.filters) {
			variables.filterInfo = buildDataFilters(view.filters);
		}
		const queryStr = `
			query get_grid(
				$acct_id: String!,
				$pagingInfo: crm_grid_paging,
				$type: crm_type_enum!,
				$parent: crm_grid_parent,
				$productName: String,
				$filterInfo: crm_${type}_grid_input
			) {
				crm(acct_id: $acct_id, productName: $productName) {
					get_grid(
						type: $type,
						parent: $parent,
						pagingInfo: $pagingInfo
						${type}: $filterInfo
					) {
						${buildDataString(type, view.columns)}
						count
					}
				}
			}
		`;
	
		try {
			const response = await query({
				ax: this._ax,
				variables,
				query: queryStr,
				url: this._graphUrl,
				token: context.token,
				type: "get_grid"
			});

			return response.crm.get_grid;
		}
		catch(e: any) {
			return {
				success: false,
				message: e.message
			};
		}
		
		return;
	}
}
