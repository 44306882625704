// 3rd party
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
// SV
import RenderForm from "@react-ui/components/Form";
// DMS
import styles from "./styles";
import { Creators as FormCreators } from "../../../actions/recordActions";
import CircularProgressCentered from "../../CircularProgressCentered/index";
import callbacks from "../../../common/callbacks/index";
import customComps from "../CustomFields";
import { ADD } from "../../../common/consts";
import initForm from "./initForm";

import store from "../../../store";
import useTransientElements from "../../../hooks/useTransientElements";
import { useDispatch } from "react-redux";
import useFetchGrid from "../../Grid/useFetchGrid";

const { getState } = store;

const FormFrame = props => {
	const dispatch = useDispatch();
	const logSubmit = props.logSubmit || ((moduleName) => dispatch(FormCreators.logUpdate(moduleName)));
	const { setDrawerOpen } = useTransientElements();
	const [formState, updateFormState] = useState({});
	const [isLoading, updateLoading] = useState(true);
	const [cbs, updateCbs] = useState({});
	const {
		type,
		refId,
		wrapSubmit,
		afterSubmit,
		customSetDrawerOpen,
		title: overwriteTitle,
		viewOnly
	} = props;

	let { formTitle, actions, recId, layout } = formState;

	const { BASE_URL, GOOGLE_MAPS_DEFAULT_CLIENTID } = process.env;

	const { crmGraphServer, damClient, acctId, productName } = getState().login;

	const { kbLinks } = getState().header;

	useEffect(() => {
		const { type, recId, action, refId, PendingID, defaultData } = props;

		initForm({ updateLoading, type, recId, action, refId, PendingID }).then(res => {
			if (res) {
				updateFormState({
					...res,
					defaultData: {
						...res.defaultData,
						...defaultData
					}
				});
				updateLoading(false);

				if (res.loadGoogleMaps === true && !window.google) {
					const script = document.createElement("script");
					script.type = "text/javascript";
					script.src = `https://maps.googleapis.com/maps/api/js?client=${GOOGLE_MAPS_DEFAULT_CLIENTID}&libraries=places`;

					const node = document.getElementsByTagName("script")[0];
					node.parentNode.insertBefore(script, node);
				}
			}
		}).catch(err => updateLoading(false));

		const cbs = { ...callbacks };

		Object.keys(cbs).forEach(cb => {
			cbs[cb] = cbs[cb](crmGraphServer.general);
		});
		updateCbs({ ...cbs });

		return function cleanup() {
			// cleanup any artifacts from forms like pac containers
			document.querySelectorAll(".pac-container").forEach(a => {
				a.remove();
			});
		};
	}, [props.reMount]);

	if (layout?.sections?.length && !isLoading) {
		const titlePreface = `${formState.formLabel || type} Detail:`;
		const title = overwriteTitle || (formTitle && `${titlePreface} ${formTitle}`) || "";
		const typeArray = type.split(".");
		const kbLink = typeArray.length ? kbLinks[typeArray[typeArray.length - 1]] : null;
		if (actions && props.custom) {
			actions = actions.filter(act => act.label !== "save & new");
			actions = actions.map(act => {
				// TODO: use action type instead of label
				if (act.label === "cancel") {
					return { ...act, callback: () => {
						if (customSetDrawerOpen) {
							customSetDrawerOpen(false);
						} else {
							setDrawerOpen(false);
						}
					}
					};
				}
				if (act.label === "save" && wrapSubmit) {
					return { ...act, callback: wrapSubmit(cbs["submit"], "submit"), type: "submit" };
				}
				if (act.label === "apply to form" && wrapSubmit) {
					return { ...act, callback: wrapSubmit(cbs[`${act.callback}`], "apply"), type: "submit" };
				}
			});
		}


		const actionMap = actions ? actions.map(action => ({
			label: action.label,
			type: action.type || action.callback,
			callback: action.callback,
			args: ["submit", "handleApproval", "handleDenial"].includes(action.callback) || action.type === "submit" ? {
				type,
				recId,
				action,
				refId,
				validate: true,
				logSubmit,
				andNew: !!(action.args && action.args.includes("andNew")),
				setPending: !!(action.args && action.args.includes("setPending")),
				setDrawerOpen,
				afterSubmit,
			} : { setDrawerOpen },
			color: action.color || "primary",
			subscriptions: action.subscriptions
		})) : [];

		return (
			<RenderForm
				title={title}
				formType={type}
				defaultTitle={`${titlePreface} ${ADD}`}
				callbacks={cbs}
				customComponents={customComps}
				refId={refId}
				localeSettings={{
					prefix: "$",
					suffix: "",
					thousandSeparator: ",",
					decimalSeparator: ".",
					decimalScale: 2,
					timeZone: getState()?.header?.tenantSettings?.timeZone
				}}
				{ ...formState }
				actions={actionMap}
				CustUploadAdapter={CustUploadAdapter}
				server={crmGraphServer.general}
				damClient={damClient}
				baseUrl={BASE_URL}
				acctId={acctId}
				gridHook={useFetchGrid}
				viewOnly={viewOnly}
				helpTextLink={kbLink}
				hideHelp={productName !== "dms"}
				fieldSize={getState()?.header?.FieldSize || "normal"}
			/>
		);
	} else {
		return <CircularProgressCentered />;
	}
};


export class CustUploadAdapter {
	constructor( loader, enableSubmit ) {
		this.loader = loader;
		this.enableSubmit = enableSubmit;
	}

	upload() {
		return this.loader.file
			.then( file => new Promise( ( resolve, reject ) => {
				this._initRequest();
				this._initListeners( resolve, reject, file );
				this._sendRequest( file );
			} ) );
	}

	abort() {
		if ( this.xhr ) {
			this.xhr.abort();
		}
	}

	convertFileToFileObject = async function(file) {
		if ( typeof file !== "object" ) {
			return new Error("{File} is not an object.");
		}
		if ( file.size === undefined || file.name === undefined ) {
			return new Error("{File} is not a valid file.");
		}
	
		const reader = new FileReader();
		const returnObject = {
			name: file.name,
			length: file.size.toString(),
			dataFormat: "base64",
			data: []
		};
	
		return new Promise((resolve, reject) => {
			reader.onerror = () => {
				reader.abort();
				reject(new Error("Unable to parse file data."));
			};
	
			reader.onload = () => {
				returnObject.data[0] = reader.result;
				resolve(returnObject);
			};
	
			reader.readAsDataURL(file);
		});
	};

	// Initializes the XMLHttpRequest object using the URL passed to the constructor.
	_initRequest() {
		const xhr = this.xhr = new XMLHttpRequest();
		xhr.open( "POST", "/dam-graphql", true );
		xhr.responseType = "json";
	}

	// Initializes XMLHttpRequest listeners.
	_initListeners( resolve, reject, file ) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = `Couldn't upload file: ${ file.name }.`;

		xhr.addEventListener( "error", () => {
			this.enableSubmit(true);
			reject( genericErrorText ); 
		} );
		xhr.addEventListener( "abort", () => {
			this.enableSubmit(true);
			reject();
		} );
		xhr.addEventListener( "load", () => {
			this.enableSubmit(true);
			const response = xhr.response;
			if ( !response.success ) {
				return reject( genericErrorText );
			}

			resolve( {
				default: response.doc.asset_url
			} );
		} );

		if ( xhr.upload ) {
			xhr.upload.addEventListener( "progress", evt => {
				if ( evt.lengthComputable ) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			} );
		}
	}

	// Prepares the data and sends the request.
	_sendRequest( file ) {
		this.convertFileToFileObject(file).then(res => {
			// add token with XMLHttpRequest.setRequestHeader()
			const { acct_id: acctId, token } = getState().login.crmGraphServer.general._graphServer.context;
			this.xhr.setRequestHeader("Authorization", `Bearer ${token}`);
			this.xhr.setRequestHeader("Content-Type", "application/json");

			const query = {
				fileObject: res,
				acct_id: acctId,
				private: false
			};

			this.xhr.send( JSON.stringify(query) );
			this.enableSubmit(false);
		});
	}
}

export default withStyles(styles)(FormFrame);
