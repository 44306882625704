import { extractInfo } from "../form";

/**
 * staticArgs:
 * 0 - fieldPropname: String
 *     prop name
 * 1 - toSet: any
 *     value to set prop to
 */    
 
const setReferralEnabled = crm => info => {
	const { updateField, staticArgs = [] } = extractInfo(info);
	const [attrName, toSet] = staticArgs;

	const update = (value) => {
		updateField({
			[attrName]: value
		});
	};

	update(toSet);
};

export default setReferralEnabled;
