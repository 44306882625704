import { ERROR_TYPES } from "../consts";
const { IS_REQUIRED } = ERROR_TYPES;

const isFormBuilderRequired = ({ fieldName, compField, compIndex, dispatchWrapper, getState, subscriber }) => {
	const { data: { config: { fields = [], requiredFields = [] } } } = getState();
	const fieldParts = [fieldName, compField, compIndex];
	
	if (requiredFields.length) {
		const formFields = fields.map(f => f.name); 
		const missingFields = [];

		requiredFields.forEach(f => {
			if (!formFields.includes(f.name)) {
				missingFields.push(f.label);
			}
		});
		if (missingFields.length) {
			dispatchWrapper("setValidation", [...fieldParts, IS_REQUIRED, "field is required"]);
			dispatchWrapper("setDialog", [true, "", `The following fields are missing from the form and are required\n${missingFields.join("\r\n")} `]);
		} else {
			dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);			
		}
	} else {
		dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);
	}
	
};

export default isFormBuilderRequired;
