import { extractInfo } from "../form";
import store from "../../store";
import { Creators } from "../../actions/transientElementActions";
import { GLOBAL_TABBED_DRAWER } from "../consts";

const { dispatch } = store;


const getContactEmail = crm => info => {
	const { data, updateValue, isWebForm } = extractInfo(info);

	if (!data.ContactID || (data.ContactID && data.ContactID.length === 0) || (data.ContactID && data.ContactID.length > 0 && data.ContactID[0].value === undefined)) {
		return;
	}

	//When you click on the email link in the summary it passes in an array, but if you click
	// to Add New communication, it just passes in the id.
	const contactID = data.ContactID.length ? data.ContactID[0].value : data.ContactID.value;

	crm.get({
		type: "contact",
		filter: {
			recId: contactID
		},
		fields: "PrimaryEmailAddress"
	}).then(res => {
		if (res.success && res.recs[0]?.PrimaryEmailAddress) {
			updateValue(res.recs[0].PrimaryEmailAddress);
		} else {
			const message = "An email was not Sent. You don't have permission to send email to this contact or this contact does not have an email.";
			if (isWebForm) {
				alert(message);
			} else {
				dispatch(Creators.setMessage(message, "warning"));
			}
			dispatch(Creators.setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false }));
		}
	});
};

export default getContactEmail;
