import React, { useState } from "react";
import { Add } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import { ADD, APPLY } from "../../../common/consts";
import FormFrame from "../FormFrame";
import { TabbedDrawer } from "../../TabbedDrawer";

const LinkedForm = props => {
	const { field: { formType, formAction = "Add New", formLabel, readOnly, refId, defaultData = "{}" }, onChange, viewOnly } = props;
	const [isOpen, updateOpen] = useState(false);
	const openForm = isOpen => () => {
		updateOpen(isOpen);
	};
	const afterSubmit = () => {
		updateOpen(false);
	};

	const displayComponent = (FFrame, props) => extraProps => <FFrame
		{ ...props }
		{ ...extraProps }
	/>;

	const activeTab = `${formAction} ${formType}`;
	const tabs = [{
		name: activeTab
	}];

	const drawerProps = {
		tabContentFetching: false,
		tabs,
		activeTab,
		isOpen,
		displaySize: "lg",
		displayComponent: displayComponent(FormFrame, { defaultData: JSON.parse(defaultData), type: formType.toLowerCase(), action: ADD, recId: 0, refId, afterSubmit, custom: true }),
		type: formType.toLowerCase(),
		setDrawerOpen: openForm,
		setTab: () => null,
		setDrawerAction: () => null,
		extraProps: {
			wrapSubmit: (cbs, type) => info => {
				if (type === APPLY) {
					afterSubmit();
					const data = cbs(info);
					onChange(data);
				} else {
					cbs(info).then(res => {
						onChange(res[Object.keys(res)[0]].recId);
					});
				}
			},
			customSetDrawerOpen: open => updateOpen(open),
			setDrawerAction: () => null
		}

	};

	return (
		<>
			{ (!readOnly && !viewOnly) && <Button color="primary" className="deemphasized withIcon" size="small" onClick={openForm(true)}>
				<Add /> {formLabel || `Create New ${formType}`}
			</Button> }
			<TabbedDrawer { ...drawerProps } />
		</>
	);
};

export default LinkedForm;
