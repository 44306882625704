const defaultAccountGroups = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { refId } = getState();

	if (refId) {
		crm.get({
			type: "account",
			filter: {
				recId: refId
			},
			fields: `
                Groups {
                    recs {
                        GroupID
                        DisplayNm
                        hasPermissions
                    }
                }
			`
		}).then(response => {
            const groups = response.recs[0].Groups.recs;
            const options = [];
			if (groups.length) {
				for (const group of groups) {
                    options.push({
                        value: group.GroupID,
                        label: group.DisplayNm,
                        hasPermissions: group.hasPermissions,
                        sortOrder: null,
                        valueString: null
                    });
                }
			}
            dispatchWrapper("handleFormChange", [subscriber, "Int", options]);
		});
	}
};

export default defaultAccountGroups;