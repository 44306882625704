import {
	CleanedUpArgs,
	FieldSubscriptionCallbackArgs,
	FormFieldValue,
	FormFieldMetadata,
	FormSelectOption,
	FormCallbackArgs,
	CompositeFieldValue,
	AmenityFieldObjectValue,
} from "./callbacks/typeDef";
import {
	ACCOUNT_CONTACT_REL,
	MEDIA,
	GLOBAL_DIALOG,
	GLOBAL_SNACKBAR,
	GLOBAL_TABBED_DRAWER,
	EDIT,
	PREFERENCE,
} from "./consts";
import clean from "./clean";
import trimStrings from "./trimStrings";
import { cloneDeep as clone, uniqBy } from "lodash";
import store from "../store";
import { wrapValue, FORMAT } from "./format";
import { getModuleName } from "./moduleData";
import moment, { MomentInput } from "moment";
import FormFrame from "../components/Form/FormFrame";
import React from "react";
import { setRecentlyViewed } from "./recentlyViewed";

const { getState: uiState } = store;

const getCompFieldNames = (fieldName: string): [string | undefined, string] => {
	if (fieldName && fieldName.startsWith("$comp")) {
		const splits = fieldName.split("_");
		const compositeField = splits[1];
		const ownFieldName = splits[2];
		return [compositeField, ownFieldName];
	}
	return [undefined, fieldName];
};

interface GetValueArgs {
	data?: Record<string, FormFieldValue>;
	getState?: () => {
		data: Record<string, FormFieldValue>;
	};
	compField?: string;
	fieldName: string;
	compIndex?: number;
}

export const getValue = ({
	getState,
	compField,
	data,
	fieldName,
	compIndex: index,
}: GetValueArgs): FormFieldValue => {
	if (!data && getState) {
		data = getState().data;
	}
	if (!data) {
		throw new Error(
			"data is not specified, please provide either getState or data through args"
		);
	}
	let ownField = fieldName;
	if (!compField) {
		[compField, ownField] = getCompFieldNames(fieldName);
	}

	let value = compField
		? (data[compField] as CompositeFieldValue)
		: data[ownField];

	if (
		index !== undefined &&
		Number.isInteger(index) &&
		Array.isArray((value as CompositeFieldValue)?.compositeFields)
	) {
		value = (value as CompositeFieldValue).compositeFields[index][ownField];
	}

	return value;
};

/**
 * process args of callback, and return understandable helpers/values
 *
 * the return object should contain these key-value pairs:
 * data: data object from form state
 * fields: fields object from form state
 * subValue: value of the subscribed field
 * subFieldName: field name of the subscribed field
 * subField: field object of the subscribed field
 * ownValue: value of the subscriber field
 * ownFieldName: field name of the subscriber field
 * ownField: field object of the subscriber field
 *
 * (update helpers will handle both regular field and composite field)
 * updateField: helper function to update the ownField
 * updateValue: helper function to update the ownValue
 *
 * staticArgs: callback args from formDef
 * value: special occasion value, for example, on creatable callback, the value
 *        of the creatable.
 * submit: a boolean flag indicate whether the form has been submitted.
 *
 * addOptionsToField: append the options to the current field, if want to replace options, can use updateField instead to replace options.
 */
export const extractInfo = ({
	getState,
	fieldName,
	subscriber,
	compField,
	compIndex,
	dispatchWrapper,
	staticArgs,
	value,
}: FieldSubscriptionCallbackArgs): CleanedUpArgs => {
	const { data, fields, recId, submit, isWebForm, damServer } = getState();
	const subValue = getValue({ data, compField, fieldName, compIndex });
	const subField = compField
		? fields[`$comp_${compField}_${fieldName}`]
		: fields[fieldName];
	const ownValue = getValue({ data, fieldName: subscriber, compIndex });
	const [compositeField, ownFieldName] = getCompFieldNames(subscriber);
	// const ownField = compositeField ? fields[subscriber].index[compIndex] : fields[subscriber];
	const ownField = fields[subscriber];
	const ownIndex =
		compositeField && compIndex !== undefined
			? ownField.index[compIndex]
			: undefined;
	const updateField = (
		field: FormFieldMetadata,
		affectAll?: boolean
	): void => {
		if (affectAll) {
			for (let i = 0; i < ownField.index.length; i++) {
				ownField.index[i] = { ...ownField.index[i], ...field };
			}
			dispatchWrapper("updateField", [
				subscriber,
				{
					index: ownField.index,
				},
			]);
		} else if (!compositeField || compIndex === undefined) {
			dispatchWrapper("updateField", [subscriber, field]);
		} else {
			ownField.index[compIndex] = { ...ownIndex, ...field };
			dispatchWrapper("updateField", [
				subscriber,
				{
					index: ownField.index,
				},
			]);
		}
	};

	const updateEntireCompositeField = (field: FormFieldMetadata): void => {
		if (compIndex !== undefined && compositeField) {
			//Find all composites of this one
			for (const fieldKey in fields) {
				if (fields[fieldKey].parent === compositeField) {
					fields[fieldKey].index[compIndex] = {
						...fields[fieldKey].index[compIndex],
						...field,
					};
					dispatchWrapper("updateField", [
						fields[fieldKey].name,
						{
							index: fields[fieldKey].index,
						},
					]);
				}
			}
			if (field.readOnly) {
				const unableToDelete =
					fields[compositeField].unableToDelete || [];
				unableToDelete.push(compIndex);
				dispatchWrapper("updateField", [
					compositeField,
					{ unableToDelete },
				]);
			}
		}
	};

	const updateValue = (value: FormFieldValue, altIndex: number): void => {
		if (compositeField) {
			dispatchWrapper("handleFormChange", [
				ownFieldName,
				ownField.dataType,
				value,
				typeof altIndex === "number" ? altIndex : compIndex,
				compositeField,
			]);
		} else {
			dispatchWrapper("handleFormChange", [
				ownFieldName,
				ownField.dataType,
				value,
			]);
		}
	};

	// a better version of addOptionsToField
	const addOptionsToField = (options: FormSelectOption[]): void => {
		const field = ownIndex || ownField;
		const curOptions = field.options || [];
		let newOptions = curOptions.concat(options);

		if (curOptions.length > 0) {
			newOptions = uniqBy(newOptions, "value");
		}

		if (newOptions.length !== curOptions.length) {
			updateField({
				options: newOptions,
			});
		}
	};

	const setError = (type: string, message: string): void => {
		if (!compositeField) {
			dispatchWrapper("setValidation", [
				subscriber,
				compField,
				compIndex,
				type,
				message,
			]);
		} else {
			dispatchWrapper("setValidation", [
				ownFieldName,
				compositeField,
				compIndex,
				type,
				message,
			]);
		}
	};

	const clearError = (type: string): void => {
		if (!compositeField) {
			dispatchWrapper("clearValidation", [
				subscriber,
				compField,
				compIndex,
				type,
			]);
		} else {
			dispatchWrapper("clearValidation", [
				ownFieldName,
				compositeField,
				compIndex,
				type,
			]);
		}
	};

	return {
		isWebForm,
		data,
		value,
		fields,
		recId,
		subValue,
		subField,
		subCompField: compField,
		subCompIndex: compIndex,
		ownValue,
		ownField,
		ownIndex,
		updateField,
		updateValue,
		updateEntireCompositeField,
		ownFieldName,
		compositeField,
		staticArgs,
		subFieldName: fieldName,
		submit,
		addOptionsToField,
		setError,
		clearError,
		damServer,
	};
};

const ensureDateString = (
	value: MomentInput | moment.Moment | string,
	dataType: string
): string => {
	let dateString: string;
	const format =
		dataType === "Date" ? "YYYY-MM-DDT00:00:00Z" : "YYYY-MM-DDTHH:mm:ssZ";
	if (moment.isMoment(value)) {
		dateString = value.format(format);
	} else if (dataType === "Date") {
		dateString = moment(value).format(format);
	} else {
		dateString = value as string;
	}

	return dateString;
};

interface HandleSubmitArgs extends FormCallbackArgs {
	data: Record<string, FormFieldValue>;
	crm: Record<string, any>;
	captchaToken?: string;
	form_id?: string;
	submitDate?: Date;
}

export const handleSubmit = ({
	getState,
	crm,
	review = {},
	type,
	data,
	refId = 0,
	andNew = false,
	setPending = false,
	dispatchWrapper,
	afterSubmit,
	logSubmit,
	setDrawerOpen,
	approveEdit = false,
	isWebSubmission = false,
	captchaToken,
	form_id,
	submitDate,
}: HandleSubmitArgs): any => {
	const state = getState();
	const { recId, fields } = state;
	if (!data) {
		data = state.data;
	}
	dispatchWrapper("enableSubmit", [false]);
	// for data like note
	const typeArray = type.split(".");
	let formType = typeArray.length === 1 ? type : typeArray[1];
	if (review.approved || review.denied) {
		formType = `pending_${formType}_response`;
	}

	// TODO remove this stuff once this is fixed
	// hacks because we're not consistent right now with resolver returns
	// should probably be all returning arrays
	const refExceptions = [
		"task",
		"account",
		"contact",
		"listing",
		"eventCalendar",
		"inquiry",
		"offer",
		ACCOUNT_CONTACT_REL,
		"relatedAccount",
		"comm",
		"referral",
		"template",
		"pending_offer",
		"pending_listing_response",
		"pending_eventCalendar_response",
		"pending_offer_response",
	];
	const usesRef =
		typeArray.length > 1 &&
		!refExceptions.includes(formType) &&
		!type.includes("dropdown");

	const excludesSuccessMessageRtns = ["accountContactRel", "engagementType"];

	let reqFields =
		"success message recId snackbarMessage snackbarDisplayType snackbarLinkType";

	if (isWebSubmission) {
		reqFields += " RecordID";
	}

	const subType = type.indexOf(".") > -1 ? type.split(".")[1] : type;

	if (
		uiState().summary.hasRecordID.includes(formType) ||
		review.approved ||
		review.denied
	) {
		reqFields += " RecordID";
	}

	const resolverName = ["contact.comm", "account.comm"].includes(type)
		? type.replace(".", "_")
		: formType;

	const queryString = `
		mutation set${formType}($acct_id: String!, $input: crm_${formType}_input, $productName: String) {
			crm(acct_id: $acct_id, productName: $productName) {
				set_${resolverName}(input: $input) ${
		excludesSuccessMessageRtns.includes(formType) ? "" : `{${reqFields}}`
	}
			}
		}
	`;

	const variables: Record<string, any> = {
		input: {},
	};

	if (setPending) {
		variables.input.SetPending = true;
	}

	if (isWebSubmission === true) {
		variables.input.isWebSubmission = isWebSubmission;
	}

	const generalFormCalls = ["listing_amenity", "listing_attribute"];
	if (!generalFormCalls.includes(formType)) {
		if (usesRef) {
			variables.input.refId = Number(refId);
			variables.input.type = typeArray[0];
		}

		if (recId) {
			variables.input.recId = Number(recId);
		}

		// Set initial Customfield Array
		variables.input.CustomField = [];

		// Set initial Connection Array
		variables.input.Connection = [];

		// Set initial Additional Customfield Array
		variables.input.AdditionalCustomField = [];

		// let formData = { ...data };

		let formData = clone(data);
		// TODO: trimStrings should be on the graph side
		formData = trimStrings(clean(formData));

		// RevisionStatusID are in dms schema, so shouldn't change
		if (review.approved === true) {
			formData.RevisionStatusID = 2;
		} else if (review.denied === true) {
			formData.RevisionStatusID = 3;
		}
		if (formData.RevisionStatusID) {
			variables.input["RevisionStatusID"] = formData["RevisionStatusID"];
		}

		let keys = Object.keys(formData);
		// remove keys marked with noSubmit on field
		keys = keys.filter((key) => fields[key] && !fields[key].noSubmit);

		keys.forEach((key) => {
			if (key === "Phone_v2") {
				if (Array.isArray(formData[key])) {
					for (let record of formData[key]) {
						record.PhoneNumber = record.PhoneNumber?.replace(
							/[ ()-]/g,
							""
						);
					}
				}
			}
			if (fields[key]) {
				// TODO: clean this up into a function, since composite fields uses the same logic
				// convert array of objects back to array of values
				const field = fields[key];
				if (field.displayOnly) {
					// TODO: make it work with composite field
					delete formData[key];
				} else if (
					[
						"Chip",
						"Select",
						"MultiSelect",
						"CountrySelector",
						"AsyncSelect",
						"Tag",
					].includes(field.type)
				) {
					if (Array.isArray(formData[key])) {
						formData[key] = (
							formData[key] as FormSelectOption[]
						).map((val) => val.value);
					} else if (typeof formData[key] === "object") {
						formData[key] = (
							formData[key] as FormSelectOption
						).value;
					}
				}

				if (
					field.dataType === "Date" ||
					field.dataType === "DateTime"
				) {
					formData[key] = ensureDateString(
						formData[key] as Date,
						field.dataType
					);
				}
			}
			const compositeFields =
				formData[key] &&
				(formData[key] as CompositeFieldValue).compositeFields &&
				(formData[key] as CompositeFieldValue).compositeFields.filter(
					(record) => {
						// TODO: use better callback logic to determine whether to send a record to graph.
						if (key === "Phone") {
							if (record.PhoneNumber) {
								record.PhoneNumber =
									record.PhoneNumber.toString().replace(
										/[^0-9a-zA-Z]/g,
										""
									);
							}
							return record.PhoneNumber && record.PhoneTypeID;
						} else if (key === "Email") {
							return record.EmailAddress && record.EmailTypeID;
						}
						return true;
					}
				);
			if (key.indexOf("UDField_") != -1) {
				const idarray = key.split("_");
				const additionalModule =
					idarray.length > 3 ? idarray[3] : formType;
				const inputPlacement =
					additionalModule && additionalModule != formType
						? "Additional"
						: "";
				if (compositeFields) {
					variables.input.CustomField[key] = compositeFields.map(
						(field) => ({
							...field,
							type,
						})
					);
				} else {
					const udfInput: Record<string, any> = {
						UDFieldName: key,
						RecordID: parseInt(idarray[1].replace("neg", "-")),
						UDFType: idarray[2],
						UDFieldValue: JSON.stringify(formData[key]),
					};
					if (inputPlacement.length) {
						udfInput.UDFModule = additionalModule;
					}
					variables.input[`${inputPlacement}CustomField`].push(
						udfInput
					);
				}
			} else if (key.indexOf("Connection_") != -1) {
				const idarray = key.split("_");
				variables.input.Connection.push({
					ConnectionID: parseInt(idarray[1]),
					ConnectionType: idarray[2],
					ConnectionValue: JSON.stringify(formData[key]),
				});
			} else {
				if (compositeFields) {
					compositeFields.forEach((compField) => {
						const compKeys = Object.keys(compField);
						compKeys.forEach((compKey) => {
							const field = fields[`$comp_${key}_${compKey}`];
							if (
								field &&
								[
									"Chip",
									"Select",
									"MultiSelect",
									"CountrySelector",
								].includes(field.type)
							) {
								if (Array.isArray(compField[compKey])) {
									compField[compKey] = (
										compField[compKey] as FormSelectOption[]
									).map((val) => val.value);
								} else {
									compField[compKey] = (
										compField[compKey] as FormSelectOption
									).value;
								}
							}
						});
					});

					variables.input[key] = compositeFields.map((field) => ({
						...field,
						type,
					}));
				} else {
					variables.input[key] = formData[key];
				}
			}
		});

		// Remove CustomField Array if no fields exists
		if (variables.input.CustomField.length == 0) {
			delete variables.input.CustomField;
		}

		if (variables.input.Connection.length == 0) {
			delete variables.input.Connection;
		}

		if (variables.input.AdditionalCustomField.length == 0) {
			delete variables.input.AdditionalCustomField;
		}

		if ((review.approved || review.denied) && variables.input.recId) {
			delete variables.input.recId;
		}
	} else if (formType === "listing_amenity") {
		// if form is listing amenity form
		variables.input.recs = [];
		const dataKeys = Object.keys(data);
		for (const name of dataKeys) {
			if (fields[name].options) {
				if (Array.isArray(data[name]) && (data[name] as any[]).length) {
					for (const item of data[name] as any[]) {
						variables.input.recs.push({
							column: name,
							value: null,
							tenantXrefId: fields[name].tenantXrefId,
							tenantListId: item.value,
							amenityValueId: (
								fields[name].options as any[]
							).filter((o) => o.value === item.value)[0]
								.AmenityValueID,
						});
					}
				} else if (
					Array.isArray(data[name]) &&
					!(data[name] as any[]).length
				) {
					variables.input.recs.push({
						column: name,
						value: null,
						tenantXrefId: fields[name].tenantXrefId,
						tenantListId: -1, // -1 denotes an empty list, but still a list.
						amenityValueId: -1,
					});
				} else if (
					data[name] instanceof Object &&
					(data[name] as AmenityFieldObjectValue)
				) {
					const getValue = (obj: any) => {
						return obj.value;
					};
					variables.input.recs.push({
						column: name,
						value: null,
						tenantXrefId: fields[name].tenantXrefId,
						tenantListId: getValue(data[name]),
						amenityValueId: (fields[name].options as any[]).filter(
							(o) => o.value === getValue(data[name])
						)[0].AmenityValueID,
					});
				} else {
					variables.input.recs.push({
						column: name,
						value: null,
						tenantXrefId: fields[name].tenantXrefId,
						tenantListId: -1, // -1 denotes an empty list, but still a list.
						amenityValueId: -1,
					});
				}
			} else {
				variables.input.recs.push({
					column: name,
					value:
						typeof data[name] === "string" || data[name] === null
							? data[name]
							: fields[name].type === "File"
							? (data[name] as any[])[0].toBeDeleted
								? ""
								: JSON.stringify((data[name] as any[])[0])
							: `${data[name]}`,
					tenantXrefId: fields[name].tenantXrefId,
					amenityValueId: fields[name].id,
				});
			}
		}

		variables.input.recId = Number(recId);
	} else if (formType === "listing_attribute") {
		variables.input.recs = [];
		const dataKeys = Object.keys(data);
		for (const name of dataKeys) {
			if (fields[name].options) {
				if (Array.isArray(data[name]) && (data[name] as any[]).length) {
					variables.input.recs.push({
						column: name,
						listIDs: (data[name] as any[]).map(
							(item: any) => item.value
						),
					});
				} else if (
					data[name] instanceof Object &&
					(data[name] as AmenityFieldObjectValue)
				) {
					if (data[name].value) {
						variables.input.recs.push({
							column: name,
							listIDs: [data[name].value],
						});
					} else {
						variables.input.recs.push({
							column: name,
							listIDs: [],
						});
					}
				} else if (!isNaN(Number(data[name]))) {
					variables.input.recs.push({
						column: name,
						listIDs: [Number(data[name])],
					});
				} else {
					variables.input.recs.push({
						column: name,
						listIDs: [],
					});
				}
			} else {
				variables.input.recs.push({
					column: name,
					value:
						typeof data[name] === "string" || data[name] === null
							? data[name]
							: fields[name].type === "File"
							? (data[name] as any[]).length === 0
								? null
								: JSON.stringify((data[name] as any[])[0])
							: `${data[name]}`,
				});
			}
		}

		variables.input.recId = Number(recId);
	}

	return crm
		.setFormData({
			queryString,
			variables,
			captchaToken,
			// only for web form
			type: formType,
			form_id,
			submitDate,
		})
		.then((res: any) => {
			const response: any = res[`set_${resolverName}`];

			// this occurs with embedded forms
			if (setDrawerOpen === undefined && afterSubmit !== undefined) {
				afterSubmit({ res: response });
				dispatchWrapper("enableSubmit", [true]);
				return;
			}

			dispatchWrapper("flagSubmit");

			const hasSummary = uiState().summary.hasSummary.includes(subType);

			if (response.success) {
				const recordID = response.RecordID
					? response.RecordID
					: response.recId;
				let status = variables.input.recId ? " Updated" : " Created";
				status =
					formType.includes("pending") && !response.recId
						? " denied"
						: status;
				if (!response.snackbarMessage) {
					setDrawerOpen(GLOBAL_SNACKBAR, {
						isOpen: true,
						message: (
							<>
								{hasSummary
									? subType === "myProfile"
										? "Your profile has been"
										: wrapValue(
												`${getModuleName(subType)} ${
													recordID || ""
												}`,
												FORMAT.MODULE_LINK,
												{
													module: subType,
													data: recordID,
												}
										  )
									: getModuleName(subType)}{" "}
								{status}
								{!hasSummary && subType === "comm"
									? ": an email has successfully been sent."
									: ""}{" "}
							</>
						),
						messageType: response.success ? "success" : "error",
						autoHideDuration: hasSummary ? 10000 : 5000,
					});
				}
				if (status === " created" && hasSummary) {
					setRecentlyViewed({
						crmGraphServer: crm,
						type: subType,
						record: {
							...variables.input,
							RecordID: recordID,
						},
					});
				}
			}
			if (
				response.success ||
				(response.success === undefined && !isNaN(response))
			) {
				logSubmit && logSubmit(subType);
				afterSubmit && afterSubmit();

				if (andNew) {
					setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
					const tabs = [{ name: "" }];
					const activeTab = tabs[0].name;

					const displayComponent = (
						<FormFrame
							type={type}
							action={"add"}
							recId={0}
							refId={+refId}
							afterSubmit={afterSubmit}
							reMount={new Date()}
						/>
					);

					setDrawerOpen(GLOBAL_TABBED_DRAWER, {
						isOpen: true,
						type,
						id: 0,
						action: "add",
						tabs,
						activeTab,
						displayComponent,
					});
				} else if (approveEdit) {
					setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
					const tabs = [{ name: "" }];
					const activeTab = tabs[0].name;

					const displayComponent = (
						<FormFrame
							type={type}
							action={EDIT}
							recId={response.recId}
							reMount={true}
						/>
					);

					setDrawerOpen(GLOBAL_TABBED_DRAWER, {
						isOpen: true,
						type,
						id: response.recId,
						action: EDIT,
						tabs,
						activeTab,
						displayComponent,
					});
				} else {
					afterSubmit && afterSubmit();
					setDrawerOpen(GLOBAL_TABBED_DRAWER, {
						isOpen: false,
					});
				}
			} else if (!response.success && response.message) {
				dispatchWrapper("enableSubmit", [true]);
				setDrawerOpen(GLOBAL_DIALOG, {
					isOpen: true,
					title: "Data not saved.",
					content: `${response.message || "Please try again."}`,
					actions: [
						{
							label: "OK",
							handler: (): void => {
								setDrawerOpen(GLOBAL_DIALOG, { isOpen: false });
							},
						},
					],
				});
			}
			dispatchWrapper("enableSubmit", [true]);
			return res;
		})
		.catch((e: Error) => {
			// TODO: throw error
			console.error(e);
		});
};
