import { extractInfo } from "../form";

const accountOffers = crm => async info => {
	const { subValue, updateField } = extractInfo(info);
	let AccountID = subValue;

	if (subValue && subValue.value !== undefined) {
		AccountID = subValue.value;
	} else if (typeof subValue !== "number") {
		return;
	}

	crm.get({
		type: "accountOffers",
		filter: {
			AccountID
		},
		fields: `
			AccountID
			SpecialOfferID 
			SpecialOfferTitle
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: offers } = res;
			const options = offers.map(rec => {
				return { label: rec.SpecialOfferTitle, value: rec.SpecialOfferID };
			});
			updateField({ options });
		} else {
			updateField({ options: [] });
		}
	});
};

export default accountOffers;
