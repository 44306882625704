import React, { useEffect } from "react";
import { connect } from "react-redux";
import FormLayout from "./FormLayout";
import { Creators } from "../actions";
import isEmpty from "../../../common/isEmpty";
import coreCallbacks from "../../../common/callbacks";
import { SUBSCRIPTION_TYPES } from "../../../common/consts";

const { AFTER_CANCEL, AFTER_SUBMIT } = SUBSCRIPTION_TYPES;

const Form = ({
	formType,
	resetForm,
	title,
	data,
	layout,
	fields,
	initForm,
	callbacks,
	customComponents,
	recId,
	submit,
	fireCallbacks,
	refId,
	CustUploadAdapter,
	defaultData,
	server,
	damClient,
	baseUrl,
	acctId,
	gridHook,
	isWebForm = false,
	localeSettings,
	actions,
	viewOnly = false,
	fieldSize
}) => {
	useEffect(() => {
		const subscriptions = {};
		const { compositeFields, ...rest } = layout;

		compositeFields && compositeFields.forEach((compositeField) => {
			fields[compositeField.name].compositeFields = [];
			fields[compositeField.name].layout = compositeField;
			const compData = data[compositeField.name] && data[compositeField.name].compositeFields;
			if (!compData || compData.length === 0) {
				data[compositeField.name] = {
					compositeFields: [{}]
				};
			}
		});

		/**
		 * @param {string} subscriber
		 * @param {string} callback name of the callback
		 * @param {string} event event which would trigger the subscription
		 * @param {string} field name of the field to subscribed to.
		 * @param {string} type type of subscriber, default to field
		 */
		const addToSubscription = (subscriber, callback, event, subscribeTo, args, type = "field") => {
			if (!subscribeTo) {
				subscribeTo = subscriber;
			}
			const subscription = {
				name: callback,
				subscriber,
				type,
				args
			};
			if (!subscriptions[subscribeTo]) {
				subscriptions[subscribeTo] = {};
			}
			if (!subscriptions[subscribeTo][event]) {
				subscriptions[subscribeTo][event] = [subscription];
			} else {
				subscriptions[subscribeTo][event].push(subscription);
			}
		};

		const processSubscription = (base, subscriptions) => {
			subscriptions?.forEach(subscription => {
				const { subscribeEvents, subscribeEvent, subscribeTo, subscribeToFields, name: callback, args } = subscription;

				const events = subscribeEvents || [];
				if (subscribeEvent) {
					events.push(subscribeEvent);
				}
				const fields = subscribeToFields || [];
				if (subscribeTo) {
					fields.push(subscribeTo);
				}
				if (fields.length === 0) {
					fields.push(base);
				}

				fields.forEach(field => {
					events.forEach(event => {
						addToSubscription(base, callback, event, field, args);
					});
				});

			});
		};

		for (const name in fields) {
			const field = fields[name];
			// registrate comp fields
			if (name.startsWith("$comp_")) {
				fields[name].default = { ...field };
				const [, compName, ...rest] = name.split("_");
				// fieldName might have underscores in it
				const fieldName = rest.join("_");
				fields[compName].compositeFields.push(fieldName);
				const recordLength = data[compName].compositeFields.length;
				fields[name].index = [];
				for (let i = 0; i < recordLength; i++) {
					fields[name].index.push({ ...fields[name].default });
				}

				//Default composite fields
				if (!recId && defaultData[name]) {
					data[compName].compositeFields[0] = {
						...data[compName].compositeFields[0],
						[fieldName]: defaultData[name]
					};
				}
			}


			processSubscription(name, field.subscriptions);

			// init validators
			if (!field.validators) {
				fields[name].validators = [];
			} else {
				field.validators.forEach(validator => {
					addToSubscription(name, validator, "onBlur");
					addToSubscription(name, validator, "submit");
				});
			}

			if (field.dataType === "Date") {
				if (data[name].endsWith("Z")) {
					const lastIndex = data[name].lastIndexOf("Z");
					data[name] = data[name].substring(0, lastIndex);
				}
			}
		}

		layout.sections.forEach(section => {
			processSubscription(section.name, section.subscriptions);
		});

		for (const key in defaultData) {
			if (!isEmpty(defaultData[key]) && isEmpty(data[key]) && fields[key] && !key.startsWith("$comp_")) {
				data[key] = defaultData[key];
			}
		}

		actions.map((action, i) => {
			if (!action.name) {
				action.name = `form-action-${i}`;
			}
			return action;
		}).forEach(action => {
			processSubscription(action.name, action.subscriptions);
		});
		initForm({
			formType,
			title,
			data,
			fields,
			subscriptions,
			callbacks: { ...coreCallbacks, ...callbacks },
			defaultTitle: {},
			recId,
			refId,
			customComponents,
			CustUploadAdapter,
			server,
			damClient,
			baseUrl,
			acctId,
			gridHook,
			isWebForm,
			localeSettings,
			actions,
			layout,
			viewOnly,
			fieldSize
		});
		return async () => {
			// cleanup when unmount
			if (submit) {
				await fireCallbacks(AFTER_SUBMIT);
			} else {
				await fireCallbacks(AFTER_CANCEL);
			}
			resetForm();
		};
	}, [data, fields, callbacks]);
	return <FormLayout fields={fields} viewOnly={viewOnly} size={fieldSize} />;
};

const mapStateToProps = ({ submit }) => ({
	// TODO: deprecate this, after submit and after cancel should no longer be needed once we move the cleanup of assets to cron job
	submit
});

const mapDispatchToProps = {
	initForm: Creators.initForm,
	resetForm: Creators.resetForm,
	fireCallbacks: Creators.fireCallbacks
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form);
