const setPendingData = crm => ({ fieldName, subscriber, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (!data.JSONText) {
		return;
	}
	
	try {
		const pending = JSON.parse(data.JSONText);
		delete pending.ToBeDeleted;
		dispatchWrapper("handleFormChange", [subscriber, "Object", pending]);
	} catch (e) {
		console.log("e = ", e);
	}

};

export default setPendingData;
