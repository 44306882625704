/* eslint-disable no-undef */
import axios from "axios";
import { createActions } from "reduxsauce";
import { Creators as HeaderCreators } from "./headerActions";
import moment from "moment";

const { Types, Creators } = createActions({
	clientNotFound: null,
	loginErr: ["err", "client"],
	loginSuccess: ["productName", "acctId", "token", "auth", "subdomain", "acctLabel", "firstName", "lastName", "email", "userId", "isSVUser"],
	handleLogout: null,
	loginExpiration: ["loginExpiration"]
});

/**
 * This helper should contain any external actions that need to be dispatched on either login or token verification (for instance, to populate profile settings, etc)
 * @param dispatch - redux dispatch object from action creators.
 */
const dispatchAccountPopulationActions = dispatch => {
	dispatch(HeaderCreators.fetchTenantSettings());
	dispatch(HeaderCreators.getGeneralSettings());
};

const generalLoginError = (errorMsg) => {
	if (!errorMsg) {
		return errorMsg;
	}

	if (errorMsg.indexOf("Login failed for user") === 0) {
		errorMsg = "Login failed for database user.";
	}

	console.error(errorMsg);
	return "Simpleview CRM Apex is currently unavailable.  Please logout and try to login again at a later time.";
};

Creators.initialize = () => (dispatch, getState) => {
	const { authGraphServer, crmGraphServer } = getState().login;
	let subdomain;
	let auth;
	let productName = "dms";
	const hostArray = location.host.split(".");
	if (typeof window !== "undefined") {
		subdomain = hostArray[0];
		productName = hostArray.includes("extranet") ? "extranet" : productName;
	} else {
		dispatch(Creators.loginErr("The Simpleview CRM Apex is not supported on your browser"));
	}

	authGraphServer.account_public({
		filter: {
			name: subdomain
		},
		fields: `
			success
			message
			doc {
				acct_id
				name
				label
			}
		`
	}).then(res => {
		if (!res.success) {
			const errorMessage = generalLoginError(res.message);
			dispatch(Creators.loginErr(errorMessage, res.doc ? res.doc.label : undefined));
			return;
		}

		document.title = `Simpleview CRM Apex - ${res.doc.label}`;
		const checkToken = token => {
			crmGraphServer.general.checkToken({
				type: "check_token",
				context: {
					acct_id: res.doc.acct_id,
					token,
					productName
				}
			}).then(res2 => {
				if (!res2.success) {
					if (res2.message === "$ClientNotFound$") {
						dispatch(Creators.clientNotFound());
						return;
					}
					dispatch(Creators.finishLogin(productName, res.doc.acct_id, token, auth, subdomain, res.doc.label));
					const errorMessage = generalLoginError(res2.message);
					dispatch(Creators.loginErr(errorMessage, res.doc.label));
				} else {
					// eslint-disable-next-line no-undef
					pendo.initialize({
						visitor: {
							id: res2.email, // Required if user is logged in
							email: res2.email, // Recommended if usinggit  Pendo Feedback, or NPS Email
							full_name: `${res2.firstName} ${res2.lastName}` // Recommended if using Pendo Feedback
							// role:         // Optional

							// You can add any additional visitor level key-values here,
							// as long as it's not one of the above reserved names.
						},
						account: {
							id: res.doc.acct_id, // Highly recommended
							name: res.doc.label // Optional
							// is_paying:    // Recommended if using Pendo Feedback
							// monthly_value:// Recommended if using Pendo Feedback
							// planLevel:    // Optional
							// planPrice:    // Optional
							// creationDate: // Optional

							// You can add any additional account level key-values here,
							// as long as it's not one of the above reserved names.
						},
						disableGuides: productName === "extranet"
					});

					dispatch(Creators.finishLogin(
						productName,
						res.doc.acct_id,
						token,
						auth,
						subdomain,
						res.doc.label,
						res2.firstName,
						res2.lastName,
						res2.email,
						res2.userId,
						res2.isSVUser
					));
				}
			}).catch(error => {
				const errorMessage = generalLoginError(error.message);
				dispatch(Creators.loginErr(errorMessage, res.doc.label));
			});
		};

		try {
			auth = new sv_auth.Website({ account_name: res.doc.name, acct_id: res.doc.acct_id });

			auth.getToken().then(token => {
				// send the token to the server to see, if they have any business being here
				// and if not display a message like "You do not have access to [client name] on the Simpleview CRM Apex. Please contact your administrator."
				checkToken(token);

				auth.on("refreshed_token", refreshedToken => {
					checkToken(refreshedToken);
				});
			});
		} catch(e) {
			dispatch(Creators.loginErr("You are unable to login at this time. If the problem persists, please contact your administrator."));
			return;
		}
	});
};

Creators.finishLogin = (productName, acctId, token, auth, subdomain, acctLabel, firstName, lastName, email, userId, isSVUser) => dispatch => {
	dispatch(Creators.loginSuccess(productName, acctId, token, auth, subdomain, acctLabel, firstName, lastName, email, userId, isSVUser));
	dispatchAccountPopulationActions(dispatch);
};

Creators.logout = () => (dispatch, getState) => {
	const state = getState();

	state.login.auth.logout();
	dispatch({ type: "RESET" });
	dispatch(Creators.handleLogout());
};

Creators.updateExpires = () => (dispatch, getState) => {
	dispatch(Creators.loginExpiration(moment.utc().format()));
};

export { Creators, Types };
