import { GLOBAL_TABBED_DRAWER } from "../consts";
import { handleSubmit } from "../form";
import FormFrame from "../../components/Form/FormFrame";
import React from "react";

const previewForm = (crm) => async (info) => {
	const { getState, setDrawerOpen, afterSubmit } = info;
	const { data, refId, formType } = getState();
	await handleSubmit({ crm, ...info, data: data, type: formType });
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	const zformType = "formBuilderPreview";
	const tabs = [{ name: "" }];
	const activeTab = tabs[0].name;

	const displayComponent = <FormFrame
		type={zformType}
		action={"add"}
		recId={data.recId}
		refId={+refId}
		afterSubmit={afterSubmit}
		reMount={new Date()}
		defaultData={{ recId: data.recId }}
	/>;

	setDrawerOpen(GLOBAL_TABBED_DRAWER, {
		isOpen: true,
		type: zformType,
		id: 0,
		action: "add",
		tabs,
		activeTab,
		displayComponent
	});

};

export default previewForm;
