import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/mycalendarActions";

const INITIAL_STATE = {
	data: {},
	layout: {},
	fields: {},
	actions: [],
	modalErr: null,
	isFetching: false,
	events: [],
	dateInView: new Date(),
	viewType: "month"
};

const reducer = {};

reducer.eventsFetching = (state = INITIAL_STATE, action) => ({
	...state,
	isFetching: true,
	data: {},
	layout: {},
	fields: {},
	actions: [],
	events: []
});

reducer.eventsFetchingSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	events: action.events,
	isFetching: false
});

reducer.setDateInView = (state = INITIAL_STATE, action) => ({
	...state,
	dateInView: action.date
});

reducer.setView = (state = INITIAL_STATE, action) => ({
	...state,
	viewType: action.view
});

reducer.modalFetchErr = (state = INITIAL_STATE, action) => ({
	...state,
	modalErr: action.err,
	modalFetching: false
});

reducer.modalFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	modalFetching: true,
	data: {},
	layout: {},
	fields: {},
	actions: []
});

reducer.modalFetchSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	data: action.res.data,
	layout: action.res.layout,
	fields: action.res.fields,
	actions: action.res.actions,
	modalFetching: false
});

export default createRSReducer(Types, INITIAL_STATE, reducer);
