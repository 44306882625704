import { GLOBAL_TABBED_DRAWER } from "../consts";
import FormFrame from "./../../components/Form/FormFrame";
import React from "react";

const cancelFormBuilder = crm => (info) => {
	const { getState, setDrawerOpen, afterSubmit } = info;
	const { data } = getState();

	const type = "formBuilderLayout";
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	const tabs = [{ name: "" }];
	const activeTab = tabs[0].name;

	if (data.formDisplay !== "none") {
		const displayComponent = <FormFrame
			type={type}
			action={"edit"}
			recId={data.recId}
			refId={data.recId}
			afterSubmit={afterSubmit}
			reMount={new Date()}
		/>;

		setDrawerOpen(GLOBAL_TABBED_DRAWER, {
			isOpen: true,
			type,
			id: data.recId,
			action: "edit",
			tabs,
			activeTab,
			displayComponent,
			displaySize: "extralg"
		});
	}
};

export default cancelFormBuilder;
