const referralDetails = crm => ({ eventName, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (!data.Contact) {
		// clear fields if no contact selected
		dispatchWrapper("handleFormChange", ["FirstName", "String", ""]);
		dispatchWrapper("handleFormChange", ["LastName", "String", ""]);
		dispatchWrapper("handleFormChange", ["Groups", "Int", []]);
		if (data.Phone && data.Phone.compositeFields && data.Phone.compositeFields.length) {
			data.Phone.compositeFields.forEach((val, i) => {
				if (i === 0) {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", true, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneTypeID", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneNumber", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneExtension", "Int", undefined, i, "Phone"]);
				} else {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", false, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneTypeID", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneNumber", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneExtension", "Int", undefined, i, "Phone"]);
				}
			});
		}
		if (data.Email && data.Email.compositeFields && data.Email.compositeFields.length) {
			data.Email.compositeFields.forEach((val, i) => {
				if (i === 0) {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", true, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailTypeID", "Int", undefined, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailAddress", "Int", undefined, i, "Email"]);
				} else {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", false, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailTypeID", "Int", undefined, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailAddress", "Int", undefined, i, "Email"]);
				}
			});
		} 
	}
};

export default referralDetails;
