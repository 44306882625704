import React from "react";
import { CrmFormCallback } from "../typeDef";
import { GLOBAL_TABBED_DRAWER, COMMUNICATION, CONTACT } from "../../consts";
import { handleSubmit } from "../../form";
import FormFrame from "../../../components/Form/FormFrame";
import store from "../../../store";


const visitorSaveAndSend: CrmFormCallback = (crm) => async (info): Promise<any> => {
	const { getState, setDrawerOpen, afterSubmit } = info;
	const { data } = getState();
	const response = await handleSubmit({ crm, ...info, data: data });

	const type = `${CONTACT}.${COMMUNICATION}`;
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	const tabs = [{ name: "" }];
	const activeTab = tabs[0].name;
	const headerState = store.getState().header;
	const defaultReferralTemplate = headerState.defaultReferralTemplateID || -1;

	const displayComponent = <FormFrame
		type={type}
		action={"add"}
		recId={0}
		refId={+data.ContactID.value}
		afterSubmit={afterSubmit}
		reMount={new Date()}
		defaultData={{ ReferralID: response.set_visitor.recId, TemplateID: {label: "Default Referral", value: defaultReferralTemplate, sortOrder: null} }}
	/>;

	setDrawerOpen(GLOBAL_TABBED_DRAWER, {
		isOpen: true,
		type,
		id: 0,
		action: "add",
		tabs,
		activeTab,
		displayComponent
	});

};

export default visitorSaveAndSend;
