import { extractInfo } from "../form";

const setPreviewData = crm => (info) => {
	const { updateField } = extractInfo(info);
	const { acctId, data } = info.getState();

	if (data.recId) {
		updateField({
			form_id: data.recId,
			acct_id: acctId, 
			formDisplay: true
		});
	}
};

export default setPreviewData;
