import index from "../arrayIndex";
const inquiryDetails = crm => ({ eventName, dispatchWrapper, getState }) => {
	const { data, fields } = getState();
	if (data.Contact) {
		crm.get({
			type: "contact",
			filter: {
				recId: data.Contact.value
			},
			fields: `
				FirstName
				LastName
				Email {
					recs {
						EmailAddress
						EmailTypeID
						IsPrimary
						recId
					}
				}
				Phone {
					recs {
						PhoneNumber
						PhoneExtension
						PhoneTypeID
						IsPrimary
						recId
					}
				}
				Groups {
					recs {
						GroupID
						DisplayNm
					}
				}
			`
		}).then(response => {
			const { recs: contacts } = response;
			if (contacts.length) {
				const contact = contacts[0];
				dispatchWrapper("handleFormChange", ["FirstName", "String", contact.FirstName]);
				dispatchWrapper("handleFormChange", ["LastName", "String", contact.LastName]);
				if (contact.Phone.recs.length) {
					contact.Phone = contact.Phone.recs.filter(phone => phone.IsPrimary);
					contact.Phone.forEach((phoneNumber, i) => {
						Object.keys(phoneNumber).forEach(key => {
							let value = phoneNumber[key] || "";
							const options = fields[`$comp_Phone_${key}`].options;
							if (options) {
								const optionIndex = index(options, "value");
								const option = optionIndex[phoneNumber[key]];
								value = { label: option.label, value: option.value };
							}
							dispatchWrapper("handleFormChange", [key, "String", value, i, "Phone"]);
						});
					});
				}
				if (contact.Email.recs.length) {
					contact.Email = contact.Email.recs.filter(email => email.IsPrimary);
					contact.Email.forEach((email, i) => {
						Object.keys(email).forEach(key => {
							let value = email[key] || "";
							const options = fields[`$comp_Email_${key}`].options;
							if (options) {
								const optionIndex = index(options, "value");
								const option = optionIndex[email[key]];
								value = { label: option.label, value: option.value };
							}
							dispatchWrapper("handleFormChange", [key, "String", value, i, "Email"]);
						});
					});
				}
				if (contact.Groups.recs.length) {
					contact.Groups = contact.Groups.recs.map(elem => ({ value: elem.GroupID, label: elem.DisplayNm }));
					dispatchWrapper("handleFormChange", ["Groups", "Int", contact.Groups]);
				}
			}
		});
	} else {
		// clear fields if no contact selected
		dispatchWrapper("handleFormChange", ["FirstName", "String", ""]);
		dispatchWrapper("handleFormChange", ["LastName", "String", ""]);
		dispatchWrapper("handleFormChange", ["Groups", "Int", []]);
		if (data.Phone && data.Phone.compositeFields && data.Phone.compositeFields.length) {
			data.Phone.compositeFields.forEach((val, i) => {
				if (i === 0) {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", true, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneTypeID", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneNumber", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneExtension", "Int", undefined, i, "Phone"]);
				} else {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", false, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneTypeID", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneNumber", "Int", undefined, i, "Phone"]);
					dispatchWrapper("handleFormChange", ["PhoneExtension", "Int", undefined, i, "Phone"]);
				}
			});
		}
		if (data.Email && data.Email.compositeFields && data.Email.compositeFields.length) {
			data.Email.compositeFields.forEach((val, i) => {
				if (i === 0) {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", true, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailTypeID", "Int", undefined, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailAddress", "Int", undefined, i, "Email"]);
				} else {
					dispatchWrapper("handleFormChange", ["IsPrimary", "Boolean", false, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailTypeID", "Int", undefined, i, "Email"]);
					dispatchWrapper("handleFormChange", ["EmailAddress", "Int", undefined, i, "Email"]);
				}
			});
		} 
	}
};

export default inquiryDetails;
