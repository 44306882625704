const updateRelatedItems = crm => async ({
	fieldName,
	subscriber,
	dispatchWrapper,
	getState
}) => {
	const { data } = getState();
	const items = data[fieldName] || [];
	const ids = items.map(item => item.recId);

	dispatchWrapper("handleFormChange", [subscriber, "Int", ids]);
};

export default updateRelatedItems;
