const modifiers = {
	"!": (value) => !value,
	"!!": (value) => !!value,
	"-": (value) => -value
};

const compares = {
	">=": (value, arg) => value >= arg,
	">": (value, arg) => value > arg,
	"<": (value, arg) => value = arg,
	"<=": (value, arg) => value <= arg,
	"==": (value, arg) => value == arg,
	"===": (value, arg) => value === arg,
	"!=": (value, arg) => value != arg,
	"!==": (value, arg) => value !== arg,
	"includes": (value, arg) => arg.includes(value),
	"!includes": (value, arg) => !arg.includes(value)
};

const setSectionPropsWithValue = crm => async ({ dispatchWrapper, subscriber, staticArgs, getState, fieldName }) => {
	const [attrName, operator, compValue, valueToSet, elseValueToSet] = staticArgs;
	const update = value => {
		dispatchWrapper("updateSection", [subscriber, { [attrName]: value }]);
	};

	const state = getState();
	let value = state.data[fieldName];
	if (value && value.value) {
		value = value.value;
	}

	if (elseValueToSet !== undefined) {
		update(compares[operator](value, compValue) ? valueToSet: elseValueToSet);
	} else if (valueToSet !== undefined) {
		if (compares[operator](value, compValue)) {
			update(valueToSet);
		}
	} else if (compValue !== undefined) {
		let parsedCompValue = compValue;
		try {
			parsedCompValue = JSON.parse(compValue);
		} catch (e) {
			//JSON is already parsed
		}
		update(compares[operator](value, parsedCompValue));
	} else if (operator !== undefined) {
		if (operator in modifiers) {
			update(modifiers[operator](value));
		} else {
			update(operator);
		}
	} else {
		update(value);
	}
};

export default setSectionPropsWithValue;

