import createRSReducer from "../common/createRSReducer";

import { Types } from "../actions/transientElementActions";
import { GLOBAL_TRANSIENT_ELEMENTS } from "../common/consts";

export const { DIALOG, SNACKBAR, DRAWER, SPINNER } = GLOBAL_TRANSIENT_ELEMENTS;
export const INITIAL_STATE = {
	drawerOpen: {
		[SNACKBAR]: {
			isOpen: false,
			message: null,
			messageType: "info"
		},
		[DRAWER]: {
			isOpen: false,
			tabs: null,
			displayComponent: null,
			id: 0,
			action: "_blank",
			displaySize: "lg",
			activeTab: null
		},
		[DIALOG]: {
			isOpen: false,
			title: null,
			content: "",
			actions: []
		},
		[SPINNER]: {
			isOpen: false
		}
	},
	drawerCurrentTab: "",
	drawerTabs: [],
	anchorElement: {},
	messageType: "info",
	message: null,
	openNav: ""
};

const getInitialElementState = element => {
	return INITIAL_STATE.drawerOpen[element];
};

export const reducer = {};

reducer.setMessage = (state = INITIAL_STATE, action) => {
	return {
		...state,
		drawerOpen: {
			...state.drawerOpen,
			[SNACKBAR]: {
				isOpen: true,
				message: action.message,
				messageType: action.messageType || "info"
			}
		}
	};
};

//DEV NOTE: We need to retain the values on closing to guarantee we don't get weird shifts during transition (like the snackbar turning blue)
//But, we can safely clear on open and set state to be only what that element needs.
reducer.setDrawerOpen = (state = INITIAL_STATE, action) => {
	return {
		...state,
		drawerOpen: {
			...state.drawerOpen,
			[action.name]: action.drawerControlInfo && action.drawerControlInfo.isOpen
				? {
					...action.drawerControlInfo
				}
				: {
					...state.drawerOpen[action.name],
					...action.drawerControlInfo
				}
		}
	};
};

reducer.setDrawerAction = (state = INITIAL_STATE, action) => {
	return {
		...state,
		drawerOpen: {
			...state.drawerOpen,
			[action.name]: {
				...state.drawerOpen[action.name],
				tabs: action.tabs,
				activeTab: action.activeTab
			}
		}
	};
};

// set a drawer back to the initial state
reducer.clearElement = (state = INITIAL_STATE, action) => ({
	...state,
	drawerOpen: {
		...state.drawerOpen,
		[action.name]: {
			...getInitialElementState(action.name)
		}
	}
});

reducer.setAnchorElement = (state = INITIAL_STATE, action) => ({
	...state,
	anchorElement: {
		...state.anchorElement,
		[action.anchorElement]: action.element
	}
});

reducer.setActiveNav = (state = INITIAL_STATE, action) => {
	return {
		...state,
		openNav: action.openNav
	};
};

reducer.setDrawerCurrentTab = (state = INITIAL_STATE, action) => {
	const activeTab = state.drawerOpen[DRAWER].tabs.filter(t => t.name === action.tab);
	return {
		...state,
		drawerOpen: {
			...state.drawerOpen,
			[action.drawer]: {
				...state.drawerOpen[DRAWER],
				activeTab: activeTab.length ? activeTab[0].name : state.drawerOpen[DRAWER].tabs[0].name,
				displayComponent: activeTab.length ? activeTab[0].component : state.drawerOpen[DRAWER].displayComponent
			}
		}
	};
};

export default createRSReducer(Types, INITIAL_STATE, reducer);
