import React, { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { sectionRefs } from "./FormLayout";
import FormControl from "./FormControl";
import { Creators } from "../actions";
import {
	Grid,
	Button,
	useMediaQuery,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	IconButton
} from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "./styles";
import Captcha from "./Captcha";
import ActionList from "./ActionList";
import useWindowSize from "../../../hooks/useWindowSize";
import HelpIcon from "@material-ui/icons/Help";

const ActionPanel = ({ 
	formTitle,
	defaultTitle,
	classes,
	canSubmit,
	captchaSiteKey,
	showRecaptcha,
	callbacks,
	submit,
	windowSize,
	wrap,
	storedLayout,
	viewOnly,
	helpTextLink,
	hideHelp
}) => {
	const sections = (storedLayout && storedLayout.sections) || [];
	const [reCaptchaCheckPass, setRecaptchaCheck] = useState(!showRecaptcha);
	const [style, setStyle] = useState(null);
	const ref = useRef(null);
	const sectionRef = useRef(null);
	const helpLink = helpTextLink || "https://kb.simpleviewdms.com/hc/en-us";
	useEffect(() => {
		if (showRecaptcha || wrap || !sectionRef.current) {
			return;
		}

		const bottom = ref.current.getBoundingClientRect().bottom;
		const sectionHeight = sectionRef.current.clientHeight;
		if (windowSize.height < bottom) {
			setStyle({
				maxHeight: sectionHeight - (bottom - windowSize.height),
				overflowY: "scroll"
			});
		} else {
			setStyle(null);
		}
	}, [windowSize, sectionRef.current]);

	return <div ref={ref} className={classes.detailPageWrapper} style={{
		width: (wrap || showRecaptcha) ? "100%": "auto",
		paddingRight: (wrap || showRecaptcha) ? 0 : 36,
	}}>
		{showRecaptcha ? null : <h4>{formTitle || defaultTitle}</h4>}
		{(showRecaptcha && !submit) &&
		<Captcha siteKey={captchaSiteKey} enableSubmit={setRecaptchaCheck} />
		}
		<ActionList canSubmit={canSubmit && reCaptchaCheckPass} captchaSiteKey={captchaSiteKey} viewOnly={viewOnly} />
		{(showRecaptcha || viewOnly) ? null : <div>
			<h5>Sections</h5>
			<div style={style} ref={sectionRef} className={classNames(classes.detailActionSections, { scroll: scroll }, "CySectionList")}>
				{
					sections && sections.map((val, i) => {
						if (val.hidden) {
							return "";
						}
						return (
							<div key={`section_${i}`}>
								<Button className={classNames("link")} size="small" value={val.name}
									onClick={e => {
										const div = sectionRefs[val.name];
										div.scrollIntoView({ behavior: "smooth" });
										e.preventDefault();
									}}>
									{val.label}
								</Button>
							</div>
						);
					})
				}
				{hideHelp ? null : <Button className={classNames("link")} size="small" value={"Help"}
					onClick={() => window.open(helpLink, "_blank")}>
					<HelpIcon color="primary" fontSize="small" /> &nbsp; Help
				</Button>}
			</div>
		</div>}
	</div>;
};

const ConfirmDialog = ({ isOpen, title = "", content = "", handleClose, onConfirm, classes }) => {
	const handleDialogClose = () => {
		handleClose(false);
	};
	return (
		<Dialog open={isOpen} onClose={handleDialogClose} onExited={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" className={classes.confirmDialogContent}>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDialogClose}>Cancel</Button>
				<Button color={"primary"} onClick={onConfirm}>OK</Button>
			</DialogActions>
		</Dialog>
	);
};

const RenderForm = (props) => {
	const {
		classes,
		formTitle,
		defaultTitle,
		section,
		captchaSiteKey,
		formType,
		dialogOpen,
		dialogText,
		dialogTitle,
		dialogConfirmFunction,
		setDialog
	} = props;
	const showRecaptcha = useMemo(() => captchaSiteKey !== undefined, [captchaSiteKey]);
	const windowSize = useWindowSize();
	useEffect(() => {
		if (section && !showRecaptcha) {
			// need to wait for sections to be fully expanded
			setTimeout(() => {
				const div = sectionRefs[section];
				div.scrollIntoView({ behavior: "smooth" });
			}, 500);
		}
	}, [section]);

	const theme = useTheme();
	const wrap = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<div className="dms-form" id={`form-${formType}`}>
			<ConfirmDialog
				isOpen={dialogOpen || false}
				title={dialogTitle}
				handleClose={() => {
					if (dialogConfirmFunction) {
						dialogConfirmFunction(false);
					}
					setDialog(false, undefined, undefined, undefined);
				}}
				onConfirm={() => {
					if (dialogConfirmFunction) {
						dialogConfirmFunction(true);
					}
					setDialog(false, undefined, undefined, undefined);
				}}
				content={dialogText}
				classes={classes}
			/>
			<Grid container wrap={showRecaptcha ? "wrap" : wrap ? "wrap" : "nowrap"} alignItems="stretch" justify="flex-start" spacing={0}>
				{showRecaptcha ? null : (<Grid item xs={12} lg={showRecaptcha ? 12 : 3}>
					<ActionPanel windowSize={windowSize} wrap={wrap} {...props} />
				</Grid>)}
				<Grid xs={12} lg={showRecaptcha ? 12 : 9} item className={classes.formComponent}>
					{showRecaptcha ? <h4 style={{ fontFamily: "inherit", margin: "revert", fontWeight: "bold" }}>{formTitle || defaultTitle}</h4> : null}
					<FormControl {...props} />
				</Grid>
				{showRecaptcha || wrap ? <ActionPanel windowSize={windowSize} wrap={wrap} {...props} showRecaptcha={showRecaptcha} /> : null}
			</Grid>
		</div>
	);
};

const mapStateToPropsContainer = ({ title, canSubmit, submit, dialogOpen, dialogText, dialogTitle, dialogConfirmFunction, layout, callbackQueue }) => ({
	formTitle: title,
	canSubmit: canSubmit && !Object.keys(callbackQueue).length,
	submit,
	dialogOpen,
	dialogText,
	dialogTitle,
	dialogConfirmFunction,
	storedLayout: layout
});

const mapDispatchToPropsContainer = {
	fireAction: Creators.fireAction,
	setDialog: Creators.setDialog
};

export default connect(
	mapStateToPropsContainer,
	mapDispatchToPropsContainer
)(withStyles(styles)(RenderForm));
