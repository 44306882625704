import { getDisplayName } from "./moduleData";

export const setRecentlyViewed = async ({ crmGraphServer, type, record }) => {
	const server = crmGraphServer.general || crmGraphServer;
	// get children if it is a react element
	for (const key in record) {
		if (record[key]?.props?.children) {
			record[key] = record[key].props.children;
		}
	}
	const id = record.RecordID || record.recId.toString();
	await server.set({
		type: `${type}_recentlyViewed`,
		inputType: "recentlyViewed",
		fields: "success",
		input: {
			RecordID: id,
			Title: getDisplayName(type, record)
		}
	});
};
