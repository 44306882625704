const styles = theme => ({
	browseButton: {
		color: theme.colors.blue,
		"&:hover": {
			color: theme.colors.blueHover
		}
	},
	drawer: {
		marginTop: "100em"
	},
	drawerPaper: {
		marginTop: theme.constants.header.height,
		height: `calc(100% - ${theme.constants.header.height}px)`,
		width: "75%",
		minWidth: 900,
		transition: "width .2s ease-in-out",
		"&.fullscreen": {
			height: "100%",
			width: "100%"
		},
		"&.sm": {
			width: "30%",
			minWidth: 300,
			"& .drawerData": {
				margin: 0
			}
		},
		"&.md": {
			width: "50%",
			minWidth: 500,
			"& .drawerData": {
				margin: 0
			}
		},
		"&.lg": {
			width: "70%",
			minWidth: 700
		},
		"&.extralg": {
			width: "calc(100% - 250px)",
			minWidth: 700
		}
	},
	gridWithMargin: {
		margin: theme.spacing(3),
		marginLeft: theme.spacing(3.5),
		marginRight: theme.spacing(2.5),
	},
	gridComponent: {
		padding: theme.spacing(2),
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		}
	},
	fullWidth: {
		width: "100%"
	},
	tableHead: {
		backgroundColor: "#F0F2F5"
	},
	tableButtonCell: {
		display: "inline-flex"
	},
	tableButton: {
		minWidth: "0%"
	},
	chipMargin: {
		margin: theme.spacing(.5)
	}
});

export default styles;
