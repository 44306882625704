import { createActions } from "reduxsauce";
import isIterable from "../../common/isIterable";
import { SUBSCRIPTION_TYPES } from "../../common/consts";

const { ON_UPDATE } = SUBSCRIPTION_TYPES;

const { Types, Creators } = createActions({
	initForm: [
		"state"
	],
	handleNewOption: ["fieldName", "option", "compositeField", "index"],
	handleFormChangeSuccess: ["field", "dataType", "value", "index", "compositeField"],
	// change this to update field to be more general
	updateField: ["name", "update"],
	addField: ["name"],
	removeField: ["name", "index"],
	addOptionsToField: ["name", "options"],
	setValidation: ["fieldName", "compField", "compIndex", "callback", "message"],
	clearValidation: ["fieldName", "compField", "compIndex", "callback"],
	setFieldRef: ["fieldDataKey", "ref"],
	resetData: null,
	resetForm: null,
	flagSubmit: ["overwrite"],
	enableSubmit: ["value"],
	updateAction: ["condition", "update"],
	setDialog: ["dialogOpen", "dialogTitle", "dialogText", "dialogConfirmFunction"],
	updateSection: ["name", "update"],
	addToCallbackQueue: ["debounceKey"],
	removeFromCallbackQueue: ["debounceKey"],
});

Creators.handleFormChange = (name, dataType, value, index, compositeField) => dispatch => {
	dispatch(Creators.handleFormChangeSuccess(name, dataType, value, index, compositeField));
	// fire onUpdate event;
	dispatch(Creators.fireSubscriptionsByEventName({ eventName: ON_UPDATE, fieldName: name, compField: compositeField, compIndex: index }));
};

const dispatchWrapper = (dispatch, getState, action, args) => {
	if (!getState().acctId) {	// if form is unmounted halt operations
		return;
	}
	if (typeof Creators[action] === "function") {
		if (isIterable(args)) {
			dispatch(Creators[action](...args));
		} else {
			dispatch(Creators[action]());
		}
	} else {
		console.error(`Callback's dispatched action "${action}" not found`);
	}
};

const fireCallback = async ({
	callback,
	compField,
	compIndex,
	dispatch,
	eventName,
	fieldName,
	getState,
	module,
	name,
	recid,
	subscriber,
	staticArgs,
	value
}) => {
	const { callbackQueue, data } = getState();
	let info = "";
	if (compField && data[compField] && data[compField][compIndex] && data[compField][compIndex][fieldName]) {
		info = data[compField][compIndex][fieldName];
	} else if (data[fieldName]) {
		info = data[fieldName];
	}

	const debounceKey = JSON.stringify({
		compField,
		compIndex,
		data: info,
		eventName,
		fieldName,
		name,
		module,
		recid,
		subscriber,
		staticArgs,
		value
	});

	if (!callbackQueue[debounceKey]) {
		dispatch(Creators.addToCallbackQueue(debounceKey));

		await callback({
			compField,
			compIndex,
			dispatchWrapper: dispatchWrapper.bind(null, dispatch, getState),
			eventName,
			fieldName,
			getState,
			module,
			recid,
			subscriber,
			staticArgs,
			value
		});
		dispatch(Creators.removeFromCallbackQueue(debounceKey));
	}
};

const fireSubscription = async ({ el, eventName, fieldName, compField, compIndex, dispatch, getState }) => {
	const { subscriptions, callbacks, recId, viewOnly } = getState();

	if (subscriptions[el] && subscriptions[el][eventName] && Array.isArray(subscriptions[el][eventName]) && (!viewOnly || eventName === "onLoad")) {
		// eslint-disable-next-line no-unused-vars
		for (const subscription of subscriptions[el][eventName]) {
			const { name, args: staticArgs, subscriber } = subscription;
			if (callbacks[name]) {
				await fireCallback({
					callback: callbacks[name],
					dispatch,
					fieldName,
					compField,
					compIndex,
					eventName,
					name,
					subscriber,
					staticArgs,
					getState,
					recid: recId
				});
			} else {
				console.warn(`callback ${name} not found, ignored.`);
			}
		}
	}
};

Creators.fireSubscriptionsByEventName = ({ eventName, fieldName, compField, compIndex }) => async (dispatch, getState) => {
	const args = { el: fieldName, eventName, fieldName, compField, compIndex, dispatch, getState };
	if (compField && compField.length) {
		args.el = `$comp_${compField}_${fieldName}`;
		await fireSubscription(args);
	} else {
		await fireSubscription(args);
	}
};

Creators.fireAction = ({ callback, actionArgs, bindArgs, type }) => (dispatch, getState) => async () => {
	const { callbacks } = getState();

	if (actionArgs.validate) {
		let validationCallback = callback;
		if (type && type === "submit") {
			validationCallback = "submit";
			if (actionArgs && actionArgs.captchaSiteKey) {
				const siteKey = actionArgs.captchaSiteKey;
				await window.grecaptcha.execute(siteKey, { action: "homepage" }).then(token => {
					actionArgs.captchaToken = token;
				});
				delete actionArgs.captchaSiteKey;
			}
		}
		await validateFieldsWithActionCallbacks(validationCallback, dispatch, getState);
		const { validation, fieldRefs } = getState();
		if (Object.keys(validation).length > 0) {
			const firstInvalidField = fieldRefs.find(field => validation[field.fieldDataKey]);
			if (firstInvalidField && firstInvalidField.ref) {
				setTimeout(() => {
					firstInvalidField.ref.scrollIntoView({ behavior: "smooth" });
				}, 300);
			}
			return; // validation has failed
		}
	}

	if (callbacks[callback]) {
		if (bindArgs) {
			callbacks[callback].bind(null, bindArgs);
		}

		callbacks[callback]({
			dispatchWrapper: dispatchWrapper.bind(null, dispatch, getState),
			getState,
			...actionArgs
		});
	} else if (callback instanceof Function) {
		callback({
			dispatchWrapper: dispatchWrapper.bind(null, dispatch, getState),
			getState,
			...actionArgs
		});
	}
};

Creators.fireCallbackByName = ({ callbackName, module, fieldName, value, compField, compIndex, eventName, subscriber, staticArgs }) => async (dispatch, getState) => {
	const { callbacks } = getState();

	if (callbacks[callbackName]) {
		await fireCallback({
			callback: callbacks[callbackName],
			dispatch,
			module,
			fieldName,
			value,
			compField,
			compIndex,
			eventName,
			subscriber,
			staticArgs,
			getState
		});
	}
};

// this fires all the events
Creators.fireCallbacks = (eventName) => async (dispatch, getState) => {
	await validateFieldsWithActionCallbacks(eventName, dispatch, getState);
};

const validateFieldsWithActionCallbacks = async (eventName, dispatch, getState) => {
	const { subscriptions, data } = getState();

	// eslint-disable-next-line no-unused-vars
	for (const [name, subscription] of Object.entries(subscriptions)) {
		if (subscription[eventName]) {
			if (name.startsWith("$comp_")) {
				const [, compField, ...rest] = name.split("_");
				const fieldName = rest.join("_");
				if (data[compField].compositeFields instanceof Array) {
					let compIndex = 0;
					// eslint-disable-next-line no-unused-vars
					for (const composite of data[compField].compositeFields) {
						await fireSubscription({ el: `$comp_${compField}_${fieldName}`, eventName, fieldName, compField, compIndex, dispatch, getState });
						compIndex++;
					}
				}
			} else {
				await fireSubscription({ el: name, fieldName: name, eventName, dispatch, getState });
			}
		}
	}
};

export { Creators, Types, dispatchWrapper };
