
import React, { useMemo } from "react";
import AsyncPaginate from "react-select-async-paginate"; 
import selectComponents from "@react-ui/components/Fields/SelectField/selectComponents";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Chip from "@react-ui/components/Chip";

type OptionValue = string | number;

export interface Option {
	value: OptionValue;
	label: string;
	sortOrder?: number;
	hasPermissions?: boolean;
	readonly?: boolean;
}

export interface Options {
	options: Option[];
	hasMore: boolean;
	additional: Additional;
}

interface Additional {
	page: number;
}

export type LoadOptionsCallback = (search: string, loadedOptions: Option[], { page }: Additional) => Promise<Options>

export type CreateOptionCallback = (value: string) => Promise<void>

interface Props {
	loadOptions: LoadOptionsCallback;
	onChange: (option: Option | Option[]) => void;
	onCreateOption?: CreateOptionCallback;
	isMulti?: boolean;
	label?: string | any;
	variant?: string;
	placeholder?: string;
	disabled?: boolean;
	error?: boolean;
	errorMsg?: string;
	value?: Option | Option[] | [];
	// to disable the prop check warning
	options?: Option[];
	defaultOptions?: boolean;
	className?: string;
}

export const AsyncSelect: React.FC<Props> = ({
	loadOptions,
	onChange,
	isMulti,
	label,
	variant,
	placeholder,
	disabled,
	error,
	errorMsg,
	value,
	onCreateOption,
	options = [],
	defaultOptions= true,
	className
}) => {
	const SelectField = useMemo(() => {
		// eslint-disable-next-line react/display-name
		return React.forwardRef((props, ref: any) => {
			return onCreateOption ?
				<CreatableSelect {...props} ref={ref} onCreateOption={onCreateOption} /> :
				<Select {...props} ref={ref} />;
		});
	}, [onCreateOption]);

	const removeChip = (val: OptionValue): void => {
		const newValues = (value as Option[]).filter(data => {
			// keep the chip if it is not what's been removed, or you don't
			// have permission to remove it.
			return (data.value !== val) || data.hasPermissions === false;
		});
		if (newValues.length < (value as Option[]).length) {
			onChange(newValues);
		}
	};
	return (
		<>
			<AsyncPaginate
				className={className}
				options={options.length ? options : undefined }
				defaultOptions={defaultOptions} // auto call loadOptions
				hideSelectedOptions={false}
				backspaceRemovesValue={false}
				components={selectComponents}
				loadOptions={loadOptions}
				placeholder={placeholder}
				isDisabled={disabled}
				fullWidth={true}
				isMulti={isMulti}
				isClearable={!isMulti}
				onChange={onChange}
				error={error}
				value={value}
				errorMsg={errorMsg}
				textFieldProps={{
					label,
					variant,
					InputLabelProps: {
						shrink: true
					}
				}}
				SelectComponent={SelectField as any}
				additional={{
					page: 1
				}}
			>
			</AsyncPaginate>
			<div style={{
				marginTop: "1rem"
			}}>
				{ isMulti && value && (value as Option[]).map(({ value, label, hasPermissions=true, readonly=false }) => (
					<Chip 
						variant="outlined"
						tabIndex={-1}
						label={label}
						key={value}
						style={{
							margin: "0.25rem"
						}}
						disabled={hasPermissions === false || readonly}
						onDelete={hasPermissions === false || readonly ? null : (): void => removeChip(value) }
					/>
				))}
			</div>
		</>
	);
};

export default AsyncSelect;
