import checkRowActions from "./checkRowActions";

const checkCommunicationStatus = ({ row, action }) => {
	const checkVal = checkRowActions({ row, action });

	if (checkVal && (row?.EmailStatus === "Draft" || row?.EmailStatus === "Failed to Send")) {
		return true; 
	} else if (row?.EmailStatus !== "Draft" && action?.name === "read") {
		return true;
	}
	return false;
};

export default checkCommunicationStatus;
