const forms: { [key: string]: [{ id: string, label: string }] } = {};

interface Option {
	label: string;
	value: string;
}

const fetchDefaultWebForms = (crm: { get: (arg0: { type: string; fields: string; filter: { module: string; }; }) => any; }) => async ({ fieldName, subscriber, getState, dispatchWrapper }: { fieldName: string, subscriber: string, getState: any, dispatchWrapper: any }): Promise<void> => {
	const { data } = getState();
	let module;

	if (data[fieldName] && data[fieldName].value) {
		module = data[fieldName].value;
	}

	if (forms[module] !== undefined) {
		dispatchWrapper("updateField", [subscriber, { options: forms[module] }]);
		return;
	}

	if (module === undefined) {
		return;
	}

	let options = await crm.fetchDefaultWebForms({
		module
	});

	options = options.map((option: { label: string; id: string; }) => ({
		label: option.label,
		value: option.id
	}));

	if (data[subscriber] && data[subscriber].value && options.findIndex((option: { label: string; value: string; }) => option.value === data[subscriber].value) === -1) {
		dispatchWrapper("handleFormChange", [subscriber, "String", ""]);
	}

	forms[module] = options;

	dispatchWrapper("updateField", [subscriber, { options }]);
};

export default fetchDefaultWebForms;
