import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { FormLabel, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Grid } from "@material-ui/core";
import classNames from "classnames";
import { Clear, Add } from "@material-ui/icons";
import Row from "./Row";
import { Creators } from "../actions";
import { SUBSCRIPTION_TYPES } from "../../../common/consts";

const { ON_CHANGE } = SUBSCRIPTION_TYPES;

const CompositeRecord = ({
	name,
	i,
	classes,
	handleRemoveField,
	rows,
	flexDirection,
	delLabel,
	canDel,
	unableToDelete = [],
	fireSubscriptionsByEventName,
	showSeparator,
	isLast,
	compositeFields,
	viewOnly
}) => {
	const removeRecord = useCallback(
		() => {
			handleRemoveField(i);
			fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: name });
			if (compositeFields && compositeFields.length && i >= 0) {
				compositeFields.forEach(elem => {
					fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: elem, compField: name, compIndex: i });
				});
			}
		},
		[i, handleRemoveField, fireSubscriptionsByEventName, name],
	);
	const borderStyle = {};
	if (showSeparator) {
		if (flexDirection === "column") {
			borderStyle.borderBottom = "2px #bfbfbf solid";
		} else {
			borderStyle.borderRight = "2px #bfbfbf solid";
		}
	}
	return (
		<Grid container direction="row" className={classes.compositeRecord} style={!isLast ? borderStyle: {}}>
			{rows && rows.map((row, r) => {
				const rowName = `${name}-${i}-row-${r}`;

				return <Row classes={classes} key={rowName} name={rowName} compField={name} compIndex={i} {...row} />;
			})}


			{(!viewOnly && canDel && flexDirection === "column" && !unableToDelete.includes(i)) ?
				<Button className={classNames(classes.removeButtonCol, "withIcon")} size="small" onClick={removeRecord}><Clear /></Button> : null}
			{(!viewOnly && canDel && flexDirection !== "column" && !unableToDelete.includes(i)) ?
				<Button color="primary" className={classNames("deemphasized", "withIcon", classes.removeButtonRow)} size="small" onClick={removeRecord}>
					{delLabel}
				</Button>: null}
		</Grid>
	);
};

const ConfirmDialog = ({ isOpen, title = "Remove Record?", content = "", handleClose, onConfirm }) => {
	const handleDialogClose = () => {
		handleClose(false);
	};
	return (
		<Dialog open={isOpen} onClose={handleDialogClose} onExited={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description" >{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDialogClose}>Cancel</Button>
				<Button color={"primary"} onClick={onConfirm}>OK</Button>
			</DialogActions>
		</Dialog>
	);
};

const CompositeField = ({ name,
	layout: { rows, flexCount = 1, flexDirection = "column" },
	minDisplay = 1,
	classes,
	limitedUseOptions,
	confirmRemove,
	confirmRemoveTitle,
	label,
	hideLabel,
	data,
	addField,
	delField,
	unableToDelete,
	handleAddField,
	handleRemoveField,
	fireSubscriptionsByEventName,
	showSeparator = false,
	compositeFields,
	viewOnly
}) => {
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [recIndex, setRecIndex] = useState(-1);
	const addRecord = useCallback((i = -1) => {
		handleAddField(name);
		fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: name });
		if (compositeFields && compositeFields.length && i >= 0) {
			compositeFields.forEach(elem => {
				fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: elem, compField: name, compIndex: i });
			});
		}
	}, [name, handleAddField, fireSubscriptionsByEventName]);


	if (flexDirection === null) {
		flexDirection = "column";
	}

	const canAdd = addField;
	const canDel = delField === false ? delField : true;
	const clearOnRemove = useMemo(() => data.length <= minDisplay, [minDisplay, data && data.length]);
	const delLabel = useMemo(() => `${clearOnRemove ? "Clear" : "Remove"} ${label}`, [clearOnRemove, label]);
	const removeRecord = useCallback(
		(i) => {
			if (confirmRemove) {
				setRecIndex(i);
				setDialogOpen(true);
			} else {
				handleRemove(i);
			}
		},
		[name, handleRemoveField],
	);

	const handleRemove = (i) => {
		const index = i > -1 ? i : recIndex;
		handleRemoveField(name, index);
		if (clearOnRemove) {
			addRecord(i);
		}
		setRecIndex(-1);
		setDialogOpen(false);
		fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: name });
		if (compositeFields && compositeFields.length && index >= 0) {
			compositeFields.forEach(elem => {
				fireSubscriptionsByEventName({ eventName: ON_CHANGE, fieldName: elem, compField: name, compIndex: index });
			});
		}
	};

	return <div className={classes.compositeField}>
		<ConfirmDialog
			isOpen={isDialogOpen}
			title={confirmRemoveTitle && confirmRemoveTitle.length > 0 ? confirmRemoveTitle : undefined}
			handleClose={setDialogOpen}
			onConfirm={handleRemove}
			content={confirmRemove}
		/>
		{!hideLabel && <FormLabel>{label}</FormLabel>}
		<Grid
			container
			direction={flexDirection || "column"}
		>
			{data && data.map((record, i) => <Grid
				item
				xs={12}
				xl={12 / (flexCount || 1)}
				key={`${name}-${i}`}
			>
				<CompositeRecord
					key={`${name}-${i}`}
					classes={classes}
					i={i}
					name={name}
					clearOnRemove={clearOnRemove}
					handleRemoveField={removeRecord}
					flexDirection={flexDirection}
					rows={rows}
					delLabel={delLabel}
					canDel={canDel}
					unableToDelete={unableToDelete}
					fireSubscriptionsByEventName={fireSubscriptionsByEventName}
					showSeparator={showSeparator}
					isLast={i === data.length - 1}
					compositeFields={compositeFields}
					viewOnly={viewOnly}
				/>
			</Grid>)}
		</Grid>
		{(!viewOnly && canAdd) && <Button color="primary" className="deemphasized withIcon" size="small" onClick={addRecord}>
			<Add /> Add {label}
		</Button>}
	</div>;
};

const mapStateToProps = (state, ownProps) => {
	const fieldsToCheckForLimitedUse = ownProps.compositeFields;
	const { name } = ownProps;
	let isLimited = false;
	let limitedFieldName = null;
	let limitCount = null;
	fieldsToCheckForLimitedUse.forEach(fieldName => {
		const field = state.fields[`$comp_${name}_${fieldName}`];
		if (field.limitedUseOptions) {
			isLimited = true;
			limitedFieldName = fieldName;
			limitCount = field.options?.length;
		}
	});
	const fieldCount = state?.data[name]?.compositeFields.length > 0 
		? state?.data[name]?.compositeFields?.length
		: state.fields[`$comp_${name}_${limitedFieldName}`]?.index?.length;

	return {
		addField: ownProps.addField && ((isLimited && fieldCount < limitCount) || (!isLimited)),
		viewOnly: state.viewOnly
	};
};

const mapDispatchToProps = {
	handleAddField: Creators.addField,
	handleRemoveField: Creators.removeField,
	fireSubscriptionsByEventName: Creators.fireSubscriptionsByEventName
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositeField);
