import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = [thunk.withExtraArgument()];

function configureStore(initialState) {
	let store = null;
	if (process.env.NODE_ENV === "production") {
		store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
	} else {
		const actionSanitizer = (action) => {
			if (action.type === "SET_DRAWER_OPEN") {
				return{
					...action,
					drawerControlInfo: {
						...action.drawerControlInfo,
						displayComponent: action.drawerControlInfo.displayComponent ?"<<component>>" : undefined
					}
				};
			}
			return action;
		};
		const stateSanitizer = (state) => {
			return { ...state, 
				transientElements: {
					...state.transientElements,
					drawerOpen: {
						...state.transientElements?.drawerOpen,
						globalTabbedDrawer: {
							...state.transientElements?.globalTabbedDrawer?.drawerOpen,
							displayComponent: "<<display_component>>"
						}
					},
					anchorElement: "<<should_be_removed>>"
				},
			};
		};
		const composeEnhancers = composeWithDevTools({
			name: "Main UI Redux",
			trace: true,
			traceLimit: 25,
			actionSanitizer,
			stateSanitizer,
			actionsDenylist: ["SET_ANCHOR_ELEMENT"]
		});
		store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
	}

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("../store", () => {
			const { rootReducer: nextRootReducer } = require("../store");
			store.replaceReducer(nextRootReducer);
		});
	}
	return store;
}

const store = configureStore();

export default store;
