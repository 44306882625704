import { extractInfo } from "../form";


const addCustomEmail = crm => info => {
	const { value, addOptionsToField, updateValue, ownValue = [] } = extractInfo(info);
	addOptionsToField([{
		label: value,
		value
	}]);
	updateValue([...ownValue, {
		label: value,
		value
	}]);
};

export default addCustomEmail;
