import moment from "moment";
const { AdminPrefix, AuthPrefix } = require("@simpleview/sv-auth-client");
import { GraphServer } from "../";

interface Creds {
	client_email: string;
	private_key: string;
}

class ServiceAccount {
	authUrl: string;
	private creds: Creds;
	private authServer: any;
	private expires: moment.Moment;
	private token: string;
	
	constructor(creds: Creds, authUrl: string) {
		this.creds = creds;

		this.authServer = new GraphServer({
			graphUrl: authUrl,
			prefixes: [
				AdminPrefix,
				AuthPrefix
			]
		});

		this.expires;
		this.token;
	}

	async getToken() {
		if (this.token !== undefined && moment().isBefore(this.expires)) {
			return this.token;
		}

		const res = await this.authServer.auth.login_service_account({
			input: {
				email: this.creds.client_email,
				private_key: this.creds.private_key
			},
			fields: "success message token"
		});

		if (!res.success) {
			throw new Error(res.message);
		}

		this.expires = moment().add(6, "days");
		this.token = res.token;

		return res.token;
	}
}

export {
	ServiceAccount
};