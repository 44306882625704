import { extractInfo } from "../form";

const getNAICSCodes = crm => async info => {
	const { updateField } = extractInfo(info);
	updateField({ hidden: false });
	await crm.get({
		type: "NAICSCode",
		fields: `
			recId
			Title
			children {
				...naics
				children {
					...naics
				}
			}
		`,
		fragments: `
			fragment naics on crm_naicsCode {
				recId
				Title
			}
		`
	}).then(res => {
		if (res.success) {
			const options = [{
				recId: res.recs[0].recId,
				Title: res.recs[0].Title,
				Level: res.recs[0].Level,
				children: res.recs[0].children
			}];
			updateField({ options });
		} else {
			updateField({ options: [] });
		}
	});
};

export default getNAICSCodes;
