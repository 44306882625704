import React, { useCallback, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
const moment = require("moment-timezone");
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DATE_PICKER_PROP_TYPES, ERROR_TYPES } from "../../../common/consts";
import { connect } from "react-redux";
import { Creators } from "../../../components/Form/actions";
const { IS_VALID_DATE } = ERROR_TYPES;
import ViewOnly from "../ViewOnly";
import { formatDate } from "../../../common/format";
import FormHelperText from "@material-ui/core/FormHelperText";

const dateFormat = "YYYY-MM-DD";

const DateField = props => {
	const { field, value, onChange, onBlur, error, errorMsg, setValidation, clearValidation, localeSettings, viewOnly, preventInput = false, size } = props;
	const { name, label, overrides = {}, readOnly, maxLength, useClientTimezone, helperText } = field;

	const [selectedDate, setDate] = useState(null);

	const onChangeCallback = useCallback(date => {
		let updatedDate = date;

		if (updatedDate === null) {
			onChange(updatedDate);
			return;
		}

		if (date && date.isValid() && useClientTimezone && localeSettings.timeZone) {
			updatedDate = date.tz(localeSettings.timeZone);
		}

		onChange(updatedDate.format(dateFormat));
	}, [onChange]);

	const onErrorCallback = React.useCallback((error, date) => {
		if (date && moment.isMoment(date) === false) {
			date = moment(date);
		} else if (!date) {
			clearValidation(name, undefined, undefined, IS_VALID_DATE);
			return;
		}

		if (error && date.isValid() === false) {
			setValidation(name, undefined, undefined, IS_VALID_DATE, error);
		} else if (!error && date.isValid()) {
			clearValidation(name, undefined, undefined, IS_VALID_DATE);
		}
	}, [setValidation, selectedDate]);

	DATE_PICKER_PROP_TYPES.forEach(key => {
		if (typeof props[key] !== "undefined") {
			overrides[key] = props[key];
		}
	});

	const defaults = {
		fullWidth: true,
		name,
		id: name,
		label,
		inputVariant: "outlined",
		margin: "none",
		...overrides
	};

	useEffect(() => {
		let updatedValue = value;
		if (value && moment(value).isValid() && useClientTimezone && localeSettings.timeZone) {
			updatedValue = moment(value).tz(localeSettings.timeZone);
		}

		setDate(updatedValue);
	}, [value]);

	if (viewOnly) {
		return <ViewOnly
			label={label}
			value={field.type === "Date" && selectedDate ? moment(selectedDate).format("ll") : formatDate(selectedDate)}
		/>;
	}

	const inputProps = { readOnly, maxLength };

	if (preventInput === true) {
		inputProps.onKeyDown = (e) => {
			e.preventDefault();
		};
	}

	return (
		<div>
			<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="en">
				<KeyboardDatePicker
					{...defaults}
					value={selectedDate || null}
					onChange={onChangeCallback}
					onBlur={onBlur}
					inputProps={inputProps}
					disabled={readOnly}
					error={error}
					onError={onErrorCallback}
					helperText={errorMsg}
					FormHelperTextProps={{ className: "helper-text" }}
					format="MM/DD/YYYY"
					size={size}
				/>
			</MuiPickersUtilsProvider>
			<FormHelperText
				className="helper-text"
				variant="outlined"
			>
				{helperText}
			</FormHelperText>
		</div>
	);
};

const mapDispatchToProps = {
	setValidation: Creators.setValidation,
	clearValidation: Creators.clearValidation
};
const mapStateToProps = (state) => ({
	localeSettings: state.localeSettings
});
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(DateField));
