// from routing
export const CONTACT = "contact";
export const ACCOUNT = "account";
export const LISTING = "listing";
export const INQUIRY = "inquiry";
export const OFFER = "offer";
export const MEDIA = "media";
export const EVENTCALENDAR = "eventCalendar";
export const ADMIN = "admin";
export const TAG = "tag";
export const AMENITY = "amenity";
export const AMENITYCATEGORY = "amenityCategory";
export const BENEFITSUMMARY = "benefitSummary";
export const MEETINGFACILITY = "meetingFacility";
export const PREFERENCE = "preference";
export const DROPDOWN = "dropdown";
export const ADMINMODULE = "module";
export const SVADMIN = "svadmin";
export const USER = "user";
export const MYPROFILE = "myProfile";
export const RECYCLEBIN = "recyclebin";
export const SUMMARY = "summary";
export const SUMMARY_PARAM = ":recId";
export const GRID = "grid";
export const VISITREASON = "visitReason";
export const VISITOR = "visitor";
export const CONNECTION = "connection";
export const CONNECTIONGROUP = "connectionGroup";
export const NOTE = "note";
export const TASK = "task";
export const COMMUNICATION = "comm";
export const REFERRAL = "referral";
export const HISTORY = "history";
export const BROCHURE = "brochure";
export const CUSTOMFIELD = "CustomField";
export const MARKETSEGMENT = "marketSegment";
export const PREFIX = "prefix";
export const REFERRALSOURCE = "referralSource";
export const PRIORITY = "priority";
export const PRIMARYBUSINESSCATEGORY = "primaryBusinessCategory";
export const OFFERCATEGORY = "offerCategory";
export const NOTECATEGORY = "noteCategory";
export const SOURCECODE = "sourceCode";
export const SALUTATION = "salutation";
export const REGION = "region";
export const TASKTYPE = "taskType";
export const LISTINGRANK = "listingRank";
export const GROUP = "group";
export const LOCATION = "location";
export const CONTACTROLE = "contactRole";
export const BATCHREFERRAL = "batchReferral";
export const DEPARTMENT = "department";
export const ENGAGEMENTLEVEL = "engagementLevel";
export const EVENTCATEGORY = "eventCategory";
export const MODULESETTING = "moduleSetting";
export const MODULESETTINGGROUP = "moduleSettingGroup";
export const LISTINGTYPE = "listingType";
export const LISTINGCATEGORY = "listingCategory";
export const LISTINGSUBCATEGORY = "listingSubCategory";
export const INQUIRYTYPE = "inquiryType";
export const EVENTCALENDARRANK = "eventCalendarRank";
export const INTEREST = "interest";
export const USERROLE = "userRole";
export const FILLREQUEST = "fillRequest";
export const FORMBUILDER = "formBuilder";
export const BULLETINCATEGORY = "bulletinCategory";
export const BULLETIN = "bulletin";
export const CAROUSEL = "carousel";
export const TEMPLATE = "template";
export const EXTRANET = "extranet";
export const ATTRIBUTE = "attribute";

// user settings
export const GENERAL = "general";
export const ADD_NEW = "addNew";
export const KB_LINK = "kbLink";

export const GLOBAL_TABBED_DRAWER = "globalTabbedDrawer";
export const GLOBAL_SNACKBAR = "globalMessage";
export const GLOBAL_DIALOG = "globalAlertDialog";
export const ACCOUNT_CONTACT_REL = "accountContactRel";
export const GLOBAL_SPINNER = "globalSpinner";

export const GRID_HEADER_HEIGHT = 48;
export const GRID_ROW_HEIGHT = 40;
export const GRID_CHECKBOX_WIDTH = 48;
export const GRID_SETTINGS_WIDTH = 48;

export const GLOBAL_TRANSIENT_ELEMENTS = {
	DIALOG: GLOBAL_DIALOG,
	SNACKBAR: GLOBAL_SNACKBAR,
	DRAWER: GLOBAL_TABBED_DRAWER,
	SPINNER: GLOBAL_SPINNER
};
export const DEFAULT_AUTO_HIDE_DURATION = 5000;

export const CUSTOM_FILTER_NAME = "custom_filter";

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm a";

export const ADD = "Add New";
export const EDIT = "edit";
export const CLONE = "clone";
export const REVIEW = "review";
export const APPLY = "apply";
export const ACTIONS = {
	EDIT,
	CLONE
};

export enum FormActions {
	ADD = "Add New",
	EDIT = "edit",
	CLONE = "clone",
	REVIEW = "review",
	APPLY = "Apply To Form"
}

export const CALENDAR_MODAL_NAME = "calendarModal";
export const DIFF_MODAL_NAME = "diffModal";

/* String Labels */
export const ADD_NEW_STR = "Add New";

export const ADMIN_DD_REF_TABLES = [
	"EVENTCALENDARRANK",
	"ENGAGEMENTLEVEL",
	"ENGAGEMENTTYPE",
	"EVENTCATEGORY",
	"GENDER",
	"LISTINGRANK",
	"NOTECATEGORY",
	"OFFERCATEGORY",
	"PREFIX",
	"PRIMARYBUSINESSCATEGORY",
	"PRIORITY",
	"REGION",
	"REFERRALSOURCE",
	"SALUTATION",
	"TAG",
	"TASKTYPE",
	"INTEREST",
	"BROCHURE",
	"DEPARTMENT",
	"INQUIRYTYPE",
	"CONTACTROLE",
	"MARKETSEGMENT",
	"SOURCECODE",
	"TEMPLATE",
	"VISITREASON",
	"CONNECTION",
	"CONNECTIONGROUP",
	"GROUP",
	"LOCATION",
	"USERROLE",
	"LISTINGTYPE",
	"LISTINGCATEGORY",
	"AMENITYCATEGORY",
	"LISTINGSUBCATEGORY",
	"BULLETINCATEGORY",
	"BULLETIN",
	"CAROUSEL"
];

export const RECENTLY_VIEWED_DRAWER_NAME = "recentlyViewed";
export const GRID_OPTIONS_DRAWER_NAME = "gridOptions";
export const ALERT_DRAWER_NAME = "alerts";
