import uniqueId from "lodash/uniqueId";

import store from "../../../store";
import { Creators } from "../../../actions/transientElementActions";
import { ACCOUNT_CONTACT_REL, CLONE, FORMBUILDER } from "../../../common/consts";
import index from "../../../common/arrayIndex";
import isEmpty from "../../../common/isEmpty";

let dispatch;

const showError = message => dispatch(Creators.setMessage(message, "error"));

let crmGraphServer;

const getFormSettings = (type, recId, action, refId) => {
	//TODO WTF EVEN IS THIS?
	const defaults = {
		useFilter: action !== "add",
		useType: false,
		typeArray: type.split("."),
		filterName: "crm_recId_filter"
	};
	defaults.formType =
		defaults.typeArray.length === 1 ? type : defaults.typeArray[1];
	defaults.dataType = defaults.formType;

	// ADD SPECIAL FORM SETTINGS HERE
	const settings = {
		account: { ...defaults, filterName: "crm_account_filter" },
		"account.comm": {
			...defaults,
			formType: "account_comm",
			dataType: "account_comm",
			filterName: "crm_comm_filter"
		},
		"contact.comm": {
			...defaults,
			formType: "contact_comm",
			dataType: "contact_comm",
			filterName: "crm_comm_filter",
			filter: { recId, refId },
			useFilter: true
		},
		[`account.${ACCOUNT_CONTACT_REL}`]: {
			...defaults,
			formType: ACCOUNT_CONTACT_REL,
			filterName: `crm_${ACCOUNT_CONTACT_REL}_filter`,
			filter: { AccountID: recId, ContactID: refId },
			useFilter: true
		},
		"meetingFacilityRoom": {
			...defaults,
			formType: "account_meetingFacilityRoom",
			dataType: "account_meetingFacilityRoom",
			filterName: "crm_account_meetingFacilityRoom_filter"
		},
		"relatedAccount": {
			...defaults,
			formType: "account_relatedAccount",
			dataType: "account_relatedAccount",
			filterName: "crm_account_relatedAccount_filter",
			filter: { AccountID: recId, ParentAccountID: refId },
			useFilter: true
		},
		"account.note": {
			...defaults,
			filterName: "crm_account_note_filter",
			dataType: "account_note"
		},
		"account.task": {
			...defaults,
			filterName: "crm_account_task_filter",
			dataType: "account_task"
		},
		"account.media": {
			...defaults,
			filter: { refId: Number(refId), recId: Number(recId), type: "account" }
		},
		"eventCalendar.media": {
			...defaults,
			formType: "eventCalendar_media",
			filter: { refId: Number(refId), recId: Number(recId), type: "eventCalendar" }
		},		
		contact: {
			...defaults,
			filterName: "crm_contact_filter"
		},
		"contact.note": {
			...defaults,
			filterName: "crm_contact_note_filter",
			dataType: "contact_note"
		},
		"contact.task": {
			...defaults,
			filterName: "crm_contact_task_filter",
			dataType: "contact_task"
		},
		listing: {
			...defaults,
			formType: action === "review" ? `${defaults.formType}_review`: defaults.formType,
			dataType: action === "review" ? `${defaults.dataType}_review`: defaults.dataType,
			filterName: action === "review" ? "crm_listing_review_filter" : "crm_listing_filter"
		},
		offer: {
			formType: action === "review" ? `${defaults.formType}_review`: defaults.formType,
			dataType: action === "review" ? `${defaults.dataType}_review`: defaults.dataType,
			filterName: action === "review" ? "crm_offer_review_filter" : "crm_offer_filter"
		},
		eventCalendar: {
			formType: action === "review" ? `${defaults.formType}_review`: defaults.formType,
			dataType: action === "review" ? `${defaults.dataType}_review`: defaults.dataType,
			filterName: action === "review" ? "crm_eventCalendar_review_filter" : "crm_eventCalendar_filter"
		},
		"offer.note": {
			...defaults,
			filterName: "crm_offer_note_filter",
			dataType: "offer_note"
		},
		"listing.note": {
			...defaults,
			filterName: "crm_listing_note_filter",
			dataType: "listing_note"
		},
		"inquiry.note": {
			...defaults,
			filterName: "crm_inquiry_note_filter",
			dataType: "inquiry_note"
		},
		"eventCalendar.note": {
			...defaults,
			filterName: "crm_eventCalendar_note_filter",
			dataType: "eventCalendar_note"
		},
		[`contact.${ACCOUNT_CONTACT_REL}`]: {
			...defaults,
			formType: ACCOUNT_CONTACT_REL,
			filterName: `crm_${ACCOUNT_CONTACT_REL}_filter`,
			filter: { AccountID: recId, ContactID: refId },
			useFilter: true
		},
		note: { ...defaults, useFilter: false },
		noteCategory: { ...defaults, useFilter: false },
		visitor: {
			...defaults,
			filterName: "crm_visitor_filter"
		},
		batchReferral: {
			...defaults,
			filterName: "crm_batchReferral_filter"
		},
		contactextranetsecurity: {
			...defaults,
			filter: { refId: Number(refId), recId: Number(recId) }
		}
	};

	return settings[type] || settings[defaults.formType] || defaults;
};

const getFieldData = async ({
	fieldNames,
	filter,
	dataType,
	hasCustomFields,
	hasConnections,
	recId,
	fields,
	PendingID
}) => {
	let customFieldQry = "";
	if (hasCustomFields) {
		customFieldQry = `CustomField { UDFID RecordID FieldType DataType UDFieldValue Attachments { recs { id name path url } } Asset { recs { AssetID AssetPath MediaID Title } } }`;
	}

	let connectionQry = "";
	if (hasConnections) {
		connectionQry = `Connection { ConnectionID FieldType DataType ConnectionValue }`;
	}

	const tempFilter = {};
	if (PendingID) {
		tempFilter.PendingID = PendingID;
	} else if (dataType.startsWith(FORMBUILDER)) {
		tempFilter.recId = recId;
	} else {
		tempFilter.recId = Number(recId);
	}

	const res = await crmGraphServer.general.get({
		type: dataType,
		fields: `${fieldNames
			.filter((field) => field != null && field.indexOf("UDField_") === -1 && field.indexOf("Connection_") === -1)
			.join(" ")} ${customFieldQry} ${connectionQry}`,
		filter: filter ? filter : tempFilter
	});

	if (res.count === 0) {
		return {};
	}

	const data = res.recs[0];

	const compositeFields = fields.filter((field) => field.type === "composite" && !field.noFetch);
	compositeFields.forEach((field) => {
		data[field.name].compositeFields = data[field.name].recs;
		delete data[field.name].recs;
	});

	return data;
};

// TODO: we can update the getters, so that they're on the main getRootData query
const getOptionData = async ({
	 fieldOptions,
	 formType,
	 getters,
	 recId,
	 fieldData = {},
}) => {
	let queryString = "";

	// TODO we will probably want to get the options by the current group
	// should be able to get that from store, once that's in place
	fieldOptions.forEach((option) => {
		const currentId = fieldData[option.name];
		let currentIdString = "";

		if (currentId) {
			if (currentId instanceof Array) {
				if (currentId.length > 0) {
					currentIdString = ` , currentId: [${currentId}]`;
				}
			} else {
				currentIdString = ` , currentId: ${currentId}`;
			}
		}

		let filterString = "";

		if (option.options.filter) {
			option.options.filter.forEach((filter, i) => {
				if (filter.useRefId) {
					filterString += `${filter.name}: ${recId}`;
				} else {
					filterString += `${filter.name}: "${filter.value}"`;
				}
				if (i < option.options.filter.length - 1) {
					filterString += ", ";
				}
			});
		}

		// hack for right now, since get_groups returns a different contract than the rest
		if (["contacts", "get_groups", "eventCategorys", "listingTypes"].includes(option.options.name)) {
			queryString += `
				${option.options.name}(filter: { IsActive: true ${option.options.name !== "eventCategorys" ? ", IsDeleted: false" : ""} }) {
					recs {
						${option.options.label}
						${option.options.value}
						${option.options.hasPermissions || ""}
					}
				}
			`;
		} else if (option.name.indexOf("UDField_") != -1) {
			const idarray = option.name.split("_");
			queryString += `
				${option.name}: ${option.options.name}(UDFRecordID: ${idarray[1].replace("neg", "-")}, type: "${formType}") { success message count recs {
				${option.options.label}
				${option.options.value}
			}}`;
		} else {
			if (option.isActiveOnly) {
				if (filterString.length > 0) {
					filterString += `, isActiveOnly: true${currentIdString}`;
				} else {
					filterString += `isActiveOnly: true${currentIdString}`;
				}
			}

			if (filterString.length > 0) {
				filterString = `(${filterString})`;
			}

			queryString += `
				${option.options.name} ${filterString} {
					recs {
						${option.options.label}
						${option.options.value}
						${option.isActiveOnly ? "IsActive" : ""}
					}
				}
			`;
		}
	});
	getters.forEach((getter) => {
		if (getter.name.indexOf("UDField_") != -1) {
			const idarray = getter.name.split("_");
			queryString += `get_${getter.name}: ${getter.getter}(type: "${formType}", refId: ${recId}, UDFRecordID: ${idarray[1].replace("neg", "-")}) { success message count recs {`;
			queryString += "UDFieldListItemID";
			queryString += "}}";
		} else {
			queryString += `${
				getter.getter
			}(type: "${formType}", refId: ${recId}) { success message count recs {`;

			if (getter.compositeFields) {
				queryString += getter.compositeFields.join(" ");
			} else if (getter.fieldNames) {
				queryString += getter.fieldNames.join(" ");
			} else {
				queryString += getter.name;
			}
			queryString += "}}";
		}
	});
	if (queryString.length > 0) {
		queryString = `query getFieldData($acct_id: String!, $productName: String) { crm(acct_id: $acct_id, productName: $productName) {
${queryString}
}}`;

		const data = await crmGraphServer.general.getFormData({
			queryString
		});

		return data;
	}

	return {};
};

const getData = async ({
	action,
	fieldNames,
	fieldOptions,
	filter,
	dataType,
	formType,
	getters,
	hasCustomFields,
	hasConnections,
	recId,
	typeArray,
	fields,
	PendingID
}) => {
	if (action === "add") {
		const optionData = await getOptionData({
			fieldOptions,
			formType,
			getters,
			recId,
			typeArray
		});
		return {
			optionData,
			fieldData: {}
		};
	}

	const fieldData = await getFieldData({
		fieldNames,
		filter,
		dataType,
		hasCustomFields,
		hasConnections,
		recId,
		fields,
		PendingID
	});
	const optionData = await getOptionData({
		fieldOptions,
		formType,
		getters,
		recId,
		fieldData,
		typeArray
	});

	return { optionData, fieldData };
};

const organizeSubscriptionsByField = (subscriptionsArry) => {
	return subscriptionsArry.reduce((collection, subscription) => {
		const {
			subscribeTo,
			subscribeToFields = [],
			subscribeEvent,
			subscribeEvents = [],
			name,
			args,
			subscriber,
			debounce
		} = subscription;

		const addSubscription = (field, event) => {
			if (!collection[field]) {
				collection[field] = {};
			}
			if (!collection[field][event]) {
				collection[field][event] = [];
			}
			collection[field][event].push({ name, args, subscriber, debounce });
		};

		const fields = subscribeToFields || [];
		const events = subscribeEvents || [];

		if (subscribeTo) {
			fields.push(subscribeTo);
		}

		if (subscribeEvent) {
			events.push(subscribeEvent);
		}

		if (fields.length === 0) {
			fields.push(subscriber);
		}

		fields.forEach((field) => {
			events.forEach((event) => {
				addSubscription(field, event);
			});
		});

		return collection;
	}, {});
};

export async function initForm_v2({ module, action, id, parentId }) {
	const { crmGraphServer } = store.getState().login;
	const res = await crmGraphServer.form.fetchCoreFormWithData(module, action, id);
	return res;
}

const initForm = async ({ updateLoading, type, recId, action, refId, PendingID, dispatch, getState }) => {
	if (!dispatch || !getState) {
		getState = store.getState;
		dispatch = store.dispatch;
	}
	updateLoading(true);
	const generalFormCalls = ["listing_amenity", "listing_attribute"];
	const {
		dataType,
		formType,
		typeArray,
		useType,
		useFilter,
		filterName,
		filter
	} = getFormSettings(type, recId, action, refId);
	const getter = `${formType}_${action === "modal" ? "modal" : "form"}`;
	({ crmGraphServer } = getState().login);

	if (generalFormCalls.includes(type)) {

		const res = await crmGraphServer.general.getForm({ formType: getter, recId });

		const data = JSON.parse(res.data);
		const def = JSON.parse(res.formDef);

		updateLoading(false);
		return {
			data,
			dynamicFields: {},
			fields: def.fields,
			actions: def.actions,
			layout: def.layout,
			formTitle: def.name,
			formLabel: def.label,
			subscriptions: {},
			defaultData: {},
			recId: Number(recId)
		};
	} else {
		const res = await crmGraphServer.general
			.get({
				type: getter,
				fields: `
			hasCustomFields
			hasConnections
			name
			label
			actions {
				label
				callback
				args
				type
				color
				subscriptions {
					subscribeTo
					subscribeToFields
					subscribeEvent
					subscribeEvents
					name
					args
					debounce
				}
			}
			fields {
				...fields
			}
			layout {
				sections {
					name
					label
					hidden
					columns {
						width
						fields
						rows {
							fields
							columns {
								width
								fields
							}
							width
						}
					}
					rows {
						dense
						fields
						columns {
							width
							fields
							rows {
								dense
								fields
								columns {
									width
									fields
								}
								width
							}
						}
						width
					}
					subscriptions {
						subscribeTo
						subscribeToFields
						subscribeEvent
						subscribeEvents
						name
						args
						debounce
					}
				}
				compositeFields {
					...composites
				}
			}
		`,
				fragments: `
			fragment composites on crm_compositeField {
				name
				flexCount
				flexDirection
				rows {
					dense
					fields
					columns {
						width
						fields
					}
				}
			}
			fragment fields on crm_field {
				name
				label
				labelMap
				map {
					label
					value
				}
				convertData {
					oldLabels
					newLabels
				}
				chipsLabel
				hideLabel
				confirmRemove
				confirmRemoveTitle
				type
				dataType
				required
				readOnly
				componentName
				inputType
				validators
				fileAppendText {
					label
					labelLink {
						href
						target
					}
					dialog {
						callWithValuesFromFields
						onLoad
						onOpen
						multiFile
					}
				}
				relOptions {
					name
					label
					value
					filterField
					hasPermissions
					filter {
						name
						value
						useRefId
					}
				}
				subFields
				limitedUseOptions
				async
				getter
				rows
				rowsMax
				mode
				getterFieldNames
				setter
				addField
				delField
				showSeparator
				hidden
				defaultInt
				defaultString
				defaultBoolean
				defaultObject
				useInitialValue
				dynamicDefaultIsRefId
				dynamicDefaultIsRecId
				isClearable
				isActiveOnly
				permissionCheck
				displayOnly
				valuePrefix
				valueSuffix
				wrapper
				module
				formTitle
				subscriptions {
					subscribeTo
					subscribeToFields
					subscribeEvent
					subscribeEvents
					name
					args
					debounce
				}
				creatable {
					moduleName
					callback
				}
				props {
					name
					value
				}
				tooltipText
				helperText
				defaultSelect {
					listValueString
					listValue
					listDesc
				}
				noFetch
				gridOptions
				noSubmit
				useClientTimezone
				yesLabel
				noLabel
				groupId
				groupName
				options {
					label
					value
				}
			}
		`
			});

		//TODO Not sure how to handle cancelling atm. hopefully it's not needed from this side anymore.
		// if (getState().form.cancel) {
		// 	dispatch(Creators.resetForm(false));
		// 	return;
		// }
		if (res.success === false) {
			showError("There was an issue fetching the form's configuration.", "error");
			return;
		}

		const def = res.recs[0];
		const defaultData = {};
		const emptyData = {};
		const dynamicFields = [];
		const fieldNames = [];
		const fieldOptions = [];
		const asyncFieldOptions = [];
		const getters = [];
		let subscriptionsArry = [];
		const compositeFields = {};
		const fieldIndex = {};
		let loadGoogleMaps = false;

		const organizeFieldsByName = (array) => {
			return array.reduce((object, fld) => {
				object[fld.name] = fld;
				return object;
			}, {});
		};

		def.fields.forEach((field) => {
			field.fieldId = `field_${uniqueId()}`;

			if (field.subscriptions) {
				subscriptionsArry = subscriptionsArry.concat(
					field.subscriptions.map((subscription) => ({
						...subscription,
						subscriber: field.name
					}))
				);
			}

			if (field.type === "PrivateFiles" || field.type === "PrivateFile") {
				field.isPrivate = true;
			}

			if (["AddressSearch", "Map", "Address_v2"].includes(field.type)) {
				loadGoogleMaps = true;
			}

			if (field.props) {
				field.props.forEach(({ name, value }) => field[name] = value);
				delete field.props;
			}

			if (field.defaultInt) {
				defaultData[field.name] = field.defaultInt;
			}

			if (field.defaultString) {
				defaultData[field.name] = field.defaultString;
			}

			if ("defaultBoolean" in field && !field.name.includes("$comp_")) {
				defaultData[field.name] = field.defaultBoolean;
			}

			if (field.dynamicString) {
				dynamicFields.push(field);
			}

			if (field.defaultObject) {
				defaultData[field.name] = field.defaultObject;
			}

			if (
				refId &&
				field.dynamicDefaultIsRefId &&
				field.dynamicDefaultIsRefId === type &&
				/*field.async &&*/ field.type === "Select"
			) {
				defaultData[field.name] = { value: Number(refId) };
			}

			if (refId && type === "listing.eventCalendar" && field.name === "VenueListingID" && field.type === "Select") {
				defaultData[field.name] = { value: Number(refId) };
			} else if (refId && type === "account.eventCalendar" && field.name === "VenueAccountID" && field.type === "Select") {
				defaultData[field.name] = { value: Number(refId) };
			}

			// labelMap is used for single selects, where we're already getting the ID from the name (e.g. ListingTypeID)
			// and we also want the label
			if (field.labelMap && !field.noFetch) {
				fieldNames.push(field.labelMap);
			}

			// logic for composite fields
			if (field.name.match(/^\$comp_/)) {
				const fieldSplit = field.name.split("_");
				if (compositeFields[fieldSplit[1]] === undefined) {
					compositeFields[fieldSplit[1]] = [];
				}
				compositeFields[fieldSplit[1]].push(fieldSplit[2]);
				field.parent = fieldSplit[1];
				field.dataName = fieldSplit[2];
			}

			// labelMap is used for single selects, where we're already getting the ID from the name (e.g. ListingTypeID)
			// and we also want the label
			if (field.labelMap && !field.parent && !field.noFetch) {
				fieldNames.push(field.labelMap);
			}

			// this is a poc on replacing field.getter
			if (field.map && !field.noFetch) {
				if (field.parent) {
					compositeFields[field.parent].push(field.map.label);
				} else {
					fieldNames.push(
						`${field.name} { recs { ${field.map.value} ${field.map.label} ${field.permissionCheck ? "hasPermissions" : ""} } }`
					);
				}
			} else if (field.convertData && !field.noFetch) {
				fieldNames.push(
					`${field.name} { recs { ${field.convertData.oldLabels.join(" ")} } }`
				);
			}


			if (!field.getter && !field.map && !field.noFetch && !field.convertData &&
				!field.subFields) {
				if (field.name != null && field.name.indexOf("UDField_") !== -1) {
					def.hasCustomFields = true;
				} else if (
					!["button", "composite"].includes(field.type.toLowerCase()) &&
					field.name.indexOf("$comp_") < 0
				) {
					fieldNames.push(field.name);
				}
			}

			if (field.relOptions) {
				if (field.async) {
					asyncFieldOptions.push({
						name: field.name,
						options: field.relOptions,
						getter: field.getter
					});
				}
				if (!field.async || (field.async && !field.getter)) {
					fieldOptions.push({
						name: field.name,
						options: field.relOptions,
						isActiveOnly: field.isActiveOnly || false
					});
				}
			}

			if (field.getter && !field.map) {
				getters.push({
					name: field.name,
					getter: field.getter,
					fieldNames: field.getterFieldNames,
					async: field.async,
					relOptions: field.relOptions,
					compositeFields:
						field.type === "composite" ? def.fields
							.filter(
								(fld) => fld.name.indexOf(`$comp_${field.name}_`) === 0
							)
							.map((fld) => fld.name.split("_")[2])
							: null
				});
			}

			if (field.subFields && !field.map) {
				fieldNames.push(`${field.name} { recs { ${field.subFields.join(" ")} }}`);
			}

			if (
				action === "add" &&
				field.name.indexOf("$comp_") < 0 &&
				(defaultData[field.name] === undefined || defaultData[field.name] === null)
			) {
				defaultData[field.name] = "";
			}

			emptyData[field.name] = "";

			fieldIndex[field.name] = field;
		});
		def.layout.sections.forEach(section => {
			if (section.subscriptions) {
				subscriptionsArry = subscriptionsArry.concat(
					section.subscriptions.map((subscription) => ({
						...subscription,
						subscriber: section.name
					}))
				);
			}
		});

		// if it's new and there are no fieldOptions to get, we can return here
		if (action === "add" && fieldOptions.length === 0) {
			const fields = organizeFieldsByName(def.fields);
			const subscriptions = organizeSubscriptionsByField(subscriptionsArry);

			return {
				data: { ...defaultData },
				dynamicFields,
				fields,
				actions: def.actions,
				layout: def.layout,
				formLabel: def.label,
				formTitle: "Add New",
				subscriptions,
				defaultData: { ...emptyData, ...defaultData },
				recId
			};
		}

		// composite field logic
		for (const field in compositeFields) {
			compositeFields[field].forEach(val => {
				const compField = fieldIndex[`$comp_${field}_${val}`];
				if (compField && compField.labelMap) {
					compositeFields[field].push(compField.labelMap);
				}
			});

			if (!fieldIndex[field].noFetch) {
				fieldNames.push(
					`${field} { recs { ${compositeFields[field].join(" ")} } }`
				);
			}
		}

		const resData = await getData({
			action,
			fieldNames,
			fieldOptions,
			filter,
			filterName,
			dataType,
			formType,
			getters,
			hasCustomFields: def.hasCustomFields,
			hasConnections: def.hasConnections,
			recId,
			refId,
			typeArray,
			useFilter,
			useType,
			fields: def.fields,
			PendingID
		});

		// if (getState().form.cancel) {
		// 	dispatch(Creators.resetForm(false));
		// 	return;
		// }

		if (resData === null) {
			showError("There was an error loading the form. Please contact your administrator");
			return;
		}

		const { fieldData, optionData } = resData;

		const options = fieldOptions.map((option) => {
			// short term hack for get_groups
			if (option.options.name === "get_groups") {
				const recs = optionData[option.options.name].recs || [];
				return {
					name: option.name,
					options: recs.map((val) => {
						const toReturn = {
							label: val[option.options.label],
							value: val[option.options.value],
							hasPermissions: val[option.options.hasPermissions]
						};
						return toReturn;
					})
				};
			} else if (option.name.indexOf("UDField_") != -1) {
				return {
					name: option.name,
					options: optionData[option.name].recs ? optionData[option.name].recs.map(val => ({
						label: val[option.options.label],
						value: val[option.options.value]
					})) : []
				};
			} else {
				const recs = optionData[option.options.name].recs || [];
				return {
					name: option.name,
					options: recs.map((val) => ({
						label: val[option.options.label],
						value: val[option.options.value],
						isActive: option.isActiveOnly ? val.IsActive : undefined
					}))
				};
			}
		});

		for (const val of asyncFieldOptions) {
			if (optionData[val.getter] && optionData[val.getter].length) {
				const refs = await crmGraphServer.general.get_old({
					type: val.options.name,
					queryString: `
					${val.options.name} (recId: [${optionData[val.getter].map((res) => res[val.options.value]).join(",")}]) {
						recId
						${val.options.label}
					}
				`
				});

				if (refs) {
					options.push({
						name: val.name,
						options: refs
							.filter((option) => option)
							.map((option) => ({
								label: option[val.options.label],
								value: option.recId
							}))
					});
				}
			}
		}

		getters.forEach((getter) => {
			if (getter.compositeFields) {
				const recs = resData.fieldData[getter.getter] ? resData.fieldData[getter.getter].recs : [];
				const keys = recs[0] ? Object.keys(recs[0]) : [];
				const compositeFields = [];
				recs.forEach((val) => {
					const compositeField = {};
					keys.forEach((key) => {
						if (
							formType === "contact" &&
							action === "clone" &&
							["Address", "Email", "Phone"].includes(getter.name)
						) {
							// clean up contacts for clones
							compositeField[key] = "";
						} else {
							compositeField[key] = val[key];
						}
					});

					compositeFields.push({ ...compositeField });
				});
				if (compositeFields.length === 0) {
					compositeFields.push({});
				}
				fieldData[getter.name] = { compositeFields };
			} else if (getter.fieldNames) {
				fieldData[getter.name] = optionData[getter.getter];
			} else if (getter.name.indexOf("UDField_") != -1) {
				fieldData[getter.name] = optionData[`get_${getter.name}`].recs.map(val => val[getter.relOptions.value]);
			} else {
				const recs = resData[getter.getter]
					? resData[getter.getter].recs
					: [];
				fieldData[getter.name] = recs.map((val) => val[getter.name]);
			}
		});

		// Process CustomFields.
		if (fieldData && fieldData.CustomField) {
			for (const cfield of fieldData.CustomField) {
				const key = `UDField_${cfield.RecordID}_${cfield.FieldType}`.replace(/-/g, "neg");
				if (cfield.FieldType === "Select") {
					fieldData[key] = parseInt(cfield.UDFieldValue);
				} else if (cfield.FieldType === "FileUpload") {
					fieldData[key] = cfield.Attachments;
				} else if (cfield.FieldType === "ImageUpload") {
					fieldData[key] = cfield.Asset;
				} else if (cfield.FieldType !== "Chip" && cfield.FieldType !=="MultiSelect") {
					fieldData[key] = cfield.UDFieldValue;
				} 
			}
			delete fieldData.CustomField;
		}

		// Process Connections.
		if (fieldData && fieldData.Connection) {
			for (const cfield of fieldData.Connection) {
				const key = `Connection_${cfield.ConnectionID}_${cfield.FieldType}`;
				fieldData[key] = cfield.ConnectionValue;
			}
			delete fieldData.Connection;
		}

		const optionIndex = options.reduce((collection, opt) => {
			collection[opt.name] = opt.options;
			return collection;
		}, {});
		const data = { ...defaultData, ...fieldData };
		let formTitle;

		def.fields.forEach((field) => {
			if (optionIndex[field.name]) {
				field.options = [...optionIndex[field.name]];
			}

			if (
				refId &&
				field.dynamicDefaultIsRefId &&
				field.dynamicDefaultIsRefId === type &&
				(!data[field.name] || (data[field.name] && String(data[field.name]).length === 0))
			) {
				if (field.type === "Select" && field.options) {
					const indexedRefOptions = index(field.options, "value");
					data[field.name] = indexedRefOptions[refId];
				} else {
					data[field.name] = Number(refId);
				}
			}

			if (
				field.dynamicDefaultIsRecId &&
				field.dynamicDefaultIsRecId === type &&
				(!data[field.name] || data[field.name] && String(data[field.name]).length === 0)
			) {
				if (field.type === "Select" && field.options) {
					const indexedRefOptions = index(field.options, "value");
					data[field.name] = indexedRefOptions[recId].value ? indexedRefOptions[recId].value : 0;
				} else {
					defaultData[field.name] = Number(recId);
				}
			}

			if (field.name.indexOf("$comp_") < 0) {
				if (isEmpty(data[field.name])) {
					data[field.name] = defaultData[field.name] || "";
				}
			} else {
				// TODO: clean this up since it's the same logice as used below for regular fields
				if (field.relOptions && data[field.parent] && field.options && data[field.parent].compositeFields) {
					data[field.parent].compositeFields.forEach(compField => {
						// TODO: for comps with relOptions right now, need to expand to use map and labelMap
						const indexedOptions = index(field.options, "value");
						if (Array.isArray(compField[field.dataName])) {
							compField[field.dataName] = compField[field.dataName].map(val => ({
								label: indexedOptions[val].label,
								value: indexedOptions[val].value
							}));
						} else {
							if (indexedOptions[compField[field.dataName]]) {
								compField[field.dataName] = {
									label: indexedOptions[compField[field.dataName]].label,
									value: indexedOptions[compField[field.dataName]].value
								};
							} else {
								compField[field.dataName] = "";
							}
						}
					});
				}

				if (field.type === "Select" && data[field.parent] && data[field.parent].compositeFields && !field.relOptions) {
					data[field.parent].compositeFields.forEach(compField => {
						if ( field.map ) {
							compField[field.dataName] = { label: compField[field.map.label], value: compField[field.map.value] };
						} else {
							const value = compField[field.dataName];
							const option = { label: value, value };
							if (field.labelMap) {
								option.label = compField[field.labelMap];
							}
							compField[field.dataName] = option;
						}
					});
				}
			}

			if (field.formTitle) {
				formTitle = data[field.name];
			} else if (field.name === "formJSON") {
				formTitle = data[field.name].label;
			} else if (field.name === "config") {
				try {
					const configJSON = JSON.parse(data[field.name]);
					formTitle = configJSON.label;
				} catch(e) {
					//Do nothing if the config isn't what we expect for form builder.
				}
			}

			if (field.labelMap && data[field.name] && data[field.labelMap]) {
				field.options = [
					{ label: data[field.labelMap], value: data[field.name] }
				];
				data[field.name] = { label: data[field.labelMap], value: data[field.name] };
			}

			if (data[field.labelMap] && !field.parent) {
				// we don't need this going to the form on data
				delete data[field.labelMap];
			}

			if (field.convertData && field.convertData.newLabels && field.convertData.oldLabels && data[field.name]) {
				data[field.name] = data[field.name].recs.map(datum => {
					const newObj = {};
					Object.keys(datum).forEach(prop => newObj[field.convertData.newLabels[field.convertData.oldLabels.indexOf(prop)]] = datum[prop]);
					return newObj;
				});
			}

			if (field.map && data[field.name]) {
				if (data[field.name].recs) {
					const options = data[field.name].recs.map(rec => ({
						label: rec[field.map.label],
						value: rec[field.map.value],
						hasPermissions: rec.hasPermissions
					}));
					if (!field.relOptions) {
						field.options = options;
					}
					data[field.name] = options;
				} else if (field.dynamicDefaultIsRefId && field.async) {
					const options = [{
						label: "",
						value: data[field.name].value
					}];
					if (!field.relOptions) {
						field.options = options;
					}
					data[field.name] = options;
				} else {
					field.options = [];
					data[field.name] = data[field.name] && data[field.name].value ? [{ value: data[field.name].value, label: data[field.name].label }] : [];
				}
			}

			// fix for data with fields with relOptions and no labelMap or map
			if (field.relOptions && data[field.name] && field.options && !field.labelMap && !field.map) {
				const indexedOptions = index(field.options, "value");
				if (Array.isArray(data[field.name])) {
					if (field.type !== "Checkbox") {
						data[field.name] = data[field.name].filter(val => indexedOptions[val]).map(val => ({
							label: indexedOptions[val].label,
							value: indexedOptions[val].value
						}));
					}
				} else {
					if (indexedOptions[data[field.name]]) {
						if (field.type !== "Radio") {
							data[field.name] = {
								label: indexedOptions[data[field.name]].label,
								value: indexedOptions[data[field.name]].value
							};
						}
					} else {
						data[field.name] = "";
					}
				}
			}

			if (field.type === "Select" && data[field.name] && !field.labelMap && !field.map && !field.relOptions) {
				data[field.name] = { label: data[field.name], value: data[field.name] };
			}
		});

		const fields = organizeFieldsByName(def.fields);
		const subscriptions = organizeSubscriptionsByField(subscriptionsArry);

		// prepare for clones
		if (action === "clone") {
			delete data.recId;
			delete data.RecordID;
			if (formType === "contact") {
				data.Email.compositeFields = [];
				data.Phone.compositeFields = [];
				data.Address.compositeFields = [];
			}
			formTitle = "Clone";
			delete data.Attachments;
			// TODO, this logic is getting moved to server side
			// and should be handled by the specific module resolver
			if (formType === "task") {
				delete data.CompleteDesc;
				delete data.DueDate;
				delete data.CompleteTask;
			}
			// clear out updated and created data
			delete data.CreatedDt;
			delete data.CreatedBy;
		} else if (action === "add") {
			formTitle = "Add New";
		} else if (action === "edit" && !formTitle) {
			formTitle = "Edit";
		} else if (action === "review") {
			formTitle = "Review";
		}

		return {
			data,
			dynamicFields,
			fields,
			actions: def.actions,
			layout: def.layout,
			formLabel: def.label,
			formTitle,
			subscriptions,
			defaultData: { ...emptyData, ...defaultData },
			recId: action !== CLONE ? recId : 0,
			loadGoogleMaps
		};


	}
};

export default initForm;
