import isRequired from "./isRequired";
import isRequiredHOC from "./isRequiredHOC";
import isRequiredCompare from "./isRequiredCompare";
import isValidDateOrder from "./isValidDateOrder";
import limitedUseOptions from "./limitedUseOptions";
import isEmail from "./isEmail";
import fillAddress from "./fillAddress";
import clearField from "./clearField";
import isFormBuilderRequired from "./isFormBuilderRequired";

const callbacks = {
	isEmail,
	isRequired,
	isRequiredHOC,
	isRequiredCompare,
	isValidDateOrder,
	limitedUseOptions,
	fillAddress,
	isFormBuilderRequired,
	clearField
};

export default { ...callbacks };
