import React from "react";
import { Grid } from "@material-ui/core";
import Column from "./Column";
import Field from "./Field";

// converts old percentage based widths to grid widths
function getGridWidth(width) {
	return width ? Math.floor(12 * (width * .01)) : 12;
}

const Row = ({ classes, columns, fields, name, compField, compIndex, width }) => {
	return <>
		{fields && fields.map(field => {
			const gridWidth = getGridWidth(width);
			return <Grid
				item
				xs={12}
				lg={gridWidth}
				key={field}
			>
				<Field key={field} name={field} classes={classes} compField={compField} compIndex={compIndex} />
			</Grid>;
		})}
		{columns && columns.map((column, i) => {
			const columnName = name + "-column-" + i;
			const gridWidth = getGridWidth(column.width);
			return <Grid
				xs={12}
				lg={gridWidth}
				key={columnName}
				item
			>
				<Column
					key={columnName}
					name={columnName}
					classes={classes}
					compField={compField}
					compIndex={compIndex}
					{...column}
					width={"100%"}
				/>
			</Grid>;
		})}
	</>;
};

export default Row;
