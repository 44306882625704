import { CrmFormCallback } from "./typeDef";
import store from "../../store";
import { GLOBAL_SNACKBAR } from "../consts";

const sendTestComm: CrmFormCallback = crm => ({ getState, setDrawerOpen }): void => {
	const { data } = getState();
	const { email } = store.getState().login;
	const myEmailAddress = {
		email
	};

	crm.sendEmail({
		input: {
			subject: data.SubjectLine,
			from: {
				email: data.FromAddress
			},
			// send to current user for now
			// later it should show a popup with to, cc, bcc selection
			to: [myEmailAddress],
			bodyHTML: data.BodyHTML,
			templateId: data.TemplateID?.value,
			attachments: data.Attachments,
			refs: {
				ReferralID: data.ReferralID || undefined,
				ContactID: data.ContactID?.value,
				AccountID: data.AccountID?.value
			}
		}
	}).then(() => {
		setDrawerOpen(GLOBAL_SNACKBAR, {
			isOpen: true,
			message: `Test Email has been sent to ${myEmailAddress.email}`,
			messageType: "success"
		});
	});
};

export default sendTestComm;
