const checkForPrimary = crm => info => {
	const { subscriber, getState, dispatchWrapper } = info;
	const data = getState().data;

	const accounts = data.Accounts;

	if (accounts.length && !accounts.some(acc => acc.IsPrimary)) {
		accounts[0].IsPrimary = true;
	}

	dispatchWrapper("handleFormChange", [subscriber, "Object", accounts]);
	dispatchWrapper("updateField", [subscriber]);
};

export default checkForPrimary;
