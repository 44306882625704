import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import CompositeField from "./CompositeField";
import { Creators } from "../actions";
import RenderField from "../../Fields";
import fieldNameToDataKey from "../../../common/fieldNameToDataKey";
import { SUBSCRIPTION_TYPES } from "../../../common/consts";

const { ON_LOAD, ON_CHANGE, ON_BLUR, ON_CLICK } = SUBSCRIPTION_TYPES;

const Field = React.memo((props) => {
	const {
		name,
		formType,
		value = "",
		field,
		compField,
		compIndex,
		handleFormChange,
		fireSubscriptionsByEventName,
		classes,
		validation = {},
		setValidation,
		clearValidation,
		viewOnly,
		fieldSize
	} = props;
	if (!field) {
		return null;
	}
	const updateValue = useCallback(
		(val) => {
			handleFormChange(name, field.dataType, val, compIndex, compField);
		},
		[name, compIndex, compField]
	);

	const updateField = useCallback(
		(update) => {
			props.updateField(name, update);
		},
		[name]
	);

	const callSubscriptionsAndHandler = useCallback(
		(eventName, val) => {
			if (typeof props[eventName] === "function") {
				props[eventName](val);
			}
			fireSubscriptionsByEventName({
				eventName,
				fieldName: name,
				compField,
				compIndex,
			});
		},
		[name, compField, compIndex]
	);

	const setError = useCallback(
		(errorName, errorMessage) => {
			setValidation(name, compField, compIndex, errorName, errorMessage);
		},
		[setValidation]
	);

	const clearError = useCallback(
		(errorName) => {
			clearValidation(name, compField, compIndex, errorName);
		},
		[setValidation]
	);

	const onChange = useCallback(
		(val, fieldUpdates) => {
			updateValue(val);
			if (
				fieldUpdates &&
				(fieldUpdates.name || fieldUpdates.recurrence)
			) {
				updateField(fieldUpdates);
			}
			callSubscriptionsAndHandler(ON_CHANGE, val);
		},
		[props.onChange]
	);

	const onBlur = useCallback(() => {
		callSubscriptionsAndHandler(ON_BLUR, value);
	}, [props.onBlur]);

	const onLoad = useCallback(() => {
		callSubscriptionsAndHandler(ON_LOAD, value);
	}, [props.onLoad]);

	const onClick = useCallback(() => {
		callSubscriptionsAndHandler(ON_CLICK, value);
	}, [props.onClick]);

	const setFieldRef = useCallback(
		(ref) => {
			const fieldDataKey = fieldNameToDataKey({
				fieldName: name,
				compField,
				compIndex,
			});
			props.setFieldRef(fieldDataKey, ref);
		},
		[name, compField, compIndex]
	);

	const validationResult = useMemo(() => {
		for (const errorMsg of Object.values(validation)) {
			return { error: true, errorMsg: errorMsg || null };
		}
		return { error: false, errorMsg: null };
	}, [validation]);

	useEffect(() => {
		onLoad();
	}, [name]);

	const { props: fieldProps, hidden, ...rest } = field;
	const { error, errorMsg } = validationResult;
	const callbacks = {
		onChange,
		onLoad,
		onBlur,
		onClick,
		setError,
		clearError,
	};

	// add asterisk to label
	if (
		!viewOnly &&
		rest.subscriptions &&
		rest.subscriptions.find(
			(subscription) =>
				((subscription.name === "isRequired" ||
					subscription.name === "isRequiredHOC") &&
				(!subscription.args || subscription.args.length === 0) ||
				subscription.name === "isRequiredCompare")
		)
	) {
		rest.label = (
			<span>
				{rest.label} <span className="required">*</span>
			</span>
		);
	}

	return (
		<Hidden xsUp={!!hidden} implementation={"css"}>
			{field.compositeFields || field.type === "composite" ? (
				<CompositeField
					formType={formType}
					{...callbacks}
					{...rest}
					data={value}
					classes={classes}
					{...fieldProps}
					viewOnly={viewOnly}
				/>
			) : (
				<RenderField
					formType={formType}
					{...callbacks}
					field={rest}
					value={value}
					updateValue={updateValue}
					updateField={updateField}
					error={error}
					errorMsg={errorMsg}
					setFieldRef={setFieldRef}
					{...fieldProps}
					compIndex={compIndex}
					compField={compField}
					viewOnly={viewOnly}
					size={fieldSize}
				/>
			)}
		</Hidden>
	);
});

const mapStateToProps = (
	{ data, fields, validation, formType, viewOnly, fieldSize },
	{ name, compField, compIndex }
) => {
	const compValue =
		compField &&
		data[compField].compositeFields[compIndex] &&
		data[compField].compositeFields[compIndex][name];
	const compValues = data[name] && data[name].compositeFields;
	const value = compValue || compValues || data[name];
	const field = compField
		? fields[`$comp_${compField}_${name}`] &&
		  fields[`$comp_${compField}_${name}`].index[compIndex]
		: fields[name];
	const fieldDataKey = fieldNameToDataKey({
		fieldName: name,
		compField,
		compIndex,
	});

	return {
		value,
		field,
		options: field && field.options ? field.options : null,
		validation: validation[fieldDataKey],
		formType,
		viewOnly,
		fieldSize
	};
};

const mapDispatchToProps = {
	updateField: Creators.updateField,
	handleFormChange: Creators.handleFormChange,
	handleReplaceOptions: Creators.handleReplaceOptions,
	setFieldRef: Creators.setFieldRef,
	fireSubscriptionsByEventName: Creators.fireSubscriptionsByEventName,
	setValidation: Creators.setValidation,
	clearValidation: Creators.clearValidation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Field);
