import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Typography } from "@material-ui/core";
import Chip from "@react-ui/components/Chip";
import { Done, Clear } from "@material-ui/icons";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import FormFrame from "../components/Form/FormFrame";
import { GLOBAL_TABBED_DRAWER, EDIT, GRID_ROW_HEIGHT } from "./consts";
import {
	formatUSPhone,
	formatTimeUTC,
	formatDate,
	formatValue,
	formatPhone,
	FORMAT as COMMON_FORMAT,
} from "@react-ui/common/format";
import { getModulePath, getEndingModule } from "./moduleData";
import openCommForm from "./comm";
import ExpandableText from "../components/ExpandableText";

const FORMAT = {
	...COMMON_FORMAT,
	// UI only format goes here
	BOOL_ICON: "boolean_icon",
	EVENTDATE: "eventDate",
	MODULE_LINK: "moduleLink",
	CHIP: "Chip",
	CHIPLINK: "ChipLink",
	AVATAR: "avatar",
	IMAGE: "image",
	TITLEDIMAGE: "titled_image",
	URL: "url",
	PHONE: "phone",
	GRIDCHIPS: "gridchips",
	TEXT_EXPANDABLE: "text_expandable",
	BULLETIN_MODAL: "BulletinModal",
	HTML_EDITOR: "html editor",
	SHORT_HTML_EDITOR: "shorthtmleditor",
	HTML_LINE_BREAKS: "htmllinebreaks"
};
export { FORMAT, formatValue };

const wrapEmail = (value, { module, id, setDrawerOpen, data }) => {
	if (!module || !id) {
		return <a href={`mailto:${value}`}>{value}</a>;
	}
	return (
		<a
			style={{
				cursor: "pointer",
			}}
			onClick={() => {
				if (
					!openCommForm(
						getEndingModule(module),
						typeof data === "number" ? data : id,
						setDrawerOpen,
						value
					)
				) {
					window.open(`mailto:${value}`);
				}
			}}
		>
			{value}
		</a>
	);
};

const wrapPhone = (value) => {
	if (!value) {
		return value;
	}

	// This a hack for displaying phone number format correctly, even though the phone number might be invalid
	// we should be using parsePhoneNumberFromString to check
	const numericValue = value.replace(/[^\d]/g, "");
	if (numericValue.length === 10) {
		// use the manual format
		return (
			<a href={`tel:+1${numericValue}`}>
				{isNaN(value) ? value : formatUSPhone(value)}
			</a>
		);
	}
	const phoneNumber = parsePhoneNumberFromString(value);
	if (phoneNumber) {
		const phoneFormatString =
			phoneNumber.country === "US" || phoneNumber.countryCallingCode === 1
				? phoneNumber.formatNational()
				: phoneNumber.formatInternational();
		return <a href={`${phoneNumber.getURI()}`}>{phoneFormatString}</a>;
	} else {
		return formatPhone(value);
	}
};

const wrapAvatar = (value) => {
	return (
		<a href={value} target="_blank" rel="noopener noreferrer">
			<Avatar src={value} />
		</a>
	);
};

const wrapAddress = (value) => {
	return (
		<a
			href={`https://maps.google.com/?q=${value}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{value}
		</a>
	);
};

const wrapChip = (value) => {
	if (value === null || value === undefined) {
		return value;
	}
	if (value.label) {
		return (
			<Chip
				style={value.style}
				key={value.key}
				color="primary"
				label={value.label}
				className="chip"
				component="span"
			/>
		);
	}
	return (
		<Chip color="primary" label={value} className="chip" component="span" />
	);
};

const wrapChipLink = (value) => {
	if (value) {
		const chip = (
			<Chip
				color="primary"
				label={value.label}
				className="chip"
				component="span"
				clickable={true}
			/>
		);
		return <Link to={value.link}>{chip}</Link>;
	}
};

const wrapImageGridThumbnail = (value, args) => {
	// padding arround image so they don't touch
	// each other in the grid
	const height = GRID_ROW_HEIGHT - 8;
	let image = {};
	let url = "";
	let name = "";
	if (!value?.includes("https://assets.dam.simpleviewinc.com/")) {
		return value;
	}

	try {
		
		image = JSON.parse(value);
		url = image.length ? image[0].AssetPath : image.url;
		name = image.length ? image[0].Title : image.name;
	} catch(e) {
		// do nothing
	}

	return (
		<div
			style={{
				height,
				width: "80%",
				padding: 4,
				position: args.position || "static",
			}}
		>
			<a href={url} target="_blank" rel="noopener noreferrer">
				{name}
			</a>
		</div>
	);
};

const wrapTitledImage = (value, args) => {
	const height = GRID_ROW_HEIGHT - 8;
	if (!value?.includes("https://assets.dam.simpleviewinc.com/")) {
		return value;
	}

	return (
		<div
			style={{
				height: args.height || height,
				width: args.width || "80%",
				position: "relative",
			}}
		>
			<a href={value} target="_blank" rel="noopener noreferrer">
				<img
					src={`${value}/image/`}
					alt="image"
					className="image"
					style={{
						display: "block",
						width: "100%",
						height: "auto",
					}}
				/>
				{args.label ? (
					<div
						style={{
							position: "absolute",
							bottom: 0,
							background: "rgba(0, 0, 0, 0.5)",
							color: "#f1f1f1",
							width: "97%",
							paddingLeft: "3%",
						}}
					>
						{args.label}
					</div>
				) : null}
			</a>
		</div>
	);
};

const wrapURL = (value) => {
	return (
		<a href={value} target="_blank" rel="noopener noreferrer">
			{value}
		</a>
	);
};

const wrapHTML = (value) => {
	return (
		<div dangerouslySetInnerHTML={{ __html: value }} />
	);
};

const wrapBooleanIcon = (value) => {
	return value === "true" ||
		value === true ||
		value === "Yes" ||
		value === "1" ? (
			<Done displayvalue="Yes" />
		) : (
			<Clear displayvalue="No" />
		);
};

// data should be the id
const wrapModuleLink = (value, { module, data }) => {
	// this is mainly for summary pages like listing account
	if (Array.isArray(data) && data.length && data[0].label && data[0].value) {
		value = data[0].label;
		data = data[0].value;
	}

	//TODO: This is not correct routing. This needs to be discussed to determine what changes need to happen to facilitate this style of grid linking

	const link = getModulePath(module, data);
	if (!link) {
		return value;
	}

	let rtnId = data.match ? data : "";
	const recordIdMatch = rtnId.match(/\d+/g);
	if (recordIdMatch !== null) {
		rtnId = recordIdMatch[0];
	}
	const ID = Number(rtnId);

	if (isNaN(ID)) {
		return value;
	}
	return <Link to={link}>{value}</Link>;
};

const wrapBulletinModal = (value, { onClick }) => {
	return (
		<Button
			color="primary"
			disableRipple
			className={"deemphasized"}
			onClick={onClick}
		>
			{value}
		</Button>
	);
};

const wrapEventDate = (data, { setDrawerOpen, summaryFetch, classes }) => {
	if (data.RecurrenceType !== "NONE") {
		const openFormFrame = () => {
			const displayComponent = (
				<FormFrame
					type={"eventCalendar"}
					action={EDIT}
					recId={data.recId}
					section={"dateAndTimes"}
				/>
			);

			const tab = { name: `${EDIT} EVENT` };

			setDrawerOpen(GLOBAL_TABBED_DRAWER, {
				type: "eventCalendar",
				isOpen: true,
				id: data.recId,
				action: EDIT,
				displayComponent,
				tabs: [tab],
				activeTab: tab.name,
			});
		};

		const recurrenceType = data.RecurrenceType
			? data.RecurrenceType.toLowerCase()
			: "";

		return (
			<Button
				color="primary"
				disableRipple
				className={"deemphasized"}
				onClick={openFormFrame}
			>
				View {recurrenceType} Recurrence
			</Button>
		);
	} else {
		return (
			<React.Fragment>
				<span>
					<Typography
						variant="caption"
						className={classes.summaryLabel}
					>
						Start Date:
					</Typography>
					<span className={classes.summaryText}>
						{formatDate(data.StartDt)}
					</span>
				</span>
				<span className="fieldset-span">
					<Typography
						variant="caption"
						className={classes.summaryLabel}
					>
						End Date:
					</Typography>
					<span className={classes.summaryText}>
						{formatDate(new Date(data.EndTime) < new Date(data.StartTime) ? 
							(new Date(data.EndDt)).setDate(new Date(data.StartDt).getDate() + 1) : data.EndDt)}
					</span>
				</span>
				<span className="fieldset-span">
					<Typography
						variant="caption"
						className={classes.summaryLabel}
					>
						Start Time:
					</Typography>
					<span className={classes.summaryText}>
						{formatTimeUTC(data.StartTime)}
					</span>
				</span>
				<span className="fieldset-span">
					<Typography
						variant="caption"
						className={classes.summaryLabel}
					>
						End Time:
					</Typography>
					<span className={classes.summaryText}>
						{formatTimeUTC(data.EndTime)}
					</span>
				</span>
			</React.Fragment>
		);
	}
};

const wrapChips = (chips) => {
	if (!(chips instanceof Array)) {
		return chips;
	}
	return (
		<div>
			{chips?.map((chip) =>
				wrapChip({
					label: chip.Title,
					key: chip.ID,
					style: {
						margin: "0.25rem",
					},
				})
			)}
		</div>
	);
};

const wrapExpandableText = (text) => {
	return <ExpandableText content={text} />;
};

export const wrapValue = (value, format, args) => {
	if (!format) {
		return value;
	}

	const {
		EMAIL,
		PHONE,
		AVATAR,
		URL,
		ADDRESS,
		CHIP,
		CHIPLINK,
		MODULE_LINK,
		BOOL_ICON,
		EVENTDATE,
		IMAGE,
		TITLEDIMAGE,
		GRIDCHIPS,
		TEXT_EXPANDABLE,
		BULLETIN_MODAL,
		HTML,
		HTML_EDITOR,
		SHORT_HTML_EDITOR,
		HTML_LINE_BREAKS
	} = FORMAT;
	const wrappers = {
		[EMAIL]: wrapEmail,
		[PHONE]: wrapPhone,
		[AVATAR]: wrapAvatar,
		[ADDRESS]: wrapAddress,
		[CHIP]: wrapChip,
		[CHIPLINK]: wrapChipLink,
		[MODULE_LINK]: wrapModuleLink,
		[URL]: wrapURL,
		[BOOL_ICON]: wrapBooleanIcon,
		[IMAGE]: wrapImageGridThumbnail,
		[TITLEDIMAGE]: wrapTitledImage,
		[EVENTDATE]: wrapEventDate,
		[GRIDCHIPS]: wrapChips,
		[TEXT_EXPANDABLE]: wrapExpandableText,
		[BULLETIN_MODAL]: wrapBulletinModal,
		[HTML]: wrapHTML,
		[HTML_EDITOR]: wrapHTML,
		[SHORT_HTML_EDITOR]: wrapHTML,
		[HTML_LINE_BREAKS]: wrapHTML
	};
	if (format in wrappers) {
		return wrappers[format](value, args);
	} else {
		return value;
	}
};

export const wrapFormatValue = (value, format, wrapper, args) => {
	if (!format) {
		return value;
	}
	wrapper = wrapper || format;
	return wrapValue(formatValue(value, format), wrapper, args);
};
