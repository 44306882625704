import { ERROR_TYPES } from "../consts";
const { IS_REQUIRED } = ERROR_TYPES;

const clearField = ({ compField, compIndex, dispatchWrapper, getState, staticArgs, value }) => {
	const { data, fields } = getState();
	if (staticArgs && staticArgs[0] && !data[staticArgs[0]] && staticArgs[1] && staticArgs[2]) {
		const onConfirm = value => {
			if (value) {
				dispatchWrapper("handleFormChange", [staticArgs[1], "String", ""]);
			} else {
				dispatchWrapper("handleFormChange", [staticArgs[0], "String", true]);
			}
			
			dispatchWrapper("clearValidation", [...[staticArgs[0], compField, compIndex], IS_REQUIRED]);
			dispatchWrapper("clearValidation", [...[staticArgs[1], compField, compIndex], IS_REQUIRED]);
		};

		dispatchWrapper("setDialog", [true, "", staticArgs[2], onConfirm]);
	}
};

export default clearField;
