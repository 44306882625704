const isUniqueEmail = crm => async ({ fieldName, compField, compIndex, dispatchWrapper, getState }) => {
	const { data, formType, recId } = getState();
	const fieldParts = [fieldName, compField, compIndex];
	const IS_UNIQUE_EMAIL = "isUniqueEmail";
	const type = formType.split(".").pop();

	let value;
	let EmailID;
	let isUnique = true;
	
	if (compField && compIndex !== undefined && data[compField] && data[compField].compositeFields[compIndex]) {
		value = data[compField].compositeFields[compIndex][fieldName];
		EmailID = data[compField].compositeFields[compIndex].recId;
	} else {
		value = data[fieldName];
	}

	if (!value) {
		return;
	}

	// is the email already on the form?
	if (compField) {
		data[compField].compositeFields.forEach((val, i) => {
			if (i !== compIndex) {
				if (val[fieldName] === value) {
					isUnique = false;
				}
			}
		});
	}

	if (!isUnique) {
		// dispatch form validation error
		dispatchWrapper("setValidation", [...fieldParts, IS_UNIQUE_EMAIL, "This email address is already on the form."]);
		return;
	}

	const res = await crm.get({
		type: "email_byEmailAddress",
		filter: {
			type: "contact",
			email: value
		},
		fields: `
			EmailAddress
			refId
			EmailID
		`
	});

	const {
		count,
		recs: [email]
	} = res;
	// the check for email.refId !== recId is only valid on a contact form
	// elsewhere recId won't be for a contact
	if (count && ( email.EmailID !== EmailID || (type === "contact" && email.refId !== recId))) {
		// dispatch form validation error
		dispatchWrapper("setValidation", [...fieldParts, IS_UNIQUE_EMAIL, "The email address is already in the system."]);
	} else {
		// ensure EmailID is set on the record, in case the user cleared the value, and re-added it
		if (count) {
			dispatchWrapper("handleFormChange", ["recId", "String", email.EmailID, compIndex, compField]);
		}
		dispatchWrapper("clearValidation", [...fieldParts, IS_UNIQUE_EMAIL]);
	}
};

export default isUniqueEmail;
