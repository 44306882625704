import { emphasize } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 250
	},
	input: {
		display: "flex",
		height: "1.1875em", // same as muiBaseInput lineHeight
		overflow: "hidden",
		whiteSpace: "nowrap"
	},
	indicatorSeperator: {
		width: 0
	},
	dropdownIndicator: {
		padding: "0 !important"
	},
	valueContainer: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		overflow: "hidden"
	},
	singleValue: {
		font: "inherit",
		overflow: "hidden"
	},
	chip: {
		margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
	},
	disabledChip: {
		backgroundColor: "rgba(0, 0, 0, 0.1)"
	},
	chipFocused: {
		backgroundColor: emphasize(theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
	},
	noOptionsMessage: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
	},
	placeholder: {
		fontSize: "inherit",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	paper: {
		position: "absolute",
		zIndex: 2000,
		left: 0,
		minWidth: "100%"
	},
	menuItem: {
		height: "unset",
		whiteSpace: "unset",
		wordBreak: "break-word",
		minHeight: theme.spacing(3)
	},
	divider: {
		height: theme.spacing(4)
	},
	multiSelectLabel: {
		padding: "1rem 0",
		color: theme.colors.black
	},
	fieldSizeSmall: {
		"& .MuiOutlinedInput-input": {
			padding: "10.5px 14px"
		}
	}
});

export default styles;
