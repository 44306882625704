import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/dashboardActions";

const INITIAL_STATE = {
	dashboards: [],
	data: {
		dashboardId: 1
	}
};

const reducer = {};

reducer.setDashboardData = (state = INITIAL_STATE, action) => ({
	...state,
	data: {
		...state.data,
		...action.data
	}
});

reducer.fetchDashboardsSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	dashboards: action.dashboards
});

export default createRSReducer(Types, INITIAL_STATE, reducer);
