const styles = theme => ({
	detailPageWrapper: {
		height: "100%"
	},
	detailContentWrapper: {
		margin: theme.spacing(2),
		width: "100%"
	},
	detailActionPaper: {
		width: 245,
		height: "100%",
		padding: "15px",
		"& h4": {
			padding: 0
		}
	},
	detailActionButton: {
		width: "100%"
	},
	detailActionSections: {
		paddingLeft: "20px",
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		}
	}
});

export default styles;
