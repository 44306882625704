import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

/**
 * Determine whether refetch should happen based on the form global state.
 * @param {String} moduleName
 * @param {Object} param
 * @param {Function} param.handleFetch - Refetch function, instead of comsuming the state outside of the hook, useShouldFetch will call this function and set the timestamp for you if this function is specified.
 * @param {Boolean} param.fetchOnLoad - whether to fetch on load, defaults to true
 *
 * @return {Array} first element is whether refetch should happen based on refetch timestamp and update timestamp. The second element is a callback function to set the last refetch timestamp.
 */
const useShouldFetch = (moduleName, options = {}) => {
	const { 
		handleFetch,
		fetchOnLoad = true
	 } = options;
	const [state, setState] = useState({});
	const { lastFetch, lastModule } = state;
	const lastUpdate = useSelector(rState => {
		return rState.records ? rState.records.history[moduleName] : undefined;
	});
	let shouldRefetch = false;
	// reset timer if module changed
	if (lastModule !== moduleName) {
		setState({
			lastFetch: undefined,
			lastModule: moduleName
		});
	} else {
		// Fetch:
		// - if lastFetch is not set and shouldFetchOnLoad
		// - if lastUpdate is set and no lastFetch or lastFetch is before lastUpdate
		shouldRefetch = Boolean((!lastFetch && fetchOnLoad) || lastUpdate && lastFetch && lastFetch < lastUpdate);
	}

	useEffect(() => {
		if (shouldRefetch) {
			if (handleFetch) {
				setState({
					...state,
					lastFetch: new Date(Date.now())
				});
				handleFetch();
			}
		}
	}, [shouldRefetch, handleFetch, state]);
	
	
	return [shouldRefetch, () => {
		setState({
			...state,
			lastFetch: new Date(Date.now())
		});
	}];
};

export default useShouldFetch;
