import { createActions } from "reduxsauce";
import initForm from "../components/Form/FormFrame/initForm";
const { Types, Creators } = createActions({
	eventsFetching: null,
	eventsFetchingSuccess: ["events"],
	modalFetchStart: null,
	modalFetchSuccess: ["res"],
	modalFetchErr: ["err"],
	setDateInView: ["date"],
	setView: ["view"],
});

Creators.eventsFetch = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	dispatch(Creators.eventsFetching());

	crmGraphServer.general
		.get({
			type: "mycalendar_event",
			fields: `
				title
				completed
				allDay
				start
				end
				className
				color
				backgroundColor
				borderColor
				textColor
				type
				recId
				priorityOrder
				typeProps {
					name
					value
				}
			`
		})
		.then((res) => {
			const events = res.recs || [];
			const specialFormatters = {
				DueDate: (value) => new Date(Number(value))
			};

			events.forEach((event) => {
				if (event.typeProps) {
					event.typeProps.forEach((prop) => {
						if (specialFormatters[prop.name]) {
							event[prop.name] = specialFormatters[prop.name](prop.value);
						} else {
							event[prop.name] = prop.value;
						}
					});
				}
			});

			dispatch(Creators.eventsFetchingSuccess(events));
		});
};

// this action is very similar to the summaryAction Fetch. It would be ideal to
// create a definition-building utility that can be used in both actions
Creators.modalFetch = (type, id) => (dispatch, getState) => {
	dispatch(Creators.modalFetchStart());
	const updateLoading = () => {};
	initForm({ updateLoading, type, recId: id, action: "modal", dispatch, getState }).then(res => {
		if (!res.layout || !res.data) {
			dispatch(Creators.modalFetchErr(`There was an issue fetching the ${type}`, true));
		} else {
			dispatch(Creators.modalFetchSuccess(res));
		}
	});
};

export { Creators, Types };
