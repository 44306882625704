import LinkedForm from "./LinkedForm";
import Diff from "../../Modal/Diff";
import Grid from "./Grid";
import EmbeddedForm from "../../EmbeddedForm";

const components = {
	LinkedForm,
	Diff,
	Grid,
	EmbeddedForm
};

export default { ...components };
