import { extractInfo } from "../form";

const defaultToday = crm => async info => {
	const { recId, updateValue } = extractInfo(info);
	const today = new Date();
	!recId && updateValue(today);
};

export default defaultToday;

