import React, { forwardRef } from "react";
import MuiTextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";

import styles from "../../Form/Components/styles";
import ViewOnly from "../ViewOnly";

const TextField = forwardRef(({
	field,
	multiline,
	value,
	onChange,
	onBlur = () => {},
	onFocus = () => {},
	error,
	errorMsg,
	inputProps,
	viewOnly,
	classes,
	size
}, ref) => {
	const { label, helperText, variant = "outlined", placeholder, readOnly, maxLength, inputType = "string", defaultString = "" } = field;
	if (viewOnly) {
		return <ViewOnly 
			label={label}
			value={value || defaultString}
		/>;
	}

	return <div>
		<MuiTextField
			inputRef={ref}
			fullWidth
			multiline={multiline}
			rows={multiline ? 5 : 1}
			variant={variant}
			label={label}
			value={value || defaultString || ""}
			onChange={React.useCallback(e => onChange(e.target.value, e), [onChange])}
			onFocus={onFocus}
			onBlur={onBlur}
			placeholder={placeholder}
			inputProps={{ maxLength }}
			InputProps={{ ...inputProps, readOnly }}
			error={error}
			helperText={errorMsg || helperText}
			disabled={readOnly}
			FormHelperTextProps={{ className: "helper-text" }}
			type={inputType}
			size={size}
		/>
		{multiline && maxLength && <FormHelperText className={classes.helperText}>Character Count: {value ? value.length : 0} / {maxLength}</FormHelperText>}
	</div>;
});

export default React.memo(withStyles(styles)(TextField));
