import { ERROR_TYPES } from "../consts";
const { IS_REQUIRED } = ERROR_TYPES;

const isRequiredHOC = ({ fieldName, dispatchWrapper, getState, requiredFields = [] }) => {
	const { data, fields } = getState();
	const reqFields = fields[fieldName]?.["requiredFields"] ? JSON.parse(fields[fieldName]["requiredFields"]) : requiredFields;

	let items;
	if (["Address_v2", "ContactAddress_v2"].includes(fieldName)) {
		items = data[fieldName].address[0];
	} else if (fieldName === "Email_v2" || fieldName === "Phone_v2") {
		items = data[fieldName][0];
	}
	const values = reqFields.reduce((obj, key) => ({ ...obj, [key]: items[key] }), {});
	const emptyFields = [];
	for (const requiredField in values) {
		if (!values[requiredField]) {
			emptyFields.push(requiredField);
		}
	}
	const fieldParts = [fieldName, emptyFields, undefined];
	if (emptyFields.length) {
		dispatchWrapper("setValidation", [...fieldParts, IS_REQUIRED, "field is required"]);
	} else {
		dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);
	}
};

export default isRequiredHOC;
