import { Types } from "../actions/summaryActions";
import createRSReducer from "../common/createRSReducer";

import { MYPROFILE, USER } from "../common/consts";

const INITIAL_STATE = {
	data: {
		module: {},
		recId: null,
		RecordID: null
	},
	definition: {
		sections: []
	},
	SummaryTopCard: undefined,
	summaryErr: null,
	tabs: null,
	activeTab: undefined,
	shouldRedirect: false,
	categories: [],
	benefitSummary: {},
	meetingFacilities: [],
	formDef: {},
	loadingAmenities: false,
	loadingBenefitSummary: false,
	hasSummary: [],
	hasRecordID: []
};

const reducer = {};

reducer.summaryReset = (state = INITIAL_STATE) => ({
	...state,
	shouldRedirect: false
});


reducer.summaryFetchErr = (state = INITIAL_STATE, action) => ({
	...state,
	summaryErr: action.err,
	summaryFetching: false,
	shouldRedirect: !!action.redirect
});

reducer.clearSummaryErr = (state = INITIAL_STATE, action) => ({
	...state,
	summaryErr: null,
	summaryFetching: false,
	shouldRedirect: false
});

reducer.summaryFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	data: {},
	summaryFetching: true
});

reducer.summaryFetchSuccess = (state = INITIAL_STATE, action) => {
	const { data, definition } = action;

	for (const key in data) {
		if (data[key] && data[key].recs) {
			definition.sections.forEach(section => {
				section.fields.forEach(field => {
					if (key === field.name) {
						data[key] = data[key].recs.map(rec => (
							{ label: rec[field.map.label], value: rec[field.map.value] })
						);
					}
				});
			});
		}
	}

	return {
		...state,
		data,
		definition,
		summaryFetching: false
	};
};

reducer.setHasSummary = (state = INITIAL_STATE, action) => ({
	...state,
	hasSummary: action.modules.filter(module => [USER].includes(module) === false),
	hasRecordID: action.modules.filter(module => [MYPROFILE, USER].includes(module) === false)
});

reducer.summaryTopCardSet = (state = INITIAL_STATE, action) => ({
	...state,
	SummaryTopCard: action.SummaryTopCard
});

reducer.tabsFetchSuccess = (state = INITIAL_STATE, action) => { 
	return {
		...state,
		tabs: action.tabs,
		tabsFetching: false
	};
};

/**
 * This reducer takes the record list received from graph and transforms it into an object tree of:
 * 		categoryTitle -> amenityTitle -> [valueObject]
 * @param state
 * @param action
 * @returns {state}
 */
reducer.fetchAmenitiesSuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		categories: action.amenities,
		loadingAmenities: false
	};
};

reducer.setLoadingAmenities = (state = INITIAL_STATE) => ({
	...state,
	loadingAmenities: true
});

reducer.fetchBenefitSummarySuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		benefitSummary: action.benefitSummary,
		loadingBenefitSummary: false
	};
};

reducer.setLoadingBenefitSummary = (state = INITIAL_STATE) => ({
	...state,
	loadingBenefitSummary: true
});

reducer.fetchMeetingFacilitiesSuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		meetingFacilities: action.meetingFacilities,
		formDef: action.formDef,
		loadingMeetingFacilities: false
	};
};

reducer.setLoadingMeetingFacilities = (state = INITIAL_STATE) => ({
	...state,
	loadingMeetingFacilities: true
});

reducer.setTabsSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	summary: {
		...state.summary,
		tabs: action.tabs
	}
});

export default createRSReducer(Types, INITIAL_STATE, reducer);
