const contactAddressPrefill = crm => ({ dispatchWrapper, getState }) => {
	const { refId, formType, recId, data, fields } = getState();
	if (recId !== 0 || formType !== "account.contact") {
		return;
	}

	crm.get({
		type: "account",
		filter: {
			recId: refId
		},
		fields: `
			PhysicalAddressLine1
			PhysicalAddressLine2
			PhysicalAddressLine3
			PhysicalCity
			PhysicalStateCode
			PhysicalCountryCode
			PhysicalPostalCode
			PhysicalPostalCode4
		`
	}).then(res => {
		if (res.success && res.count) {
			if (data.Address && data.Address.compositeFields && data.Address.compositeFields.length === 1) {
				const addr = res.recs[0];
				
				let hasAddr = false;
				
				Object.keys(addr).sort().forEach(key => {
					if (addr[key] !== null) {
						hasAddr = true;
					}

					if (key === "PhysicalCountryCode") {
						const countryOptionIndex = fields["$comp_Address_CountryCode"].index[0].options.findIndex(option => option.value === addr[key]);

						if (countryOptionIndex > -1) {
							addr[key] = fields["$comp_Address_CountryCode"].index[0].options[countryOptionIndex];
							dispatchWrapper("handleFormChange", ["CountryCode", "String", addr[key] || "", 0, "Address"]);
						}
					} else if (key === "PhysicalStateCode") {
						setTimeout(function() {
							// let state options load first
							const stateOptionIndex = fields["$comp_Address_StateCode"].index[0].options.findIndex(option => option.value === addr[key]);

							if (stateOptionIndex > -1) {
								addr[key] = fields["$comp_Address_StateCode"].index[0].options[stateOptionIndex];
								dispatchWrapper("handleFormChange", ["StateCode", "String", addr[key] || "", 0, "Address"]);
							}
						}, 1000);
					} else {
						dispatchWrapper("handleFormChange", [key.replace("Physical", ""), "String", addr[key] || "", 0, "Address"]);
					}				
				});

				if (hasAddr) {
					dispatchWrapper("handleFormChange", ["AddressTypeID", "Int", { label: "Physical", value: 1 }, 0, "Address"]);
				}
				
			}
		}
	});
};

export default contactAddressPrefill;
