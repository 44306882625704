import { theme } from "@simpleview/sv-mosaic";

const variables = () => ({
	apptoolbarheight: "64px",
	leftnavwidth: "256px",

	astral: "#e0e0e0",
	black: "#000",
	brandblue: "#00a3e3",
	brandgray: "#bdbdbd",
	brandgreen: "#8dc53e",
	brandorange: "#d0592c",
	brandpurple: "#6c1f7d",
	brandteal: "#33a4b9",
	brandyellow: "#fcb823",
	exterminatered: "#b20000",
	filtergreen: "#79a935",
	secondaryteal: "#04b0cd",
	primaryblue: "#0088c6",
	almostblack: "#1a1a1a",
	darkgray: "#777",
	darkergray: "#5b5751",
	shadow: "#313131",
	gandolf: "#929292",
	system20: "#f2f2f2",

	breakpointslg: "1280px",
	breakpointsmd: "960px",
	breakpointssm: "600px",
	breakpointsxl: "1920px",
	breakpointsxs: "0",
	contrastthreshold: "3",
	filtercontrasttext: "#fff",
	filterdark: "#497a00",
	filterlight: "#abdb65",
	filtermain: "#79a935",

	/* legacy DMS fonts, for reference
	fontfamilysansserif: "'Noto Sans', 'Museo Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif",
	fontfamilyserif: "'Museo Slab', Georgia, serif",
	*/
	lightgray: "#efefef",
	primarycontrasttext: "#fff",

	// font weight
	light: 200,
	normal: 400,
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700,
	extrabold: 800,


	tonaloffset: "0.2",
	typographyheadlineFont: "'Noto Sans', 'Museo Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif",
	typographyheadlineFontsize: "22px",
	typographyheadlineFontwieght: "300",
	white: "#fff",

	// overwrite variables from mosaic
	...theme.colors,
	...theme.fontWeight,
	...theme.borders,
	colors: theme.colors,
	fontWeight: theme.fontWeight,
	
	// inherit font family from mosaic
	fontfamilysansserif: theme.fontFamily,
	fontfamilyserif: theme.fontFamily,
	fontFamily: theme.fontFamily,
	iconFontSize: theme.iconFontSize
});

export default variables;
