import query from "./query";

class General {
	constructor({ graphUrl, graphServer, ax }) {
		this.name = "general";
		this._graphUrl = graphUrl;
		this._graphServer = graphServer;
		this._ax = ax;
	}
	async get_old({ type, queryString, context, fragments = "", filter = {} }) {
		context = context || this._graphServer.context;

		const filterType = filter.filterType ? filter.filterType : "";
		delete filter.filterType;

		const variables = {
			acct_id: context.acct_id,
			productName: context.productName,
			filter
		};

		const queryStr = `
		${fragments }
			query($acct_id: String!, $productName: String${filterType ? `, $filter: ${filterType}` : ""}) {
				crm(acct_id: $acct_id, productName: $productName) {
					${queryString }
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type
		});
		return type ? response.crm[type] : response.crm;
	}
	async get({ type, filter, options, fields, fragments, context, grid, formDef }) {
		context = context || this._graphServer.context;
		let resolver = "";
		const toInclude = [
			"account",
			"contact",
			"listing",
			"listing_note",
			"contact_note",
			"account_note",
			"note",
			"eventCalendar",
			"eventCalendar_note",
			"gender",
			"account_task",
			"contact_task",
			"listing_task",
			"eventCalendar_task",
			"preference",
			"taskType",
			"eventCalendarRank",
			"eventCategory",
			"listingCategory",
			"listingRank",
			"listingSubCategory",
			"listingType",
			"pending_listing_response",
			"contactRole",
			"primaryBusinessCategory",
			"region",
			"salutation",
			"tag",
			"engagementLevel",
			"userRole",
			"contactMethod",
			"prefix",
			"noteCategory",
			"priority",
			"mycalendar_event",
			"group",
			"account_amenity",
			"account_benefitSummary",
			"account_relatedAccount",
			"account_meetingFacility",
			"interest",
			"brochure",
			"inquiryType",
			"inquiry",
			"moduleSetting",
			"moduleSettingGroup",
			"inquiry_note",
			"inquiry_task",
			"myProfile",
			"myProfile_note",
			"myProfile_task",
			"department",
			"user",
			"user_note",
			"user_task",
			"offer",
			"offerCategory",
			"offer_listing",
			"offer_note",
			"offer_task",
			"deleted_inquiry",
			"deleted_account",
			"deleted_contact",
			"deleted_listing",
			"deleted_eventCalendar",
			"deleted_offer",
			"referral",
			"referralSource",
			"template",
			"amenityCategory",
			"amenity",
			"marketSegment",
			"sourceCode",
			"listing_review",
			"offer_review",
			"eventCalendar_review",
			"user_setting",
			"visitReason",
			"visitor",
			"connection",
			"connectionGroup",
			"location",
			"batchReferral",
			"bulletinCategory",
			"formBuilder",
			"bulletin",
			"carousel",
			"formBuilderJSON",
			"NAICSCode",
			"defaultWebForm",
			"attribute",
			"contactrelatedaccount",
			"contactextranetsecurity"
		];

		const untouched = [
			"recently_viewed",
			"account_comm",
			"contact_comm",
			"comm",
			"task",
			"media",
			"myProfiles",
			"user_settings",
			"account_meetingFacilityRoom",
			"searchResults",
			"eventCalendar_media"
		];

		type = type.replace("TenantAmenityCategory", "amenityCategory");

		if (toInclude.includes(type)) {
			resolver = `${ type }s`;
		} else if (type.includes("_form") || type.includes("_modal") || untouched.includes(type)) {
			if (type.includes(".")) {
				resolver = type.split(".")[1];
			} else {
				resolver = type;
			}
		} else {
			resolver = `get_${ type }`;
		}
		if (["account_amenitys", "account_benefitSummarys", "account_relatedAccounts", "account_meetingFacilitys", "amenitys", "listing_reviews", "offer_reviews", "eventCalendar_reviews"].includes(resolver)) {
			resolver = resolver.replace(/s$/, "");
		}

		const variables = {
			filter,
			options,
			acct_id: context.acct_id,
			grid,
			productName: context.productName
		};

		let rootString = "$acct_id: String!, $productName: String";
		let argString = filter || options ? "(" : "";

		if (filter) {
			const filterType = filter.filterType ? filter.filterType : `crm_${ type.replace("deleted_", "") }_filter`;
			delete filter.filterType;
			if (Array.isArray(filter)) {
				rootString += `, $filter: [${ filterType }]`;
				argString += "filters: $filter";
			} else {
				rootString += `, $filter: ${ filterType }`;
				argString += "filter: $filter";
			}
			if (options) {
				rootString += ", ";
				argString += ", ";
			}
		}

		if (options) {
			rootString += "$options: crm_options";
			argString += "options: $options";

			if (grid) {
				rootString += ", ";
				argString += ", ";
			}
		}

		if (grid) {
			rootString += `$grid: crm_grid_info`;
			argString += "grid: $grid";
		}

		argString += filter || options ? ")" : "";

		let fieldString = "";

		if (fields) {
			fieldString += `{
				success
				message
				recs {
					${fields }
				}
				count
				${grid ? `appliedFilter` : "" }
				${grid ? `filterName` : "" }
				${formDef ? `formDef {${ formDef }}` : "" }
			}`;
		}

		const response = await query({
			ax: this._ax,
			query: `
				query(${rootString }) {
					crm(acct_id: $acct_id, productName: $productName) {
						${resolver }${ argString } ${ fieldString }
					}
				}
				${fragments ? fragments : "" }
			`,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: resolver
		});

		return response.crm[resolver];
	}

	async getForm({ formType, recId, context }) {
		context = context || this._graphServer.context;
		recId = Number(recId);
		const queryString = `
			query($acct_id: String!, $recId: Int!, $productName: String) {
				crm(acct_id: $acct_id, productName: $productName) {
					${formType }(recId: $recId) {
						data
						formDef
					}
				}	
			}
		`;

		const obj = { recId, acct_id: context.acct_id, productName: context.productName };

		const response = await query({
			ax: this._ax,
			query: queryString,
			variables: obj,
			url: this._graphUrl,
			token: context.token,
			type: formType
		});

		return response.crm[formType];
	}

	async getFormData({ queryString, variables, context }) {
		context = context || this._graphServer.context;

		const obj = { ...variables, acct_id: context.acct_id, productName: context.productName };

		const response = await query({
			ax: this._ax,
			query: queryString,
			variables: obj,
			url: this._graphUrl,
			token: context.token
		});

		return response.crm;
	}

	async setFormData({ queryString, variables, context }) {
		context = context || this._graphServer.context;

		const obj = { ...variables, acct_id: context.acct_id, productName: context.productName };

		const response = await query({
			ax: this._ax,
			query: queryString,
			variables: obj,
			url: this._graphUrl,
			token: context.token
		});

		return response.crm;
	}
	async set_old({ queryString, context, variables }) {
		context = context || this._graphServer.context;

		variables = {
			acct_id: {
				value: context.acct_id,
				type: "String"
			},
			productName: {
				value: context.productName,
				type: "String"
			},
			...variables
		};

		const variableValues = {};
		const variableNames = [];
		for (const name in variables) {
			variableNames.push(name);
			variableValues[name] = variables[name].value;
		}

		const queryStr = `
			mutation(${variableNames.map(name => `$${name}: ${variables[name].type}!`).join(", ")}) {
				crm(acct_id: $acct_id, productName: $productName) {
					${queryString}
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables: variableValues,
			url: this._graphUrl,
			token: context.token
		});

		return response.crm;
	}
	async set({ type, input, inputType, fields, context }) {
		context = context || this._graphServer.context;

		const variables = {
			input,
			acct_id: context.acct_id,
			productName: context.productName
		};
		const fieldsWithBracket = `{${ fields }}`;
		const response = await query({
			ax: this._ax,
			query: `
				mutation($acct_id: String!, $input: crm_${inputType || type }_input!, $productName: String) {
					crm(acct_id: $acct_id, productName: $productName) {
						set_${type }(input: $input) ${ fields ? fieldsWithBracket : "" }
					}
				}`,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: `set_${ type }`
		});

		return response.crm[`set_${ type }`];
	}

	/**
	 * Just delete the record, doesn't care what will happen
	 *
	 * @param {Object} option
	 * @param {Pbject} option.context
	 * @param {type} option.type - module type of the record to be deleted.
	 * @param {number} option.recId - record id
	 */
	async delete({ recType, recId, context }) {
		context = context || this._graphServer.context;
		const variables = {
			recId,
			acct_id: context.acct_id,
			productName: context.productName
		};
		const queryStr = `mutation($acct_id: String!, $recId: Int!, $productName: String) {
			crm(acct_id: $acct_id, productName: $productName) {
				delete_${recType } (
					ID: $recId
				)
			}
		}`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: `delete_${ recType }`
		});
		return response.crm[`delete_${ recType }`];
	}

	async delete_v2({ recType, refType, recId, context }) {
		context = context || this._graphServer.context;
		const variables = {
			recId,
			type: refType,
			acct_id: context.acct_id,
			productName: context.productName
		};
		const queryStr = `mutation($acct_id: String!, $productName: String, $recId: Int!${ refType ? ", $type: String!" : "" }) {
			crm(acct_id: $acct_id, productName: $productName) {
				delete_${recType } (
					recId: $recId
					${refType ? `type: $type` : "" }
				) {
					success
				}
			}
		}`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: `delete_${ recType }`
		});
		return response.crm[`delete_${ recType }`];
	}

	async restore({ recType, refType, recId, context, moduleName }) {
		context = context || this._graphServer.context;
		const variables = {
			recId,
			type: moduleName || refType,
			acct_id: context.acct_id
		};
		const type = moduleName || recType.split("_")[1];
		const queryStr = `mutation($acct_id: String!, $recId: Int!) {
			crm(acct_id: $acct_id) {
				restore_${type } (
					recId: $recId
				)
			}
		}`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: `restore_${ type }`
		});
		return response.crm[`restore_${ type }`];
	}

	/**
	 * TODO: This is not implemented yet on graph side, the way to handle delete check is TBD
	 *
	 * New way of doing delete, can just check to see if something is deletable, and return the status of what exactly happened like if the record is removed permanantly, if the record is actually deleted, or if the record is even deletable.
	 *
	 * @param {Object} option
	 * @param {Object} option.context
	 * @param {type} option.type - module type of the record to be deleted.
	 * @param {number} option.recId - record id
	 * @param {boolean} option.preventExecute - prevent execution of delete query.
	 */
	async delete_future({ recType, recId, preventExecute, context }) {
		context = context || this._graphServer.context;
		const variables = {
			recId,
			acct_id: context.acct_id
		};
		const queryStr = `mutation($acct_id: String!, $recId: Int!) {
			crm(acct_id: $acct_id) {
				delete_${recType } (
					ID: $recId,
					${preventExecute && `preventExecute: true` }
				) {
					recId
					recType
					recName
					wouldDelete
					wouldRemove
					executed
				}
			}
		}`;

		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: `delete_${ recType }`
		});

		return response.crm[`delete_${ recType }`];
	}

	async checkToken({ context }) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			productName: context.productName
		};
		const response = await query({
			ax: this._ax,
			query: `
				query($acct_id: String!, $productName: String) {
					crm(acct_id: $acct_id, productName: $productName) {
						get_check_token {
							success
							message
							firstName
							lastName
							email
							userId
							isSVUser
						}
					}
				}
			`,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "get_check_token"
		});

		return response.crm.get_check_token;
	}
	async uploadAttachments({ files, context }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			files
		};
		const queryStr = `
			mutation($acct_id: String!, $files: [Upload!]) {
				crm(acct_id: $acct_id) {
					upload_attachments(files: $files) {
						fileName
						key
						path
						url
					}
				}
			}
		`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "upload_attachments"
		});
		return response.crm.upload_attachments;
	};

	async deleteAttachment({ fileName, context }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			fileName
		};
		const queryStr = `
			mutation($acct_id: String!, $fileName: String!) {
				crm(acct_id: $acct_id) {
					delete_attachment(fileName: $fileName) {
						success
					}
				}
			}
		`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "delete_attachments"
		});
		return response.crm.delete_attachments;
	};

	async uploadAsset({ file, context }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			file
		};
		const queryStr = `
			mutation($acct_id: String!, $file: Upload!) {
				crm(acct_id: $acct_id) {
					upload_asset(file: $file) {
						success
						message
						asset {
							id
							url
						}
					}
				}
			}
		`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "upload_asset"
		});
		return response.crm.upload_asset;
	}
	async removeAsset({ id, context }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			id
		};
		const queryStr = `
			mutation($acct_id: String!, $id: String!) {
				crm(acct_id: $acct_id) {
					delete_asset(id: $id) {
						success
						message
					}
				}
			}
		`;
		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "delete_asset"
		});
		return response.crm.delete_asset;
	}

	async sendEmail({ input, context }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			input
		};
		const queryStr = `
			mutation($acct_id: String!, $input: crm_comm_email_input) {
				crm(acct_id: $acct_id) {
					send_email(input: $input) {
						success
						message
					}
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			query: queryStr,
			variables,
			url: this._graphUrl,
			token: context.token,
			type: "send_email"
		});
		return response.crm.send_email;
	}

	async fetchFormOptions({ context, parentType, parentID, parentColumn, optionTable, labelColumn, valueColumn, isActiveColumn, optionFunction, functionPath, functionArgs, pagingOptions, filter, valueToString }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			parentType,
			parentID,
			parentColumn,
			optionTable,
			labelColumn,
			valueColumn,
			isActiveColumn,
			optionFunction,
			functionPath,
			functionArgs,
			pagingOptions,
			filter,
			valueToString,
			productName: context.productName
		};
		const queryStr = `
			query fetchFormOptions($acct_id: String!, $parentType: String!, $parentID: Int, $parentColumn: String, $optionTable: String, $labelColumn: String!, $valueColumn: String!, $isActiveColumn: String, $optionFunction: String, $functionPath: String, $functionArgs: JSON, $pagingOptions: crm_PagingOptions, $filter: [crm_form_option_filter], $valueToString: Boolean, $productName: String) {
				crm(acct_id: $acct_id, productName: $productName) {
					form_option(parentType: $parentType, parentID: $parentID, parentColumn: $parentColumn, optionTable: $optionTable, labelColumn: $labelColumn, valueColumn: $valueColumn, isActiveColumn: $isActiveColumn, optionFunction: $optionFunction, functionPath: $functionPath, functionArgs: $functionArgs, pagingOptions: $pagingOptions, filter: $filter, valueToString: $valueToString)
				}
				
			}
		`;

		const resonse = await query({
			ax: this._ax,
			variables,
			query: queryStr,
			url: this._graphUrl,
			token: context.token,
			type: "form_option"
		});

		return resonse.crm.form_option;
	}

	async fetchFormById({ context, id, graphUrl, showOnWeb, usePostDates, formDisplay }) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			id
		};

		const queryString = `
			query fetchFormById($acct_id: String!, $id: String!) {
				crm(acct_id: $acct_id) {
					formById(id: $id ${showOnWeb === undefined ? "" : `, showOnWeb: ${showOnWeb}`}${usePostDates ? ",usePostDates: true" : ""}${formDisplay ? ",formDisplay: true" : ""})
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			variables,
			query: queryString,
			url: graphUrl || this._graphUrl,
			token: context.token,
			type: "formById"
		});

		return response.crm.formById;
	}

	async fetchDefaultWebForms({ context, module, graphUrl }) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			module
		};

		const queryString = `
			query fetchDefaultWebForms($acct_id: String!, $module: String!) {
				crm(acct_id: $acct_id) {
					defaultWebForms(module: $module) {
						id
						label
					}
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			variables,
			query: queryString,
			url: graphUrl || this._graphUrl,
			token: context.token,
			type: "defaultWebForms"
		});

		return response.crm.defaultWebForms;
	}

	async verifyCaptchaToken({ context, token, graphUrl }) {
		context = context || this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			token
		};

		const queryString = `
			query verifyCaptchaToken($acct_id: String!, $token: String!) {
				crm(acct_id: $acct_id) {
					verifyCaptchaToken(token: $token)
				}
			}
		`;

		const response = await query({
			ax: this._ax,
			variables,
			query: queryString,
			url: graphUrl || this._graphUrl,
			token: context.token,
			type: "verifyCaptchaToken"
		});

		return response.crm.verifyCaptchaToken;
	}

	async fetchGrid({ context, moduleName, parent, form, registry, view, pagingOptions, isInitCall = false }) {
		if (!parent || (parent && !parent.moduleName && !parent.ID)) {
			parent = undefined;
		}
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			view,
			pagingOptions,
			moduleName,
			parent,
			form,
			registry,
			info: {
				isInitCall
			},
			productName: context.productName
		};
		const queryStr = `
			query fetchGrid($acct_id: String!, $view: JSON, $pagingOptions: crm_PagingOptions, $moduleName: String!, $parent: crm_GridParentModule, $info: crm_grid_info!, $form: String, $registry: String, $productName: String) {
				crm(acct_id: $acct_id, productName: $productName) {
					grid(moduleName: $moduleName, parent: $parent, view: $view, pagingOptions: $pagingOptions, grid: $info, form: $form, registry: $registry) {
						success
						message
						data
						count
						config
						userViews
						currentView
						sharedViews
						snackbarMessage
						snackbarDisplayType
					}
				}
				
			}
		`;

		const response = await query({
			ax: this._ax,
			variables,
			query: queryStr,
			url: this._graphUrl,
			token: context.token,
			type: "grid"
		});

		return response.crm.grid;
	}

	async saveGridView({ context, moduleName, parentModule, view, name, form, id, type }) {
		context = context || this._graphServer.context;
		const variables = {
			acct_id: context.acct_id,
			view,
			moduleName,
			parentModule,
			name,
			form,
			id,
			type,
			productName: context.productName
		};

		const queryStr = `
		mutation saveGridView($acct_id: String!, $name: String!, $view: JSON!, $moduleName: String!, $parentModule: String, $form: String, $id: String, $type: String, $productName: String) {
			crm(acct_id: $acct_id, productName: $productName) {
				saveView(moduleName: $moduleName, parent: $parentModule, view: $view, name: $name, form: $form, id: $id, type: $type) {
					success
					message
					snackbarMessage
					snackbarDisplayType
					RecordID
				}
			}
			
		}
		`;

		const response = await query({
			ax: this._ax,
			variables,
			query: queryStr,
			url: this._graphUrl,
			token: context.token,
			type: "saveView"
		});

		return response.crm.saveView;
	};
}

export default General;
