const setAmenityAdminFields = crm => ({ fieldName, compField, compIndex, eventName, subscriber, staticArgs, dispatchWrapper, getState }) => {
	const { data, fields: fieldObjs } = getState();
	const recId = Number(data.recId);

	const AmenityCategory = data.AmenityCategory?.value;
	const CoreAmenityID = data.CoreTitle?.value;
	const fields = ["AmenityID", "CoreTitle", "ShortDesc", "FieldType", "FieldTypeID"];

	const updatefields = (ShortDesc="", FieldType="", recId="", FieldTypeID="") => {
		dispatchWrapper("handleFormChange", ["ShortDesc", "String", ShortDesc]);
		dispatchWrapper("handleFormChange", ["FieldType", "String", FieldType]);
		dispatchWrapper("handleFormChange", ["recId", "Int", recId]);
		dispatchWrapper("handleFormChange", ["FieldTypeID", "Int", FieldTypeID]);
	};

	if (eventName === "onLoad" && data[fieldName]?.value) {
		if (recId > 0) {
			dispatchWrapper("handleFormChange", ["CoreTitle", "Int", { label: data["CoreTitle"].label, value: recId }]);
			dispatchWrapper("updateField", ["CoreTitle", { ...fieldObjs["CoreTitle"], readOnly: true }]);
			dispatchWrapper("updateField", ["AmenityCategory", { ...fieldObjs["AmenityCategory"], readOnly: true }]);
		}
		return;
	}

	if (CoreAmenityID && fieldName === "CoreTitle") {
		crm.get({
			type: "amenity",
			filter: { "recId": CoreAmenityID },
			fields: `${fields.join(" ")}`
		}).then(res => {
			updatefields(res.recs[0]["ShortDesc"], res.recs[0]["FieldType"], CoreAmenityID, res.recs[0]["FieldTypeID"]);
		});

		return;
	}

	if (AmenityCategory && fieldName === "AmenityCategory") {
		crm.get({
			type: "unassignedAmenities",
			filter: { "AmenityCategory": AmenityCategory, isDms: true },
			fields: `${fields.join(" ")}`
		}).then(res => {
			if (res.recs.length) {
				const options = res.recs.map(amenity => ({ label: amenity.CoreTitle, value: amenity.AmenityID }));
				dispatchWrapper("updateField", ["CoreTitle", { ...fieldObjs["CoreTitle"], options, readOnly: false }]);
				dispatchWrapper("handleFormChange", ["CoreTitle", "Int", ""]);
			} else {
				dispatchWrapper("updateField", ["CoreTitle", { ...fieldObjs["CoreTitle"], options: [], readOnly: false }]);
				dispatchWrapper("handleFormChange", ["CoreTitle", "Int", ""]);
			}
		});
	}
	updatefields();

};

export default setAmenityAdminFields;
