import styles from "../styles/themes/default/themePalette.js";
import variables from "../styles/variables.js";
import { createMuiTheme } from "@material-ui/core/styles";

export const getTheme = theme => {
	const themeToUse = { ...variables(), ...styles(), ...theme };
	return {
		palette: {
			type: themeToUse.type.toString().replace(/"/gi, ""),
			primary: {
				main: themeToUse.primarymain,
				light: themeToUse.primarylight,
				dark: themeToUse.primarydark,
				contrastText: themeToUse.primarycontrasttext
			},
			secondary: {
				light: themeToUse.secondarylight,
				main: themeToUse.secondarymain,
				dark: themeToUse.secondarydark,
				contrastText: themeToUse.secondarycontrasttext
			},
			filter: {
				light: themeToUse.filterlight,
				main: themeToUse.filtermain,
				dark: themeToUse.filterdark,
				contrastText: themeToUse.filtercontrasttext
			},
			background: {
				default: themeToUse.backgrounddefault,
				paper: themeToUse.backgroundpaper
			},
			action: {
				active: themeToUse.white,
				hover: themeToUse.secondarymain
			},
			error: {
				dark: themeToUse.errordark,
				light: themeToUse.errorlight,
				main: themeToUse.errormain,
				contrastText: themeToUse.primarycontrasttext
			},
			input: {
				background: "rgba(0, 0, 0, 0.09)",
				hover: "rgba(0, 0, 0, 0.13)"
			}
		},
		colors: {
			// legacy DMS colors
			brandYellow: themeToUse.brandyellow,
			brandGreen: themeToUse.brandgreen,
			brandBlue: themeToUse.brandblue,
			brandTeal: themeToUse.brandteal,
			brandOrange: themeToUse.brandorange,
			brandGray: themeToUse.brandgray,
			exterminateRed: themeToUse.exterminatered,
			filterGreen: themeToUse.filtergreen,
			secondaryTeal: themeToUse.secondaryteal,
			primaryBlue: themeToUse.primaryblue,
			almostBlack: themeToUse.almostblack,
			darkGray: themeToUse.darkgray,
			darkerGray: themeToUse.darkergray,
			black: themeToUse.black,
			shadow: themeToUse.shadow,
			gandolf: themeToUse.gandolf,
			astral: themeToUse.astral,
			system20: themeToUse.system20,
			secondaryGray: themeToUse.secondarymain,
			secondaryGrayDark: themeToUse.secondarydark,
			primary: themeToUse.primarymain,
			secondary: themeToUse.secondarymain,
			...themeToUse.colors
		},
		// this is kept to prevent regressions but font weight
		// is overwritten with mosaic value.
		fontWeights: {
			semiBold: themeToUse.semiBold,
			black: themeToUse.extrabold,
			light: themeToUse.light,
			bold: themeToUse.bold,
			regular: themeToUse.regular,

			...themeToUse.fontWeight
		},
		fontWeight: themeToUse.fontWeight,
		constants: {
			header: {
				height: Number(themeToUse.apptoolbarheight.toString().replace("px", ""))
			},
			leftNav: {
				width: Number(themeToUse.leftnavwidth.toString().replace("px", ""))
			},		
			semiBold: themeToUse.semibold
		},
		breakpoints: {
			values: {
				lg: Number(themeToUse.breakpointslg.toString().replace("px", "")),
				md: Number(themeToUse.breakpointsmd.toString().replace("px", "")),
				sm: Number(themeToUse.breakpointssm.toString().replace("px", "")),
				xl: Number(themeToUse.breakpointsxl.toString().replace("px", "")),
				xs: Number(themeToUse.breakpointsxs.toString().replace("px", ""))
			}
		},
		overrides: {
			MuiPaper: {
				elevation1: {
					boxShadow: "0px 1px 3px rgba(0,0,0,0.16)"
				},
				elevation2: {
					boxShadow: "0px 3px 6px rgba(0,0,0,0.16)"
				},
				elevation3: {
					boxShadow: "0px 5px 9px rgba(0,0,0,0.16)"
				},
				elevation4: {
					boxShadow: "0px 7px 12px rgba(0,0,0,0.16)"
				},
				root: {
					"& .drawerData": {
						margin: "32px 36px",
						"& .oddRow:hover": {
							backgroundColor: themeToUse.blue,
							"& p, & span": {
								color: "#fff"
							}
						},
						"& .evenRow": {
							backgroundColor: themeToUse.system20,
							"&:hover": {
								backgroundColor: themeToUse.blue,
								"& p, & span": {
									color: "#fff"
								}
							}
						}
					}
				}
			},
			MuiFab: {
				root: {
					boxShadow: "0px 3px 6px rgba(0,0,0,0.16)"
				}
			},
			MuiMenuItem: {
				root: {
					fontWeight: "regular",
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
				}
			},
			MuiListItem: {
				button: {
					"&:hover": {
						color: `${themeToUse.white}`,
						backgroundColor: themeToUse.blue
					},
				},
				root: {
					"&$selected": {
						color: `${themeToUse.black} !important`,
						opacity: 1,
					},
					"&.title": {
						fontWeight: "bold",
						backgroundColor: "transparent !important",
						opacity: 1
					}
				},
			},
			MuiListItemIcon: {
				root: {
					color: "inherit"
				}
			},
			MuiIconButton: {
				root: {
					"&.link svg": {
						color: themeToUse.blue
					},
					color: undefined // force to inherit the color
				}
			},
			MuiFormControl: {
				marginNormal: {
					marginBottom: 14,
					marginTop: 14
				}
			},
			MuiInputBase: {
				fullWidth: {
					boxSizing: "border-box"
				}
			},
			MuiButton: {
				root: {
					textTransform: "capitalize",
					"& svg": {
						width: 15,
						height: 15,
						margin: 4
					},
					"&.deemphasized": {
						fontWeight: "normal",
						textTransform: "capitalize",
						// disable ripple
						"& span:last-child": {
							display: "none"
						},
						// keep the label
						"& span:first-child": {
							display: "inherit"
						},
						"& span svg": {
							margin: 0
						}

					},
					"& .routerLink": {
						color: themeToUse.blue,
						textDecoration: "none"
					},
					"&.link": {
						fontWeight: "normal",
						minWidth: 0,
						color: themeToUse.blue,
						textTransform: "inherit",
						cursor: "pointer",
						"&:hover": {
							color: themeToUse.blueHover
						},
						// disable ripple
						"& span:last-child": {
							display: "none"
						},
						// keep the label
						"& span:first-child": {
							display: "inherit"
						},
						"& span svg": {
							margin: 0
						}
					},
				},
				text: {
					color: themeToUse.blue,
					minWidth: 64,
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
					fontWeight: themeToUse.semibold,
					padding: 8,
					"&:hover": {
						color: themeToUse.blueHover,
						backgroundColor: "transparent"
					},

				},
				textPrimary: {
					color: themeToUse.primarymain,
					"&:hover": {
						color: themeToUse.primarydark,
						backgroundColor: `${themeToUse.primarymain}12`
					},
				},
				textSecondary: {
					color: themeToUse.black,
					"&:hover": {
						color: themeToUse.secondaryteal,
						backgroundColor: themeToUse.gray200
					}
				},
				contained: {
					boxShadow: "none"
				},
				containedPrimary: {
					color: "#fff",
					minWidth: 64,
					backgroundColor: themeToUse.primarymain,
					"&:hover": {
						boxShadow: "none",
						backgroundColor: themeToUse.primarydark
					}
				},
				containedSecondary: {
					color: themeToUse.blue,
					minWidth: 64,
					backgroundColor: "#fff",
					border: `solid 1px ${themeToUse.blue}`,
					"&:hover": {
						color: themeToUse.blueHover,
						backgroundColor: themeToUse.white,
						border: `solid 1px ${themeToUse.blueHover}`
					}
				}
			},
			MuiTypography: {
				root: {
					"&.body3": {
						fontFamily: themeToUse.fontFamily,
						fontSize: 14,
						fontWeight: themeToUse.regular
					},
					"&.body4": {
						fontFamily: themeToUse.fontFamily,
						fontSize: 14,
						fontWeight: themeToUse.light
					}
				},
				h1: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 42,
					fontWeight: themeToUse.light,
					letterSpacing: -1
				},
				h2: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 30,
					fontWeight: themeToUse.regular,
					letterSpacing: -1
				},
				h3: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 24,
					fontWeight: themeToUse.regular,
					letterSpacing: -2
				},
				h4: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 20,
					fontWeight: themeToUse.regular,
					letterSpacing: -0.5
				},
				h5: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 18,
					fontWeight: themeToUse.regular,
					letterSpacing: -0.5
				},
				h6: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 16,
					fontWeight: themeToUse.regular,
					letterSpacing: 0
				},
				subtitle1: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 16,
					fontWeight: themeToUse.regular
				},
				subtitle2: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 16,
					fontWeight: themeToUse.light
				},
				body1: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
					fontWeight: themeToUse.regular
				},
				body2: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
					fontWeight: themeToUse.extrabold
				},
				caption: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 13,
					fontWeight: themeToUse.medium,
					letterSpacing: 2
				},
				overline: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 10,
					fontWeight: themeToUse.regular,
					textTransform: "uppercase"
				},
				button: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
					fontWeight: themeToUse.semibold,
					textTransform: "uppercase"
				}
			},
			MuiModal: {
				root: {
					"& .drawerData": {
						margin: 28,
						"& .oddRow:hover": {
							backgroundColor: themeToUse.blue,
							"& p, & span": {
								color: "#fff"
							}
						},
						"& .evenRow": {
							backgroundColor: themeToUse.system20,
							"&:hover": {
								backgroundColor: themeToUse.blue,
								"& p, & span": {
									color: "#fff"
								}
							}
						}
					}
				}
			},
			MuiTab: {
				root: {
					textTransform: "Capitalize"
				},
				textColorPrimary: {
					color: themeToUse.darkGray
				},
				textColorSecondary: {
					color: themeToUse.darkGray
				},
				labelIcon: {
					fontFamily: themeToUse.fontFamily,
					fontSize: 14,
					fontWeight: 500
				},
				wrapper: {
					display: "inline"
				}
			},
			MuiTabs: {
				root: {
					"&.drawerTabs": {
						minHeight: "inherit",
						"& span": {
							fontFamily: themeToUse.fontFamily,
							fontSize: 16,
							fontWeight: "700",
							textTransform: "uppercase"
						},
						"& .drawerClose": {
							marginLeft: "auto",
							minWidth: "inherit",
							maxWidth: 60,
							borderRight: "none",
							"& span": {
								padding: 0
							}
						},
						"& button": {
							flex: "1 1 auto",
							padding: "0px 10px",
							margin: 0,
							minWidth: 0,
							minHeight: 40,
							opacity: 1,
							underline: "none",
							color: "white",
							borderRight: `2px solid ${themeToUse.gandolf}`,
							whiteSpace: "nowrap"
						}
					}
				}
			},
			MuiPickersToolbarButton: {
				toolbarBtn: {
					fontSize: "1.75rem"
				}
			},
			MuiPickerDTTabs: {
				tabs: {
					backgroundColor: "white",
					color: "#333"
				}
			},
			MuiPickerDTHeader: {
				dateHeader: {
					height: undefined,
					minWidth: undefined
				},
				timeHeader: {
					height: undefined,
					minWidth: undefined
				},
				ampmLabel: {
					fontSize: "1.25rem"
				},
				hourMinuteLabel: {
					top: 0
				},
				ampmSelection: {
					top: "0.875rem"
				}
			},
			MuiSelect: {
				icon: {
					color: undefined // force inherit from default
				}
			},
			MuiOutlinedInput: {
				adornedEnd: {
					paddingRight: 0
				},
				multiline: {
					// to make multiline take 2 standard field height
					padding: "22.5px 14px"
				}
			},
			MuiSkeleton: {
				root: {
					backgroundColor: "rgba(240, 242, 245, 0.5)"
				},
				wave: {
					"&:after": {
						background: "linear-gradient(90deg, transparent, #f0f2f5, transparent)"
					}
				}
			},
			MuiExpansionPanel: {
				root: {
					"&$expanded": {
						margin: "24px 0",
					}
				}
			},
			MuiExpansionPanelSummary: {
				root: {
					"&$expanded": {
						minHeight: 36
					},
					minHeight: 36,
					margin: 0,
					padding: "0 24px",
				},
				content: {
					margin: "7.5px 0",
					"&$expanded": {
						margin: "7.5px 0"
					}
				},
				expandIcon: {
					padding: "0 24px"
				}
			},
			MuiChip: {
				root: {
					backgroundColor: themeToUse.secondarydark
				},
				deleteIcon: {
					width: 16,
					height: 16,
					margin: "0 8px 0 -8px"
				}
			},
			MuiDrawer: {
				paper: {
					overflowX: "hidden"
				}
			},
			MuiPickersTimePickerToolbar: {
				toolbarLandscape: {
					justifyContent: "center"
				}
			}
		}
	};
};

export const defaultTheme = createMuiTheme(getTheme());
