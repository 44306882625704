import { extractInfo } from "../form";
import store from "../../store";

const valueMap = {
	email: state => state.email,
	fullname: state => `${state.firstName} ${state.lastName}`,
	lastname: state => state.lastName,
	firstname: state => state.firstName,
	imageUrl: state => state.imageUrl,
	userId: state => state.userId,
	isSVUser: state => state.isSVUser
};

const setValueFromToken = crm => info => {
	const { subValue, data, updateValue, staticArgs } = extractInfo(info);
	const [arg] = staticArgs;

	if (!subValue || subValue.length === 0) {
		const loginState = store.getState().login;

		if (arg !== "nonSVUserId") {
			updateValue(valueMap[arg](loginState));
		} else if ((arg === "nonSVUserId") && !data.recId && !valueMap["isSVUser"](loginState)) {
			//nonSVUserId is a unique case.  We use this to default the assigned user on tasks for a new task ONLY.
			// So if recId is already set, then we don't want to override it.  Also we never assign tasks to 
			// SV Users, so we won't ever do that.
			const value = { label: valueMap["fullname"](loginState), value: valueMap["userId"](loginState), sortOrder: 0 };
			updateValue([value]);
		}
	}
};

export default setValueFromToken;
