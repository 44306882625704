import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/gridActions";

const INITIAL_STATE = {
	gridType: null
};

const reducer = {
	setGridViewInStore: (state = INITIAL_STATE, action) => {
		return {
			...state,
			gridType: action.gridType
		};
	}
};

export default createRSReducer(Types, INITIAL_STATE, reducer);
