import { handleSubmit } from "../form";
import { Creators } from "../../actions/headerActions";
import store from "../../store";
const { dispatch } = store;

const handleUpdateSettings = crm => async info => {
	const result = await handleSubmit({ crm, ...info });
	dispatch(Creators.getGeneralSettings());
};

export default handleUpdateSettings;
