import {
	Home,
	Group,
	Person,
	Public,
	Extension,
	LocalOffer,
	Apps,
	Settings,
	SettingsApplications,
	Storage,
	CalendarToday,
	SettingsSystemDaydream,
	Notes,
	LowPriority,
	Assignment,
	List,
	Event,
	Warning,
	QuestionAnswer,
	CardTravel,
	Forum,
	Add,
	Edit,
	Delete,
	FileCopyOutlined,
	RestoreFromTrash,
	RemoveRedEye,
	ClearAll,
	ChangeHistory,
	LabelOutlined,
	AddToPhotos,
	MailOutline,
	NoteAdd,
	Help,
	LineStyle
} from "@material-ui/icons";

import Export from "../components/Icons/Export";
import Preview from "../components/Icons/Preview";
import Resource from "../components/Icons/Resource";
import LayoutEdit from "../components/Icons/LayoutEdit";

const iconComponents = {
	LayoutEdit,
	Export,
	MailOutline,
	LabelOutlined,
	ChangeHistory,
	ClearAll,
	Add,
	Edit,
	Delete,
	FileCopyOutlined,
	RestoreFromTrash,
	RemoveRedEye,
	Home,
	Group,
	Person,
	Public,
	Extension,
	LocalOffer,
	Apps,
	CalendarToday,
	Settings,
	SettingsApplications,
	Storage,
	SettingsSystemDaydream,
	Notes,
	LowPriority,
	Assignment,
	List,
	Event,
	Warning,
	QuestionAnswer,
	CardTravel,
	Forum,
	AddToPhotos,
	NoteAdd,
	Preview,
	Help,
	Resource,
	LineStyle
};

export default iconComponents;

export const getIcon = name => {
	const modifiedName = name?.endsWith("Icon") ? name.substring(0, name.lastIndexOf("Icon")) : name;
	return iconComponents[name] || iconComponents[modifiedName] || iconComponents.Warning;
};
