import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators } from "../actions";
import styles from "./styles";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(styles);

const ActionList = ({ canSubmit, captchaSiteKey, viewOnly }) => {
	const actions = useSelector(state => {
		return state.actions;
	});
	const classes = useStyles();
	const dispatch = useDispatch();
	if (viewOnly) {
		const action = actions.find(act => act.callback === "cancel");
		return <div>
			{ action &&
				<Button
					className={classes.detailActionButton}
					variant="contained"
					color={action.color}
					size="large"
					key={action.name || 0}
					disabled={!canSubmit}
					type={["submit", "reset", "button"].includes(action.type) ? action.type : "button"}
					onClick={dispatch(Creators.fireAction({ type: action.type, callback: action.callback, actionArgs: action.args || {}, bindArgs: action.bindArgs }))}>
					{action.label}
				</Button>
			}
		</div>;
	}
	return <div>
		{actions.map((action, i) => {
			if (action.type === "submit") {
				action.args.captchaSiteKey = captchaSiteKey;
			}
			return (
				action.hidden ? null : <Button
					className={classes.detailActionButton}
					variant="contained"
					color={action.color}
					size="large"
					key={action.name || i}
					disabled={!canSubmit}
					type={["submit", "reset", "button"].includes(action.type) ? action.type : "button"}
					onClick={dispatch(Creators.fireAction({ type: action.type, callback: action.callback, actionArgs: action.args || {}, bindArgs: action.bindArgs }))}>
					{action.label}
				</Button>
			);
		})}
	</div>;
};

export default ActionList;
