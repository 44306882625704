const _blockTags = ["head", "title", "style", "script", "noscript", "textarea", "iframe", "embed", "object", "canvas"];
const _blockMatch = _blockTags.map(function(val) {
	return "<"+val+"[\\s\\S]*?>[\\s\\S]*?</"+val+">";
});
const _commentMatch = "<!--[\\s\\S]*?-->";
const _genericTagMatch = "</?[\\w-]+[\\s\\S]*?>";
const _brokenMatch = "</?[\\w-]+(?=[^>]*?(<|$))";
const _defaultMatchArray = _blockMatch.concat(_brokenMatch, _commentMatch);
const _defaultRegex = new RegExp(_defaultMatchArray.concat(_genericTagMatch).join("|"), "g");

const stripHtml = (str, args) => {
	if (str === undefined || str === "" || typeof str !== "string") {
		return str || "";
	}

	args = args || {};

	// pre-lowercase all HTML tags which contain an upper-case letter
	str = str.replace(/<\/?\w*[A-Z]\w*[\s>]/g, function(match) {
		return match.toLowerCase();
	});

	// remove tags, strips out the contents of a specific set of tags declared above, otherwise just removes start and end tags
	let regex = _defaultRegex;

	if (args.allowTags !== undefined) {
		// instead of the _genericTagMatch we build our own with a negative lookahead to exclude matching tags we allow, since a match would remove it
		const allowTagsMatch = "<(?!\\/?(" + args.allowTags.join("|") + ")(>|\\s))/?[\\w-]+[\\s\\S]*?>";
		regex = new RegExp(_defaultMatchArray.concat(allowTagsMatch).join("|"), "g");
	}

	str = str.replace(regex, " ");
	str = str.replace(/&nbsp;/g, " ");

	return str.replace(/\s+/g, " ").trim();
};

export default stripHtml;
