const getValue = (myVar, propPath) => {
	let temp = myVar;
	for (let i = 0; i < propPath.length; i++) {
		temp = temp[propPath[i]];
	}

	return temp;
};

const indexGetValue = (keyArr, val) => getValue(val, keyArr);

const index = (arr, key, multiple) => {
	multiple = multiple === undefined ? false : multiple;

	const indexed = {};
	const value = key instanceof Function ? key : indexGetValue.bind(null, [].concat(key));

	for (let i = 0; i < arr.length; i++) {
		const val = arr[i];
		const tempKey = value(val);

		if (multiple) {
			if (indexed[tempKey] === undefined) {
				indexed[tempKey] = [];
			}

			indexed[tempKey].push(val);
		} else {
			indexed[tempKey] = val;
		}
	}

	return indexed;
};

export default index;
