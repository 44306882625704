import { extractInfo } from "../form";

const setDefaultVenueListing = crm => async info => {
	const { getState } = info;
	const { formType, refId } = getState();
	const { updateField, updateValue } = extractInfo(info);
	let ListingID;
	if (formType === "listing.eventCalendar") {
		ListingID = refId
	}

	if (ListingID) {

		crm.get({
			type: "listing",
			filter: {
				recId: ListingID,
			},
			fields: `
				Title
				ListingID
				IsActive
				IsDeleted
                IsApproved
			` 
		}).then(res => {
			if (res.success && res.count) {
				const { recs: listings } = res;
				const options = listings.filter(rec => rec.IsActive && !rec.IsDeleted && rec.IsApproved).map(rec => {
					return { label: rec.Title, value: rec.ListingID };
				});
				updateValue(options[0]);
				updateField({ readOnly: true });
			} else {
				updateValue(undefined);
				updateField({ options: [] });
			}
		});
	}
};

export default setDefaultVenueListing;
