import React from "react";
import { Provider } from "react-redux";
import configureStore from "./store";
import ConnectedRenderForm from "./Components/ConnectedRenderForm";

export let store;

const RenderFormWrapper = (props) => {
	store = configureStore();

	return (
		<Provider store={store}>
			<ConnectedRenderForm {...props} />
		</Provider>
	);
};

export default RenderFormWrapper;
