const limitedUseOptions = ({ fieldName, compField, compIndex, eventName, dispatchWrapper, getState, staticArgs, subscriber }) => {
	const { data, fields } = getState();
	const rootField = fields[subscriber];

	if (compField && data[compField] && data[compField].compositeFields && fields[subscriber] && fields[subscriber].index) {
		fields[subscriber].index.forEach((field, i) => {
			field.options = rootField.options.filter(option => {
				const dataIndex = data[compField].compositeFields.findIndex((val) => val[fieldName] ? val[fieldName].value === option.value : false);
				return dataIndex === -1 || dataIndex === i;
			});
		});
	}
};

export default limitedUseOptions;
