import React, { useEffect, useState, useCallback } from "react";
import {
	GridPrimaryFilter,
	DataViewFilterDropdownButtons,
	DataViewFilterDropdown
} from "@simpleview/sv-mosaic";
import { TextField } from "@material-ui/core";

const RangeFilter = ({ label, type, onRemove, data, onChange, args = {} }) => {
	const [lowerBound, setLowerBound] = useState("");
	const [upperBound, setUpperBound] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = useCallback((e) => {
		setAnchorEl(null);
		if (e) {
			setLowerBound(data?.value?.rangeStart || data?.rangeStart || "");
			setUpperBound(data?.value?.rangeEnd || data?.rangeEnd || "");
		}		
	});

	const validateNumber = useCallback(value => {
		if (!value.length) {
			return true;
		} else if (Number(value) === 0) {
			return true;
		}
		return Number(value) && Number(value) <= 2147483647 && Number(value) >= -2147483647;
	});
	
	const handleBoundChangeLower = useCallback((i) => {
		(!args.numericOnly || validateNumber(i)) && setLowerBound(i);
	});

	const handleBoundChangeUpper = useCallback((i) => {
		(!args.numericOnly || validateNumber(i)) && setUpperBound(i);
	});

	const onClear = useCallback(() => {
		setLowerBound("");
		setUpperBound("");
	});

	const onApply = useCallback(() => {
		if (lowerBound || upperBound) {
			onChange({
				rangeStart: lowerBound,
				rangeEnd: upperBound,
				value: `${lowerBound} - ${upperBound}`,
				comparison: "between"
			});
		} else {
			onChange({
				comparison: undefined
			});
		}
		handleClose();
	});

	useEffect(() => {
		if (data && data.value) {
			data.value.rangeStart && setLowerBound(data.value.rangeStart);
			data.value.rangeEnd && setUpperBound(data.value.rangeEnd);
		} else {
			setLowerBound("");
			setUpperBound("");
		}
	}, [data]);

	useEffect(() => {
		if (lowerBound && upperBound && (Number(upperBound) < Number(lowerBound))) {
			setErrorMessage("End of range cannot be before start of range");
		} else {
			setErrorMessage("");
		}
	}, [data, lowerBound, upperBound]);

	return <>
		<GridPrimaryFilter
			label={label}
			value={`${lowerBound} - ${upperBound}`}
			type={type}
			onRemove={onRemove}
			onClick={handleClick}
		/>
		<DataViewFilterDropdown
			anchorEl={anchorEl}
			onClose={handleClose}
		>
			<TextField 
				value={lowerBound}
				onChange={e => handleBoundChangeLower(e.target.value)}
				placeholder={"Lower Bound"}
			/>
			<TextField
				value={upperBound}
				onChange={e => handleBoundChangeUpper(e.target.value)}
				placeholder={"Upper Bound"}
			/>
			{
				errorMessage &&
				<div className="errorMessage">
					<h5>Error: {errorMessage}</h5>
				</div>
			}
			<DataViewFilterDropdownButtons onApply={onApply} onClear={onClear} onCancel={handleClose} disableApply={errorMessage ? true : false} />
		</DataViewFilterDropdown>
	</>;
};

export default RangeFilter;
