import { extractInfo } from "../form";

const accountListings = crm => async info => {
	const { ownValue, subValue, updateField, updateValue, ownField, ...rest } = extractInfo(info);

	let AccountID = subValue;

	if (subValue && subValue.value !== undefined) {
		AccountID = subValue.value;
	} else if (typeof subValue !== "number") {
		updateField({ options: [] });
		updateValue(undefined);
		return;
	}

	crm.get({
		type: "accountListings",
		filter: {
			AccountID,
			NewPending: false
		},
		fields: `
			AccountID
			ListingID 
			RecordID
			IDAndTitle
			CompanyName
			CompanyNameSort
			AccountCompanyName
			AccountCompanyNameSort
			ListingType
			Category
			Subcategory
			NewPending
			IsActive
			IsApproved
			IsDeleted
			
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: listings } = res;
			const options = listings.filter(rec => rec.IsActive && rec.IsApproved && !rec.IsDeleted).map(rec => {
				const label = `(${rec.RecordID}) ${rec.CompanyNameSort || rec.CompanyName || rec.AccountCompanyNameSort || rec.AccountCompanyName} - ${rec.ListingType} - ${rec.Category} - ${rec.Subcategory}`;
				return { label, value: rec.ListingID };
			});
			updateField({ options });
			if (ownValue && ownValue.value && !options.some(opt => opt.value === ownValue.value) ) {
				updateValue(undefined);
			}
		} else {
			updateValue(undefined);
			updateField({ options: [] });
		}
	});
};

export default accountListings;
