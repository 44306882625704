import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import styles from "./styles";
import Select from "react-select";
import AsyncPaginate from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import selectComponents from "./selectComponents";
import Chip from "../../Chip";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { Creators } from "../../Form/actions";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";

class SelectField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			limitedDialogOpen: false
		};
	}

	componentDidMount = () => {
		const { onLoad, value, isMulti, updateValue, field } = this.props;
		onLoad && onLoad();
		if (isMulti && (value || field?.defaultSelect)) {
			const sortedValue = field?.defaultSelect ? [...value, ...field?.defaultSelect] : [...value];
			
			sortedValue.sort((a, b) => {
				return a.label > b.label;
			});
			updateValue(sortedValue);
		}

		if (!isMulti && (field?.defaultSelect)) {
			const defaultSelect = Array.isArray(field.defaultSelect) ? field.defaultSelect[0] : field.defaultSelect;
			
			if (defaultSelect.listValueString) {
				defaultSelect.value = defaultSelect.listValueString;
				defaultSelect.label = defaultSelect.listDesc;
			}
			
			updateValue(defaultSelect);
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		const currentValue = Array.isArray(this.props.value) ? sortBy(this.props.value, val => val.value) : this.props.value;
		const nextValue = Array.isArray(nextProps.value) ? sortBy(nextProps.value, val => val.value) : nextProps.value;
		const currentOptions = Array.isArray(this.props.field.options) ? sortBy(this.props.field.options, val => val.value) : this.props.field.options;
		const nextOptions = Array.isArray(nextProps.field.options) ? sortBy(nextProps.field.options, val => val.value) : nextProps.field.options;
		return (!isEqual(nextValue, currentValue)) ||
			(!isEqual(nextOptions, currentOptions)) ||
			(!isEqual(this.state, nextState)) ||
			(!isEqual(this.props.field?.asyncColumn, nextProps.field?.asyncColumn)) ||
			(nextProps.error !== this.props.error || nextProps.errorMsg !== this.props.errorMsg || nextProps.field.readOnly !== this.props.field.readOnly);
	}

	onOptionUpdate = async (boundArgs, search, loadedOptions, { page }) => {
		const { callbacks, optionUpdater, formType, 
			field: { name, async, asyncColumn, options, isActiveOnly, dynamicDefaultIsRefId, permissionCheck, isClearable = true }, onChange } = this.props;
		let newOptions;

		if (!async && options) {
			if (search) {
				const searchLower = search.toLowerCase();
				const filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower));
				newOptions = { options: filteredOptions, hasMore: false };
			} else {
				newOptions = { options: loadedOptions.length ? [] : options, hasMore: false };
			}

			return newOptions;
		}

		newOptions = await callbacks[async]({ search, page, column: asyncColumn || name, module: formType, 
			isActiveOnly, boundArgs, dynamicDefaultIsRefId, permissionCheck, isClearable });
		// to set a default with async loaded options
		if (async && dynamicDefaultIsRefId === formType && boundArgs && boundArgs.value && !boundArgs.value.label) {
			let optionIndex;
			if (boundArgs.value instanceof Array) {
				optionIndex = newOptions.options.findIndex(option => option.value === boundArgs.value[0].value);
			} else {
				optionIndex = newOptions.options.findIndex(option => option.value === boundArgs.value.value);
			}
			if (optionIndex > -1) {
				onChange(newOptions.options[optionIndex]);
			}
		}

		optionUpdater(name, newOptions.options);

		return newOptions;
	};

	handleCreate = value => {
		const {
			field: { name, creatable },
			fireCallbackByName
		} = this.props;
		if (!creatable) {
			return;
		}

		fireCallbackByName({ module: creatable.moduleName, callbackName: creatable.callback, fieldName: name, value, subscriber: name });
	};

	handleChange = (val, fieldUpdates) => {
		const { field: { limitedUseOptions } } = this.props;
		if (limitedUseOptions) {
			const { siblingData } = this.props;
			let hasDuplicate = false;
			let duplicateIndex = -1;
			for (let i = 0; i < siblingData.length; i++) {
				if (val && val.value === siblingData[i]?.value) {
					hasDuplicate = true;
					duplicateIndex = i;
				}
			}
			if (hasDuplicate) {
				this.setState({ limitedDialogOpen: true, val, fieldUpdates, duplicateIndex });
			} else {
				this.props.onChange(val, fieldUpdates);
			}
		} else {
			this.props.onChange(val, fieldUpdates);
		}
	};

	handleDialogClose = () => {
		this.setState({ limitedDialogOpen: false, val: null, fieldUpdates: null, duplicateIndex: null });
	};

	render() {
		const {
			handleCreate,
			handleChange,
			handleDialogClose,
			onOptionUpdate,
			props: { onBlur, classes, value, isMulti, field, error, errorMsg, variant = "outlined", selectProps: parentSelectProps, size }
		} = this;
		const { label, async, creatable, placeholder = `${async || creatable ? "Search" : "Select"} ${isMulti || creatable ? "options" : "an option"}`, 
			selectedOptions, isClearable = true } = field;

		const options = field.options || [];
		const readOnlyClassName = field.readOnly ? "readOnly" : "";

		const SelectField = React.forwardRef(
			(props, ref) => {
				const fieldSizeSmall = props.size === "small" ? classes.fieldSizeSmall : "";

				return creatable ?
					<CreatableSelect ref={ref} {...props} isDisabled={props.field.readOnly} onCreateOption={handleCreate} className={classNames(readOnlyClassName, fieldSizeSmall)} /> :
					<Select ref={ref} {...props} isDisabled={props.field.readOnly} className={classNames(readOnlyClassName, fieldSizeSmall)} />
			}
		);

		return (
			<>
				<Dialog
					open={this.state.limitedDialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Duplicate Field"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{`'${this.state.val?.label}' is already related to this record.`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDialogClose} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
				<AsyncPaginate
					{...parentSelectProps}
					cacheUniq={options || selectedOptions}
					defaultOptions={true}
					fullWidth={true}
					classes={classes}
					isMulti={isMulti}
					cacheOptions={false}
					textFieldProps={{
						label: label,
						variant,
						InputLabelProps: {
							shrink: true
						}
					}}
					options={options}
					field={field}
					hideSelectedOptions={false}
					backspaceRemovesValue={true}
					components={selectComponents}
					placeholder={placeholder}
					onChange={handleChange}
					onBlur={onBlur}
					error={error}
					errorMsg={errorMsg}
					isDisabled={field.readOnly}
					value={value}
					isClearable={!isMulti && (isClearable !== false)}
					loadOptions={onOptionUpdate.bind(null, { value })}
					additional={{ page: field.page || 1 }}
					SelectComponent={SelectField}
					size={size}
				/>
			</>
		);
	}
}

const SelectWrapper = props => {
	const { field, formType, callbacks, viewOnly, style } = props;
	const selectProps = { ...props };

	selectProps.value = props.value === "" ? [] : props.value;

	// we don't need to pass in options, if async
	// this will also prevent it from re-rendering when we update options in the store
	const { isMulti, onChange, classes, field: { readOnly, noFetch, delField, options, permissionCheck, async, name, isActiveOnly, dynamicDefaultIsRefId, isClearable, helperText } } = props;
	let value = selectProps.value;
	if (async) {
		delete selectProps.field.options;
	}

	const removeChip = React.useCallback((val) => {
		const newValues = value.filter(data => (data.value !== val && data !== val) || data.hasPermissions === false);
		if (newValues.length < value.length) {
			onChange(newValues);
		}
	}, [onChange, value]);

	const Chips = React.memo(() => {
		if (!isMulti && !viewOnly) {
			return null;
		}

		if (!Array.isArray(value)) {
			value = [value];
		}

		const renderChip = ({ label, value, hasPermissions = false }) => {
			let canDelete = null;
			if (permissionCheck && !viewOnly) {
				canDelete = options?.findIndex(option => value === option.value);
				if (canDelete > -1) {
					canDelete = options[canDelete].hasPermissions;
				} else {
					canDelete = hasPermissions;
				}
			} else if (delField === false || viewOnly || readOnly) {
				canDelete = false;
			}

			return (
				<Chip
					deleteIcon={<ClearIcon fontSize="small" />}
					tabIndex={-1}
					label={label}
					key={`${label}${value}Chip`}
					className={classNames(classes.chip, canDelete === false ? classes.disabledChip : "")}
					onDelete={canDelete === false ? null : e => removeChip(value)}
					disabled={canDelete === false}
					data-cy={label}
				/>
			);
		};

		return <div style={{ marginTop: 5 }}>{value.map((data, i) => {
			if (!data) {
				return;
			}

			return renderChip(data);
		})}</div>;
	}, [isMulti, onChange, value, classes, options, permissionCheck]);

	if (noFetch && viewOnly && value && async) {
		callbacks[async]({ search: "", page: 1, column: name, module: formType, isActiveOnly, 
			boundArgs: { value }, dynamicDefaultIsRefId, permissionCheck, isClearable }).then(newOptions => {
			if (newOptions.options && newOptions.options.length === 1) {
				onChange(newOptions.options[0]);
			}
		});
	}

	return (
		<div style={style}>
			{viewOnly ? <Typography style={{ fontWeight: "bold" }}> {field.label}: </Typography> : <SelectField {...selectProps} />}
			<Chips />
			<FormHelperText
				className="helper-text"
				variant="outlined"
			>
				{helperText}
			</FormHelperText>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const ret = {
		callbacks: state.callbacks
	};

	if (ownProps.field.name.indexOf("$comp_") > -1) {
		ret.siblingFields = [...state.fields[ownProps.field.name].index] || [];
		ret.siblingData = state.data[ownProps.field.parent] !== undefined ?
			state.data[ownProps.field.parent].compositeFields.map(field => field[ownProps.field.name.split("_")[2]]) :
			[];
	}
	return ret;
};

const mapDispatchToProps = {
	optionUpdater: Creators.addOptionsToField,
	fireCallbackByName: Creators.fireCallbackByName,
	handleFormChange: Creators.handleFormChange
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SelectWrapper)
);
