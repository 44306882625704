
const addContact = crm => info => {
	const { subscriber, fieldName, getState, staticArgs, dispatchWrapper } = info;
	const [resolverToCall, fieldToQuery] = staticArgs;
	const value = getState().data[fieldName];
	let options = getState().fields[subscriber].options;
	crm.get({
		type: resolverToCall,
		filter: {
			recId: value
		},
		fields: fieldToQuery
	}).then(res => {
		const newOption = { label: res.recs[0][fieldToQuery], value };
		options = [...options, newOption];
		dispatchWrapper("updateField", [subscriber, { options }]);
		dispatchWrapper("handleFormChange", [subscriber, "Int", newOption]);
	});
};

export default addContact;
