const listingContacts = crm => ({ fieldName, compField, compIndex, eventName, subscriber, staticArgs, dispatchWrapper, getState }) => {
	const { data } = getState();
	const recIds = [];
	console.log("listingContacts:", data);
	if (data.VenueListingID) {
		recIds.push(data.VenueListingID.value);
	}

	if (data.HostingListingID) {
		recIds.push(data.HostingListingID.value);
	}

	if (recIds.length) {
		crm.get({
			type: "listingContacts",
			filter: {
				recId: recIds
			},
			fields: "FirstName LastName ContactID"
		}).then(response => {
			const { recs: contacts } = response;
			const options = contacts.map(contact => ({ label: contact.FirstName + " " + contact.LastName, value: contact.ContactID }));
			dispatchWrapper("updateField", ["ContactID", { options }]);
		});
	}
};

export default listingContacts;
