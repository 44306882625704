const styles = theme => ({
	additionalDates: {
		"& input, & input:focus": {
			boxShadow: "none",
			backgroundColor: "inherit",
			border: "none",
			boxSizing: "content-box",
			margin: 0,
			paddingLeft: "14px"
		}
	},
	formComponent: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignItems: "stretch",
		//sv-dms-form
		"& textarea, & textarea:focus": {
			boxShadow: "none",
			backgroundColor: "inherit"
		},
		"& input, & input:focus": {
			boxShadow: "none",
			backgroundColor: "inherit",
			border: "none",
			boxSizing: "content-box",
			margin: 0,
			paddingLeft: "14px"
		},
		"& input[type='text'][disabled]": {
			backgroundColor: "inherit",
			color: "rgba(0, 0, 0, 0.26)"
		},
		"& .css-1g6gooi": {
			display: "block"
		}
	},
	gridContainer: {
		display: "block",
		"& pre.diffFieldPre": {
			display: "inherit",
			fontFamily: "inherit"
		},
		"& .readOnly p": {
			color: "rgba(0, 0, 0, 0.38)" 
		}
	},
	section: {
		marginBottom: "24px !important"
	},
	groupedSection: {
		margin: "12px 0 !important",
		width: "100%"
	},
	groupedSectionHidden: {
		display: "none"
	},
	groupedGrid: {
		width: "50%"
	},
	sectionHeader: {
		backgroundColor: theme.colors.secondaryGray,
	},
	row: {
		marginBottom: "20px",
		width: "100%",
		"&.dense": {
			marginBottom: 0
		}
	},
	lastRow: {
		marginBottom: 0
	},
	rowClear: {
		clear: "both"
	},
	column: {
		float: "left",
		height: "100%",
		padding: 0
	},
	inner: {
		height: "100%",
		padding: "10px",
		position: "relative",
		"& .column": {
			padding: 0
		},
		"& .inner": {
			padding: [[0, 5]]
		}
	},
	divider: {
		position: "absolute",
		left: "100%",
		top: "10%",
		bottom: "10%",
		"border-left": "1px solid",
		color: "#D9D9D9"
	},
	field: {
		marginTop: 14,
		marginBottom: 14,
		width: "100%",
		"&.push-right": {
			display: "flex",
			alignItems: "flex-end"
		},
		"&.pad-top": {
			paddingTop: theme.spacing(1)
		}
	},
	outerLabel: {
		margin: "15px 0"
	},
	radio: {
		padding: 0
	},
	radioControl: {
		"& span": {
			fontSize: "12px !important",
		}
	},
	switchField: {
		height: 56
	},
	checkbox: {
		height: 34
	},
	selectField: {
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	compositeField: {
		paddingRight: theme.spacing(1)
	},
	compositeRecord: {
		position: "relative"
	},
	addButton: {
		justifyContent: "left"
	},
	removeButtonCol: {
		"& > span > svg": {
			margin: 2
		},
		"&:hover": {
			background: theme.colors.astral
		},
		"&:active": {
			boxShadow: "none"
		},
		boxShadow: "none",
		color: theme.colors.gandolf,
		background: "transparent",
		borderRadius: "100%",
		padding: "2px",
		minWidth: "4px",
		minHeight: "4px",
		lineHeight: theme.spacing(1),
		marginTop: "15px",
		position: "absolute",
		right: "-12px",
		top: 14,
		transition: ".5s ease"
	},

	removeButtonRow: {
		textAlign: "right",
	},

	TextField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	detailPageWrapper: {
		position: "sticky",
		top: `${theme.spacing(2)}px`,
		minHeight: 0,
		marginBottom: "15px",
		"& h4": {
			textAlign: "center",
			margin: "0 0 16px 0"
		},
		"& h5": {
			margin: "8px 0 16px 0"
		},
		"& a": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blue,
			textDecoration: "none"
		},
		"& a:hover": {
			fontWeight: theme.fontWeights.semiBold,
			color: theme.colors.blueHover,
			textDecoration: "none"
		}
	},
	compositeLabel: {
		color: theme.colors.black,
		paddingBottom: theme.spacing(1)
	},
	detailContentWrapper: {
		margin: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		width: "100%",
		"& svg": {
			marginLeft: "auto"
		},
		overflow: "hidden"
	},
	detailActionPaper: {
		
	},
	detailActionPaperEmbed: {
		padding: "15px"
	},
	detailActionButton: {
		width: "100%",
		marginBottom: 8,
		paddingTop: 5,
		paddingBottom: 5
	},
	detailActionSections: {
		paddingLeft: "20px",
		"& a": {
			cursor: "pointer",
			textDecoration: "none",
			fontSize: "14px"
		},
		"& button": {
			textAlign: "left"
		}
	},
	sectionHelperText: {
		marginLeft: 0,
		marginRight: 12,
		marginBottom: 24
	},
	helperText: {
		marginLeft: 12,
		marginRight: 12
	},
	toolTip: {
		marginBottom: -5,
		fontSize: 12
	},
	textToolTip: {
		marginBottom: 3,
		fontSize: 12
	},
	errorBorder: {
		border: "1px #B20000 solid",
		borderRadius: "4px"
	},
	errorLabel: {
		color: "#B20000"
	},
	confirmDialogContent: {
		
	},
	helperText: {
		marginBottom: "20px",
		marginTop: "10px"
	},
	fieldSizeSmall: {
		"& .MuiOutlinedInput-input": {
			padding: "10.5px 14px"
		},
		"& label": {
			marginTop: "-7px"
		}
	}
});

export default styles;
