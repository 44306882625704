import { FormActions } from "../common/consts";
import { useDispatch } from "react-redux";
import { Creators } from "../actions/transientElementActions";
import { GLOBAL_DIALOG, GLOBAL_SNACKBAR, GLOBAL_TABBED_DRAWER } from "../common/consts";
import React, { ReactNode } from "react";
import FormFrame from "../components/Form/FormFrame";

interface OpenFormInput {
	action?: FormActions;
	moduleName: string;
	parentModule?: string;
	recId?: number;
	refId?: number | string;
	defaultData?: Record<string, any>;
	afterSubmit?: () => void;
	PendingID?: number;
	formName?: string;
	viewOnly?: boolean;
	displaySize?: string;
}

interface OpenDialogInput {
	dialogMessage: string;
	dialogSubText: string;
	actions: any;
}

interface OpenConfirmDialogInput {
	title?: string;
	content?: string;
	confirmLabel?: string;
	cancelLabel?: string;
	handleConfirm: () => void;
	handleCancel?: () => void;
	handleExit?: () => void;
}

interface ReturnType {
	showSnackbarMessage: (message: string, messageType: string) => void;
	openForm: (input: OpenFormInput) => void;
	openDialog: (input: OpenDialogInput) => void;
	closeDialog: () => void;
	openConfirmDialog: (input: OpenConfirmDialogInput) => void;
	setDrawerOpen: (a: any, b: any) => void;
}

/**
 * Return a callback which will update the given transient element
 * @param {*} name
 */
const useTransientElements = (): ReturnType => {
	const dispatch = useDispatch();
	const result: ReturnType = {
		showSnackbarMessage: (message: string, messageType = "info"): void => {
			dispatch(Creators.setDrawerOpen(GLOBAL_SNACKBAR, {
				isOpen: true,
				message,
				messageType
			}));
		},
		openForm: ({ 
			action = FormActions.ADD,
			moduleName,
			parentModule,
			recId = 0,
			refId = 0,
			defaultData,
			afterSubmit,
			PendingID,
			formName,
			viewOnly = false,
			displaySize = "lg" 
		}: OpenFormInput): void => {
			const formType = parentModule && parentModule !== "admin" ? `${parentModule}.${moduleName}` : moduleName;
			let comp: ReactNode;
			if (PendingID) {
				comp = <FormFrame
					type={formType}
					action={action}
					refId={+refId}
					PendingID={PendingID}
					defaultData={defaultData}
					afterSubmit={afterSubmit}
				/>;
			} else {
				comp = <FormFrame
					type={formType}
					action={action}
					refId={+refId}
					recId={recId}
					defaultData={defaultData}
					afterSubmit={afterSubmit}
					viewOnly={viewOnly}
				/>;
			}
			dispatch(Creators.setDrawerOpen(GLOBAL_TABBED_DRAWER, {
				type: formType,
				isOpen: true,
				action,
				displayComponent: comp,
				tabs: [{ name: formName }],
				activeTab: formName,
				displaySize
			}));
		},
		openDialog: ({ dialogMessage, dialogSubText, actions }) => {
			dispatch(Creators.setDrawerOpen(GLOBAL_DIALOG, {
				isOpen: true,
				title: dialogMessage,
				content: dialogSubText,
				actions
			}));
		},
		closeDialog: () => {
			dispatch(Creators.setDrawerOpen(GLOBAL_DIALOG, {
				isOpen: false,
			}));
		},
		openConfirmDialog: ({ 
			title = "Are you sure?",
			content = "Are you sure?",
			confirmLabel = "OK",
			cancelLabel = "Cancel",
			handleConfirm,
			handleCancel = (): void => {},
			handleExit = (): void => {}
		}) => {
			dispatch(Creators.setDrawerOpen(GLOBAL_DIALOG, {
				isOpen: true,
				title,
				content,
				actions: [{
					label: cancelLabel,
					handler: (): void => {
						dispatch(Creators.setDrawerOpen(GLOBAL_DIALOG, {
							isOpen: false,
						}));
						handleCancel && handleCancel();
					}
				}, {
					label: confirmLabel,
					color: "primary",
					handler: (): void => {
						dispatch(Creators.setDrawerOpen(GLOBAL_DIALOG, {
							isOpen: false,
						}));
						handleConfirm && handleConfirm();
					}
				}],
				handleExit
			}));
		},
		setDrawerOpen: (a, b) => dispatch(Creators.setDrawerOpen(a, b))
	};

	return result;

};

export default useTransientElements;
