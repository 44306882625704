const styles = theme => ({
	label: {
		maxWidth: 150,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		display: "block"
	}
});

export default styles;
