import React, { useState, useEffect, useCallback } from "react";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import Paper from "@material-ui/core/Paper";
import { DamClient } from "@simpleview/dam-client";
import RenderForm from "@react-ui/components/Form";
import { formatDatetime } from "@react-ui/common/format";
import styles from "./styles";
import { factory } from "../common/axiosSetup";
import callbacks from "../common/callbacks";
import { getTheme } from "../common/createTheme";
import { AsyncSelectField } from "./Form/CustomFields/SelectField";

const ax = factory(true);

class Api {

	constructor(url, acct_id) {
		this.url = url;
		this.acct_id = acct_id;
	}

	async call(operation, input, prefix) {
		const res = await Axios.post(this.url, {
			operation,
			acct_id: this.acct_id,
			input,
			prefix
		});
		return res.data;
	}

	async fetchFormById(input) {
		return this.call("fetchFormById", input);
	}

	async setFormData(input) {
		return this.call("setFormData", input);
	}

	async get(input) {
		return this.call("get", input);
	}

	async get_old(input) {
		return this.call("get_old", input);
	}

	async fetchFormOptions(input) {
		return this.call("fetchFormOptions", input);
	}

	async verifyCaptchaToken(input) {
		return this.call("verifyCaptchaToken", input);
	}

	async removeAsset(input) {
		return this.call("removeAsset", input);
	}

	async uploadAttachments(input) {	
		return this.call("uploadAttachments", input);	
	}	

	async deleteAttachment(input) {	
		return this.call("deleteAttachment", input);	
	}

	async fetchTenantSettings(input) {
		return this.call("fetchTenantSettings", input, "crm_admin");
	}
}

const damClient = new DamClient({
	graphUrl: "https://graphql.simpleviewinc.com",
	assetsUrl: "https://assets.dam.simpleviewinc.com/"
});

/*
* Things to do:
* 	- set graphqlUrl per environment
* 	- pass custom css for client
*	- finish adding captcha
*/

function EmbeddedForm({ classes, acct_id, form_id, primaryColor = "#0070F2", secondaryColor = "#F0F2F5", field }) {
	const [loading, setLoading] = useState(true);
	const [state, setState] = useState();
	const [reload, setReload] = useState(new Date());
	let timeZone = "America/Phoenix";
	let formDisplay = false;
	if (field?.form_id) {
		acct_id = field.acct_id;
		form_id = field.form_id;
		formDisplay = field?.formDisplay;
	}

	const { CAPTCHA_KEY, BASE_URL } = process.env;
	const api = new Api(BASE_URL + "api", acct_id);

	const theme = {};
	if (primaryColor) {
		theme.primarymain = primaryColor;
	}
	if (secondaryColor) {
		theme.secondarymain = secondaryColor;
	}
	const defaultTheme = createMuiTheme(getTheme(theme));

	useEffect(() => {
		if (field && !field.acct_id) {
			return;
		}
		api.fetchTenantSettings({}).then(results => {
			if (results.success) {
				timeZone = results.rec.timeZone;
			}
		});
		api.fetchFormById({
			id: form_id,
			formDisplay: formDisplay
		}).then(results => {
			if (results && results.success === false || results === null) {
				return;
			}

			const cbs = { ...callbacks };
			Object.keys(cbs).forEach(cb => {
				cbs[cb] = cbs[cb](api);
			});

			results.server = api;

			results.actions.forEach(action => {
				action.args.isWebSubmission = true;
				action.args.form_id = form_id;
				action.args.acct_id = acct_id;
				action.args.submitDate = formatDatetime(new Date());
				action.args.afterSubmit = ({ res }) => {
					if (res && res.success) {
						alert("Form Submitted Successfully.");
						results.responseUrl ? window.location.href = results.responseUrl : null;
						setReload(new Date());
					} else {
						const alertMessage = `Error: ${res && res.message ? res.message : "Failed to submit."}`;
						alert(alertMessage);
					}
					
				};
			});

			if (field?.form_id) {
				results.actions = [];
			}

			setState({ ...results, callbacks: cbs, captchaSiteKey: CAPTCHA_KEY });

			setLoading(false);
		});
	}, [reload, field]);

	const fetchOptions = useCallback(async (moduleName, column, page, search) => {
		return api.fetchFormOptions({
			moduleName,
			column: `web_${column}`,
			page,
			search
		});
	}, [api]);

	if (loading === true) {
		return null;
	}
	
	return <MuiThemeProvider theme={defaultTheme}>
		<Paper className={classes.embedForm}>
			<RenderForm
				isWebForm={true}
				customComponents={{
					AsyncSelect: (props) => <AsyncSelectField {...props} fetchOptions={fetchOptions} />
				}}
				{ ...state }
				acctId={acct_id}
				baseUrl={BASE_URL}
				damClient={damClient}
				localeSettings={{
					prefix: "$",
					suffix: "",
					thousandSeparator: ",",
					decimalSeparator: ".",
					decimalScale: 2,
					timeZone
				}}
			/>
		</Paper>
	</MuiThemeProvider>;
}

export default withStyles(styles)(EmbeddedForm);
