// IMPORTANT!: This callback should always be used on composite field
// if compositeField param is undefined, it means the callback is triggered
// by part of the compositeField wrapper (add button or remove button), so
// use fieldName as composite field name.
const setPrimary = crm => ({ fieldName, compField, compIndex, staticArgs, eventName, dispatchWrapper, getState }) => {
	const primaryFieldName = staticArgs ? staticArgs[0] : "IsPrimary";
	compField = compField || fieldName;
	if (fieldName === primaryFieldName) {
		const data = getState().data[compField].compositeFields;
		if (data && eventName !== "onLoad") {
			if (compIndex !== undefined) {
				let i = 0;
				for (const rec of data) {
					if (rec[primaryFieldName] === true && i !== compIndex) {
						dispatchWrapper("handleFormChange", [primaryFieldName, "Boolean", false, i, compField]);
					}
					i++;
				}
			}
		} 
		//Always do this, as if data changes we need to make sure a primary
		if (data && data.filter(d => !d.ToBeDeleted).findIndex(phone => phone.IsPrimary) === -1) {
			const topIndex = data.findIndex(d => !d.ToBeDeleted);
			dispatchWrapper("handleFormChange", [primaryFieldName, "Boolean", true, topIndex, compField]);
		}
	}
};

export default setPrimary;
