import accountListings from "./accountListings";

const listingPrefill = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { refId, formType, recId } = getState();
	if (recId !== 0 || !formType.includes("listing")) {
		return;
	}

	crm.get({
		type: "listing",
		filter: {
			recId: refId
		},
		fields: `
			Title
			ListingID
			AccountID
			AccountCompanyName
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: listings } = res;
			let options = listings.map(rec => {
				return { label: rec.AccountCompanyName, value: rec.AccountID };
			});

			dispatchWrapper("handleFormChange", ["AccountID", "Int", options[0]]);
			dispatchWrapper("updateField", ["AccountID", { readOnly: true }]);
			accountListings(crm)({ fieldName: "AccountID", subscriber, dispatchWrapper, getState });
			
			options = listings.map(rec => {
				return { label: rec.Title, value: rec.ListingID };
			});
			dispatchWrapper("handleFormChange", [subscriber, "Chip", options]);
		}
	});
};

export default listingPrefill;
