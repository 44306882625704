import { ERROR_TYPES } from "../consts";
import validateEmail from "../../common/validateEmail";
const { IS_EMAIL } = ERROR_TYPES;

const isEmail = ({ fieldName, compField, compIndex, eventName, dispatchWrapper, getState }) => {
	const { data } = getState();
	const fieldParts = [fieldName, compField, compIndex];
	const email = compField && Number.isInteger(compIndex) ? data[compField].compositeFields[compIndex][fieldName] : data[fieldName];
	if (!email || email === "") {
		dispatchWrapper("clearValidation", [...fieldParts, IS_EMAIL]);
	} else {
		const valid = validateEmail(email);
		if (!valid) {
			dispatchWrapper("setValidation", [...fieldParts, IS_EMAIL, "Email format is invalid"]);
		} else {
			dispatchWrapper("clearValidation", [...fieldParts, IS_EMAIL]);
		}
	}
};

export default isEmail;
