import { handleSubmit } from "../form";
import { setRecentlyViewed } from "../../common/recentlyViewed";
import store from "../../store";
import { Creators } from "../../actions/transientElementActions";
import { GLOBAL_TABBED_DRAWER } from "../consts";
const { dispatch } = store;

const handleApprove = crm => async (info) => {
	const result = await handleSubmit({ crm, review: { approved: true }, ...info });

	if (!result) {
		dispatch(Creators.setMessage("There was an error approving this offer. Please try again.", "error"));
		dispatch(Creators.setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false }));
		return;
	}

	const diff = info.getState().data.DiffJson;
	const returnData = result["set_pending_" + info.type + "_response"];
	if (diff && Object.keys(diff.oldValue).length === 0) {
		// new pending record
		setRecentlyViewed({ crmGraphServer: crm, type: info.type, record: {
			...diff?.newValue,
			recId: returnData.recId,
			RecordID: returnData.RecordID
		} });
	}
};

export default handleApprove;
