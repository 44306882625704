import {
	ACCOUNT,
	CONTACT,
	LISTING,
	EVENTCALENDAR,
	INQUIRY,
	OFFER,
	MYPROFILE,
	USER,
	SUMMARY,
	BULLETIN,
	TASK
} from "./consts";
import upperFirst from "lodash/upperFirst";
import { getIcon } from "./icons";

export const getDisplayName = (moduleType, data) => {
	const results = {
		[ACCOUNT]: data.CompanyName,
		[CONTACT]:
			data.FirstName && data.LastName
				? `${data.FirstName} ${data.LastName}`
				: (Array.isArray(data.Contact) && data.Contact.length ? data.Contact[0].label : data.Contact),
		[LISTING]: data.Title,
		[EVENTCALENDAR]: data.Title,
		[INQUIRY]: data.InquiryType
			? `${data.InquiryType}${
					data.Contact?.length
						? ` - ${data.Contact[0].label}`
						: data.Contact?.recs?.length
							? ` - ${data.Contact.recs[0].FullName}`
							: null
			  }`
			: `${data.FirstName} ${data.LastName}`,
		[OFFER]: data.SpecialOfferTitle,
		[MYPROFILE]: `${data.FirstName} ${data.LastName}`,
		[USER]: `${data.FirstName} ${data.LastName}`,
		[TASK]: data.Title
	};

	if (!(moduleType in results)) {
		console.warn(`Unrecognizable module name: ${moduleType}`);
		// make some guesses
		return data.title || data.Title || data.displayName || data.displayNm;
	}

	return results[moduleType];
};

export const getModuleIcon = (moduleType, override) => {
	const results = {
		[ACCOUNT]: "Public",
		[CONTACT]: "Group",
		[LISTING]: "List",
		[EVENTCALENDAR]: "Event",
		[INQUIRY]: "Forum",
		[OFFER]: "LocalOffer",
		[MYPROFILE]: "Person",
		[USER]: "Person",
		[BULLETIN]: "Resource",
		[TASK]: "Assignment"
	};

	let icon = override || "Warning";
	if (moduleType in results) {
		icon = results[moduleType];
	}

	return getIcon(icon);
};

export const getModulePath = (moduleType, id) => {
	if (!id) {
		return null;
	}
	return `/${SUMMARY}/${moduleType}/${id}`;
};

// TODO: we should be using a label not the type for things like this
export const getModuleName = (moduleType, useLower = false, count = 1) => {
	if (useLower) {
		return getModuleNameLower(moduleType, count);
	}

	const moduleTypeMapper = {
		accountCustomField: "Account Configurable Field",
		contactCustomField: "Contact Configurable Field",
		eventCalendarCustomField: "Event Calendar Configurable Field",
		inquiryCustomField: "Inquiry Configurable Field",
		listingCustomField: "Listing Configurable Field",
		offerCustomField: "Offer Configurable Field",
		taskCustomField: "Task Configurable Field",
		listingRank: "Listing Rank",
		contactRole: "Contact Role",
		engagementLevel: "Engagement Level",
		noteCategory: "Note Category",
		offerCategory: "Offer Category",
		referralSource: "Referral Source",
		sourceCode: "Source Code",
		taskType: "Task Type",
		bulletinCategory: "Bulletin Category",
		eventCalendar: "Event Calendar",
		visitReason: "Reason for Visit",
		visitor: "Visitor Inquiry"
	}

	return moduleTypeMapper[moduleType] ? moduleTypeMapper[moduleType] : upperFirst(moduleType);
};

const getModuleNameLower = (moduleType, count) => {
	const plurral = count > 1;
	switch (moduleType) {
		case INQUIRY:
			return plurral ? "inquiries" : INQUIRY;
		case EVENTCALENDAR:
			return plurral ? "events" : "event";
		default:
			return plurral ? moduleType + "s" : moduleType;
	}
};

export const getEndingModule = (moduleType) => {
	return moduleType.split(".").pop();
};
