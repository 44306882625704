import { CrmFormCallback } from "./typeDef";
import { GLOBAL_TABBED_DRAWER } from "../consts";
import { handleSubmit } from "../form";
import FormFrame from "../../components/Form/FormFrame";
import React from "react";

const followUpTask: CrmFormCallback = (crm) => async (info): Promise<any> => {
	const { getState, setDrawerOpen, afterSubmit } = info;
	const { data, formType, refId } = getState();
	const response = await handleSubmit({ crm, ...info, data: data });
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	const tabs = [{ name: "" }];
	const activeTab = tabs[0].name;

	const displayComponent = <FormFrame
		type={formType}
		action={"add"}
		recId={0}
		refId={+refId}
		afterSubmit={afterSubmit}
		reMount={new Date()}
		defaultData={{ ParentTaskID: response.set_task.recId, ParentTaskTitle: response.set_task.message }}
	/>;

	setDrawerOpen(GLOBAL_TABBED_DRAWER, {
		isOpen: true,
		type: formType,
		id: 0,
		action: "add",
		tabs,
		activeTab,
		displayComponent
	});

};

export default followUpTask;
