import { extractInfo } from "../form";
import Axios from "axios";

const getAssetIds = crm => async info => {
	const { updateField, ownValue } = extractInfo(info);

	if (!ownValue) {
		return;
	}
	let value = ownValue;
	if (Array.isArray(ownValue) === false) {
		value = [ownValue];
	}

	let totalFileSize = 0;
	// TODO have DAM expose file info
	for (const file of value) {
		if (file.MediaType && file.MediaType !== "Video") {
			try {
				const axiosFile = await Axios.get(file.url, { responseType: "arraybuffer" });
				if (axiosFile && axiosFile.headers && axiosFile.headers["content-length"]) {
					totalFileSize += Number(axiosFile.headers["content-length"]);
					file.size = Number(axiosFile.headers["content-length"]);
				}
			} catch (e) {
				file.size = 0;
			}
		}
	}

	updateField({
		files: value.map(file => {
			return {
				...file,
				original: true
			};
		}),
		totalFileSize
	});
};

export default getAssetIds;
