const contactsFromAccount = crm => async({ subscriber: contactField, dispatchWrapper, getState }) => {
	const { formType, refId } = getState();
	if (formType === "account.referral" && refId) {
        const res = await crm.get({
			type: "contact",
			fields: `
            ContactID
			FullName
			Email {
				recs {
					EmailAddress
				}
			}`,
			filter: {
				AccountID: refId,
				AccountRelationshipActive: true
			}
		});

        const options = [];

		if (res.success && res.count) {
            for (const contact of res.recs) {
                for (const email of contact.Email.recs) {
                    options.push({
                        value: contact.ContactID,
                        label: `${contact.FullName} <${email.EmailAddress || "no email"}>`,
                        sortOrder: null,
                        valueString: null
                    });
                }
            }
		}
        if (options.length) {
            dispatchWrapper("updateField", [contactField, { options }]);
        } else {
            dispatchWrapper("updateField", [contactField, { options: [] }]);
        }
	} else if (formType === "contact.referral" && refId) {
        const res = await crm.get({
			type: "contact",
			fields: `
            ContactID
			FullName
			Email {
				recs {
					EmailAddress
				}
			}`,
			filter: {
				recId: refId
			}
		});

        if (res.success && res.count) {
            dispatchWrapper("handleFormChange", [contactField, "Int", {
                value: res.recs[0].ContactID,
                label: `${res.recs[0].FullName} <${res.recs[0].Email.recs[0]?.EmailAddress || "no email"}>`,
                sortOrder: null,
                valueString: null
            }]);
        } else {
            dispatchWrapper("handleFormChange", [contactField,"Int", []]);
        }
    }
};
export default contactsFromAccount;
