import { extractInfo } from "../form";

const accountEventCalendars = crm => async info => {
	const { subValue, updateField } = extractInfo(info);
	let HostingAccountID = subValue;

	if (subValue && subValue.value !== undefined) {
		HostingAccountID = subValue.value;
	} else if (typeof subValue !== "number") {
		return;
	}

	crm.get({
		type: "accountEventCalendars",
		filter: {
			HostingAccountID
		},
		fields: `
			HostingAccountID
			EventCalendarID 
			Title
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: eventCalendars } = res;
			const options = eventCalendars.map(rec => {
				return { label: rec.Title, value: rec.EventCalendarID };
			});
			updateField({ options });
		} else {
			updateField({ options: [] });
		}
	});
};

export default accountEventCalendars;
