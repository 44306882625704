import React, { useEffect } from "react";
const Captcha = ({ siteKey, enableSubmit }) => {
	const handleLoaded = () => {
		window.grecaptcha.ready(() => {
			enableSubmit(true);
		});
	};

	useEffect(() => {
		enableSubmit(false);
		const script = document.createElement("script");
		script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
		script.addEventListener("load", handleLoaded);
		document.body.appendChild(script);
	}, []);

	return <div
		className="g-recaptcha"
		data-sitekey={siteKey}
		data-size="invisible"
	></div>;
};

export default Captcha;
