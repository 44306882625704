const listingAccountOverWrites = crm => ({ eventName, dispatchWrapper, getState }) => {
	const { 
		data,
		data: {
			ListingAddress_v2
		},
		fields
	} = getState();

	if (data.AccountID) {
		crm.get({
			type: "account",
			filter: {
				recId: data.AccountID.value
			},
			fields: `
				CompanyName
				CompanyNameSort
				WebURL
				Latitude
				Longitude
				Address_v2
				Phone {
					recs {
						PhoneNumber
						PhoneExtension
						PhoneTypeID
						IsPrimary
					}
				}
				Email {
					recs {
						EmailAddress
						IsPrimary
					}
				}
			`
		}).then(response => {
			// TODO: update this so that it doesn't violate the subscription model
			const { recs: accounts } = response;
			if (accounts.length && ((eventName === "onLoad" && !data.recId) || eventName === "onChange")) {
				const account = accounts[0];
				const phone = account.Phone.recs.filter(phone => phone.IsPrimary === true);
				const email = account.Email.recs.filter(email => email.IsPrimary === true);
				dispatchWrapper("handleFormChange", ["AccountCompanyName", "String", account.CompanyName]);
				dispatchWrapper("handleFormChange", ["AccountCompanyNameSort", "String", account.CompanyNameSort || ""]);
				dispatchWrapper("handleFormChange", ["AccountWebURL", "String", account.WebURL || ""]);
				dispatchWrapper("handleFormChange", ["AccountPhoneNumber", "String", phone.length ? phone[0].PhoneNumber : ""]);
				dispatchWrapper("handleFormChange", ["AccountEmailAddress", "String", email.length ? email[0].EmailAddress : ""]);
				dispatchWrapper("handleFormChange", ["AccountLatitude", "String", account.Latitude || ""]);
				dispatchWrapper("handleFormChange", ["AccountLongitude", "String", account.Longitude || ""]);

				let addresses;
				if (account.Address_v2) {
					try {
						addresses = JSON.parse(account.Address_v2);
					} catch (error) {
						console.log(error);
					}
				}

				if (account.Latitude && account.Longitude) {
					dispatchWrapper("handleFormChange", ["Latitude", "String", account.Latitude]);
					dispatchWrapper("handleFormChange", ["Longitude", "String", account.Longitude]);
					dispatchWrapper("handleFormChange", ["LatLng", "String", `${account.Latitude}, ${account.Longitude}`]);
				} else {
					dispatchWrapper("handleFormChange", ["Latitude", "String", ""]);
					dispatchWrapper("handleFormChange", ["Longitude", "String", ""]);
					dispatchWrapper("handleFormChange", ["LatLng", "String", ""]);
				}
				
				if (addresses?.address) {
					addresses.address = [addresses.address.filter(address => address.AddressTypeID.value === 1)[0]];
					if (addresses.address?.length && addresses.address[0]) {
						dispatchWrapper("handleFormChange", ["AccountAddress_v2", "Address_v2", addresses]);
					}
				}

				if (eventName === "onChange" && data.Phone && data.Phone.compositeFields) {
					for (let i = data.Phone.compositeFields.length; i > 0; i--) {
						dispatchWrapper("removeField", ["Phone", i - 1]);
					}
					dispatchWrapper("addField", ["Phone"]);
				}

			}
		});
	}
};

export default listingAccountOverWrites;
