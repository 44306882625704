const fetchRelatedItems = crm => async ({
	fieldName,
	subscriber,
	dispatchWrapper,
	getState
}) => {
	const { data, fields, formType, recId, refId } = getState();
	let ids = data[fieldName];
	const { gridOptions: { moduleName, listColumns } } = fields[subscriber];
	let addParent = false;
	/**
	 * if it's a new record, we want to auto add the parent reference
	 */
	if (recId === 0 && formType.split(".")[0] === moduleName) {
		ids = refId;
		addParent = true;
	}

	if (!ids) {
		return [];
	}

	crm.get({
		type: moduleName,
		filter: {
			recId: ids
		},
		fields: listColumns.map(column => column.name).join(" ")
	}).then(items => {
		if (items.success && items.count) {
			if (addParent === true) {
				dispatchWrapper("handleFormChange", [fieldName, "Int", ids]);
			}

			dispatchWrapper("handleFormChange", [subscriber, "Object", items.recs]);
		}
	});
};

export default fetchRelatedItems;
