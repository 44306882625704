const contactAccounts = crm => ({ fieldName, subscriber: accountField, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (data[fieldName]) {
		crm.get({
			type: "contact",
			filter: {
				recId: data[fieldName].value,
				AccountRelationshipActive: true
			},
			fields: `
				Accounts {
					recs {
						CompanyName
						AccountID
					}
				}
			`
		}).then(res => {
			if (res.success && res.count) {
				const accounts = res.recs[0].Accounts.recs.sort((a, b) => (a.CompanyName > b.CompanyName ? 1 : -1));

				if (accounts.length) {
					dispatchWrapper("updateField", [accountField, { options: accounts.map(account => ({ label: account.CompanyName, value: account.AccountID })) }]);
				} else {
					dispatchWrapper("updateField", [accountField, { options: [] }]);
					dispatchWrapper("handleFormChange", [accountField, "Int", []]);
				}
			} else {
				dispatchWrapper("updateField", [accountField, { options: [] }]);
				dispatchWrapper("handleFormChange", [accountField, "Int", []]);
			}
		});
	}
};
export default contactAccounts;
