const showDTNListing = crm => async ({ dispatchWrapper, subscriber }) => {
	crm.get({
		type: "user_settings",
		filter: {
			SettingName: "DTN", 
			ModuleName: "svadmin"
		},
		fields: "SettingName SettingValue"
	}).then(res => {
		const settingVal = res && res.recs ? res.recs[0].SettingValue : "0";

		if (settingVal === "1") {
			dispatchWrapper("updateSection", [subscriber, { hidden: false }]);
		}
	});
};

export default showDTNListing;

