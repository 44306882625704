const accountContactRel = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { data, refId } = getState();

	if (data.recId) {
		crm.get({
			type: "contact",
			filter: {
				recId: data.recId
			},
			fields: `
				Accounts {
					recs {
						recId
						AccountID
						CompanyName
						ContactRelation {
							recs {
								ContactID
								IsPrimary
								IsActive
								AccountID
								ContactRoles {
									recs {
										ContactRoleID
										Title
									}
								}
							}
						}
					}
				}
			`
		}).then(response => {
			const { recs: contacts } = response;
			if (contacts.length) {
				const contact = contacts[0];
				if (contact.Accounts.recs.length) {
					const gridRecs = contact.Accounts.recs.map(acct => {
						const ContactRoles = [];
						const ContactRoleID = [];

						acct.ContactRelation.recs[0].ContactRoles.recs.forEach(cr => {
							ContactRoles.push(cr.Title);
							ContactRoleID.push(cr.ContactRoleID);
						});

						return {
							recId: acct.recId,
							AccountID: acct.AccountID,
							CompanyName: acct.CompanyName,
							IsPrimary: acct.ContactRelation.recs[0].IsPrimary,
							RelIsActive: acct.ContactRelation.recs[0].IsActive,
							ContactRoles,
							ContactRoleID
						};
					});
					dispatchWrapper("handleFormChange", [subscriber, "Object", gridRecs]);
				} 
			}
		});
	} else {
		if (refId) {
			crm.get({
				type: "account",
				filter: {
					recId: refId
				},
				fields: `
					CompanyName
				`
			}).then(response => {
				const { recs: accounts } = response;
				const gridRecs = accounts.map(acct => {
					const ContactRoles = [];
					const ContactRoleID = [];

					return {
						recId: refId,
						AccountID: refId,
						CompanyName: acct.CompanyName,
						IsPrimary: true,
						RelIsActive: true,
						ContactRoles,
						ContactRoleID
					};
				});
				dispatchWrapper("updateField", [subscriber, { gridRecs }]);
				dispatchWrapper("handleFormChange", [subscriber, "Object", gridRecs]);
			});
		}
	}
};

export default accountContactRel;
