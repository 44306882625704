const setModuleSettingFields = crm => ({ fieldName, compField, compIndex, eventName, subscriber, staticArgs, dispatchWrapper, getState }) => {
	const { data } = getState();
	const recId = Number(data.recId);

	if (eventName === "onLoad" && data[fieldName]) {
		if (recId > 0) {
			dispatchWrapper("updateField", ["ModuleID", { readOnly: true }]);
		}
		return;
	}
};

export default setModuleSettingFields;
