import { extractInfo } from "../../common/form";
import { ERROR_TYPES } from "@react-ui/common/consts";

const city = crm => (info) => {
	
	const { subValue, subField, updateValue, subCompIndex, clearError } = extractInfo(info);
	if (!subValue) {
		return;
	}

	if (subField.index[subCompIndex]?.geocodeCache) {
		updateValue(subField.index[subCompIndex].geocodeCache.City);
		clearError(ERROR_TYPES.IS_REQUIRED);
	}

};

export default city;
