import React from "react";
import { Paper, MenuItem, Typography, TextField, Popover } from "@material-ui/core";
import { components } from "react-select";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const selectComponents = {
	Control: props => {
		const { innerRef, children, selectProps, innerProps } = props;
		const classes = useStyles();
		// added hack below to value to check for validators,
		// because required border wasn't going away otherwise
		// we're getting rid of this field anyway in refactor
		return (
			<TextField
				fullWidth
				variant="outlined"
				id={selectProps?.field?.name}
				name={selectProps?.field?.name}
				error={selectProps?.error}
				helperText={selectProps?.errorMsg}
				FormHelperTextProps={{ className: "helper-text" }}
				InputProps={{
					inputComponent,
					inputProps: {
						className: classes.input,
						inputRef: innerRef,
						children,
						...innerProps
					}
				}}
				{...selectProps.textFieldProps}
			/>
		);
	},
	Menu: props => {
		const { children, selectProps, innerProps } = props;
		const classes = useStyles();
		return (
			<Paper className={classes.paper} {...innerProps}>
				{children}
			</Paper>
		);
	},
	SingleValue: props => {
		const { children, selectProps, innerProps } = props;
		const classes = useStyles();
		return (
			<Typography className={classes.singleValue} {...innerProps}>
				{children && children.value || children}
			</Typography>
		);
	},
	MultiValue: (props) => {
		// hide the chips, since we are rendering them outside of the input
		// this just would ensure adding a chip still work like it used to.
		return null;
	},
	NoOptionsMessage: props => {
		const { selectProps, innerProps, children } = props;
		const classes = useStyles();
		return (
			<div style={{ height: 300 }}>
				<Typography color='textSecondary' className={classes.noOptionsMessage} {...innerProps}>
					{children}
				</Typography>
			</div>
		);
	},
	Option: props => {
		const { isFocused, isSelected, innerProps, children, innerRef, options, value, selectProps } = props;
		const opt = options.find(opt => opt.value === value);
		const classes = useStyles();

		return (
			<MenuItem
				key={value}
				buttonRef={innerRef}
				selected={isFocused}
				component='div'
				className={classes.menuItem}
				style={{
					fontWeight: isSelected ? 900 : 400
				}}
				{...innerProps}
				disabled={opt.hasPermissions === false || opt.isActive === false}
			>
				{children}
			</MenuItem>
		);
	},
	Placeholder: props => {
		const { selectProps, innerProps, children } = props;
		const classes = useStyles();
		return (
			<Typography color='textSecondary' className={classes.placeholder} {...innerProps}>
				{children}
			</Typography>
		);
	},
	ValueContainer: props => {
		const { selectProps, children } = props;
		const classes = useStyles();
		return <div className={classes.valueContainer}>{children}</div>;
	},
	IndicatorSeparator: ({ innerProps, selectProps }) => {
		const classes = useStyles();
		return <span style={classes.indicatorSperator} {...innerProps} />;
	},
	DropdownIndicator: (props) => {
		const { selectProps } = props;
		const classes = useStyles();
		return <components.DropdownIndicator className={classes.dropdownIndicator} {...props}></components.DropdownIndicator>;
	}
};

export default { inputComponent, ...selectComponents };
