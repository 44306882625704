const all = `
	recId
	AccountID
	Account
	RecordID
	ListingID
	ExternalID
	ContactID
	Contact	
	ListingRankID
	Rank
	RankOrder
	ListingTypeID
	ListingType
	CategoryID
	Category
	SubcategoryID
	Subcategory
	AdditionalSubcategories {
		recs {
			recId
			ListingCategoryID
			Title
			CatSubcatTitle
			SortOrder
			ShortDesc
			ParentListingCategoryID
			ParentCategory
			IsActive
			CreatedDt
			CreatedBy
			LastModifiedDt
			LastModifiedBy
		}
	}
	Title
	Keyword
	IsActive
	ListingDesc
	AccountCompanyName
	AccountCompanyNameSort
	AccountWebURL
	AccountEmailAddress
	AccountPhoneNumber
	AccountPhoneExtension
	AccountAddress {
		recs {
			AddressID
			AddressTypeID
			AddressType
			AddressLine1
			AddressLine2
			AddressLine3
			City
			StateCode
			PostalCode
			PostalCode4
			CountryCode
		}
	}
	CompanyName
	CompanyNameSort
	WebURL
	EmailAddress
	PhoneNumber
	PhoneExtension
	Address {
		recs {
			AddressID
			AddressTypeID
			AddressType
			AddressLine1
			AddressLine2
			AddressLine3
			City
			StateCode
			PostalCode
			PostalCode4
			CountryCode
		}
	}
	ShowMap	
	Tag {
		recs {
			Title
		}
	}
	Connection {
		DisplayNm
		ConnectionValue          
	}
	CustomField {
		UDFieldName
		UDFieldDisplayValue
		FieldType
	}
	IsApproved
	IsDeleted
	CreatedDt
	CreatedBy
	LastModifiedDt
	LastModifiedBy
	RecInfo
	FullContact {
		recs {
			ContactID
			recId
			RecordID
			ExternalID
			ContactMethodID
			ContactMethod
			SalutationID
			Salutation
			PrefixID
			Prefix
			FirstName
			LastName
			FullName
			Suffix
			Alias
			GenderID
			Gender
			BirthMonth
			BirthDay
			SendEmail
			SendFax
			IsPending
			IsDeleted
			CreatedDt
			CreatedBy
			LastModifiedDt
			LastModifiedBy
			PrimaryEmailAddress
			PrimaryEmailTypeID
			PrimaryEmailType
			PrimaryPhoneNumber
			PrimaryPhoneTypeID
			PrimaryPhoneType
			AddressLine1
			AddressLine2
			AddressLine3
			TitleNm
			City
			StateCode
			CountryCode
			PostalCode
			PrimaryAccountName
			PrimaryAccountID
			PrimaryAccountRecordID
			GroupID
			AssetPath
			RecInfo
			UserID
			IsAnonymized
		}
	}
	Asset {
		recs {
			AssetPath
			Title
		}
	}
`;

export default all;
