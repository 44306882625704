import { extractInfo } from "../form";
import store from "../../store";

/**
 * staticArgs:
 * 0 - fieldPropname: String
 *     prop name
 * 1 - toSet: any
 *     value to set prop to
 */    
 
const setDTNEnabled = crm => info => {
	const { updateField, staticArgs = [] } = extractInfo(info);
	const [attrName, toSet] = staticArgs;
	const loginState = store.getState().login;
	let isDTNAdmin = false;

	const update = (value) => {
		updateField({
			[attrName]: value
		});
	};

	crm.get({
		type: "user_permissions",
		filter: {
			FullPerm: "admin.dtn"
		},
		fields: "Permission"
	}).then(res => {
		if (res.success && res.recs.length > 0) {
			isDTNAdmin = true;
		}

		if (!(loginState.isSVUser || isDTNAdmin)) {
			update(toSet);
		}
	});
};

export default setDTNEnabled;
