import React, { useEffect, useState, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";

const CircularProgressCentered = props => {
	const [spinningState, setSpinning] = useState(true);
	const { classes, timeout = 15000, size, spinning = true, color, useTimeout = true, onTimeout, forwardedRef } = props;

	const handleTimeout = useCallback(
		() => {
			onTimeout && onTimeout();
			setSpinning(false);
		},
		[onTimeout],
	);

	useEffect(() => {
		if (useTimeout) {
			const timeoutRef = setTimeout(handleTimeout, timeout);
			return function cleanup() {
				// clear timeout on destroy
				clearTimeout(timeoutRef);
			};
		}
	}, []);

	const shouldSpin = spinningState && spinning;

	return shouldSpin ? (
		<div className={classes && classes.circularProgressCentered}>
			<CircularProgress ref={forwardedRef} size={size} color={color} />
		</div>
	): null;
};


export default CircularProgressCentered;
