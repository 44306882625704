const accountContacts = crm => async({ fieldName, subscriber: contactField, dispatchWrapper, getState }) => {
	const { data, formType, refId } = getState();
	let AccountID;
	if (formType === "listing.eventCalendar" && refId) {
		const res = await crm.get({
			type: "listing",
			filter: {
				recId: refId,
			},
			fields: `
				ListingID
				AccountID
			` 
		});
	
		if (res.success && res.count) {
			AccountID = res.recs[0].AccountID;
		}
	}
	if (data[fieldName] || formType === "listing.eventCalendar" && AccountID) {
		crm.get({
			type: "account",
			filter: {
				recId: formType === "listing.eventCalendar" ? AccountID : data[fieldName].value
			},
			fields: `
				ContactRelation {
					recs {
						ContactID
						FirstName
						LastName
						IsActive
					}
				}
			`
		}).then(res => {
			if (res.success && res.count) {
				const contacts = res.recs[0].ContactRelation.recs.filter(c => c.IsActive === true);
				if (contacts.length) {
					const contactData = (Array.isArray(data[contactField]) && data[contactField].length) ? data[contactField][0] : data[contactField];
					dispatchWrapper("updateField", [contactField, { options: contacts.map(contact => ({ label: `${contact.FirstName} ${contact.LastName}`, value: contact.ContactID })) }]);

					if (contactData) {
						const contactValueIndex = contacts.findIndex(contact => contact.ContactID === contactData.value);
						if ( contactValueIndex === -1) {
							dispatchWrapper("handleFormChange", [contactField, "Int", []]);
						} else {
							dispatchWrapper("handleFormChange", [
								contactField,
								"Int",
								{ label: `${contacts[contactValueIndex].FirstName} ${contacts[contactValueIndex].LastName}`, value: contacts[contactValueIndex].ContactID }
							]);
						}
					}
				} else {
					dispatchWrapper("updateField", [contactField, { options: [] }]);
					dispatchWrapper("handleFormChange", [contactField, "Int", []]);
				}
			} else if (!data[contactField]) {
				dispatchWrapper("updateField", [contactField, { options: [] }]);
				dispatchWrapper("handleFormChange", [contactField, "Int", []]);
			}
		});
	}
};
export default accountContacts;
