import debounce from "lodash/debounce";

const listingGeocode = crm => debounce(({ subscriber, dispatchWrapper, getState, staticArgs, eventName }) => {
	const {
		data: {
			AutoGeocode,
			ListingAddress_v2,
			AccountAddress_v2
		},
		data
	} = getState();
	dispatchWrapper("enableSubmit", [false]);

	const listingAddress = ListingAddress_v2.address[0];
	const accountAddress = AccountAddress_v2.address[0];
	//Don't change the Lat/Long if not explicity saying or Auto is set to Yes
	const geoCode = eventName === "onClick" || AutoGeocode;
	const addressLine1 = listingAddress.AddressLine1 || accountAddress.AddressLine1;
	const postalCode = listingAddress.PostalCode || accountAddress.PostalCode;
	const city = listingAddress.City || accountAddress.City;
	const stateCode = (listingAddress.StateCode && listingAddress.StateCode.value) || (accountAddress.StateCode && accountAddress.StateCode.value);
	const countryCode = (listingAddress.CountryCode && listingAddress.CountryCode.value) || (accountAddress.CountryCode && accountAddress.CountryCode.value);

	if (geoCode && listingAddress && listingAddress.AddressLine1 && addressLine1 && countryCode && stateCode && postalCode) {
		crm.get({
			type: "geolocation_byaddress",
			filter: {
				AddressLine1: addressLine1,
				AddressLine2: listingAddress.AddressLine2,
				AddressLine3: listingAddress.AddressLine3,
				City: city,
				StateCode: stateCode,
				PostalCode: postalCode,
				CountryCode: countryCode
			},
			fields: "Latitude Longitude"
		}).then(res => {
			const [location] = res.recs;
			const { Latitude = 0, Longitude = 0 } = location;
			dispatchWrapper("handleFormChange", [subscriber, "String", `${Latitude},${Longitude}`]);
			dispatchWrapper("enableSubmit", [true]);
		});
	} else {
		const latitude = geoCode ? data.AccountLatitude : data.Latitude || data.AccountLatitude;
		const longitude = geoCode ? data.AccountLongitude : data.Longitude || data.AccountLongitude;

		if(geoCode && latitude && longitude) {
			dispatchWrapper("handleFormChange", [subscriber, "String", `${latitude},${longitude}`]);
		}
		dispatchWrapper("enableSubmit", [true]);
	}
}, 1000);

export default listingGeocode;
