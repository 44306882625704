import { extractInfo, getValue } from "../form";

/**
 * dynamicStringJoin
 * 
 * args
 * 0 - seperator
 * 1-N - fieldNames
 */
const dynamicStringJoin = crm => info => {
	const { updateValue, staticArgs, data, subCompIndex } = extractInfo(info);
	const [seperator = " ", ...fields] = staticArgs;
	const values = fields.map(field => {
		let rtn = getValue({ data, 
			fieldName: field, 
			compIndex: subCompIndex
		});
		
		if (rtn instanceof Object && rtn.label) {
			rtn = rtn.label;
		}  

		return rtn;
	}).filter(value => !!value);
	
	updateValue(values.join(seperator));
};

export default dynamicStringJoin;
