import axios from "axios";
import React from "react";
import { Creators } from "../actions/transientElementActions";
import { GLOBAL_SNACKBAR } from "./consts";

const REQUEST_TIMEOUT = 35000;

export const factory = (webForm = false) => {
	const axiosInstance = axios.create({
		timeout: REQUEST_TIMEOUT
	});
	
	axiosInstance.interceptors.response.use(res => {
		// required to prevent circular import
		const store = require("../store").default;
		const { wrapValue } = require("./format");
		const { dispatch } = store;
	
		// if error return here
		if (res.data.errors) {
			if (res.data.errors.length && res.data.errors[0].message) {
				for (const key in res.data.data.crm) {
					res.data.data.crm[key] = {
						success: false,
						message: res.data.errors[0].message
					};
				}
			}
	
			return res;
		}
	
		let responseData = res.data.data.crm;
		const key = Object.keys(responseData)[0];
		responseData = { ...responseData[key] };

		if(responseData.snackbarMessage && responseData.snackbarLinkType && responseData.snackbarLinkType !== "none") {
			const embeddedValue = responseData["RecordID"];
			const module = responseData.snackbarLinkType.indexOf("/") > -1 ? responseData.snackbarLinkType.split("/")[1] : responseData.snackbarLinkType;
			if (!webForm && embeddedValue) {
				dispatch(Creators.setDrawerOpen(GLOBAL_SNACKBAR, {
					isOpen: true,
					message: <>
						{wrapValue(embeddedValue, "moduleLink", { module: module, data: embeddedValue })}
						{` ${responseData.snackbarMessage}`}
					</>,
					messageType: responseData.snackbarDisplayType || "success",
					autoHideDuration: 10000
				}));
			} else {
				alert(responseData.snackbarMessage);
			}
		} else if (responseData.snackbarMessage) {
			if (webForm) {
				alert(responseData.snackbarMessage);
			} else {
				dispatch(Creators.setMessage(responseData.snackbarMessage, responseData.snackbarDisplayType || "success"));
			}
		}

		return res;
	});
	return axiosInstance;
};

const ax = factory();

export default ax;
