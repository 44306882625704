const accountFromRef = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { refId } = getState();

	if (refId) {
		crm.get({
			type: "account",
			filter: {
				recId: refId
			},
			fields: `
				CompanyName
			`
		}).then(response => {
			const { recs: accounts } = response;
			if (accounts.length) {
				const options = [{ value: refId, label: accounts[0].CompanyName }];
				dispatchWrapper("updateField", [subscriber, { options }]);
				dispatchWrapper("handleFormChange", [subscriber, "Int", options[0]]);
			}			
		});
	}
};

export default accountFromRef;
