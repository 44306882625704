import checkCommunicationStatus from "./checkCommunicationStatus";
import checkRowActions from "./checkRowActions";
import checkPending from "./checkPending";

const rowActionShowMap = {
	checkCommunicationStatus,
	checkRowActions,
	checkPending
};

export default { ...rowActionShowMap };
