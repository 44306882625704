import isEmpty from "lodash/isEmpty";
import { ERROR_TYPES } from "../consts";
import getFieldInfo from "../fieldInfo";
import isRequiredHOC from "./isRequiredHOC";
const { IS_REQUIRED } = ERROR_TYPES;

const isRequired = ({ fieldName, compField, compIndex, dispatchWrapper, getState, staticArgs, subscriber }) => {
	const hocComponents = ["Email_v2", "Phone_v2", "Address_v2", "ContactAddress_v2"];
	const { data, fields } = getState();
	const fieldParts = [fieldName, compField, compIndex];
	const field = fields[fieldName];

	if (hocComponents.includes(fieldName)) {
		const requiredFieldsMapper = {
			Email_v2: ["EmailTypeID", "EmailAddress"],
			Phone_v2: ["PhoneTypeID", "PhoneNumber"],
			Address_v2: ["AddressTypeID", "AddressLine1", "PostalCode"],
			ContactAddress_v2: ["AddressTypeID", "AddressLine1", "PostalCode"]
		};
		let requiredFields = field?.requiredFields || requiredFieldsMapper[fieldName] || [];
		
		try {
			requiredFields = JSON.parse(requiredFields);
		} catch(e) {
			//nothing
		}

		isRequiredHOC({ fieldName, dispatchWrapper, getState, requiredFields });
		return;
	}

	if (compField && data[compField] && data[compField].compositeFields && compIndex >= data[compField].compositeFields.length) {
		dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);
		return;
	}

	const fieldCheck = getFieldInfo({ subscriber, compField, compIndex, data });
	const val = compField && Number.isInteger(compIndex) ? data[compField].compositeFields[compIndex][fieldName] : data[fieldName];
	const doubleCheckDate = fields[fieldName]?.type && ["Date"].includes(fields[fieldName].inputType);
	const bitField = ["Boolean"].includes(fields[fieldName]?.dataType);


	if (staticArgs && staticArgs.length) {
		let hasValue = false;
		staticArgs.forEach(field => {
			if (!isEmpty(data[field])) {
				hasValue = true;
			} else if (data[compField] && !isEmpty(data[compField].compositeFields[compIndex][field])) {
				hasValue = true;
			}
		});

		if (hasValue && !fieldCheck.value.toString().length) {
			dispatchWrapper("setValidation", [fieldCheck.name, compField, compIndex, IS_REQUIRED, "This field is required when one or more related fields are populated."]);
		} else {
			dispatchWrapper("clearValidation", [fieldCheck.name, compField, compIndex, IS_REQUIRED]);
		}
	} else {
		if ((val === undefined || val === null) || val !== undefined && val !== null && val.length === 0 && !bitField) {
			dispatchWrapper("setValidation", [...fieldParts, IS_REQUIRED, "field is required"]);
		} else if (bitField && !val && field?.type === "Checkbox") {
			dispatchWrapper("setValidation", [...fieldParts, IS_REQUIRED, "field is required"]);
		} else if (bitField && ["RadioYN", "RadioYNNA"].includes(field?.type) && ![true, false, "true", "false", "Yes", "No", "N/A"].includes(val)) {
			dispatchWrapper("setValidation", [...fieldParts, IS_REQUIRED, "field is required"]);			
		} else if (!doubleCheckDate || val !== "Invalid date") {
			dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);
		}
	}
};

export default isRequired;
