import { extractInfo } from "../form";

const dynamicStringSplit = crm => info => {
	const { updateValue, subValue, staticArgs = [] } = extractInfo(info);
	const [delimiter = " ", index] = staticArgs;
	if (!subValue) {
		return;
	}
	if (index === undefined) {
		updateValue(subValue.split(delimiter));
	} else {
		updateValue(subValue.split(delimiter)[index]);
	}
};

export default dynamicStringSplit;
