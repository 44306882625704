import React from "react";
import { connect } from "react-redux";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@react-ui/components/CircularProgressCentered";
import { Creators } from "../../actions/transientElementActions";
import { GLOBAL_TABBED_DRAWER } from "../../common/consts";


const CircularProgressCentered = ({ setDrawerOpen, setMessage, message, messageType, useTimeout, onTimeout = () => {
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	setMessage(message ? message : "An action is taking longer than expected, please try again", messageType ? messageType : "error");
}, ...props }) => {
	return <CircularProgress {...props} onTimeout={onTimeout} useTimeout={useTimeout} />;
};


const mapStateToProps = state => ({

});

const mapDispatchToProps = {
	setMessage: Creators.setMessage,
	setDrawerOpen: Creators.setDrawerOpen
};

export default withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(CircularProgressCentered));
