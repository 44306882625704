import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Drawer, AppBar, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Creators } from "../../actions/transientElementActions";
import CircularProgressCentered from "../CircularProgressCentered";
import styles from "./styles";
import {
	GLOBAL_TABBED_DRAWER,
	GLOBAL_DIALOG,
	GRID_OPTIONS_DRAWER_NAME,
	ALERT_DRAWER_NAME,
	RECENTLY_VIEWED_DRAWER_NAME
} from "../../common/consts";

const showTabTypes = [GRID_OPTIONS_DRAWER_NAME, ALERT_DRAWER_NAME, RECENTLY_VIEWED_DRAWER_NAME];

const ConnectedTabbedDrawer = props => {

	const setDrawerOpen = (isOpen, noDialogue = false) => () => {
		if (props.tabs.length > 1 || props.type === "recentlyViewed" || noDialogue || props.displayComponent.props.viewOnly) {
			props.setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen });
			props.setCurrentTab(GLOBAL_TABBED_DRAWER, props.tabs[0].name);
		} else {
			const actions = [
				{
					label: "Cancel"
				},
				{
					label: "OK",
					color: "primary",
					handler: () => {
						props.setDrawerOpen(GLOBAL_DIALOG, { isOpen: false });
						props.setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen });
						props.setCurrentTab(GLOBAL_TABBED_DRAWER, props.tabs[0].name);
					}
				}
			];
			props.setDrawerOpen(GLOBAL_DIALOG, {
				isOpen: true,
				title: "Do you want to exit the form? Your information will not be saved.",
				content: "Click OK to confirm. Click Cancel to go back.",
				actions
			});
		}
	};

	const setTab = () => (event, tab) => {
		if (tab !== "close" && props.tabs && props.tabs.length > 1) {
			props.setCurrentTab(GLOBAL_TABBED_DRAWER, tab);
		}
	};
	const {
		tabContentFetching, tabs, activeTab, isOpen, displaySize, displayComponent, type
	} = props;

	const drawerProps = { tabContentFetching, tabs, activeTab, isOpen, displaySize, displayComponent, type, setDrawerOpen, setTab };

	return <TabbedDrawer { ...drawerProps } />;
};

export const TabbedDrawer = withStyles(styles)(({
	classes,
	tabContentFetching,
	tabs,
	activeTab,
	isOpen,
	displaySize,
	displayComponent,
	type,
	setDrawerOpen,
	setTab,
	extraProps
}) => {
	return (
		<React.Fragment>
			<div>
				<Drawer
					disableEnforceFocus
					variant="temporary"
					anchor="right"
					open={isOpen}
					onClose={setDrawerOpen(false)}
					classes={{
						root: classes.drawer,
						paper: classNames(classes.drawerPaper, displaySize),
						modal: "CyTabbedDrawerBackDrop"
					}}
					className={"CyTabbedDrawer"}
				>
					{tabContentFetching ? (
						<CircularProgressCentered size={75} color="primary" />
					) : (
						<React.Fragment>
							{ showTabTypes.includes(type) && <AppBar className={classNames(classes.drawerAppBar, tabs && tabs.length === 1 && classes.drawerAppBarGrey)} position="static">
								<Tabs
									className="drawerTabs"
									value={activeTab || ""}
									onChange={setTab(type)}
									classes={{
										indicator: classes.hide
									}}
								>
									{tabs &&
									tabs.map(tab => (
										<Tab
											value={tab.name || ""}
											label={tab.name || ""}
											key={`${tab.name}TabForFormDrawer`}
											className={classNames(classes.tab, "CyTabbedDrawerTab")}
											classes={{
												root: classes.root
											}}
										/>
									))}
									<Tab value="close" className={classNames("drawerClose", "CyTabbedDrawerTab")} label="x" onClick={setDrawerOpen(false, true)} />
								</Tabs>
							</AppBar>
							}
							<div className="drawerData">
								{
									isOpen && ( displayComponent instanceof Function ? displayComponent(extraProps) : displayComponent )
								}
							</div>
						</React.Fragment>
					)}
				</Drawer>
			</div>
		</React.Fragment>
	);
});


const mapStateToProps = state => ({
	...state.transientElements.drawerOpen[GLOBAL_TABBED_DRAWER],
	tabContentFetching: state.header.tabContentFetching
});

const mapDispatchToProps = {
	setDrawerOpen: Creators.setDrawerOpen,
	setCurrentTab: Creators.setDrawerCurrentTab
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedTabbedDrawer);
