const styles = theme => ({
	drawerPaper: {
		marginTop: theme.constants.header.height,
		height: `calc(100% - ${theme.constants.header.height}px)`,
		width: "75%",
		transition: "width .2s ease-in-out",
		"&.fullscreen": {
			height: "100%",
			width: "100%"
		},
		"&.sm": {
			width: "30%",
			minWidth: 300,
			"& .drawerData": {
				margin: 0
			}
		},
		"&.md": {
			width: "50%",
			minWidth: 500,
			"& .drawerData": {
				margin: 0
			}
		},
		"&.lg": {
			width: "70%",
			minWidth: 700
		},
		"&.extralg": {
			width: "calc(100% - 250px)",
			minWidth: 700
		}
	},
	drawerAppBar: {
		boxShadow: "none",
		backgroundColor: theme.colors.shadow
	},
	drawerAppBarGrey: {
		backgroundColor: theme.colors.gandolf,
		"& button": {
			textAlign: "left",
			padding: "0 10px !important",
			maxWidth: 370
		},
		"& button.drawerClose": {
			backgroundColor: theme.colors.gandolf,
			textAlign: "center",
			padding: "0 10px !important"
		}
	},
	root: {
		padding: "0px !important",
		margin: "0px !important"
	},
	tab: {
		color: "#757575",
		paddingLeft: "12px !important",
		paddingRight: "12px !important"
	},
	hide: {
		display: "none"
	},
	
});

export default styles;
