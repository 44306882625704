const addRelatedAccount = crm => info => {
	const { subscriber, getState, dispatchWrapper } = info;
	const data = getState().data;
	const relatedAccount = data.AddRelatedAccount;

	const update = (IsPrimary, accounts, accountIndex) => {
		if (IsPrimary) {
			accounts.forEach((acc, index) => {
				if (index !== accountIndex) {
					acc.IsPrimary = false;
				}
			});
		}

		if (accounts.length && !accounts.some(acc => acc.IsPrimary)) {
			accounts[0].IsPrimary = true;
		}

		dispatchWrapper("handleFormChange", [subscriber, "Object", accounts]);
		dispatchWrapper("updateField", [subscriber]);
	};

	if (relatedAccount && relatedAccount.AccountID) {
		const accounts = data.Accounts || [];
		let accountIndex = accounts.findIndex(account => account.AccountID === relatedAccount.AccountID.value);
		
		const ContactRoles = [];
		const ContactRoleID = [];

		relatedAccount.ContactRoleID.forEach(cr => {
			ContactRoles.push(cr.label);
			ContactRoleID.push(cr.value);
		});

		const newAccount = {
			recId: relatedAccount.AccountID.value,
			AccountID: relatedAccount.AccountID.value,
			CompanyName: relatedAccount.AccountID.label,
			ContactRoleID,
			ContactRoles,
			RelIsActive: relatedAccount.IsActive,
			IsPrimary: relatedAccount.IsPrimary 
		};

		if (accountIndex > -1) {
			const onConfirm = value => {
				if (value) {
					accounts[accountIndex] = newAccount;
					update(relatedAccount.IsPrimary, accounts, accountIndex);
				}
			};

			dispatchWrapper("setDialog", [true, "", "This relationship already exists, would you like to update it?", onConfirm]);			
		} else {
			accounts.push(newAccount);
			accountIndex = accounts.length - 1;
			
			update(relatedAccount.IsPrimary, accounts, accountIndex);
		}
	}
};

export default addRelatedAccount;
