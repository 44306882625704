const eventCallback = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { refId, formType, recId } = getState();
	if (recId !== 0 || !formType.includes("eventCalendar")) {
		return;
	}

	crm.get({
		type: "eventCalendar",
		filter: {
			recId: refId
		},
		fields: `
			Title
			EventCalendarID
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: events } = res;
			const options = events.map(rec => {
				return { label: rec.Title, value: rec.EventCalendarID };
			});

			dispatchWrapper("updateField", [subscriber, { options }]);
			dispatchWrapper("handleFormChange", [subscriber, "Chip", options]);
		}
	});
};

export default eventCallback;
