const setFieldType = crm => ({ fieldName, subscriber, dispatchWrapper, getState }) => {
	const { data } = getState();
	const fieldTypeID = data[fieldName];

	const fieldTypeMap = {
		"1": { fieldType: "TextField", dataType: "String" },
		"2": { fieldType: "TextArea", dataType: "String" },
		"3": { fieldType: "HTML Editor", dataType: "String" },
		"4": { fieldType: "Number", dataType: "Int" },
		"5": { fieldType: "Decimal", dataType: "float" },
		"6": { fieldType: "Currency", dataType: "float" },
		"7": { fieldType: "Date", dataType: "Date" },
		"8": { fieldType: "Time", dataType: "time" },
		"9": { fieldType: "DateTime", dataType: "Date" },
		"10": { fieldType: "Select", dataType: "Int" },
		"11": { fieldType: "MultiSelect", dataType: "Int" },
		"12": { fieldType: "Phone", dataType: "String" },
		"13": { fieldType: "Email", dataType: "String" },
		"15": { fieldType: "Switch", dataType: "Boolean" },
		"16": { fieldType: "Checkbox", dataType: "String" },
		"17": { fieldType: "ImageUpload", dataType: "file" },
		"18": { fieldType: "FileUpload", dataType: "file" },
		"19": { fieldType: "Radio", dataType: "String" },
		"20": { fieldType: "RadioYN", dataType: "Boolean" }
	};

	if (fieldTypeMap[fieldTypeID]) {
		const { fieldType, dataType } = fieldTypeMap[fieldTypeID];
		dispatchWrapper("updateField", [subscriber, { type: fieldType, dataType }]);
	}
};

export default setFieldType;
