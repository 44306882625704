import { extractInfo } from "../form";

const fetchConnectionOptions = crm => async info => {
	const { updateField, updateValue, recId } = extractInfo(info);
	const filter = {};

	updateField({ hidden: false, readOnly: true });
	
	if(recId) {
		filter["recId"] = recId;
	}

	await crm.get({
		type: "available_connections",
		fields: `recId InUse DisplayNm`,
		filter
	}).then(res => {
		if (res.success) {
			const options = res.recs.map(rec => {
				return {
					label: rec["DisplayNm"],
					value: rec["recId"]
				};
			});
			
			if (recId) {
				const dataIndex = options.findIndex(option => option.value === recId);
				if (dataIndex > -1) {
					updateValue(options[dataIndex]);
				}
			} else {
				updateField({ options, readOnly: false });
			}			
			
		}
	});
	
};

export default fetchConnectionOptions;
