const fillAddress = ({ compField, compIndex, dispatchWrapper, getState, value }) => {
	const { fields } = getState();

	const fieldMap = {
		street_number: {
			name: "AddressLine1",
			comp_type: "short"
		},
		route: {
			name: "AddressLine1",
			comp_type: "long"
		},
		locality: {
			name: "City",
			comp_type: "long"
		},
		postal_town: {
			name: "City",
			comp_type: "long"
		},
		administrative_area_level_1: {
			name: "StateCode",
			comp_type: "dropdown"
		},
		country: {
			name: "CountryCode",
			comp_type: "dropdown"
		},
		postal_code: {
			name: "PostalCode",
			comp_type: "short"
		}
	};

	//If there is a locality use that over postal_town
	if (value.locality) {
		delete value.postal_town;
	}

	//Clear fields
	for (const clearField in fieldMap) {
		if (compField) {
			dispatchWrapper("handleFormChange", [fieldMap[clearField].name, "String", null, compIndex, compField]);
		} else {
			dispatchWrapper("handleFormChange", [fieldMap[clearField].name, "String", null]);
		}
	}

	//Build address object
	const addressValues = {};

	for (const addressPart in value) {
		const fieldToUpdate = fieldMap[addressPart].name;
		const fieldType = fieldMap[addressPart].comp_type;
		if (fieldToUpdate && !addressValues[fieldToUpdate]) {
			if (fieldType === "dropdown") {
				addressValues[fieldToUpdate] = {
					label: value[addressPart].long,
					value: value[addressPart].short
				};
			} else {
				addressValues[fieldToUpdate] = value[addressPart][fieldType];
			}
		} else if (fieldToUpdate) {
			addressValues[fieldToUpdate] += " " + value[addressPart][fieldType];
		}
	}

	if (compField && fields[compField]) {
		for (const field in addressValues) {
			dispatchWrapper("handleFormChange", [field, "String", addressValues[field], compIndex, compField]);
		}
	} else {
		for (const field in addressValues) {
			dispatchWrapper("handleFormChange", [field, "String", addressValues[field]]);
		}
	}
};

export default fillAddress;
