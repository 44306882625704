import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/loginActions";
import { AuthPrefix } from "@simpleview/sv-auth-client";
import { DamClient } from "@simpleview/dam-client";
import ax from "../common/axiosSetup";
import { GraphServer, GeneralPrefix, AdminPrefix, FormPrefix, ListingPrefix, GridPrefix } from "@simpleview/crm-client";
import moment from "moment";

const GRAPHQL_URL = "/graphql/";
const AUTH_GRAPHQL_URL = "/auth-graphql/";

const INITIAL_STATE = {
	acctId: null,
	token: null,
	auth: null,
	subdomain: null,
	loginErr: null,
	loginExpiration: null,
	clientNotFound: false,
	crmGraphServer: new GraphServer({
		graphUrl: GRAPHQL_URL,
		prefixes: [AdminPrefix, GeneralPrefix, FormPrefix, ListingPrefix, GridPrefix],
		context: {
			acct_id: null,
			token: null
		},
		ax
	}),
	authGraphServer: new GraphServer({
		graphUrl: AUTH_GRAPHQL_URL,
		prefixes: [AuthPrefix],
		context: {
			acct_id: null,
			token: null
		}
	}).auth,
	damClient: new DamClient({
		graphUrl: "https://graphql.simpleviewinc.com",
		assetsUrl: "https://assets.dam.simpleviewinc.com/"
	}),
	productName: null
};

const reducer = {};

reducer.loginSuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		productName: action.productName,
		acctId: action.acctId,
		acctLabel: action.acctLabel,
		token: action.token,
		auth: action.auth,
		subdomain: action.subdomain,
		firstName: action.firstName,
		lastName: action.lastName,
		email: action.email,
		userId: action.userId,
		isSVUser: action.isSVUser,
		crmGraphServer: new GraphServer({
			graphUrl: GRAPHQL_URL,
			prefixes: [AdminPrefix, GeneralPrefix, FormPrefix, ListingPrefix, GridPrefix],
			context: {
				acct_id: action.acctId,
				token: action.token,
				productName: action.productName
			},
			ax
		}),
		authGraphServer: new GraphServer({
			graphUrl: AUTH_GRAPHQL_URL,
			prefixes: [AuthPrefix],
			context: {
				acct_id: action.acctId,
				token: action.token
			}
		}).auth,
		damClient: new DamClient({
			graphUrl: "https://graphql.simpleviewinc.com",
			assetsUrl: "https://assets.dam.simpleviewinc.com/"
		})
	};
};

reducer.loginErr = (state = INITIAL_STATE, action) => ({
	...state,
	loginErr: action.err,
	client: action.client
});

reducer.loginExpiration = (state = INITIAL_STATE, action) => ({
	...state,
	loginExpiration: action.loginExpiration
});

reducer.clientNotFound = (state = INITIAL_STATE) => ({
	...state,
	clientNotFound: true
});

reducer.handleLogout = (state = INITIAL_STATE) => ({
	...state,
	token: null
});

export default createRSReducer(Types, INITIAL_STATE, reducer);
