import moment from "moment-timezone";
import stripHtml from "./stripHtml";
import capitalize from "lodash/capitalize";

let config = {
	Locale: "en-us",
	TimeZone: null,
	ClientTimeZone: null,
	Language: "English" // not used for now
};

export const setConfig = setting => {
	config = {
		...config,
		...setting
	};
};

export const updateConfig = (name, value) => {
	if (name in config) {
		config[name] = value;
	}
};

export const setLocale = locale => {
	config.Locale = locale;
};

export const setTimezone = timezone => {
	config.TimeZone = timezone;
};

export const setClientTimezone = timezone => {
	config.ClientTimeZone = timezone;
};

export const setLanguage = lang => {
	config.Language = lang;
};

// this is mainly for testing
export const getConfig = () => {
	return config;
};

export const FORMAT = {
	DATE: "date",
	DAY: "day",
	MONTH: "month",
	TIME: "time",
	DT: "datetime",
	DATETIME: "datetime",
	EMAIL: "email",
	TIMESTAMP: "timestamp",
	SHORT_DATE: "shortDate",
	MOMENT_FROM_NOW: "moment_from_now",
	MOMENT_CALENDAR: "moment_calendar",
	UPPER: "upper",
	LOWER: "lower",
	CAPITALIZE: "capitalize",
	BOOL_YES_NO: "boolean_yes_no",
	HTML: "html",
	TIMEUTC: "timeutc",
	DATEUTC: "dateutc",
	CLIENTDATE: "clientdate",
	CLIENTDATETIME: "clientdatetime",
	DATETIMEUTC: "datetimeutc",
	PHONE: "phone",
	HTML_EDITOR: "html editor",
	SHORTDESC: "shortdesc",
	SHORT_HTML_EDITOR: "shorthtmleditor",
	ADDRESS: "address",
	HTML_LINE_BREAKS: "htmllinebreaks"
};

export const formatPhone = (phone) => {
	if (!phone || typeof phone !== "string") {
		return "";
	}

	let updatedValue = phone.replace(/[()\s-]|[^a-zA-Z0-9]/g, "");
	const list = updatedValue.split("");
	const length = list.length;

	if (length > 3 && length <= 7) {
		list.splice(3, 0, "-");
		updatedValue = list.join("");
	} else if (length > 7 && length < 11) {
		list.unshift("(");
		list.splice(4, 0, ") ");
		list.splice(8, 0, "-");
		updatedValue = list.join("");
	}

	return updatedValue;
};

export const formatAddress = (addressLine1, addressLine2, addressLine3, city, state, postalCode) => {
	const addressLines = [addressLine1, addressLine2, addressLine3].filter(line => line).join("\n");
	
	return `${addressLines}
${city}, ${state} ${postalCode}`;
};

export const formatFileSize = (bytes, decimalPoint) => {
	if (bytes == 0) {
		return "0 Bytes";
	}
	const k = 1000;
	const dm = decimalPoint || 2;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// This only works on US phone number
export const formatUSPhone = value => {
	if (value) {
		let numericValue = value.replace(/[^\d]/g, "");

		if (numericValue.length === 11 && parseInt(numericValue.substring(0, 1), 10) === 1) {
			// Remove the US 1 code from the front of phone numbers.
			numericValue = numericValue.substring(1);
		}

		if (numericValue.length === 10) {
			return numericValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
		} else if (numericValue.length === 7) {
			return numericValue.replace(/(\d{3})(\d{4})/, "$1-$2");
		}
	}
	return value;
};

export const getMomentFromValue = value => {
	if (!value) {
		return { format: () => null };
	}
	const { Locale, TimeZone } = config;
	Locale && moment.locale(Locale);
	// use js Date constructor for now, probably need to switch to moment formatter later.
	const result = moment(new Date(value));
	let date = TimeZone ? result.tz(TimeZone) : result;

	const priorDay = moment(date).add(-1, "days");
	const nextDate = moment(date).add(1, "days");
	const daylightSavingsDate = TimeZone ? moment(new Date(value)).tz(TimeZone).add(1, "days") : moment(new Date(value)).add(1, "days");
	const isDayLightStartDate = !date.isDST() && daylightSavingsDate.isDST() && daylightSavingsDate.format("Hms") === "2300";
	
	if (!priorDay.isDST() && date.isDST() && nextDate.isDST() && date.format("Hms") !== "000") {
		date = date.add(-1, "days");
	}

	return isDayLightStartDate ? daylightSavingsDate : date;
};

export const formatDate = value => value ? getMomentFromValue(value).format("ll") : "";

export const formatDateUTC = value => value ? getMomentFromValue(value).utc().format("ll") : "";

export const formatTime = value => value ? getMomentFromValue(value).format("LT") : "";

export const formatTimeUTC = value => value ? getMomentFromValue(value).utc().format("LT") : "";

export const formatDatetime = value => value ? getMomentFromValue(value).format("lll") : "";

export const formatDatetimeUTC = value => value ? getMomentFromValue(value).utc().format("lll") : "";

export const formatShortDate = value => value ? getMomentFromValue(value).format("MMMM Do") : "";

export const formatShortDesc = value => {
	if (!value) {
		return "";
	}

	return value.length > 255 ? `${value.substring(0, 255).trim()}...` : value;
};

export const formatClientDate = value => {
	if (!value) {
		return "";
	}
	const { Locale, ClientTimeZone } = config;
	Locale && moment.locale(Locale);
	const result = getMomentFromValue(value);
	return ClientTimeZone ? result.tz(ClientTimeZone).format("ll") : result.format("ll");
};

export const formatClientDatetime = value => {
	if (!value) {
		return "";
	}
	const { Locale, ClientTimeZone } = config;
	Locale && moment.locale(Locale);
	const result = getMomentFromValue(value);
	return ClientTimeZone ? result.tz(ClientTimeZone).format("lll") : result.format("lll");
};

export const formatDatetimeCustom = value => {
	if (!value) {
		return "";
	}
	const { Locale, TimeZone } = config;
	Locale && moment.locale(Locale);
	const result = moment(value);
	return TimeZone ? result.tz(TimeZone).format("lll") : result.format("lll");
};

/**
 * @param {*} value
 * @returns unix timestamp of the moment
 */
export const formatTimestamp = value => {
	return getMomentFromValue(value).format("X");
};

export const formatMomentFromNow = value => {
	return getMomentFromValue(value).fromNow();
};

export const formatMomentCalendar = value => {
	return getMomentFromValue(value).calendar();
};

export const formatToLowerCase = value => {
	return value ? value.toLowerCase() : value;
};

export const formatToUpperCase = value => {
	return value ? value.toUpperCase() : value;
};

export const formatBooleanYesOrNo = value => {
	return value ? "Yes" : "No";
};

export const formatMonth = value => {
	if (value < 1 || value > 12) {
		return value;
	} else {
		return getMomentFromValue(`2000-${value}-20`).format("MMMM");
	}
};

export const formatDay = value => {
	if (value < 1 || value > 31) {
		return value;
	} else {
		return getMomentFromValue(`2000-12-${value}`).format("Do");
	}
};

export const formatHtml = value => {
	return stripHtml(value);
};

export const formatHtmlWithLineBreaks = value => {
	return stripHtml(value, { allowTags: ["br"]});
};

export const formatHtmlEditor = value => {
	return stripHtml(value);
};

export const formatShortHtmlEditor = value => {
	return formatShortDesc(stripHtml(value));
};

export const formatCapitalize = value => {
	return capitalize(value);
};

export const formatValue = (value, format) => {
	if (!format) {
		return value;
	}
	const { PHONE, DATE, CLIENTDATE, TIME, DATETIME, CLIENTDATETIME, MOMENT_CALENDAR, MOMENT_FROM_NOW, TIMESTAMP, EMAIL, UPPER, SHORT_DATE, BOOL_YES_NO, HTML, MONTH, DAY, TIMEUTC, DATEUTC, DATETIMEUTC, CAPITALIZE, HTML_EDITOR, SHORTDESC, SHORT_HTML_EDITOR, ADDRESS, HTML_LINE_BREAKS } = 
		FORMAT;

	const formatters = {
		[DAY]: formatDay,
		[MONTH]: formatMonth,
		[DATE]: formatDate,
		[CLIENTDATE]: formatClientDate,
		[TIME]: formatTime,
		[DATETIME]: formatDatetime,
		[CLIENTDATETIME]: formatClientDatetime,
		[TIMESTAMP]: formatTimestamp,
		[MOMENT_CALENDAR]: formatMomentCalendar,
		[MOMENT_FROM_NOW]: formatMomentFromNow,
		[EMAIL]: formatToLowerCase,
		[UPPER]: formatToUpperCase,
		[SHORT_DATE]: formatShortDate,
		[BOOL_YES_NO]: formatBooleanYesOrNo,
		[HTML]: formatHtml,
		[HTML_EDITOR]: formatHtmlEditor,
		[TIMEUTC]: formatTimeUTC,
		[DATEUTC]: formatDateUTC,
		[DATETIMEUTC]: formatDatetimeUTC,
		[CAPITALIZE]: formatCapitalize,
		[PHONE]: formatUSPhone,
		[SHORTDESC]: formatShortDesc,
		[SHORT_HTML_EDITOR]: formatShortHtmlEditor,
		[ADDRESS]: formatAddress,
		[HTML_LINE_BREAKS]: formatHtmlWithLineBreaks
	};

	if (format in formatters) {
		return formatters[format](value);
	} else {
		return value;
	}
};


