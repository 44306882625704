import React, { Suspense } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";

const MapField = React.lazy(() => import("./MapField"));
const CountrySelector = React.lazy(() => import("./CountrySelector"));
const CurrencyField = React.lazy(() => import("./CurrencyField"));
const DecimalField = React.lazy(() => import("./DecimalField"));
const RecurrenceField = React.lazy(() => import("./RecurrenceField"));
const TextField = React.lazy(() => import("./TextField"));
const SelectField = React.lazy(() => import("./SelectField"));
const PhoneField = React.lazy(() => import("./PhoneField"));
const NumberField = React.lazy(() => import("./NumberField"));
const DateTimeField = React.lazy(() => import("./DateTimeField"));
const DateField = React.lazy(() => import("./DateField"));
const TimeField = React.lazy(() => import("./TimeField"));
const URLField = React.lazy(() => import("./URLField"));
const HtmlField = React.lazy(() => import("./HtmlField"));
const Checkbox = React.lazy(() => import("./Checkbox"));
const Switch = React.lazy(() => import("./Switch"));
const Radio = React.lazy(() => import("./Radio"));
const RadioYN = React.lazy(() => import("./RadioYN"));
const RadioYNNA = React.lazy(() => import("./RadioYNNA"));
const Button = React.lazy(() => import("./Button"));
const JSONEditor = React.lazy(() => import("./JSONEditor"));
const ModuleSelect = React.lazy(() => import("./ModuleSelect"));
const FileField = React.lazy(() => import("./FileField"));
const DiffField = React.lazy(() => import("./DiffField"));
const EmailField = React.lazy(() => import("./EmailField"));
const AddressSearchField = React.lazy(() => import("./AddressSearchField"));
const ViewOnly = React.lazy(() => import("./ViewOnly"));
const Recurrence_v2 = React.lazy(() => import("./Recurrence_v2"));
const Tree = React.lazy(() => import("./Tree"));
const FormBuilder = React.lazy(() => import("./FormBuilder"));
const Address_v2 = React.lazy(() => import("./Address_v2"));
const DateField_v2 = React.lazy(() => import("./DateField_v2/index.tsx"));
const Tag = React.lazy(() => import("./Tag/index.tsx"));
const Email_v2 = React.lazy(() => import("./Email_v2/index.tsx"));
const Phone_v2 = React.lazy(() => import("./Phone_v2/index.tsx"));

const PlaceHolder = ({ field, value }) => {
	return (
		<p style={{ height: 24 }}>
			<b>{field.name}</b>: {value instanceof Object ? "" : value} ({field.type})
		</p>
	);
};

const omitMargin = [
	"Email_v2",
	"Phone_v2",
	"Asset"
];

const fieldWrapper = (Field, extraProps) => (props) => {
	const fieldName = props.field.name;
	return (
		<div style={!omitMargin.includes(fieldName) ? { margin: 14 } : {}} className="dms-field" id={`field-${fieldName}`} data-cy={fieldName} ref={props.setFieldRef}>
			<Suspense fallback={<Skeleton animation="wave" height={56} />}>
				<Field {...extraProps} {...props} />
			</Suspense>
		</div>
	);
};

const multiSelect = fieldWrapper(SelectField, { isMulti: true });

const fields = {
	Chip: multiSelect,
	MultiSelect: multiSelect,
	CountrySelector: fieldWrapper(CountrySelector),
	RecurrenceField: fieldWrapper(RecurrenceField),
	Map: fieldWrapper(MapField),
	Select: fieldWrapper(SelectField),
	Radio: fieldWrapper(Radio),
	Email: fieldWrapper(EmailField),
	Money: fieldWrapper(CurrencyField),
	Currency: fieldWrapper(CurrencyField),
	Decimal: fieldWrapper(DecimalField),
	Number: fieldWrapper(NumberField),
	TextArea: fieldWrapper(TextField, { multiline: true }),
	TextField: fieldWrapper(TextField),
	URLField: fieldWrapper(URLField),
	JSONEditor: fieldWrapper(JSONEditor),
	Switch: fieldWrapper(Switch),
	Checkbox: fieldWrapper(Checkbox),
	Phone: fieldWrapper(PhoneField),
	Html: fieldWrapper(HtmlField),
	"HTML Editor": fieldWrapper(HtmlField),
	HtmlBasic: fieldWrapper(HtmlField, { itemType: "basic" }),
	Date: fieldWrapper(DateField),
	Time: fieldWrapper(TimeField),
	DateTime: fieldWrapper(DateTimeField),
	Button: fieldWrapper(Button),
	RadioYN: fieldWrapper(RadioYN),
	RadioYNNA: fieldWrapper(RadioYNNA),
	Files: fieldWrapper(FileField, { limit: 0 }),
	File: fieldWrapper(FileField, { limit: 1 }),
	PrivateFiles: fieldWrapper(FileField, { limit: 0, private: true }),
	PrivateFile: fieldWrapper(FileField, { limit: 1, private: true }),
	AddressSearch: fieldWrapper(AddressSearchField),
	ModuleSelect: fieldWrapper(ModuleSelect),
	Diff: fieldWrapper(DiffField),
	ViewOnly: fieldWrapper(ViewOnly),
	Recurrence_v2: fieldWrapper(Recurrence_v2),
	Tree: fieldWrapper(Tree),
	Address_v2: fieldWrapper(Address_v2),
	DateField_v2: fieldWrapper(DateField_v2),
	FormBuilder: fieldWrapper(FormBuilder),
	Email_v2: fieldWrapper(Email_v2),
	Phone_v2: fieldWrapper(Phone_v2),
	Tag: fieldWrapper(Tag)
};

const RenderField = (props) => {
	const { field: { type } } = props;
	const customComponents = useSelector(state => {
		return state.customComponents;
	});
	if (customComponents[type]) {
		// custom components overwrites the default component
		return fieldWrapper(customComponents[type])(props);
	} else if (type in fields) {
		return fields[type](props);
	} else if (type === "Custom") { // deprecated
		return fieldWrapper(customComponents[props.field.componentName])(props);
	} else {
		return fieldWrapper(PlaceHolder)(props);
	}
};

export default RenderField;
