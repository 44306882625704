import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionGroup from "./SectionGroup";
import styles from "./styles";
import { connect } from "react-redux";

export const sectionRefs = {};

const setSectionRef = section => element => {
	sectionRefs[section] = element;
};

const groupedSections = ["connections", "customFields"];

const Form = ({ layout, classes, fields, viewOnly, size }) => (
	<form>
		{
			layout && layout.sections.map((section => {
				if (groupedSections.includes(section.name)) {
					return (
						<div key={`form-dev-${section.name}`} ref={setSectionRef(section.name)}>
							<SectionGroup {...section} classes={classes} fields={fields} viewOnly={viewOnly} size={size} />
						</div>
					);
				} else {
					return (
						<div key={`form-dev-${section.name}`} ref={setSectionRef(section.name)}>
							<Section {...section} classes={classes} fields={fields} viewOnly={viewOnly} size={size} />
						</div>
					);
				}
			}))
		}
	</form>
);

const mapStateToProps = ({ layout }) => ({
	layout
});

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)((withStyles(styles)(Form)));
