const moduleSettingGroupFilter = crm => ({ fieldname, index, eventName, subscriber, staticArgs, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (data["ModuleID"]) {
		crm.get({
			type: "moduleSettingGroup",
			filter: {
				ModuleID: data["ModuleID"].value
			},
			fields: "ModuleSettingGroupNm ModuleSettingGroupID"
		}).then(ModuleSettingGroups => {
			const options = ModuleSettingGroups.recs.map(group => ({ label: group.ModuleSettingGroupNm, value: group.ModuleSettingGroupID }));
			dispatchWrapper("updateField", ["ModuleSettingGroupID", { options }]);
		});
	}
};

export default moduleSettingGroupFilter;
