import checkRowActions from "./checkRowActions";

const checkPending = ({ row, action }) => {
	if (row?.PendingID && action?.name === "review" && row.rowButtons.includes("review")) {
		return true;
	} else if (row?.PendingID) {
		return false;
	} else {
		return checkRowActions({ row, action });
	}
};

export default checkPending;
