import { useState, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import useGraphServer from "../../hooks/useGraphServer";
import useTransientElements from "../../hooks/useTransientElements";

import defaults from "lodash/defaults";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import isEmpty from "../../common/isEmpty";
import { wrapFormatValue, FORMAT } from "../../common/format";
import { getIcon } from "../../common/icons";
import rowActionShowMap from "./show";

import { getFilterComponent, getDefaultFilter, gridFilterToFilters } from "./filter";
import SelectHelper from "./filter/SelectHelper";
import { getViewState, getPage, parseViewState, getSkip } from "./view";
import useShouldFetch from "../../hooks/useShouldFetch";
import moment from "moment";


export const tempFunc = (name) => (data) => {
	console.log(name, "is not implemented yet, do nothing...", data);
};

//Map UDFs properly to data
const dataUDFMapper = (data = [], settings = {}) => {						
	data.forEach((record) => {
		if (record.CustomFields) {
			record.CustomFields.forEach((field) => {
				if (moment(field.value, 'YYYY-MM-DD', true).isValid()) {
					field.value = moment(field.value).format();
				} else if (moment(field.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
					field.value = `${field.value}Z`;
				} else if (moment(field.value, 'YYYY-MM-DDTHH:mm:ss-HH:mm', true).isValid()) {
					field.value = `${field.value.substring(0, field.value.lastIndexOf("-"))}Z`;
				} else if (["0", "1"].includes(field.value)) {
					const column = settings?.columns.filter(col => col.name === `CustomField_${field.UDFID}`);
					const format = column && column.length ? column[0].format : "";
					if (["boolean_icon"].includes(format)) {
						field.value = field.value === "0" ? "No" : "Yes";
					}
				}

				record[`CustomField_${field.UDFID}`] = field.value === false ? field.value : field.value || field.chips;
			});
		}
	});
};

//Map Amenity Data
const dataAmenityMapper = (data = []) => {
	data.forEach((record) => {
		if (record.Amenities) {
			record.Amenities.forEach((field) => {
				record[`Amenity_${field.TenantAmenityXrefID}`] = field.value;
			});
		}
	});
};

//Map Attribute Data
const dataAttributeMapper = (data = []) => {
	data.forEach((record) => {
		if (record.Attributes) {
			record.Attributes.forEach((field) => {
				record[`Attribute_${field.AttributeID}`] = field.value === false ? field.value : field.value || field.chips;
			});
		}
	});
};

const getSearchParams = () => {
	return new URLSearchParams(useLocation().search);
};

// This crap is needed for the saved view drawer
let removedView = [];
let renameView = {};
let retypeView = {};

const useV2 = ["offer", "eventCalendar", "contact", "inquiry", "brochure", "listing", "account"];

/**
 * get grid settings from res
 * @param {*} res
 */
export const getGridSettings = res => {
	const {
		config,
		currentView,
		userViews = [],
		sharedViews,
		count
	} = res;
	if (!config) {
		return {};
	}
	const { bulkActions, buttons, columns, filters, primaryActions, additionalActions, views, title } = config;
	const currentViewState = getViewState(currentView, config);
	const result = {
		title,
		filters: filters.map(filter => ({
			name: filter.name,
			label: filter.label,
			component: filter.component,
			type: filter.type,
			args: filter.args,
			selectOptions: filter.selectOptions,
			toFilter: filter.toFilter,
			where: filter.where
		})).sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			} else {
				return 1;
			}
		}),
		primaryFilters: currentView.primaryFilters || [],
		count,
		page: currentView.page,
		primaryActions: primaryActions.map(primaryAction => ({
			name: primaryAction.name,
			color: primaryAction.color,
			variant: primaryAction.variant,
			mIcon: primaryAction.mIcon,
			onClick: primaryAction.onClick,
			show: primaryAction.show,
			tooltip: primaryAction.tooltip || primaryAction.name
		})),
		bulkActions,
		additionalActions,
		buttons: buttons.map(button => ({
			name: button.name,
			label: button.label,
			mIcon: button.mIcon,
			color: button.color,
			variant: button.variant,
			onClick: button.onClick
		})),
		columns: columns.map(column => ({
			label: column.label,
			name: column.name,
			sortable: column.sortable,
			format: column.format,
			linkModule: column.linkModule
		})),
		savedView: {
			id: currentViewState.id || "unsaved",
			label: currentViewState.name,
			type: currentViewState.id ? currentViewState.type : "default",
			state: currentViewState
		},
		sharedViews: sharedViews?.map(view => {
			const { name, type, _id, ...rest } = view;
			return {
				label: name,
				id: _id || undefined,
				type: type || "shared",
				state: getViewState(rest)
			};
		}),
		savedViews: [...views.map((view, i) => ({
			type: "default",
			...view,
		})), ...userViews].map(view => {
			const { name, type, _id, ...rest } = view;
			return {
				label: name,
				id: _id || undefined,
				type: type || "mine",
				state: getViewState(rest)
			};
		}),
		...currentViewState
	};
	return result;
};

// we should convert this to typescript as well
// add default undefined to prevent typescript from erroring in the warpper.
const useFetchGrid = ({ moduleName, parentModule, refId, form = undefined, registry = undefined, server = undefined, systemFilters = undefined, callbackMap }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [settings, setSettings] = useState(null);
	const [isSaving, setSaving] = useState(false);
	let crmGraphServer = server || useGraphServer();
	/**
	 * TODO: crmGraphServer.general is passed in by the FormFrame so we don't have to update all of the callbacks now,
	 * but really we should update the callbacks, because the may need access to more than just the general prefix
	 */
	const gridGraphServer = crmGraphServer.get_grid;
	crmGraphServer = crmGraphServer.general || crmGraphServer;
	const { showSnackbarMessage, setDrawerOpen, openConfirmDialog } = useTransientElements();
	const searchParams = getSearchParams();
	const searchParamsView = searchParams.get("view");

	// not using useCallback because it would cause issues when passed
	// to the forms.
	const refetchGrid = (newSettings = settings, options = {}, unsavedView) => {

		const { preventReload } = options;
		if (!preventReload) {
			setLoading(true);
		}
		const view = parseViewState(newSettings);
		if (unsavedView) {
			view.id = null;
			view.name = "Select a View";
		}

		if (systemFilters !== undefined && systemFilters.length) {
			view.filters = view.filters.concat(systemFilters);
		}

		if (useV2.includes(moduleName) && !form) {
			gridGraphServer.fetchGridSettings(
				null,
				moduleName,
				{ type: parentModule, ID: refId },
				cloneDeep(view)
			).then(settingRes => {
				if (!settingRes.setting) {
					showSnackbarMessage("Error loading your selected view. Loading default view.", "error");
					const temp = getGridSettings(settingRes);
					temp.savedView = (temp && temp.savedViews) ? temp.savedViews[0] : undefined;
					if (unsavedView) {
						refetchGrid({ ...temp, ...temp.savedView.state });
					}
					return;
				}
				settingRes.setting.config.views.forEach(vw => {
					vw.filters.forEach(vwFilt => {
						try {
							vwFilt.value = JSON.parse(vwFilt.value);
						} catch(e) {
							// do nothing
						}
					});
				});
				settingRes.setting.currentView.filters.forEach(vwFilt => {
					try {
						vwFilt.value = JSON.parse(vwFilt.value);
					} catch(e) {
						// do nothing
					}
				});
				settingRes.setting.userViews.forEach(uView => {
					uView.filters.forEach(vwFilt => {
						try {
							vwFilt.value = JSON.parse(vwFilt.value);
						} catch(e) {
							// do nothing
						}
					});
				});
				settingRes.setting.sharedViews.forEach(uView => {
					uView.filters.forEach(vwFilt => {
						try {
							vwFilt.value = JSON.parse(vwFilt.value);
						} catch(e) {
							// do nothing
						}
					});
				});
				settingRes.setting.config.bulkActions.forEach(bAction => {
					if (!bAction.onAllClick) {
						delete bAction.onAllClick;	
					}
				});
				const { savedViews } = getGridSettings(settingRes.setting);
				const dataFilters = gridFilterToFilters({ gridFilter: newSettings.filter, filters: newSettings.filters });
				
				gridGraphServer.fetchGridData(
					null,
					moduleName,
					{ type: parentModule, ID: refId },
					{ 
						filters: dataFilters.map(filt => ({
							columnName: filt.name,
							value: cloneDeep(filt.value),
							jsonify: !unsavedView && filt.operation !== "contains"
						})),
						columns: newSettings.columns?.reduce((accum, curr) => {
							if (newSettings.activeColumns.includes(curr.name)) {
								accum.push({
									columnName: curr.name,
									type: curr.format
								});
							}
							return accum;
						}, [])
					},
					{ 
						limit: newSettings.limit,
						page: newSettings.page,
						orderBy: newSettings.sort.name, 
						direction: newSettings.sort.dir === "asc" ? "ASC" : "DESC"
					}
				).then(dataRes => {
					if (dataRes.success === false) {
						reloadDefaultViewGrid(newSettings);
					}

					dataUDFMapper(dataRes.data, newSettings);
					dataAmenityMapper(dataRes.data);
					dataAttributeMapper(dataRes.data);
					setData(dataRes.data || []);
					setSettings({
						...newSettings,
						count: dataRes.count,
						savedViews
					});
					setLoading(false);
				});
			});
		} else {
			crmGraphServer.fetchGrid({
				moduleName,
				parent: {
					moduleName: parentModule,
					ID: refId
				},
				pagingOptions: {
					page: newSettings.page,
					limit: view.limit,
					orderBy: view.sortedColumns
				},
				form,
				view,
				registry
	
			}).then(res => {
				if (!res.success) {
					showSnackbarMessage("Error loading your selected view. Loading default view.", "error");
					const temp = getGridSettings(res);
					temp.savedView = (temp && temp.savedViews) ? temp.savedViews[0] : undefined;
					if (unsavedView) {
						refetchGrid({ ...temp, ...temp.savedView.state });
					}
					return;
				} else if (res.message) {
					showSnackbarMessage(res.message, res.success ? "success": "error");
				}
				const { savedViews } = getGridSettings(res);
				setData(res.data || []);
				setSettings({
					...newSettings,
					count: res.count,
					savedViews
				});
				setLoading(false);
			});
		}

		history.replaceState && history.replaceState(null, "", location.pathname);
	};

	const updateSettings = useCallback(
		(update, options) => {
			if (options?.replace) {
				setSettings(update);
			}
			if (settings) {
				const newSettings = {
					...settings,
					...update
				};

				const changed = !isEqual(newSettings, settings);
				const filterChanged = false;

				if (changed && !update.savedView) {
					if (newSettings.savedView.id === "unsaved") {
						newSettings.savedView.id = undefined;
					}
					newSettings.savedView = {
						id: newSettings.savedView.id,
						label: (filterChanged ? "Select a View" : settings.savedView.label) || "Select a View",
						type: newSettings.savedView.id ? newSettings.savedView.type : "default"
					};
				}
				if (!options?.preventRefetch) {
					refetchGrid(newSettings, options, !update.savedView && changed);
				}
				setSettings(newSettings);
			}
			// if settings is undefined, don't update
			// since settings needs to be intialized with
			// the initial fetch
		},
		[settings, refetchGrid, setSettings],
	);

	const getCallbackFunction = (name) => {
		if (callbackMap) {
			if (callbackMap[name]) {
				// bind updateSettings so the callback can have access to it
				// from outside useFetchGrid
				const dataFilters = gridFilterToFilters({ gridFilter: settings.filter, filters: settings.filters });
				return callbackMap[name].bind({ updateSettings, activeColumns: settings?.activeColumns, columns: settings?.columns, filter: dataFilters, count: settings?.count });
			}
		}
		return tempFunc(name);
	};

	const [, setLastFetch] = useShouldFetch(moduleName, {
		handleFetch: refetchGrid,
		fetchOnLoad: false // init call is handled seperately
	});

	const reloadDefaultViewGrid = (newSettings = settings) => {
		showSnackbarMessage("Error loading your selected view. Loading default view.", "error");
		newSettings.savedView = (newSettings && newSettings.savedViews) ? newSettings.savedViews[0] : undefined;
		newSettings = { ...newSettings, ...newSettings.savedView.state };
		setLastFetch();
		refetchGrid(newSettings);
		return;	
	};

	// refetch on systemFilters change
	useEffect(() => {
		if (systemFilters !== undefined && settings) {
			refetchGrid(settings);
		}
	}, [systemFilters, settings === null]);

	// initial grid fetching
	useEffect(() => {
		renameView = {};
		retypeView = {};
		removedView = [];
		const defaultView = {};

		/* if parentModule is defined and refId is a string
		* don't continue
		* this occurs because summary state has not yet been updated
		* with the records recId
		*/
		if (parentModule !== undefined && refId && isNaN(refId)) {
			setLoading(false);
			return;
		}

		if (searchParamsView) {
			//setup view here
			defaultView.name = searchParamsView;
		}

		if (useV2.includes(moduleName) && !form) {
			gridGraphServer.fetchGridSettings(
				null,
				moduleName,
				{ type: parentModule, ID: refId }
			).then(settingRes => {
				try {
					let newSettings;
					if (settingRes && settingRes.setting) {
						if (searchParamsView) {
							settingRes.setting.currentView = settingRes.setting.config.views.find(view => view.name === searchParamsView) || settingRes.setting.currentView;
						}
						settingRes.setting.config.views.forEach(vw => {
							vw.filters.forEach(vwFilt => {
								try {
									vwFilt.value = JSON.parse(vwFilt.value);
								} catch(e) {
									// do nothing
								}
							});
						});
						settingRes.setting.currentView.filters.forEach(vwFilt => {
							try {
								vwFilt.value = JSON.parse(vwFilt.value);
							} catch(e) {
								// do nothing
							}
						});
						settingRes.setting.userViews.forEach(uView => {
							uView.filters.forEach(vwFilt => {
								try {
									vwFilt.value = JSON.parse(vwFilt.value);
								} catch(e) {
									// do nothing
								}
							});
						});
						settingRes.setting?.sharedViews?.forEach(uView => {
							uView.filters.forEach(vwFilt => {
								try {
									vwFilt.value = JSON.parse(vwFilt.value);
								} catch(e) {
									// do nothing
								}
							});
						});
						settingRes.setting.config.bulkActions.forEach(bAction => {
							if (!bAction.onAllClick) {
								delete bAction.onAllClick;	
							}
						});
						newSettings = getGridSettings(settingRes.setting);
					} else {
						reloadDefaultViewGrid(newSettings);
					}
					
					const dataFilters = gridFilterToFilters({ gridFilter: newSettings.filter, filters: newSettings.filters });
					gridGraphServer.fetchGridData(
						null,
						moduleName,
						{ type: parentModule, ID: refId },
						{ 
							filters: dataFilters.map(filt => ({
								columnName: filt.name,
								value: cloneDeep(filt.value),
								jsonify: filt.operation !== "contains"
							})),
							columns: newSettings.columns.reduce((accum, curr) => {
								if (newSettings.activeColumns.includes(curr.name)) {
									accum.push({
										columnName: curr.name,
										type: curr.format
									});
								}
								return accum;
							}, [])
						},
						{ 
							limit: newSettings.limit,
							page: newSettings.page,
							orderBy: newSettings.sort.name, 
							direction: newSettings.sort.dir === "asc" ? "ASC" : "DESC"
						}
					).then(dataRes => {
						if (dataRes.success === false) {
							dataRes = reloadDefaultViewGrid(getGridSettings(settingRes.setting));
						}
						
						newSettings.count = dataRes.count;
						updateSettings(newSettings, {
							preventRefetch: true,
							replace: true
						});
						dataUDFMapper(dataRes.data, newSettings);
						dataAmenityMapper(dataRes.data);
						dataAttributeMapper(dataRes.data);
						setData(dataRes.data || []);
					});
				} catch (e) {
					console.error(e);
					showSnackbarMessage("Something went wrong", "error");
				}
				setLoading(false);
				setLastFetch();
			});
		} else {
			crmGraphServer.fetchGrid({
				moduleName,
				parent: {
					moduleName: parentModule,
					ID: refId
				},
				form,
				registry,
				isInitCall: true,
				view: defaultView
			}).then(res => {
				try {
					const newSettings = getGridSettings(res);
					if (!res.success) {
						reloadDefaultViewGrid(newSettings);
					}
					
					updateSettings(newSettings, {
						preventRefetch: true,
						replace: true
					});

					/**
					 * if coming from a form and systemFilters
					 * let refetch get called to set data
					 */
					if (form === undefined || (form && !systemFilters)) {
						setData(res.data || []);
					}
				} catch (e) {
					console.error(e);
					showSnackbarMessage("Something went wrong", "error");
				}
				setLoading(false);
				setLastFetch();
			});
		}
	}, [refId, form, parentModule]);

	const defaultFilter = useMemo(() => {
		return getDefaultFilter(settings?.filters || []);
	}, [settings?.filters]);

	// this maps the settings to grid props that mosaic grid
	// would take
	const gridProps = useMemo(() => {
		return {
			buttons: (settings?.buttons)?.map(button => {
				return {
					...button,
					onClick: getCallbackFunction(button.onClick),
					mIcon: getIcon(button.mIcon)
				};
			}).concat([{
				onClick: () => {
					updateSettings({
						filter: defaultFilter,
						page: 1
					});
				},
				color: "blue",
				variant: "outlined",
				label: "Clear Filters",
				mIcon: getIcon("ClearAll")
			}]) || [],

			title: settings?.title,
			limit: settings?.limit || 50,
			skip: getSkip(settings),
			count: settings?.count || 0,
			display: settings?.view || "list",
			sort: settings?.sort,
			columns: (settings?.columns || []).map(column => {
				const { label, name, sortable, format, wrapper, linkModule } = column;
				if (settings) {
					let wrapperFormat = format;
					if (label.indexOf("(CF)") !== -1 && format === "time") {
						wrapperFormat = "timeutc";
					} else if (label.indexOf("(CF)") !== -1 && format === "datetime") {
						wrapperFormat = "datetimeutc";
					}

					const linkModuleMapper = {
						hostAccount: "HostAccountRecordID",
						venueAccount: "VenueAccountRecordID",
						primaryAccount: "PrimaryAccountRecordID",
						account: "AccountRecordID",
						contact: "ContactRecordID"
					};

					const module = linkModule ? (linkModule.toLowerCase().includes("account") ? "account" : linkModule) : moduleName;

					return {
						label, name, sortable,
						transforms: [
							({ data, row }) => wrapFormatValue(data, wrapperFormat, wrapper, {
								module,
								id: FORMAT.EMAIL === format && row.recId,
								setDrawerOpen,
								data: format === FORMAT.MODULE_LINK ? (linkModule && linkModuleMapper[linkModule] ? row[linkModuleMapper[linkModule]] : row.RecordID) : null,
								onClick: () => getCallbackFunction("handleBulletinModal")({ data: row.recId })
							})
						]
					};
				}
			}),
			primaryActions: settings?.primaryActions?.map(action => ({
				...action,
				mIcon: getIcon(action.mIcon),
				onClick: getCallbackFunction(action.onClick),
				show: !action.show ?
					true :
					typeof action.show === "string" ?
						({ row }) => rowActionShowMap[action.show]({ row, action }) :
						action.show
			})) || [],
			additionalActions: settings?.additionalActions?.map(action => ({
				...action,
				onClick: getCallbackFunction(action.onClick),
				show: !action.show ?
					true :
					typeof action.show === "string" ?
						({ row }) => rowActionShowMap[action.show]({ row, action }) :
						action.show
			})) || [],
			bulkActions: settings && settings.bulkActions && settings.bulkActions.length ? settings.bulkActions.map(action => {
				const onClicks = {
					onClick: getCallbackFunction(action.onClick)
				};
				if (action.onAllClick) {
					onClicks.onAllClick = getCallbackFunction(action.onAllClick);
				}
				return {
					...action,
					...onClicks,
					mIcon: getIcon(action.mIcon),
					show: !action.show ? true :
						typeof action.show === "string" ?
							({ row }) => rowActionShowMap[action.show]({ row, action }) :
							action.show,
					tooltip: action.tooltip || action.name
				};
			}) : undefined,
			// default filter with defaultFilter,
			// otherwise it args.comparisons must contain "equal"
			filter: settings?.filter ? defaults(settings.filter, defaultFilter) : defaultFilter,
			filters: (settings?.filters || []).map(filter => {
				if (filter.component === "text" && filter.args?.comparisons) {
					filter.component = "fullText";
				} else if (filter.component === "GridFilterMultiselect") {
					if (filter.selectOptions) {
						const optionHelper = new SelectHelper({
							...filter.selectOptions,
							server: crmGraphServer,
							parentType: parentModule || moduleName,
							parentID: refId
						});

						filter.args = {
							getOptions: optionHelper.getOptions,
							getSelected: optionHelper.getSelected
						};
					}
				}
				const component = getFilterComponent(filter.component);
				if (filter.args) {
					for (const key in filter.args) {
						if (filter.args[key] === null) {
							delete filter.args[key];
						}
					}
				}
				return {
					...filter,
					type: !settings?.primaryFilters?.length ? filter.type === "optional" ? "optional": "primary" :
						settings?.primaryFilters.indexOf(filter.name) > -1 ? "primary" :
							"optional",
					component,
					onChange: value => {
						updateSettings({
							filter: {
								...settings.filter,
								[filter.name]: value
							},
							page: 1
						});
					},
					// This prevent clear filter from crashing
					// caused by filter comparison defaults to "equals"
					args: {
						...filter.args,
						// Make it only avaliable for fullText filter for now, ideally move this to filter/index.js if the logic is going to get more complicated.
						comparisons: filter.component === "fullText" ? (filter.args?.comparisons?.includes("equals") ? filter.args?.comparisons : [
							...(filter?.args?.comparisons || ["contains", "not_contains", "not_equals"]), "equals"
						]) : undefined
					}
				};
			}),
			// default all is visible
			activeColumns: settings?.activeColumns || settings?.columns?.map(column => column.name) || [],
			activeFilters: settings?.activeFilters || [],
			onLimitChange: ({ limit }) => {
				updateSettings({
					limit,
					page: 1
				});
			},
			onSkipChange: ({ skip }) => {
				updateSettings({
					page: getPage(skip, settings?.limit)
				});
			},
			onSortChange: sort => {
				updateSettings({
					sort,
					page: 1
				});
			},
			onDisplayChange: view => {
				updateSettings({
					view
				});
			},
			savedView: settings?.savedView,
			onSavedViewSave: async (data) => {
				if (!data.label) {
					showSnackbarMessage("Saved Views can not be saved without a label.", "error");
					return;
				}
				if (data.label.length > 250) {
					showSnackbarMessage("Saved Views can not have a label greater than 250 characters.", "error");
					return;
				}
				
				if (!isSaving) {
					setSaving(true);
					if (data.id) {
						renameView[data.id] = data.label;
						retypeView[data.id] = data.type;
					}

					if (settings.sharedViews.find(v => v.id === data.id)) {
						showSnackbarMessage("Shared View(s) can not be modified.");
						return;
					}

					crmGraphServer.saveGridView({
						name: data.label,
						id: data.id,
						parentModule,
						moduleName,
						form,
						type: data.type,
						view: {
							...parseViewState({ ...data.state, filters: settings.filters })
						}
					}).then( res => {
						const id = res.RecordID || data.id;

						const newSettings = {
							...settings,
							savedView: {
								...data,
								id,
							}
						};

						newSettings.savedViews.map(view => {
							if (view.id === id) {
								return { ...data, id };
							} else {
								return { ...view };
							}
						});

						updateSettings(newSettings, {
							preventReload: true
						});

						refetchGrid(newSettings, {
							preventReload: true
						});

						showSnackbarMessage(`View ${data.label} ${data.id ? "updated" : "created"}.`, "success");
						setSaving(false);
					});
					
				}
			},
			savedViewAllowSharedViewSave: true,
			onSavedViewGetOptions: () => {
				return settings.savedViews.filter(view => {
					return !removedView.find(removedID => removedID === view.id);
				}).map(view => {
					return {
						...view,
						label: renameView[view.id] || view.label,
						type: retypeView[view.id] || view.type
					};
				});
			},
			onSavedViewChange: (view) => {
				updateSettings({
					savedView: view,
					...view.state,
					page: 1
				});
			},
			onSavedViewRemove: async ({ id, label }) => {
				if (settings.sharedViews.find(v => v.id === id)) {
					showSnackbarMessage("Shared View(s) can not be deleted.");
					return;
				}
				const promise = new Promise((resolve, reject) => {
					const handleConfirm = async () => {
						const res = await crmGraphServer.set_old({
							queryString: `removeView(id: "${id}") { success }`
						});
						if (res.removeView?.success) {
							removedView.push(id);
							if (settings.savedView.id === id) {
								updateSettings({
									savedView: {
										id: "unsaved",
										label: "Select a View",
										type: "default"
									}
								}, {
									preventReload: true
								});
							} else {
								refetchGrid(undefined, {
									preventReload: true
								});
							}
							showSnackbarMessage(`View ${label} removed.`, "success");
							resolve("done");
						}
					};
					openConfirmDialog({ 
						handleConfirm,
						content: "This action cannot be undone"
					});
				});
				await promise;
			},
			onActiveFiltersChange: ({ activeFilters, filter }) => {
				const added = difference(activeFilters, settings.activeFilters);
				const removed = difference(settings.activeFilters, activeFilters);
				const diff = added.length ? added[0] : removed[0];
				const emptyFilter = isEmpty(settings.filter[diff]);
				if (diff[0] && filter[diff]) {
					filter[diff] = undefined;
				}
				updateSettings({
					activeFilters,
					filter
				}, {
					// prevent fetching grid if the filter was empty
					preventRefetch: emptyFilter
				});
			},
			onColumnsChange: (columns) => {
				updateSettings({
					activeColumns: columns
				});
			},
			loading,
			data
		};
	}, [settings, loading, isSaving, data]);

	gridProps.activeColumns = gridProps.activeColumns.filter(column => {
		const match = !gridProps.columns.find(c => {
			return c.name === column;
		});
		return !match;
	});

	return gridProps;
};

export default useFetchGrid;
