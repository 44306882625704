import { ERROR_TYPES } from "../consts";
import getFieldInfo from "../fieldInfo";
const { IS_REQUIRED } = ERROR_TYPES;

const nonvalues = ["undefined", null, ""];
const compares = {
	">=": (value, arg) => value >= arg,
	">": (value, arg) => value > arg,
	"<": (value, arg) => value = arg,
	"<=": (value, arg) => value <= arg,
	"==": (value, arg) => value == arg,
	"===": (value, arg) => value === arg,
	"!=": (value, arg) => value != arg,
	"!==": (value, arg) => value !== arg,
	"includes": (value, arg) => JSON.parse(arg).includes(value),
	"!includes": (value, arg) => !JSON.parse(arg).includes(value),
	"nonvalues": (value, arg) => nonvalues.includes(value),
	"!nonvalues": (value, arg) => !nonvalues.includes(value)
};

/**
 * isRequiredCompare
 *
 * staticArgs:
 * 0 - toCompareField: String
 *     Field to compare to
 * 1 - operator: String
 *     Specify the operator of the action
 * 2 - compValue: any
 *     This is the value that the toCompareField is compared with.
 */

const isRequiredCompare = ({ fieldName, compField, compIndex, dispatchWrapper, getState, staticArgs, subscriber }) => {
	const { data, fields } = getState();
	const fieldParts = [fieldName, compField, compIndex];
	const [toCompareField, operator, compValue, validate = true, alternateMsg = false] = staticArgs;

	if (compField && data[compField] && data[compField].compositeFields && compIndex >= data[compField].compositeFields.length) {
		dispatchWrapper("clearValidation", [...fieldParts, IS_REQUIRED]);
		return;
	}

	const fieldCheck = getFieldInfo({ subscriber, compField, compIndex, data });
	let field = fields[fieldCheck.name];
	const bitField = ["Boolean"].includes(field?.dataType);
	let hasValue = false;

	const fieldValue = data[toCompareField];
	if (fieldValue !== undefined) {
		const value = fieldValue instanceof Object ? fieldValue.value : fieldValue;
		let compareValue = (compValue === "true") ? true : ((compValue === "false") ? false : compValue);
		//let compareValue = compValue;
		hasValue = compares[operator](value, compareValue);
	} else if (data[compField] && data[compField].compositeFields[compIndex][toCompareField]) {
		hasValue = compares[operator](data[compField].compositeFields[compIndex][toCompareField], compValue);
	}

	if (hasValue && validate !== "false" && ((!bitField && !fieldCheck.value) || (bitField && fieldCheck.value !== true))) {
		const message = alternateMsg === "true" ? "field is required" : "This field is required when one or more related fields are populated.";
		dispatchWrapper("setValidation", [fieldCheck.name, compField, compIndex, IS_REQUIRED, message]);
	} else {
		dispatchWrapper("clearValidation", [fieldCheck.name, compField, compIndex, IS_REQUIRED]);
	}
};

export default isRequiredCompare;
