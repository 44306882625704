import moment from "moment";
import { RRule } from "rrule";
import { ERROR_TYPES } from "../consts";
import { minutesOfDay } from "../moment";
const { IS_VALID_DATE_ORDER } = ERROR_TYPES;

function isValidDuration(start, end) {
	if (end.isBefore(start)) {
		return false;
	}

	return true;
}

// staticArgs has the [startDt field name, endDt field name, startTime field name]
const isValidDateOrder = ({ fieldName, subscriber, dispatchWrapper, getState, compField, compIndex, staticArgs }) => {
	const { data, fields } = getState();
	const startDate = moment(staticArgs[0] ? data[staticArgs[0]] : data[fieldName]);

	let endDate;
	const startDateField = staticArgs[0] ? fields[staticArgs[0]] : fields[fieldName];
	const endDateField = fields[staticArgs[1]];
	const fieldParts = [subscriber, compField, compIndex];
	let throwError = false;
	if (staticArgs.includes("Recurrence")) {
		const recurrence = data["Recurrence"];
		if (recurrence !== undefined && recurrence.length && recurrence[0].RecurrenceType !== undefined && recurrence[0].RRULEText) {
			const type = recurrence[0].RecurrenceType;
			if (type !== "CUSTOM") {
				const ruleObj = RRule.fromString(recurrence[0].RRULEText);
				if (ruleObj.options.until !== null) {
					endDate = moment(ruleObj.options.until);
					endDateField.label = "recurrence end date";
					const recurError = "recurrence end date by at least one";
					// trigger validation error, if recurrence ends before duration (week, month, year)
					if (isValidDuration(startDate, endDate) === false) {
						throwError = true;
					}
				}
			} else {
				let hasError = false;
				recurrence.forEach(recur => {
					const rSplit = recur.RRULEText.split(":");
					const dateString = rSplit[1].split("/")[0];
					const rrule = RRule.fromString(`DTSTART:${dateString}`);
					const recurDate = moment(rrule.options.dtstart);
					
					if (startDate.startOf("day").isAfter(recurDate.startOf("day"))) {
						endDate = recurDate;
					} else if (startDate.startOf("day").isSame(recurDate.startOf("day"))) {
						if (staticArgs[2] && data[staticArgs[2]]) {
							// check start date time
							const startTimeOfDay = minutesOfDay(data[staticArgs[2]]);
							const recurStartTime = minutesOfDay(moment(dateString));
							if (startTimeOfDay > recurStartTime) {
								fieldParts[0] = staticArgs[2];
								dispatchWrapper("setValidation", [...fieldParts, IS_VALID_DATE_ORDER, `${startDateField.label} with ${fields[staticArgs[2]].label} must precede all recurrence`]);
								hasError = true;
							}
						}
					}
					endDateField.label = "all custom recurrences";
				});
				if (hasError) {
					return;
				}
			}
		}
	} else {
		endDate = moment(staticArgs[1] ? data[staticArgs[1]] : data[subscriber]);
	}

	if (startDate.isValid() && endDate !== undefined && endDate.isValid() && (startDate.isAfter(endDate, "day") || throwError === true)) {
		dispatchWrapper("setValidation", [...fieldParts, IS_VALID_DATE_ORDER, `${endDateField.label} must not precede ${startDateField.label}`]);
	} else {
		dispatchWrapper("clearValidation", [...fieldParts, IS_VALID_DATE_ORDER]);
		if (staticArgs[2]) {
			fieldParts[0] = staticArgs[2];
			dispatchWrapper("clearValidation", [...fieldParts, IS_VALID_DATE_ORDER]);
		}
	}
};

export default isValidDateOrder;
