import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Hidden } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SUBSCRIPTION_TYPES } from "../../../common/consts";
import classNames from "classnames";
import Field from "./Field";
import { Creators } from "../actions";
import SelectField from "../../Fields/SelectField";
import FormHelperText from "@material-ui/core/FormHelperText";
const { ON_LOAD } = SUBSCRIPTION_TYPES;
const subGroups = ["connections"];
const groupedSectionHelperText = {
	connections: "Please select a Connection to set its value(s).",
	customFields: "Please select a Configurable Field to set its value."
};

const renderGroupedFields = (name, groupName, fieldsWithGroups, classes) => {
	const fieldsInGroup = fieldsWithGroups.filter(field => field.groupName === groupName);

	const fieldsToRender = fieldsInGroup.map((field, i) => {
		return <Grid
			item
			xs={12}
			lg={subGroups.includes(name) ? 6 : 12}
			key={`${field.name}-renderGroupedFields`}
		>
			<Field key={field.name} name={field.name} classes={classes} />
		</Grid>;
	});

	return fieldsToRender;
};

const renderSection = (name, label, columns, rows, classes, fields, data, displaySection, handleOnChange, viewOnly, size) => {
	let lastGroupName = "";
	const allGroupedSections = displaySection?.fieldsWithGroups?.map(field => {
		if (lastGroupName !== field.groupName) {
			lastGroupName = field.groupName;
			const selected = displaySection?.selectedOptions.findIndex(option => option.value === field.groupName) > -1;
			const groupedClasses = selected ? classNames(classes.groupedSection) : classNames(classes.groupedSection, classes.groupedSectionHidden);
			const groupedGridClasses = selected ? classNames(classes.groupedGrid) : classNames(classes.groupedGrid, classes.groupedSectionHidden);

			if (subGroups.includes(name)) {
				return <ExpansionPanel defaultExpanded className={groupedClasses} key={`${name}-${field.groupName}-renderSection-SectionGroup-EP`}>
					<ExpansionPanelSummary key={`${name}-${field.groupName}-renderSection-SectionGroup-EPS`} className={classNames(classes.sectionHeader, "CyFormSectionHeader")} expandIcon={<ExpandMoreIcon />}>
						{field.groupName}
					</ExpansionPanelSummary>
					<ExpansionPanelDetails key={`${name}-${field.groupName}-renderSection-SectionGroup-EPD`} className={classNames(classes.gridContainer)}>
						<Grid
							container
							direction="row"
							key={`${name}-${field.groupName}-renderSection`}
						>
							{
								renderGroupedFields(name, field.groupName, displaySection?.fieldsWithGroups, classes)
							}
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>;
			} else {
				return <Grid
					container
					direction="column"
					className={groupedGridClasses}
					key={`${name}${field.groupName}-renderSection`}
				>
					{
						renderGroupedFields(name, field.groupName, displaySection?.fieldsWithGroups, classes)
					}
				</Grid>;
			}

		}

		lastGroupName = field.groupName;
	}).filter(group => group !== undefined);

	return <React.Fragment>
		<Grid
			item
			xs={12}
			lg={12}
			key={`${name}-displaySelect`}
		>
			<FormHelperText
				className={classNames(classes.helperText)}
				variant="outlined"
				style={{ display: viewOnly ? "none" : "inherit" }}
				key={`${name}-FormHelperText`}
			>
				{groupedSectionHelperText[name]}
			</FormHelperText>
			<SelectField
				field={{
					label: `${label}`,
					placeholder: "Select an option",
					readOnly: false,
					name: "GroupID",
					options: displaySection?.selectOptions,
					key: `${label}-SelectField2`
				}}
				value={displaySection?.selectedOptions}
				isMulti={true}
				onChange={handleOnChange}
				style={{ display: viewOnly ? "none" : "inherit" }}
				size={size}
				key={`${label}-SelectField`}
			/>
		</Grid>
		{allGroupedSections}
	</React.Fragment>;
};

const Section = React.forwardRef(({ name, label, rows, columns, classes, hidden, fireSubscriptionsByEventName, fields, data, viewOnly, handleFormChange, size }, ref) => {
	const [displaySection, setDisplaySection] = useState(null);
	const handleOnChange = useCallback(
		(selectedOptions) => {
			if (displaySection.selectedOptions.length > selectedOptions.length) {
				//Clear data in the section since it has been removed.
				displaySection.selectedOptions.map(option => {
					const index = selectedOptions.findIndex(selected => selected.value === option.value);
					if (index === -1) {
						const fieldsToClear = displaySection.fieldsWithGroups.filter(field => field.groupName === option.value);

						fieldsToClear.map(field => {
							const clearValue = ["DateTime"].includes(field.dataType) ? null : "";
							handleFormChange(field.name, field.dataType, clearValue);
						})
					}
				});
			}

			if (selectedOptions.length) {
				selectedOptions = selectedOptions.sort((a, b) => {
					if (a.label.toLowerCase() < b.label.toLowerCase()) {
						return -1;
					}
					return 1;
				});
			}

			setDisplaySection({
				...displaySection,
				selectedOptions
			});
		},
		[handleFormChange, displaySection, setDisplaySection]
	);

	useEffect(() => {
		fireSubscriptionsByEventName({ eventName: ON_LOAD, fieldName: name });

		let allFieldNames = [];
		let fieldsWithGroups = [];
		let selectOptions = [];
		let selectedOptions = [];

		rows.map(row => {
			row.columns.map(column => {
				if (column.fields) {
					allFieldNames = allFieldNames.concat(column.fields);
				}
			});
		});

		allFieldNames.map(fieldName => {
			const fullField = fields[fieldName];

			if (fullField) {
				const field = {
					name: fullField.name,
					label: fullField.label,
					groupName: fullField.groupName || fullField.label,
					dataType: fullField.dataType,
					value: data[fullField.name]
				};

				fieldsWithGroups.push(field);

				const selectOptionsIndex = selectOptions.findIndex(option => option.value === field.groupName)
				if (selectOptionsIndex === -1) {
					selectOptions.push({
						label: field.groupName,
						value: field.groupName
					});
				}

				const selectedIndex = selectedOptions.findIndex(option => option.value === field.groupName)
				if (selectedIndex === -1 && data[fullField.name]) {
					selectedOptions.push({
						label: field.groupName,
						value: field.groupName
					});
				}
			}
		})

		if (fieldsWithGroups.length) {
			fieldsWithGroups.sort((a, b) => {
				if (a.groupName === b.groupName) {
					return a.label < b.label ? -1 : 1
				} else {
					return a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1
				}
			})
		}

		if (selectOptions.length) {
			selectOptions = selectOptions.sort((a, b) => {
				if (a.label.toLowerCase() < b.label.toLowerCase()) {
					return -1;
				}
				return 1;
			});
		}

		if (selectedOptions.length) {
			selectedOptions = selectedOptions.sort((a, b) => {
				if (a.label.toLowerCase() < b.label.toLowerCase()) {
					return -1;
				}
				return 1;
			});
		}

		setDisplaySection({
			fieldsWithGroups,
			selectOptions,
			selectedOptions
		});
	}, [name]);

	const section = (
		<ExpansionPanel ref={ref} defaultExpanded className={classes.section} key={`${label}-EP`}>
			<ExpansionPanelSummary className={classNames(classes.sectionHeader, "CyFormSectionHeader")} expandIcon={<ExpandMoreIcon />} key={`${label}-EPS`}>
				{label}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classNames(classes.gridContainer)} key={`${label}-EPD`}>
				<Grid
					container
					direction="row"
					key={`${label}-SectionGrid`}
				>
					{
						renderSection(name, label, columns, rows, classes, fields, data, displaySection, handleOnChange, viewOnly, size)
					}
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
	if (hidden) {
		return <Hidden xsUp={true}>{section}</Hidden>;
	} else {
		return section;
	}
});

const mapStateToProps = ({ data }) => ({
	data
});

const mapDispatchToProps = {
	fireSubscriptionsByEventName: Creators.fireSubscriptionsByEventName,
	handleFormChange: Creators.handleFormChange
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Section);

