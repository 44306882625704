import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
	setDashboardData: ["data"],
	fetchDashboardsSuccess: ["dashboards"]
});

Creators.fetchDashboards = () => (dispatch, getState) => {
	// TODO: Replace this with a setting fetch or similar
	const { login: { productName } } = getState();
	const dashboards = {
		dms: [
			{
				dashboardId: 1,
				title: "",
				dashlets: ["accounts&pendingActivity,calendar", "tasks"]
			}
		],
		extranet: [
			{
				dashboardId: 1,
				title: "",
				dashlets: ["bulletin", "carousel"]
			}
		]
	};
	if (dashboards[productName]) {
		dispatch(Creators.fetchDashboardsSuccess(dashboards[productName]));
	}
};

Creators.fetchTasks = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	return crmGraphServer.general.get({
		type: "assigned_tasks",
		filter: {
			Completed: false
		},			
		fields: `
			TaskID
			Title
			TaskType
			Priority			
			DueDate
		`
	});
};

Creators.fetchAccounts = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "account",
		fields: "RecordID CompanyName StatusID IsActive, LastModifiedDt",
		grid: { type: "myProfile.account", ignoreLastFilter: true, isInitCall: true },
		options: {
			limit: 3,
			orderBy: "LastModifiedDt",
			orderDir: "desc"
		}
	}).then(res => {
		dispatch(Creators.setDashboardData({ accounts: res.recs }));
	});
};

Creators.fetchStatuses = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "status",
		fields: `
		Title
		StatusID
	`
	})
		.then(res => {
			dispatch(Creators.setDashboardData({ statuses: res.recs }));
		});
};

Creators.fetchPending = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	const modules = ["listing", "offer", "eventCalendar"];
	for (const module of modules) {
		crmGraphServer.general.get({
			type: `${module}_review`,
			fields: `recId PendingID`
		})
			.then(res => {
				dispatch(Creators.setDashboardData({ [`pending${module.charAt(0).toUpperCase() + module.slice(1)}s`]: res.count }));
			});
	}
};

Creators.setLastFilter = moduleNm => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.set({
		type: "last_filter",
		input: {
			ModuleNm: moduleNm,
			ModuleSettingNm: "lastFilter",
			ModuleSettingGroupNm: "grid",
			// eslint-disable-next-line max-len
			ModuleSettingValue: `{"filters":[{"sortOrder":1,"label":"IsApproved","name":"IsApproved","dataType":"Int","display":false,"hidden":true,"filterOps":{"_eq":false}}],"name":"all_pending_changes"}`
		},
		fields: "success"
	});
};

Creators.fetchCarousel = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "carousel",
		options: {
			orderBy: "SortOrder",
			orderDir: "ASC"
		},
		filter: {
			Published: true
		},
		fields: `
		Title
		Link
		LinkText
		SlideInterval
		Caption
		AssetPath
		SortOrder
	`
	}).then(res => {
		dispatch(Creators.setDashboardData({ carousel: res.recs }));
	});
};

Creators.fetchBulletin = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "bulletin",
		options: {
			orderBy: "IsImportant",
			orderDir: "DESC",
			limit: 5
		},
		filter: {
			Published: true
		},
		fields: `
			Title
			ShortBulletin
			Bulletin
			IsImportant
			PublishStartDt
			LastModifiedDt
			SortOrder
			HasAttachment
			Attachment {
				recs {
					name
					url
				}
			}
			recId
			BulletinID
		`
	}).then(res => {
		dispatch(Creators.setDashboardData({ bulletin: res.recs }));
	});
};

Creators.fetchMine = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	const modules = ["account", "task"];
	for (const module of modules) {
		crmGraphServer.general.get({
			type: `${module}`,
			fields: `LastModifiedDt`,
			grid: { type: `myProfile.${module}`, ignoreLastFilter: true, isInitCall: true },
			options: {
				orderBy: "LastModifiedDt",
				orderDir: "desc"
			}
		})
			.then(res => {
				dispatch(Creators.setDashboardData({ [`my${module.charAt(0).toUpperCase() + module.slice(1)}s`]: res.count }));
			});
	}
};

export { Creators, Types };
