import { combineReducers } from "redux";

import { resettableReducer } from "reduxsauce";

import login from "./loginReducer";
import grid from "./gridReducer";
import header from "./headerReducer";
import transientElements from "./transientElementReducer";
import summary from "./summaryReducer";
import mycalendar from "./mycalendarReducer";
import dashboard from "./dashboardReducer";
import records, { RecordState } from "./recordReducer";

const resettable = resettableReducer("RESET");

const rootReducer = combineReducers({
	login: resettable(login),
	grid: resettable(grid),
	transientElements: resettable(transientElements),
	header: resettable(header),
	summary: resettable(summary),
	mycalendar: resettable(mycalendar),
	dashboard: resettable(dashboard),
	records: resettable(records)
});

// TODO: If you find yourself here wondering why you're not getting type-hinting, please implement the type for the reducer you're working on
export interface RootState {
	login: any;
	grid: any;
	transientElements: any;
	header: any;
	summary: any;
	mycalendar: any;
	dashboard: any;
	records: RecordState;
}

export default rootReducer;
