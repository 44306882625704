import { useSelector } from "react-redux";

/**
 * Return the corresponding graphql server.
 * 
 * @param {string} [name="crm"] name of the server, usually the prefix of the graph schema
 */
const useGraphServer = (name = "crm") => {
	const graphServer = useSelector(state => {
		return state.login[`${name}GraphServer`];
	});
	return graphServer;
};

export default useGraphServer;
