const additonalListingSubcats = crm => ({ fieldName, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (!data.SubcategoryID || (data.SubcategoryID && data.SubcategoryID.length === 0)) {
		dispatchWrapper("handleFormChange", ["AdditionalSubcategories", "Int", []]);
	}

	if (data[fieldName]) {
		crm.get({
			type: "listingSubCategory",
			filter: {
				IsActive: true,
				NEQ: {
					ListingCategoryID: data.SubcategoryID.value
				}
			},
			fields: "CatSubcatTitle Title ParentCategory ListingCategoryID"
		}).then(res => {
			if (res.success && res.count) {
				const options = res.recs.map(category => ({ label: category.CatSubcatTitle, value: category.ListingCategoryID }));
				dispatchWrapper("updateField", ["AdditionalSubcategories", { options }]);
			}
		});
	}
};

export default additonalListingSubcats;
