const eventCategory = crm => ({ fieldName, compField, compIndex, eventName, subscriber, staticArgs, dispatchWrapper, getState }) => {
	const { data } = getState();
	if (!data.EventCategories || (data.EventCategories && data.EventCategories.length === 0)) {
		dispatchWrapper("handleFormChange", ["PrimaryEventCategoryID", "Int", []]);
		return;
	}

	crm.get({
		type: "eventCategoryById",
		filter: {
			recId: data.EventCategories.map(val => val.value)
		},
		fields: `
			EventCategoryID
			Title
		`
	}).then(res => {
		if (res.success && res.count) {
			const { recs: categories } = res;
			const options = categories.map(rec => ({ label: rec.Title, value: rec.EventCategoryID }));
			dispatchWrapper("updateField", ["PrimaryEventCategoryID", { options }]);
			if (data.PrimaryEventCategoryID && options.findIndex(cat => cat.value === data.PrimaryEventCategoryID.value) === -1) {
				dispatchWrapper("handleFormChange", ["PrimaryEventCategoryID", "Int", []]);
			}
		} else {
			dispatchWrapper("updateField", ["PrimaryEventCategoryID", { options: [] }]);
			dispatchWrapper("handleFormChange", ["PrimaryEventCategoryID", "Int", []]);
		}
	});
};

export default eventCategory;
