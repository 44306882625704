
import { extractInfo } from "../form";

const cleanupDamFiles = crm => info => {
	const { ownField, submit } = extractInfo(info);
	ownField.files?.filter(file =>
		submit ? file.toBeDeleted : !file.original
	).forEach(file => {
		crm.removeAsset({ id: file.damId }).then(res => {
			if (!res.success) {
				console.error(res.message);
			}
		});
	});
};

export default cleanupDamFiles;
