import React from "react";
import isEqual from "lodash/isEqual";
import { RRule } from "rrule";
import { 
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { formatClientDate, formatDatetimeCustom, FORMAT as COMMON_FORMAT } from "@react-ui/common/format";
import styles from "./styles";
import { DiffModalProps, DiffData } from "./typeDefs";
import { wrapFormatValue } from "../../common/format";
import stripHtml from "../../common/stripHtml";
import { DATE_FORMAT } from "../../common/consts";
import moment from "moment";
import classNames from "classnames";
let EMAIL: string, PHONE: string, AVATAR: string, URL: string, CHIP: string, CHIPLINK: string, MODULE_LINK: string, BOOL_ICON: string, IMAGE: string, GRIDCHIPS: string, TEXT_EXPANDABLE: string, TITLEDIMAGE: string, HTML: string, HTML_EDITOR: string, SHORT_HTML_EDITOR: string, HTML_LINE_BREAKS: string;

const FORMAT = {
	...COMMON_FORMAT,
	BOOL_ICON: "boolean_icon",
	EVENTDATE: "eventDate",
	MODULE_LINK: "moduleLink",
	CHIP: "Chip",
	CHIPLINK: "ChipLink",
	AVATAR: "avatar",
	IMAGE: "image",
	TITLEDIMAGE: "titled_image",
	URL: "url",
	PHONE: "phone",
	GRIDCHIPS: "gridchips",
	TEXT_EXPANDABLE: "text_expandable",
	BULLETIN_MODAL: "BulletinModal",
	HTML_EDITOR: "html editor",
	SHORT_HTML_EDITOR: "shorthtmleditor",
	HTML_LINE_BREAKS: "htmllinebreaks"
};

if (FORMAT) {
	({ EMAIL, PHONE, AVATAR, URL, CHIP, CHIPLINK, MODULE_LINK, BOOL_ICON, IMAGE, GRIDCHIPS, TEXT_EXPANDABLE, TITLEDIMAGE, HTML, HTML_EDITOR, SHORT_HTML_EDITOR, HTML_LINE_BREAKS } = FORMAT);
}

const useStyles = makeStyles(styles);


interface DiffBuilderReturn {
	diff: any;
	diffCount: number;
}

export const diffBuilder = ({ newValue, oldValue }: DiffData): DiffBuilderReturn => {
	const diff: Record<string, { new: any; old: any }> = {};
	let diffCount = 0;
	Object.keys(newValue).forEach(key => {
		if (!isEqual(newValue[key], oldValue[key])) {
			let newValues = newValue[key];
			let oldValues = oldValue[key];
			
			if (key === "Connections" && newValue[key].length) {
				newValues = new Array;
				oldValues = new Array;

				newValue[key].forEach((val: any, i: any) => {
					if (val !== oldValue[key][i]) {
						newValues.push(newValue[key][i]);
						oldValues.push(oldValue[key][i]);
					}
				});
			}
			else if (key === "UDF" && newValue[key].length) {
				newValues = new Array;
				oldValues = new Array;

				newValue[key].forEach((udf: any, i: any) => {
					if (udf.FieldValue !== oldValue[key][i].FieldValue) {
						newValues.push(newValue[key][i]);
						oldValues.push(oldValue[key][i]);
					}
				});
			}
			else if (key === "CustomField" && newValue[key].length) {
				newValues = new Array;
				oldValues = new Array;

				newValue[key].forEach((udf: any, i: any) => {
					const oldValueKey = oldValue && oldValue[key] ? oldValue[key][i] || {} : {};
					const newValueKey = newValue && newValue[key] ? newValue[key][i] || {} : {};

					if (udf.UDFieldDisplayValue !== oldValueKey.UDFieldDisplayValue) {					
						newValueKey.UDFieldDisplayValue = stripHtml(newValueKey.UDFieldDisplayValue);
						oldValueKey.UDFieldDisplayValue = stripHtml(oldValueKey.UDFieldDisplayValue);
						
						newValues.push(newValueKey);
						oldValues.push(oldValueKey);
					}
				});
			}
			else if (key === "Attributes" && newValue[key].length) {
				newValues = new Array;
				oldValues = new Array;

				newValue[key].forEach((attr: any, i: any) => {
					if (attr.AttributeValue !== oldValue[key][i].AttributeValue) {
						newValues.push(newValue[key][i]);
						oldValues.push(oldValue[key][i]);
					}
				});
			}
			else if (key === "Amenities" && newValue[key].length) {
				newValues = new Array;
				oldValues = new Array;

				newValue[key].forEach((amenity: any, i: any) => {
					if (amenity.AmenityValue !== oldValue[key][i].AmenityValue) {
						newValues.push(newValue[key][i]);
						oldValues.push(oldValue[key][i]);
					}
				});
			}

			diff[key] = { new: newValues, old: oldValues };
			diffCount++;
		}
	});

	Object.keys(oldValue).forEach(key => {
		if (!isEqual(newValue[key], oldValue[key]) && !diff[key]) {
			diff[key] = { new: newValue[key], old: oldValue[key] };
			diffCount++;
		}
	});

	return { diff, diffCount };
};

type diffFormats = {
	[key: string]: any;
}

const diffFormatter: diffFormats = {
	rrule: (value: any): any => {
		if (value === null || value === undefined) {
			return value;
		} else if (value.indexOf("RDATE") > -1) {
			const rSplit = value.split(":");
			const [dateStringStart, dateStringEnd]: string[] = rSplit[1].split("/");
			return formatDatetimeCustom(dateStringStart);
		}
		
		try {
			const valueArray = value.split(";");
			valueArray.filter((rrulePart: any) => rrulePart.indexOf("UNTIL=") === 0).map((rrulePart: any) => {
				const origDate = rrulePart.replace("UNTIL=", "");
				const endDate = `${moment(formatClientDate(moment(origDate))).format("YYYYMMDDTHHmmss")}Z`;
				value = value.replace(origDate, endDate);
			})

			return RRule.fromString(value).toText();
		} catch (e) {
			//If we can't format the name, then return nothing.
			return;
		}
		
	},
	email: (value: any): any => {
		return wrapFormatValue(value, EMAIL, EMAIL, {});
	},
	phone: (value: any): any => {
		return wrapFormatValue(value, PHONE);
	},
	avatar: (value: any): any => {
		return wrapFormatValue(value, AVATAR);
	},
	url: (value: any): any => {
		return wrapFormatValue(value, URL);
	},
	chip: (value: any): any => {
		return wrapFormatValue(value, CHIP);
	},
	chipLink: (value: any): any => {
		return wrapFormatValue(value, CHIPLINK);
	},
	moduleLink: (value: any): any => {
		return wrapFormatValue(value, MODULE_LINK);
	},
	boolIcon: (value: any): any => {
		return wrapFormatValue(value, BOOL_ICON);
	},
	date: (value: any): any => {
		return moment(value).format(DATE_FORMAT);
	},
	image: (value: any): any => {
		return wrapFormatValue(value, IMAGE);
	},
	titledImage: (value: any, label: string): any => {
		return wrapFormatValue(value, TITLEDIMAGE, TITLEDIMAGE, { label, position: "relative", constrain: false, height: "100%", width: "50%" });
	},
	gridChips: (value: any): any => {
		return wrapFormatValue(value, GRIDCHIPS);
	},
	textExpandable: (value: any): any => {
		return wrapFormatValue(value, TEXT_EXPANDABLE);
	},
	textfield: (value: any): any => {
		return value;
	},
	// eslint-disable-next-line react/display-name
	multiselect: (value: any): any => {
		if (typeof value === "string") {
			const splitUpVersion = value.split(/,?sv,/).filter(elem => elem);
			return <>
				{
					splitUpVersion.map((elem: string, index: number) => <> { wrapFormatValue({ label: elem, key: `${elem}_${index}` }, CHIP) } </>)
				}
			</>;
		} else if (Array.isArray(value)) {
			const filterVersion = value.filter(elem => elem);
			return <>
				{
					filterVersion.map((elem: string, index: number) => <> { wrapFormatValue({ label: elem, key: `${elem}_${index}` }, CHIP) } </>)
				}
			</>;
		}
		return value;
	},
	"html": (value: any): any => {
		return wrapFormatValue(value, HTML);
	},
	"html editor": (value: any): any => {
		return wrapFormatValue(value, HTML_EDITOR);
	},
	"shorthtmleditor": (value: any): any => {
		return wrapFormatValue(value, SHORT_HTML_EDITOR);
	},
	"htmllinebreaks": (value: any): any => {
		return wrapFormatValue(value, HTML_LINE_BREAKS, HTML, { allowTags: ["br"] } );
	}
};

export const prepareDataForDisplay = (input: any, labels: any, formats: any, key: string): any => {
	let displayLabel = true;
	const classes = useStyles();

	if (input === null || input === undefined) {
		return <Typography />;
	} else if (typeof input === "boolean") {
		return <Typography> {input.toString()} </Typography>;
	} else if (formats && formats[key] && diffFormatter[formats[key]]) {
		return diffFormatter[formats[key]](input);
	} else if (Array.isArray(input)) {
		return <> {
			input.filter(val => val !== null && val !== undefined)
				.map(elem => prepareDataForDisplay(elem, labels, formats, key))
		} </>;
	} else if (input instanceof Object) {
		input.FieldValue = input.FieldValue || input.UDFieldDisplayValue;
		delete input.UDFieldDisplayValue;
		
		if (input.UDFieldName && input.FieldValue) {
			if (input.FieldValue.indexOf("http") === 0) {
				return <Link className={classNames(classes.link, classes.customFieldLink)} href={input.FieldValue} target="_blank">{input.UDFieldName}</Link>;
			}

			try {
				const json = JSON.parse(input.FieldValue)[0];
				if (json.path) {
					const fileName = json.path.substring(json.path.lastIndexOf("/") + 1, json.path.length);
					input.FieldValue = fileName;
				} else if (json.AssetID && json.Title) {
					input.FieldValue = json.Title;
				}
			}
			catch (e) {
				//do nothing
			}
		}

		let imageTitle = "";
		Object.keys(input).forEach(key => {
			if (formats[key] === "titledImage") {
				imageTitle = input["Title"];
			}
		});
		if (imageTitle) {
			delete input.Title;
		}
		return <>
			{
				Object.keys(input).map(val => {
					let preparedValue = null;
					const inputName = (input.UDFieldName || input.AttributeName || input.AmenityName);
					
					if (formats && formats[val]) {
						if (formats[val] === "titledImage") {
							preparedValue = diffFormatter[formats[val]](input[val], imageTitle);
							displayLabel = false;
						} else {
							preparedValue = diffFormatter[formats[val]](input[val]);
						}
					} else if (
						formats && 
						((key === "UDF" && val !== "UDFieldName") ||
						(key === "Attributes" && val !== "AttributeName") ||
						(key === "Amenities" && val !== "AmenityName")) && 
						inputName && 
						formats[inputName] && 
						diffFormatter[formats[inputName]]
					) {
						preparedValue = diffFormatter[formats[inputName]](input[val]);
					}
					return <>
						<div>
							{displayLabel && preparedValue ? `${labels[val] || val}: ` : null}{preparedValue}
							{!preparedValue && prepareDataForDisplay(input[val], labels, formats, key)}
						</div>
					</>;
				})
			}
			<Typography> &nbsp; </Typography>
		</>;
	}
	return <Typography> {input} </Typography>;
};


const Diff: React.FC<DiffModalProps> = ({ data, labels, value }) => {
	const classes = useStyles();
	const defaultLabels = {
		fieldColumn: "Field",
		oldColumn: "Old Value",
		newColumn: "New Value"
	};
	const headerLabels = value?.headerLabels || {};
	const fieldColumn = headerLabels.fieldColumn || defaultLabels.fieldColumn;	
	const oldColumn = headerLabels.oldColumn || defaultLabels.oldColumn;
	const newColumn = headerLabels.newColumn || defaultLabels.newColumn;

	if (!data && value && value.success) {
		data = {
			"newValue": {},
			"oldValue": {}
		};
		data.newValue = value.newValue;
		data.oldValue = value.oldValue;
		labels = JSON.parse(value.labels);
	} else if (!data) {
		return null;
	}
	const { newValue, oldValue } = data;

	const { diff, diffCount } = diffBuilder( { newValue, oldValue });
	
	if (!diffCount) {
		const message = (Object.keys(newValue).length || Object.keys(oldValue).length) 
			? "No difference between versions"
			: "Created";

		return <>
			<Typography align="center">
				{message}
			</Typography>
		</>;
	}
	let formats: any = {};
	let fieldLabels: any = labels;
	if (labels.formats && labels.labels) {
		formats = labels.formats;
		fieldLabels = labels.labels;
	}
	return <>
		<Table className={classes.tableDiff}>
			<TableHead>
				<TableRow>
					<TableCell key="main_field"> {fieldColumn} </TableCell>
					<TableCell key="main_old"> {oldColumn} </TableCell>
					<TableCell key="main_new"> {newColumn} </TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ Object.keys(diff).map(key => <TableRow key={key}>
					<TableCell key={`${key}_field`}> {(fieldLabels && fieldLabels[key]) || key} </TableCell>
					<TableCell key={`${key}_old`} className={classes.dataDisplay}>
						{prepareDataForDisplay(diff[key].old, fieldLabels, formats, key)}
					</TableCell>
					<TableCell key={`${key}_new`} className={classes.dataDisplay}>
						{prepareDataForDisplay(diff[key].new, fieldLabels, formats, key)}
					</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	</>;
};

export default Diff;
