import { createActions } from "reduxsauce";


const { Types, Creators } = createActions({
	setDrawerOpen: ["name", "drawerControlInfo"],
	setDrawerAction: ["name", "tabs", "activeTab"],
	clearElement: ["name"],
	setActiveNav: ["openNav"],
	setAnchorElement: ["anchorElement", "element"],
	setMessage: ["message", "messageType"],
	setDrawerCurrentTab: ["drawer", "tab"]
});

/**
 * TODO: rename it to a more general function name.
 *
 * setDrawerOpen - This action takes in the name of the drawer (usually a global instance in App.js)
 * and an object with the state of that drawer. Currently there are two drawer types with different state structures.
 * All types require an isOpen flag to determine visibility of the element.
 *
 * SNACKBAR_NAME - {
 * 		isOpen: bool,
 * 		message: str,
 * 		messageType: str(error | success | warning | info)
 * }
 * (You can use the setMessage action, alternatively)
 *
 * GLOBAL_TABBED_DRAWER - {
 *     isOpen: bool,
 *     type: str, (usually a form/grid type)
 *     tabs: Array of Object - { name: str, component: React.Component }, (Note: component may be handed in via the displayComponent option, as well)
 *     activeTab: str,
 *     displayComponent: React.Component,
 *     displaySize: str(sm | md | lg | fullscreen | extralg),
 *     id: int (this is a recId)
 * }
 *
 * GLOBAL_ALERT - {
 * 	isOpen: bool
 * 	title: str
 * 	content: str
 * 	actions: [{
 * 		label: str (for display)
 * 		color: str ("primary" | "secondary")
 * 		handler: a function for handling click
 * }]
 * }
 *
 * GLOBAL_SPINNER - {
 * 	isOpen: bool
 * }
 *
 */

export { Creators, Types };
