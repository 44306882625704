import store from "../../store";

const addSignatureHtml = crm => ({ subscriber, dispatchWrapper, getState }) => {
	const { data } = getState();

	if (data.BodyHTML) {
		return;
	}

	const loginState = store.getState().login;

	crm.get({
		type: "myProfile",
		filter: {
			UserID: loginState.userId
		},
		fields: "UserSignature"
	}).then(res => {
		if (res.success && res.count) {
			const heading = "";
			const formChange = res.recs[0].UserSignature ? heading.concat(res.recs[0].UserSignature) : res.recs[0].UserSignature;
			dispatchWrapper("handleFormChange", [subscriber, "String", formChange]);
		}
	});

    
};

export default addSignatureHtml;
