import { extractInfo } from "../form";
import uniqBy from "lodash/uniqBy";

const fetchAccountCommEmailOptions = crm => async info => {
	const { subValue, updateField } = extractInfo(info);
	if (subValue && !Array.isArray(subValue)) {
		const res = await crm.get({
			type: "contact",
			fields: `
			FullName
			Email {
				recs {
					EmailAddress
				}
			}`,
			filter: {
				AccountID: subValue.value,
				AccountRelationshipActive: true
			},
			options: {
				limit: 100
			}
		});
		const options = [];
		for (const contact of res.recs) {
			for (const email of contact.Email.recs) {
				options.push({
					value: email.EmailAddress,
					label: `${contact.FullName} <${email.EmailAddress || "no email"}>`
				});
			}
		}

		// get the account email
		const accountEmailRes = await crm.get({
			type: "account",
			filter: {
				recId: subValue.value
			},
			fields: `
				CompanyName
				Email {
					recs {
						EmailAddress
						IsPrimary
					}
				}
			`
		});

		for (const account of accountEmailRes.recs) {
			for (const email of account.Email.recs) {
				if (email.IsPrimary) {
					options.push({
						value: email.EmailAddress,
						label: `${account.CompanyName} <${email.EmailAddress || "no email"}>`
					});
				}
			}
		}

		updateField({
			options: uniqBy(options, option => option.value)
		});
	} else {
		updateField({
			options: []
		});
	}
}
;

export default fetchAccountCommEmailOptions;
