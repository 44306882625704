import React, { useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@react-ui/components/Fields/TextField";
import DateField from "@react-ui/components/Fields/DateField";
import moment from "moment-timezone";

const GridDrawerForm = props => {
	const { setDrawerState, formFields, formData, locale } = props;
	
	const closeAndApplyForm = useCallback(() => {
		const invalidSubmit = formFields.filter(rec => {
			return rec.validate;
		});

		if (invalidSubmit.length) {
			return;
		}

		formFields.forEach(field => {
			formData[field.name] = field.value;
		});
		setDrawerState({ open: false }); 
	}, [setDrawerState, formFields]);
	
	const onChangeDate = useCallback((val, rec) => {
		if (val && moment.isMoment(val) === false) {
			val = moment(val);
		} 

		if (val && val.isValid() === false) {
			rec.setValidate(true);
			rec.setValidateMsg("Invalid Date Format");
		} else {
			rec.setValidate(false);
			rec.setValidateMsg("");
			if (val) {
				val = moment(val).tz(locale.timeZone).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
			}
		}

		rec.setValue(val);
	}, [formFields]);

	formFields.forEach(field => {
		const [val, set] = useState(formData[field.name]);
		const [validate, setValidate] = useState(false);
		const [validateMsg, setValidateMsg] = useState("");
		field.value = val;
		field.setValue = set;
		field.validate = validate;
		field.setValidate = setValidate;
		field.validateMsg = validateMsg;
		field.setValidateMsg = setValidateMsg;
	});

	const renderField = (rec) => {
		if (rec.hidden) {
			return null;
		} else if (rec.type) {
			switch(rec.type) {
				case "Text":
					return (
						<div key={`${rec.name}_div`} style={{ margin: 14 }}>
							<TextField
								key={`${rec.name}_field`}
								field={{
									label: rec.label,
									placeholder: "",
									readOnly: rec.readOnly
								}}
								multiline={false}
								value={rec.value}
								onChange={(val) => {
									rec.setValue(val);
								}}
								error={null}
								errorMsg={null}
							/>
						</div>
					);
				case "Date":
					return (
						<div key={`${rec.name}_div`} style={{ margin: 14 }}>
							<DateField
								key={`${rec.name}_field`}
								field={{
									label: rec.label,
									placeholder: "",
									readOnly: rec.readOnly,
									name: rec.name
								}}
								value={rec.value}
								onChange={(val) => onChangeDate(val, rec)}
								error={rec.validate}
								errorMsg={rec.validateMsg}
							/>
						</div>
					);
				default: 
					return null;
			}
		}
		return null;
	};

	return (
		<div>
			{(formFields || []).map(rec => {
				return (
					renderField(rec)
				);
			})
			}
			<Button key="update_form_button" onClick={closeAndApplyForm}>Update</Button>
		</div>
	);
};

export default GridDrawerForm;
