import React, { useEffect, useState } from "react";
import { DataView } from "@simpleview/sv-mosaic";

const GridDrawerGrid = ({ gridHook, gridParams, actionMap }) => {

	const [gridInitialized, setGridInitialized] = useState(false);
	const gridProps = gridHook({
		...gridParams,
		callbackMap: actionMap
	});
	
	// use this to determine if the settings have been fetched
	useEffect(() => {
		if (gridProps.filters && gridProps.filters.length && gridInitialized === false) {
			setGridInitialized(true);
		}
	}, [gridProps.filters, gridInitialized]);

	return (gridInitialized ? (
		<DataView
			sticky={true}
			savedViewAllowSharedViewSave={false}
			{...gridProps}
		/>
	) : null);
};

export default GridDrawerGrid;
