import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/recordActions";

export interface RecordState {
	history: {
		[moduleName: string]: Date;
	};
	searchResults: SearchResult[];
}

export type SearchResult = {
	type: string;
	recId: number;
	RecordID: string;
	title: string;
	score: number;
	groups: number[];
}

export const INITIAL_STATE: RecordState = {
	history: {},
	searchResults: []
};

type RecordReducer = {
	[reducerName: string]: LogUpdate | SetSearchResults;
}

type LogUpdate = (state: RecordState, action: LogUpdateAction) => RecordState;
type SetSearchResults = (state: RecordState, action: SetSearchResultsAction) => RecordState;
type SetSearchResultsAction = {type: string; searchResults: SearchResult[]}
type LogUpdateAction = {type: string; moduleName: string};

export const reducer: RecordReducer = {
	logUpdate: (state = INITIAL_STATE, action: LogUpdateAction) => {
		return {
			...state,
			history: {
				...state.history,
				[action.moduleName]: new Date(Date.now())
			}
		};
	},
	setSearchResults: (state = INITIAL_STATE, action: SetSearchResultsAction) => {
		return {
			...state,
			searchResults: action.searchResults
		};
	}
};

export default createRSReducer(Types, INITIAL_STATE, reducer);
