import store from "../../store";
import { Creators } from "../../actions/transientElementActions";
import validateEmail from "@react-ui/common/validateEmail";

const { dispatch } = store;

const validateEmails = crm => ({ fieldName, dispatchWrapper, getState }) => {
	const { data, fields, isWebForm } = getState();
	let emails = data[fieldName] || [];
	let hasInvalidEmails = false;
	emails = emails.filter(email => {
		const valid = validateEmail(email.value);
		if (!valid) {
			hasInvalidEmails = true;
			dispatchWrapper("updateField", [fieldName, { ...fields[fieldName], options: fields[fieldName].options.filter(e => e.value !== email.value) }]);
		}
		return valid;
	});
	if (hasInvalidEmails) {
		if (isWebForm) {
			alert("Invalid Emails not allowed");
		} else {
			dispatch(Creators.setMessage("Invalid Emails not allowed", "error"));
		}
		dispatchWrapper("handleFormChange", [fieldName, "String", emails]);
	}
};

export default validateEmails;
