/**
 * Trim strings and condense adjacent spaces
 */
const trimStrings = obj => {
	if (typeof obj === "string") {
		return obj.trim().replace(/[^\S\r\n]{2,}/g, " ");
	}

	if (obj instanceof Array) {
		for (const key in obj) {
			obj[key] = trimStrings(obj[key]);
		}
		return obj;
	}

	if (obj instanceof Object) {
		for (const [key, value] of Object.entries(obj)) {
			if (!(value instanceof File)) {
				obj[key] = trimStrings(value);
			}
		}
		return obj;
	}
	return obj;
};

export default trimStrings;
