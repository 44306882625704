import { CrmFieldSubscriptionCallback } from "../typeDef";

const visitorSaveAndSend: CrmFieldSubscriptionCallback = crm => ({ getState, dispatchWrapper, subscriber }): void => {
	const state = getState();
	if (state.data.ReferralTypeID?.value > 0 && state.data.ListingList.length > 0) {
		dispatchWrapper("updateAction", [{
			name: subscriber
		}, {
			hidden: false
		}]);
	} else {
		dispatchWrapper("updateAction", [{
			name: subscriber
		}, {
			hidden: true
		}]);
	}
};

export default visitorSaveAndSend;
