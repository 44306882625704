import { CrmFieldSubscriptionCallback } from "../typeDef";

const modifiers: Record<string, any> = {
	"!": (value: any): boolean => !value,
	"!!": (value: any): boolean => !!value
};

const compares: Record<string, any> = {
	">=": (value: any, arg: any): boolean => value >= arg,
	">": (value: any, arg: any): boolean => value > arg,
	"<": (value: any, arg: any): boolean => value = arg,
	"<=": (value: any, arg: any): boolean => value <= arg,
	"==": (value: any, arg: any): boolean => value == arg,
	"===": (value: any, arg: any): boolean => value === arg,
	"!=": (value: any, arg: any): boolean => value != arg,
	"!==": (value: any, arg: any): boolean => value !== arg,
	"includes": (value: any, arg: any): boolean => arg.includes(value),
	"!includes": (value: any, arg: any): boolean => !arg.includes(value)
};

const setActionPropsWithValue: CrmFieldSubscriptionCallback = crm => ({ getState, dispatchWrapper, subscriber, staticArgs, fieldName }): void => {
	const [attrName, operator, compValue, valueToSet, elseValueToSet] = staticArgs;
	const update = (value: any): void => {
		dispatchWrapper("updateAction", [{
			name: subscriber
		}, {
			[attrName]: value
		}]);
	};
	const state = getState();
	const value = state.data[fieldName];

	if (elseValueToSet !== undefined) {
		update(compares[operator](value, compValue) ? valueToSet: elseValueToSet);
	} else if (valueToSet !== undefined) {
		if (compares[operator](value, compValue)) {
			update(valueToSet);
		}
	} else if (compValue !== undefined) {
		let parsedCompValue = compValue;
		try {
			parsedCompValue = JSON.parse(compValue);
		} catch (e) {
			//JSON is already parsed
		}
		update(compares[operator](value, parsedCompValue));
	} else if (operator !== undefined) {
		if (operator in modifiers) {
			update(modifiers[operator](value));
		} else {
			update(operator);
		}
	} else {
		update(value);
	}
};

export default setActionPropsWithValue;
