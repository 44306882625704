import { extractInfo } from "../form";
import { CrmFieldSubscriptionCallback, FormSelectOption } from "./typeDef";
import { Creators } from "../../actions/transientElementActions";
import store from "../../store";
const { dispatch } = store;

const commDedupEmails: CrmFieldSubscriptionCallback = () => (info): void => {
	const { staticArgs, data, ownValue, isWebForm, updateValue, ownFieldName } = extractInfo(info);
	let emails: string[] = [];
	staticArgs.filter(name => name !== ownFieldName).forEach((field: string) => {
		if (Array.isArray(data[field])) {
			emails = emails.concat((data[field] as FormSelectOption[]).map(option => option.value));
		} else if (typeof data[field] === "string") {
			emails.push(data[field] as string);
		}
	});

	const dedupedValue: FormSelectOption[] = [];
	if (Array.isArray(ownValue)) {
		(ownValue as FormSelectOption[]).forEach(selectedOption => {
			if (emails.includes(selectedOption.value)) {
				if (isWebForm) {
					alert("Duplicate Email is removed");
				} else {
					dispatch(Creators.setMessage("Duplicate Email is removed", "error"));
				}
			} else {
				dedupedValue.push(selectedOption);
				emails.push(selectedOption.value);
			}
		});
		if (dedupedValue.length < ownValue.length) {
			updateValue(dedupedValue);
		}
	}
};

export default commDedupEmails;
