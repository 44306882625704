import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const ViewOnly = ({ label, value, rawOutput = false, field, classes }) => {
	let defaultString;
	let fieldLabel;
	if (field) {
		defaultString = field.defaultString;
		fieldLabel = field.label;
	}
	const displayLabel = label || fieldLabel;
	return <div>
		<Typography style={{ fontWeight: "bold" }}>
			{displayLabel}{(displayLabel && displayLabel.length) ? ":" : ""}
		</Typography>
		{renderOutput({ value, defaultString, rawOutput, classes })}
	</div>;
};

const renderOutput = ({ value, defaultString, rawOutput, classes }) => {
	if (defaultString) {
		return <div className={classes.htmlContainer} dangerouslySetInnerHTML={{ __html: defaultString }} />;
	} else if (rawOutput) {
		// used for select types (chips, checkboxes, radio), which handle their own formatting
		return value;
	} else {
		return <Typography> {value} </Typography>;
	}
};

export default React.memo(withStyles(styles)(ViewOnly));
