import { extractInfo } from "../form";

const setCharacterCount = crm => info => {
	const { updateField } = extractInfo(info);
	const { data } = info.getState();
	updateField({
		characterLimit: 0
	});

	let value = data[info.fieldName];

	if (value?.value) {
		value = value.value;
	}	

	if (value) {
		crm.get({
			type: "listingType",
			filter: {
				recId: value
			},
			fields: `
				DescriptionCnt
			`
		}).then(res => {
			if (res?.recs?.length > 0) {
				const characterLimit = res.recs[0].DescriptionCnt || 0;
				updateField({
					characterLimit
				});
			}		
		});
	}
};

export default setCharacterCount;
