const styles = theme => ({
	circularProgressCentered: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%"
	}
});

export default styles;
