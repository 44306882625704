const getFieldInfo = ({ subscriber, compField, compIndex, data }) => {
	const fieldCheck = {};
	fieldCheck.nameSplit = subscriber.split(/^\$comp_.*_/);
	fieldCheck.name = fieldCheck.nameSplit.length > 1 ? fieldCheck.nameSplit[1] : fieldCheck.nameSplit[0];
	fieldCheck.value = compField && Number.isInteger(compIndex) ? 
		data[compField].compositeFields[compIndex][fieldCheck.name]?.value ||
		data[compField].compositeFields[compIndex][fieldCheck.name] : data[fieldCheck.name];
	fieldCheck.value = fieldCheck.value || "";
	return fieldCheck;
};

export default getFieldInfo;
