import { extractInfo } from "../form";

const map = {
	account: "CompanyName",
	contact: "FullName",
	eventCalendar: "Title",
	inquiry: "InquiryLabel",
	listing: "Title",
	offer: "SpecialOfferTitle"
};

const fetchReferenceOptions = crm => async info => {
	const { data, subValue, updateField, updateEntireCompositeField, updateValue, recId, subCompIndex } = extractInfo(info);
	const { eventName } = info;

	if (!subValue) {
		updateField({
			hidden: true
		});	
	} else {
		updateField({ hidden: false });
		await crm.get({
			type: subValue,
			fields: `recId ${map[subValue]}`,
		}).then(res => {
			if (res.success) {
				const options = res.recs.map(rec => {
					const label = rec[map[subValue]];
					return {
						label,
						value: rec["recId"]
					};
				});

				if (data?.Reference?.compositeFields[subCompIndex]?.ID) {
					const dataIndex = options.findIndex(option => option.value === data.Reference.compositeFields[subCompIndex].ID.value);
					if (dataIndex > -1 && !data.Reference.compositeFields[subCompIndex].ID.label) {
						updateValue(options[dataIndex]);
					} else if (dataIndex === -1) {
						updateValue(null);
					}
				}

				updateField({ options });
			} else if (data?.Reference?.compositeFields[subCompIndex]?.ID?.label?.length) {
				updateEntireCompositeField({ readOnly: true });
			} else {
				updateField({ options: [] });
			}
		});
	}
};

export default fetchReferenceOptions;
