import { resettableReducer } from "reduxsauce";
import createRSReducer from "../../common/createRSReducer";
import fieldNameToDataKey from "../../common/fieldNameToDataKey";
import { Types } from "./actions";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";

const INITIAL_STATE = {
	title: null,
	data: {},
	actions: [],
	fields: {},
	fieldRefs: [],
	validation: {},
	subscriptions: {},
	callbacks: {},
	defaultTitle: null,
	formType: "",
	recId: 0,
	submit: false,
	CustUploadAdapter: {},
	server: null,
	damClient: null,
	baseUrl: null,
	acctId: null,
	isWebForm: false,
	gridHook: null,
	localeSettings: {},
	dialogOpen: false,
	dialogText: "",
	dialogTitle: "",
	dialogConfirmFunction: null,
	callbackQueue: [],
	fieldSize: "normal"
};

const reducer = {};

reducer.initForm = (state = INITIAL_STATE, action) => {
	const input = action.state;
	return {
		...state,
		title: input.title,
		actions: input.actions,
		data: { ...input.data } || {},
		fields: { ...input.fields } || {},
		subscriptions: input.subscriptions || {},
		callbacks: input.callbacks || {},
		customComponents: input.customComponents || {},
		defaultTitle: input.defaultTitle,
		formType: input.formType,
		validation: {},
		fieldRefs: [],
		recId: input.recId,
		submit: false,
		refId: input.refId,
		canSubmit: true,
		CustUploadAdapter: input.CustUploadAdapter,
		server: input.server,
		damClient: input.damClient,
		baseUrl: input.baseUrl,
		acctId: input.acctId,
		isWebForm: input.isWebForm,
		gridHook: input.gridHook,
		localeSettings: input.localeSettings,
		layout: input.layout,
		viewOnly: input.viewOnly,
		callbackQueue: {},
		fieldSize: input.fieldSize
	};
};

reducer.enableSubmit = (state = INITIAL_STATE, action) => {
	return {
		...state,
		canSubmit: action.value === undefined ? true : action.value
	};
};

reducer.flagSubmit = (state = INITIAL_STATE, overwrite = true) => {
	return {
		...state,
		submit: overwrite
	};
};

reducer.updateAction = (state = INITIAL_STATE, action) => {
	const { condition, update } = action;
	const actions = state.actions.map(action => {
		for (const key in condition) {
			if (action[key] !== condition[key]) {
				return action;
			}
		}
		action = {
			...action,
			...update
		};
		return action;
	});
	return {
		...state,
		actions
	};
};

reducer.resetForm = (state = INITIAL_STATE, action) => {
	return { ...INITIAL_STATE };
};

reducer.resetData = (state = INITIAL_STATE, action) => {
	return {
		...state,
		data: {}
	};
};

reducer.setValidation = (state = INITIAL_STATE, action) => {
	const { fieldName, compField, compIndex, callback, message = null } = action;
	const validation = cloneDeep(state.validation);
	const fieldDataKey = fieldNameToDataKey({ fieldName, compField, compIndex });

	if (fieldDataKey && !validation[fieldDataKey]) {
		validation[fieldDataKey] = {};
	}

	validation[fieldDataKey] = {
		...validation[fieldDataKey],
		[callback]: message || false
	};

	//for nested fields in HoC
	if (compField && compIndex === undefined) {
		const emptySubFields = state?.emptySubFields || {};
		emptySubFields[fieldName] = compField;
		return { ...state, validation, emptySubFields };
	}
	

	return { ...state, validation };
};

reducer.clearValidation = (state = INITIAL_STATE, action) => {
	const { fieldName, compField, compIndex, callback } = action;
	const validation = cloneDeep(state.validation);
	const fieldDataKey = fieldNameToDataKey({ fieldName, compField, compIndex });
	if (validation[fieldDataKey]) {
		delete validation[fieldDataKey][callback];
		if (Object.keys(validation[fieldDataKey]).length === 0) {
			delete validation[fieldDataKey];
		}
	}
	return { ...state, validation };
};

const numberTypes = ["Int", "Float"];
const toNumber = value => {
	if (!value) {
		return value;
	} else {
		return Number(value);
	}
};

function handleCompositeChange(data, fields, action) {
	if (data[action.compositeField] === undefined) {
		return {
			data,
			fields
		};
	}

	if (data[action.compositeField].compositeFields[action.index] === undefined) {
		data[action.compositeField].compositeFields[action.index] = {};
	}

	/**
	 * 1. if there's an old value and the new value is empty
	 * 2. check if the other values for the row are also empty
	 * 3. if they're all empty clear row
	 */
	const oldValue = data[action.compositeField].compositeFields[action.index][action.field];
	let newValue;

	if (Array.isArray(action.value)) {
		newValue =
			numberTypes.includes(action.dataType) && !isNaN(action.value) ? action.value.map(val => toNumber(val)) : action.value;
	} else {
		newValue =
			numberTypes.includes(action.dataType) && !isNaN(action.value) ? toNumber(action.value) : action.value;
	}

	data[action.compositeField].compositeFields[action.index][action.field] = newValue;

	if (isEmpty(oldValue) === false && isEmpty(newValue) === true) {
		// check if all visible fields are empty
		const compositeKeys = Object.keys(fields).filter(field => field.indexOf(`$comp_${action.compositeField}`) > -1);
		// let clearField = false;
		const testFields = {};
		// let equal = true;
		for (let i = 0; i < compositeKeys.length; i++) {
			const name = compositeKeys[i];
			const field = fields[name];
			const compField = name.split("_")[2];
			const value = data[action.compositeField].compositeFields[action.index][compField];

			if (field.hidden !== true) {
				testFields[name] = 1;
				if (isEmpty(value) === true) {
					if (testFields[name] === undefined) {
						testFields[name] = 1;
					} else {
						testFields[name]++;
					}
				}
			}
		}

		const toDelete = Object.values(testFields).reduce((prev, value) => {
			if (prev !== undefined && prev === value) {
				return value;
			} else {
				return false;
			}
		});

		if (toDelete !== false) {
			({ data, fields } = reducer.removeField(
				{ data, fields },
				{ name: action.compositeField, index: action.index }
			));
			if (data[action.compositeField].compositeFields.length === 0) {
				({ data, fields } = reducer.addField(
					{ data, fields },
					{ name: action.compositeField }
				));
			}
		}
	}

	return {
		data,
		fields
	};
}

reducer.handleFormChangeSuccess = (state = INITIAL_STATE, action) => {
	let data = { ...state.data };
	let fields = { ...state.fields };

	if (action.compositeField) {
		({ data, fields } = handleCompositeChange(data, fields, action));
	} else {
		if (Array.isArray(action.value)) {
			data[action.field] = numberTypes.includes(action.dataType) && !isNaN(action.value) ? action.value.map(val => toNumber(val)) : action.value;
		} else {
			data[action.field] = numberTypes.includes(action.dataType) && !isNaN(action.value) ? toNumber(action.value) : action.value;
		}
	}

	return {
		...state,
		data,
		fields
	};
};

reducer.updateField = (state = INITIAL_STATE, action) => {
	const { name, update } = action;
	const { fields, ...rest } = state;

	return {
		...rest,
		fields: {
			...fields,
			[name]: {
				...fields[name],
				...update
			}
		}
	};
};

reducer.addField = (state = INITIAL_STATE, action) => {
	const newField = {};
	const data = { ...state.data };
	const fields = { ...state.fields };
	const { name: fieldName } = action;
	const compositeFields = data[fieldName].compositeFields;
	const ensureArray = value => {
		if (!Array.isArray(value)) {
			return [value];
		}

		return value;
	};

	const values = ensureArray(compositeFields);

	fields[fieldName].compositeFields.forEach(compFieldName => {
		let newValue = "";
		const compFieldFullName = `$comp_${fieldName}_${compFieldName}`;
		const compField = fields[compFieldFullName];
		if (compField.useInitialValue && (compField.initValue || values.length)) {
			newValue = compField.initValue || values[0][compFieldName];
		} else if (compField.defaultBoolean) {
			newValue = compField.defaultBoolean;
		}
		newField[compFieldName] = newValue;
		fields[compFieldFullName].index.push({ ...compField.default });
	});
	data[fieldName] = {
		compositeFields: [...compositeFields, newField]
	};

	return {
		...state,
		data,
		fields
	};
};

reducer.removeField = (state = INITIAL_STATE, action) => {
	const { data } = state;
	const compositeFields = [...data[action.name].compositeFields];
	const fields = { ...state.fields };
	fields[action.name].compositeFields.forEach(fieldName => {
		const fullFieldName = `$comp_${action.name}_${fieldName}`;
		const index = [...fields[fullFieldName].index];
		fields[fullFieldName].index = [...index.slice(0, action.index), ...index.slice(action.index + 1)];
	});
	return {
		...state,
		data: {
			...data,
			[action.name]: {
				compositeFields: [...compositeFields.slice(0, action.index), ...compositeFields.slice(action.index + 1)]
			}
		},
		fields
	};
};

reducer.addOptionsToField = (state = INITIAL_STATE, action) => {
	const fields = { ...state.fields };
	const currentOptions = fields[action.name].options || [];
	let newOptions = currentOptions.concat(action.options);

	if (currentOptions.length > 0) {
		newOptions = uniqBy(newOptions, "value");
	}

	return {
		...state,
		fields: {
			...state.fields,
			[action.name]: {
				...state.fields[action.name],
				options: action.options // newOptions
			}
		}
	};
};

reducer.setFieldRef = (state = INITIAL_STATE, action) => {
	const fieldRefs = [...state.fieldRefs];
	const { fieldDataKey, ref } = action;
	fieldRefs.push({ fieldDataKey, ref });
	return {
		...state,
		fieldRefs
	};
};

reducer.setDialog = (state = INITIAL_STATE, action) => ({
	...state,
	dialogOpen: action.dialogOpen,
	dialogTitle: action.dialogTitle,
	dialogText: action.dialogText,
	dialogConfirmFunction: action.dialogConfirmFunction
});

reducer.updateSection = (state = INITIAL_STATE, action) => {
	const sections = state.layout.sections.map(section => {
		if (section.name === action.name) {
			return {
				...section,
				...action.update
			};
		}
		return section;
	});
	
	return {
		...state,
		layout: {
			...state.layout,
			sections
		}
	};
};

reducer.addToCallbackQueue = (state = INITIAL_STATE, action) => {
	const { debounceKey } = action;
	return {
		...state,
		callbackQueue: {
			...state.callbackQueue,
			[debounceKey]: true
		}
	};
};

reducer.removeFromCallbackQueue = (state = INITIAL_STATE, action) => {
	const { debounceKey } = action;
	const callbackQueue = { ...state.callbackQueue };
	delete callbackQueue[debounceKey];
	return {
		...state,
		callbackQueue
	};
};

/**
 * This should be deprecated and replaced by addOptionsToField
 */
reducer.handleNewOption = (state = INITIAL_STATE, action) => {
	const { fieldName, option } = action;

	const data = { ...state.data };
	data[fieldName] = state.data[fieldName] ? state.data[fieldName].concat({ value: option.value, label: option.label }) : [{ value: option.value, label: option.label }];

	const fields = { ...state.fields };
	fields[fieldName].selectedOptions = state.fields[fieldName].selectedOptions ? state.fields[fieldName].selectedOptions.concat(option) : [option];
	fields[fieldName].options = state.fields[fieldName].options ? state.fields[fieldName].options.concat(fields[fieldName].selectedOptions) : fields[fieldName].selectedOptions;

	return {
		...state,
		data,
		fields
	};
};

const resettable = resettableReducer("RESET");

export default resettable(createRSReducer(Types, INITIAL_STATE, reducer));
