import isEmpty from "../isEmpty";

const optionLoader = crm => async ({ search, page, module, column, isActiveOnly = false, boundArgs, dynamicDefaultIsRefId, permissionCheck = false }) => {
	const split = module.split(".");
	const moduleName = split.length > 1 ? split[1] : module;
	const type = `${moduleName}_options`;
	let escapedSearch = search.trim().replace(/["\\]/g, function(match) {
		return "\\".concat(match);
	});

	let recID;
	let label = "";
	if (boundArgs && boundArgs.value) {
		if (boundArgs.value.length) {
			recID = boundArgs.value[0].value;
			label = boundArgs.value[0].label;
		} else if (boundArgs.value.value) {
			recID = boundArgs.value.value;
			label = boundArgs.value.label;
		}
	}	

	if (column === "TemplateID" && ["contact.comm", "account.comm"].includes(module) && recID) {
		escapedSearch = label.indexOf("Referral") > -1 ? "referral" : "contact";
	}

	const res = await crm.get_old({
		type,
		queryString: `
			${type}(
				options: {
					limit: 25,
					page: ${page}
				},
				column: "${column}",
				search: "${escapedSearch}",
				isActiveOnly: ${isActiveOnly}
				${(dynamicDefaultIsRefId === module && !isEmpty(recID)) ? `recId: ${recID}` : ""}
			) {
				recs {
					label
					value
					valueString
					sortOrder
					${ permissionCheck ? "hasPermissions" : ""}
				}
				success
				message
				count
			}
		`
	});

	const hasNoMoreColumns = ["AuthRoles", "ExtranetPermissions"];
	const hasMore = hasNoMoreColumns.includes(column) ? false : Math.ceil(res.count / 25) > page;
	page++;

	return {
		options: res.recs.map(option => {
			const toReturn = {
				label: option.label,
				value: option.valueString || option.value,
				sortOrder: option.sortOrder
			};

			if (permissionCheck) {
				toReturn.hasPermissions = option.hasPermissions;
			}
			return toReturn;
		}),
		hasMore,
		additional: {
			page
		}
	};
};


export default optionLoader;
