import { createActions } from "reduxsauce";
import { MYPROFILE, ACCOUNT, SUMMARY } from "../common/consts";
import { setRecentlyViewed } from "../common/recentlyViewed";
import { Creators as TransCreators } from "./transientElementActions";

const { Types, Creators } = createActions({
	summaryFetchStart: null,
	summaryReset: null,
	summaryFetchSuccess: ["data", "definition"],
	summaryFetchErr: ["err", "redirect"],
	clearSummaryErr: null,
	tabsFetchStart: null,
	tabsFetchSuccess: ["tabs"],
	setTabsSuccess: ["tabs"],
	setLoadingAmenities: null,
	setLoadingBenefitSummary: null,
	fetchAmenitiesSuccess: ["amenities"],
	fetchBenefitSummarySuccess: ["benefitSummary"],
	setLoadingMeetingFacilities: null,
	fetchMeetingFacilitiesSuccess: ["meetingFacilities", "formDef"],
	setHasSummary: ["modules"]
});

Creators.summaryFetch = (type, id) => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	dispatch(Creators.summaryFetchStart());
	const typeArray = type.split(".");
	const summaryType = typeArray.length === 1 ? type : typeArray[1];

	// if id isn't a number we don't even need to send it to graph
	if (id === undefined && ![MYPROFILE].includes(type)) {
		dispatch(Creators.summaryFetchErr("invalid id", true));
		return;
	}

	crmGraphServer.general.get_old({
		type: `${summaryType}_summary`,
		queryString: `
			${summaryType}_summary {
				sections {
					subtitle
					defaultExpanded
					fields {
						...fields
						compositeField {
							...composites
						}
					}
				}
				additionalQueryFields {
					fieldNames
				}
				success
				message
			}`,
		fragments: `
			fragment fields on crm_summaryField {
				name
				label
				map {
					label
					value
				}
				type
				valuePrefix
				valueSuffix
				wrapper
				wrapperId
				module
				groupData
				dynamicString {
					fields
					delimiter
					index
					last
				}
				link
			}
			fragment composites on crm_summaryCompositeField {
				rows {
					columns {
						fields {
							...fields
						}
					}
				}
			}`
	})
		.then(res => {
			if (res.success === false) {
				dispatch(Creators.summaryFetchErr(res.message, true));
				return;
			}
			let fields = [];
			res.sections.forEach(section => {
				section.fields.forEach(field => {
					if (field.map) {
						fields.push(`${field.name} { recs { ${field.map.label} ${field.map.value} } }`);
					} else if (field.compositeField) {
						// TODO: we can get rid of the rows and columns for summary
						field.compositeField.rows.forEach(row => {
							row.columns.forEach(column => {
								column.fields.forEach(comp => {
									if (comp.name) {
										fields.push(comp.name);
									}
								});
							});
						});
					} else if (field.dynamicString) {
						fields = fields.concat(field.dynamicString.fields);
					} else {
						if (field.name) {
							fields.push(field.name);
						}
					}
				});
			});

			if (res.additionalQueryFields && res.additionalQueryFields.fieldNames) {
				fields = fields.concat(res.additionalQueryFields.fieldNames);
			}

			const filter = {};
			if (type === MYPROFILE) {
				filter.isContextUser = true;
			}

			fields = ["recId", `RecInfo Tasks { 
				count 
				openTasks 
				recs { 
					TaskDesc
					TaskType
					Title
					Priority
					DueDate
					CompleteDt 
				} }`, ...fields];

			const stringPattern = /[a-z]+/i;
			const hasRecordID = stringPattern.test(id);

			if (type === ACCOUNT) {
				fields.push(`RelatedAccount {
					recs {
					  AccountName
					  AccountRelationshipType
					}
				  }`);
			};

			if (hasRecordID && type !== MYPROFILE) {
				filter.RecordID = id;
				fields.push("RecordID", `Notes { 
					count 
					recs {
						NoteTitle
						NoteText
					} }`);
			} else {
				filter.recId = id ? Number(id) : undefined;
			}

			crmGraphServer.general.get({
				type: summaryType,
				fields: fields.join(" "),
				filter
			}).then(res2 => {
				if (res2.success === false) {
					dispatch(Creators.summaryFetchErr(`There was an issue fetching the ${summaryType}`, true));
				} else if (res2.count === 0) {
					dispatch(Creators.summaryFetchErr(`You either do not have permission to this record, or the record does not exist. 
					For more information, please contact your Simpleview CRM Apex administrator.`, true));
				} else {
					const record = res2.recs[0];
					const id = hasRecordID ? record.RecordID : record.recId.toString();

					if (id !== undefined) {
						setRecentlyViewed({
							crmGraphServer,
							type,
							record
						});
					}

					dispatch(Creators.summaryFetchSuccess(record, res));
				}
			});
		});
};

Creators.deleteRecord = (recType, recId) => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.delete_v2({
		recType,
		recId
	});
};

Creators.setTabs = tabs => (dispatch, getState) => {
	const { grid: { gridType }, login: { crmGraphServer } } = getState();
	const [moduleName] = gridType.split(".");

	if (tabs[moduleName]) {
		crmGraphServer.general.set({
			type: "user_settings",
			input: {
				ModuleNm: moduleName,
				ModuleSettingNm: moduleName,
				ModuleSettingGroupNm: SUMMARY,
				ModuleSettingValue: JSON.stringify(tabs[moduleName])
			}
		}).then(res => {
			if (res) {
				dispatch(Creators.setTabsSuccess(tabs));
				dispatch(TransCreators.setMessage("New tab ordering saved successfully.", "success"));
			}
		});
	} else {
		dispatch(TransCreators.setMessage("No changes made. Your tabs have not been reordered.", "info"));
	}
};

export { Creators, Types };
