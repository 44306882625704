/* eslint-disable no-extend-native */
// get the script src so we can use it to set __webpack_public_path__
const url = new URL(document.currentScript.src);
// eslint-disable-next-line no-undef
__webpack_public_path__ = (url.origin + __webpack_public_path__);

import React from "react";
import ReactDOM from "react-dom";
import EmbeddedForm from "./components/EmbeddedForm";

if (module.hot) {
	module.hot.accept();
}

document.querySelectorAll(".sv-dms-form").forEach(domContainer => {
	const { form, acct, primaryColor, secondaryColor } = domContainer.dataset;
	ReactDOM.render(
		<EmbeddedForm style={{ width: "100%", height: "100%" }} acct_id={acct} form_id={form} primaryColor={primaryColor} secondaryColor={secondaryColor} />,
		domContainer
	);
	
});
