import createRSReducer from "../common/createRSReducer";
import { Types } from "../actions/headerActions";

const INITIAL_STATE = {
	alerts: {
		updates: [],
		tips: []
	},
	alertsOpen: false,
	alertsFetching: false,
	alertsFullscreen: false,
	alertsType: "all",
	bookmarks: [],
	tenantSettings: {
		timeZone: null
	},
	clientMoment: null,
	recentlyViewed: [],
	activeBookmarkRVTab: "recentlyViewed",
	leftNavLinks: [],
	leftNavFetching: false,
	LeftNav: "full",
	sections: {
		account: {
			name: "Accounts",
			isOpen: true
		},
		lead: {
			name: "Leads",
			isOpen: true
		},
		contact: {
			name: "Contacts",
			isOpen: true
		},
		profile: {
			name: "Profiles",
			isOpen: true
		},
		request: {
			name: "Service Requests",
			isOpen: true
		},
		profileSettings: {}
	},
	kbLinks: {}
};

const reducer = {};

reducer.fetchTenantSettingsSuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		tenantSettings: action.settings
	};
};

reducer.generalSettingsSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	...action.settings
});

reducer.updateGeneralSettingSuccess = (state = INITIAL_STATE, action) => {
	return {
		...state,
		[action.name]: action.value
	};
};

reducer.alertsFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	alertsFetching: true
});

reducer.alertsFetchSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	alerts: action.alerts,
	alertsFetching: false
});

reducer.alertsFullscreenOn = (state = INITIAL_STATE, action) => ({
	...state,
	alertsFullscreen: true
});

reducer.alertsFullscreenOff = (state = INITIAL_STATE, action) => ({
	...state,
	alertsFullscreen: false
});

reducer.alertsHide = (state = INITIAL_STATE, action) => ({
	...state,
	alertsOpen: false
});

reducer.alertsShow = (state = INITIAL_STATE, action) => ({
	...state,
	alertsOpen: true
});

reducer.alertsTypeSet = (state = INITIAL_STATE, action) => ({
	...state,
	alertsType: action.alertType
});

reducer.leftNavFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	leftNavFetching: true
});

reducer.leftNavFetchSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	leftNavLinks: action.leftNavLinks,
	leftNavFetching: false
});

reducer.bookmarksFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	bookmarksFetching: true
});

reducer.bookmarksFetchSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	bookmarks: action.bookmarks,
	bookmarksFetching: false
});

reducer.setBookmarks = (state = INITIAL_STATE, action) => ({
	...state,
	bookmarks: { ...action.bookmarks },
	bookmarksFetching: false
});

reducer.setActiveBookmarkRVTab = (state = INITIAL_STATE, action) => ({
	...state,
	activeBookmarkRVTab: action.tab
});

reducer.recentlyViewedFetchStart = (state = INITIAL_STATE, action) => ({
	...state,
	recentlyViewedFetching: true
});

reducer.recentlyViewedFetchSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	recentlyViewed: action.recentlyViewed,
	recentlyViewedFetching: false
});

reducer.fetchProfileSettingSuccess = (state = INITIAL_STATE, action) => ({
	...state,
	profileSettings: { ...action.settings }
});

reducer.toggleSection = (state = INITIAL_STATE, action) => ({
	...state,
	sections: {
		...state.sections,
		[action.section]: {
			...state.sections[action.section],
			isOpen: !state.sections[action.section].isOpen
		}
	}
});

reducer.fetchAddNewOptionsSuccess = (state = INITIAL_STATE, action) => {
	return {
		// TODO: get rid of approval form here and move it to grid action
		...state,
		addNewOptions: action.options
	};
};

reducer.kbLinksFetchSuccess = (state = INITIAL_STATE, action) => { 
	return {
		...state,
		kbLinks: action.kbLinks
	};
};

export default createRSReducer(Types, INITIAL_STATE, reducer);
