import Axios from "axios";
import { extractInfo } from "../form";
import store from "../../store";
import { Creators } from "../../actions/transientElementActions";

// in MB
const MAX_ASSET_BULK_SIZE = 10;
const MAX_ASSET_SIZE = 8;

// TODO: this can be waaaaay simplified
const uploadAssets = crm => async info => {
	const { acctId, baseUrl, damClient, formType } = info.getState();
	const extensionsToExclude = info.extensionsToExclude || [];
	const { isWebForm, ownField, updateField, updateValue } = extractInfo(info);
	let totalFileSize = ownField.totalFileSize ? ownField.totalFileSize : 0;
	
	const updateDisabled = (disabled) => {
		updateField({
			disabled
		});

		info.dispatchWrapper("enableSubmit", [!disabled]);
	};

	updateDisabled(true);

	const showErrorMessage = (msg) => {
		if (isWebForm) {
			alert(msg);
		} else {
			store.dispatch(Creators.setMessage(msg, "error"));
		}
	};
	const removed = [];
	const updateFiles = [...ownField.files];
	const done = [];

	const cleanupField = () => {
		const updateFieldWith = updateFiles.filter((f, i) => {
			return !removed.includes(i);
		});
		updateValue([...updateFieldWith]);
		updateField({
			files: updateFieldWith
		});
	};

	const barrier = (i) => {
		done.push(i);
		if (done.length === ownField.files.length) {
			updateField({
				totalFileSize
			});
			updateDisabled(false);
		}
	};
	let i = 0;
	for (const file of updateFiles) {
		if (!file.damId && !file.uploading) {
			const fileExtension = file.name.split(".")[1] ? file.name.split(".")[1].toLowerCase() : undefined;
			
			if (extensionsToExclude.includes(fileExtension)) {
				showErrorMessage(`${fileExtension.toUpperCase()} files can not be uploaded.`);
				removed.push(i);
				cleanupField();
				barrier(i);
			} else if ("size" in file && file.size >= MAX_ASSET_SIZE * 1000 * 1000) {
				showErrorMessage(`File size must be less than ${MAX_ASSET_SIZE}MB.`);
				removed.push(i);
				cleanupField();
				barrier(i);
			} else if ("size" in file && totalFileSize + file.size >= MAX_ASSET_BULK_SIZE * 1000 * 1000) {
				showErrorMessage(`Total size of all files must be less than ${MAX_ASSET_BULK_SIZE}MB.`);
				removed.push(i);
				cleanupField();
				barrier(i);
			} else {
				updateField({
					files: updateFiles.map((f, j) => {
						if (i === j) {
							f.uploading = true;
						}
						updateField[i] = f;
						return f;
					})
				});
				const fileObject = await damClient.convertFileToFileObject({ file });
				const { data: upsertResult } = await Axios.post(baseUrl + "dam-graphql/", {
					fileObject,
					acct_id: acctId,
					private: ownField.isPrivate
				}).catch((error) => {
					showErrorMessage(`There was an issue uploading the file. If the Issue persists, please contact your administrator.`);
					return { upsertResult: { success: false } };
				});

				if (upsertResult && upsertResult.success) {
					totalFileSize+= file.size;
					const updates = updateFiles.map((f, j) => {
						if (i === j) {
							f = { name: file.name, MediaID: file.MediaID, size: file.size };
							f.damId = upsertResult.doc.id;
							f.url = upsertResult.doc.asset_url;
							f.uploading = false;
							f.AccountSortOrder = 0;
							updateFiles[i] = f;
						}
						return f;
					}).filter((f, k) => {
						return !removed.includes(k);
					});
					updateValue([...updates.filter(f => !f.toBeDeleted).map(f => { 
						delete f.original; 
						return f;
					})]);

					if (updates.length) {
						updates.sort((a, b) => {
							const aSort = a.AccountSortOrder || a.SortOrder || 0;
							const bSort = b.AccountSortOrder || b.SortOrder || 0;
				
							if (aSort < bSort) {
								return -1;
							}
							return 1;
						});
					}

					updateField({
						files: updates
					});
					barrier(i);
				} else {
					removed.push(i);
					cleanupField();
					showErrorMessage(upsertResult.message);
					barrier(i);
				}
			}
		} else if (file.damId && file.toBeDeleted && !file.original) {
			removed.push(i);
			if (file.size) {
				totalFileSize-= file.size;
			}
			crm.removeAsset({ id: file.damId }).then(res => {
				if (res.success) {
					cleanupField();
				} else {
					console.error(res.message);
				}
				barrier(i);
			});
		} else {
			// do nothing
			if (file.toBeDeleted && file.original && file.size) {
				totalFileSize-= file.size;
			}
			barrier(i);
		}
		i++;
	}

	if(!updateFiles.length) {
		updateDisabled(false);
	}
};

export default uploadAssets;
