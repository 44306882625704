import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middleware = [thunk.withExtraArgument()];

function configureStore(initialState) {
	let store = null;
	if (process.env.NODE_ENV === "production") {
		store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
	} else {
		const actionSanitizer = (action) => (
			action.type === "INIT_FORM" && action.data ?
				{ ...action, callbacks: "<<CALLBACKS>>", customComponents: "<<CUSTOM_COMPONENTS>>" } : action
		);
		const stateSanitizer = (state) => {
			const sanitizedCallback = state.callbacks ? Object.keys(state.callbacks).map(key => key) : state.callbacks;
			const sanitizedFields = {};
			for (const field in state.fields) {
				sanitizedFields[field] = {
					...state.fields[field],
					files: state.fields[field].files?.map(file => {
						return {
							...file,
							base64: "<<base64_encoded>>"
						};
					})
				};
			}
			const sanitizedCustomComponents = {};
			for (const component in state.customComponents) {
				sanitizedCustomComponents[component] = "<<COMPONENT>>";
			}
			return {
				...state,
				customComponents: sanitizedCustomComponents,
				callbacks: sanitizedCallback,
				fields: sanitizedFields,
				fieldRefs: state.fieldRefs ? "<<FIELD_REFS>>" : undefined
			};
		};
		const composeEnhancers = composeWithDevTools({
			name: "Form Redux v2",
			trace: true,
			actionSanitizer,
			stateSanitizer,
			actionsDenylist: ["SET_FIELD_REF"],
		});

		store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
	}

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./store", () => {
			const { rootReducer: nextRootReducer } = require("./store");
			store.replaceReducer(nextRootReducer);
		});
	}
	return store;
}

export default configureStore;
