import { extractInfo } from "../form";

const setAddressFieldsByListing = (crm) => (info) => {
	const { dispatchWrapper, getState, eventName } = info;
	const { subValue } = extractInfo(info);
	const { formType, refId, recId } = getState();

	const getDefault = () => {
		return {
			address: {
				AddressTypeID: null,
				AddressLine1: "",
				AddressLine2: "",
				AddressLine3: "",
				PostalCode: "",
				PostalCode4: "",
				City: "",
				StateCode: null,
				CountryCode: null,
				AddressID: 0,
			},
			map: {
				Latitude: 0,
				Longitude: 0,
				AutoGeocode: true,
			},
		};
	};

	const getAddress = (id) => {
		crm.get({
			type: "listing",
			filter: {
				recId: id,
			},
			fields: `
			CompanyName
			AccountCompanyName
			Address {
				recs {
					AddressLine1
					AddressLine2
					AddressLine3
					City
					StateCode
					StateDesc
					PostalCode
					PostalCode4
					CountryCode
					AddressTypeID
					Latitude
					Longitude
					AutoGeocode
				}
			}
			AccountAddress {
				recs {
					AddressLine1
					AddressLine2
					AddressLine3
					City
					StateCode
					StateDesc
					PostalCode
					PostalCode4
					CountryCode
					AddressTypeID
					Latitude
					Longitude
					AutoGeocode
				}
			}
		`,
		}).then(response => {
			const recs = response.recs;
			if ((recs[0].Address.recs.length || recs[0].AccountAddress.recs.length) && (eventName === "onLoad" || eventName === "onChange")) {
				const toPush = getDefault();
				let address = {};
				let accountAddress = {};

				if (recs[0].Address.recs && recs[0].Address.recs.length) {
					address = recs[0].Address.recs[0];
				}

				if (recs[0].AccountAddress.recs && recs[0].AccountAddress.recs.length) {
					accountAddress = recs[0].AccountAddress.recs[0];
				}

				toPush.address.AddressLine1 = address.AddressLine1 || accountAddress.AddressLine1 || "";
				toPush.address.AddressLine2 = address.AddressLine2 || accountAddress.AddressLine2 || "";
				toPush.address.AddressLine3 = address.AddressLine3 || accountAddress.AddressLine3 || "";
				toPush.address.City = address.City || accountAddress.City || "";
				toPush.address.PostalCode = address.PostalCode || accountAddress.PostalCode || "";
				toPush.address.PostalCode4 = address.PostalCode4 || accountAddress.PostalCode4 || "";
				toPush.address.StateCode = address.StateCode ? { label: address.StateDesc, value: address.StateCode } : (accountAddress.StateCode ? { label: accountAddress.StateDesc, value: accountAddress.StateCode } : null);
				toPush.address.AddressTypeID = { label: "Physical", value: 1 };
				toPush.address.CountryCode = address.CountryCode 
					? { label: (address.CountryCode === "US" ? "United States" : address.CountryCode), value: address.CountryCode } 
					: null;
					
				try {
					toPush.map.Latitude = JSON.parse(address.Latitude) || JSON.parse(accountAddress.Latitude) || 0;
					toPush.map.Longitude = JSON.parse(address.Longitude) || JSON.parse(accountAddress.Longitude) || 0;
				} catch(e) {
					toPush.map.Latitude = 0;
					toPush.map.Longitude = 0;
				}
				
				toPush.map.AutoGeocode =
					"AutoGeocode" in address ? address.AutoGeocode : true;

				dispatchWrapper("handleFormChange", [
					"Address_v2",
					"Address_v2",
					{ map: toPush.map, address: [toPush.address] },
				]);

				dispatchWrapper("handleFormChange", [
					"LocationText",
					"String",
					recs[0].CompanyName || recs[0].AccountCompanyName,
				]);
			}
		});
	};

	const onConfirm = (value) => {
		if (value) {
			getAddress(subValue.value);
		}
	};

	if (eventName === "onLoad") {
		if (formType === "listing.eventCalendar" && refId && !recId) {
			getAddress(refId);
		}
	} else {
		if (subValue && subValue.value) {
			dispatchWrapper("setDialog", [
				true,
				"",
				"Would you like to set the address information to the Venue address?",
				onConfirm,
			]);
		}
	}
};

export default setAddressFieldsByListing;
