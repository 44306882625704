const styles = (theme) => ({
	htmlContainer: {
		width: "100%",
		"& img": {
			maxWidth: "300px"
		}
	},
	img: {
		maxWidth: "300px"
	}
});

export default styles;
