import React from "react";
import { ACCOUNT, CONTACT, ADD, GLOBAL_TABBED_DRAWER } from "./consts";
import FormFrame from "../components/Form/FormFrame";

const openCommForm = (refType, refId, setDrawerOpen, commFormEmail, afterSubmit) => {
	if (refType !== ACCOUNT && refType !== CONTACT) {
		return false;
	}

	if (!afterSubmit) {	
		afterSubmit = () => {	
			setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });	
		};	
	}

	const defaultData = refType === ACCOUNT ? {
		EmailAddressTo: [{
			value: commFormEmail,
			label: commFormEmail
		}] 
	} : undefined;

	const commForm = <FormFrame
		type={`${refType}.comm`}
		action={ADD}
		refId={+refId}
		recId={0}
		defaultData={defaultData}
		afterSubmit={afterSubmit}	
	/>;

	setDrawerOpen(GLOBAL_TABBED_DRAWER, {
		type: `${refType}.comm`,
		isOpen: true,
		action: ADD,
		displayComponent: commForm,
		tabs: [{ name: "SEND EMAIL" }],
		activeTab: "SEND EMAIL"
	});
	return true;
};

export default openCommForm;
