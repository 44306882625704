import { columdnDef, filterDef } from "./sharedDefs";

export const settingString = `
setting {
	config {
		title
		module
		parent
		primaryKey
		columns {
			label
			name
			sortable
			format
			linkModule
		}
		filters {
			name
			label
			component
			type
			toFilter
			args {
				numericOnly
			}
			selectOptions {
				optionTable
				optionFunction
				labelColumn
				valueColumn
				sortColumn
				isActiveColumn
				filter {
					column
					boolean
					isNull
				}
				functionPath
				functionArgs {
					moduleName
				}
			}
		}
		primaryActions {
			name
			color
			variant
			mIcon
			onClick
			show
		}
		additionalActions {
			name
			label
			onClick
			show
		}
		checkButtonPerms
		bulkActions {
			name
			color
			variant
			mIcon
			onClick
			onAllClick
			show
		}
		buttons {
			name
			label
			mIcon
			color
			variant
			onClick
		}
		views {
			_id
			name
			visibleColumns
			page
			limit
			sortedColumns {
				field
				order
			}
			filters {
				name
				operation
				value
				label
				dataType
			}
			activeFilters
		}
		productName
		displayCustomFields
	}
	userViews {
		_id
		name
		type
		visibleColumns
		page
		limit
		sortedColumns {
			field
			order
		}
		filters {
			name
			operation
			value
			label
			dataType
		}
		activeFilters
	}
	sharedViews {
		_id
		name
		type
		visibleColumns
		page
		limit
		sortedColumns {
			field
			order
		}
		filters {
			name
			operation
			value
			label
			dataType
		}
		activeFilters
	}
	currentView {
		_id
		name
		type
		visibleColumns
		page
		limit
		sortedColumns {
			field
			order
		}
		filters {
			name
			operation
			value
			label
			dataType
		}
		activeFilters
	}
}`;

export const buildDataFilters = (filters: filterDef[]) => {
	const filterInfo: any = {};
	filters.forEach(filter => {
		filterInfo[filter.columnName] = filter.value;

		if (filterInfo[filter.columnName].rangeStart) {
			filterInfo[filter.columnName].start = filterInfo[filter.columnName].rangeStart;
			delete filterInfo[filter.columnName].rangeStart;
		}

		if (filterInfo[filter.columnName].rangeEnd) {
			filterInfo[filter.columnName].end = filterInfo[filter.columnName].rangeEnd;
			delete filterInfo[filter.columnName].rangeEnd;
		}

		if (filter.jsonify) {
			try {
				filterInfo[filter.columnName] = JSON.parse(filterInfo[filter.columnName]);
			} catch (e) {
				// do nothing
			}
		}
	});

	return filterInfo;
};

const primaryKeyMap: any = {
	offer: "SpecialOfferID",
	eventCalendar: "EventCalendarID",
	contact: "ContactID",
	inquiry: "InquiryID",
	brochure: "BrochureID",
	account: "AccountID",
	listing: "ListingID"
};

const addPending = ["offer", "eventCalendar", "listing"];
const addRecordID = ["offer", "eventCalendar", "contact", "inquiry", "listing", "account"];

export const buildDataString = (type: string, columns: columdnDef[]) => {
	let toReturn = ``;
	let hasRecordID = false;
	let addCustomFields = false;
	const columnNameMapper: any = {
		Account: `
			Account
			AccountRecordID
		`,
		HostAccount: `
			HostAccount
			HostAccountRecordID
		`,
		VenueAccount: `
			VenueAccount
			VenueAccountRecordID
		`,
		PrimaryAccount: `
			PrimaryAccount
			PrimaryAccountRecordID
		`,
		Contact: `
			Contact
			ContactRecordID
		`
	};

	toReturn += `data {
		... on crm_${type}_grid	{
	`;
	columns.filter(col => !(col.columnName.startsWith("Amenity_") || col.columnName.startsWith("Attribute_"))).forEach(column => {
		if (column.type === "gridchips" && !column.columnName.startsWith("CustomField_")) {
			toReturn += `
				${column.columnName} {
					ID
					Title
				}
			`;
		} else if (!column.columnName.startsWith("CustomField_")) {
			if (column.columnName === "RecordID") {
				hasRecordID = true;
			}

			if (columnNameMapper[column.columnName]) {
				toReturn += columnNameMapper[column.columnName];
			} else {
				toReturn += `
					${column.columnName}
				`;
			}
		} else {
			addCustomFields = true;
		}
	});

	toReturn += `
			rowButtons
			${primaryKeyMap[type]}
			${addPending.includes(type) ? "PendingID" : ""}
			${addRecordID.includes(type) && !hasRecordID ? "RecordID" : ""}
			${addCustomFields ? "CustomFields { value chips { ID Title } UDFID }" : ""}
			${type === "listing" ? "Amenities { value TenantAmenityXrefID }" : ""}
			${type === "listing" ? "Attributes { value chips { ID Title } AttributeID }" : ""}
		}
	}`;

	return toReturn;
};
