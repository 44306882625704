const fetchReferralListings = crm => async ({ fieldName, subscriber, dispatchWrapper, getState }) => {
	const { data, fields } = getState();
	const listingIds = data[fieldName];
	const field = fields[subscriber];

	if (!listingIds) {
		return [];
	}

	crm.get({
		type: "listing",
		filter: {
			recId: listingIds
		},
		fields: field.gridOptions.listColumns.map(column => column.name).join(" ")
	}).then(listings => {
		if (listings.success && listings.count) {
			dispatchWrapper("handleFormChange", [subscriber, "Object", listings.recs]);
		}
	});
};

export default fetchReferralListings;
