import { AxiosInstance } from "axios";

import query from "../query";

class AdminPrefix {
	name: string;
	private _graphUrl: string;
	private _graphServer: any;
	private _ax: AxiosInstance;
	constructor({ graphUrl, graphServer, ax }: { graphUrl: string, graphServer: object, ax: AxiosInstance }) {
		this.name = "crm_admin";
		this._graphUrl = graphUrl;
		this._graphServer = graphServer;
		this._ax = ax;
	}

	async fetchTenantSettings() {
		const context = this._graphServer.context;

		const variables = {
			acct_id: context.acct_id,
			productName: context.productNameNoEnv || context.productName
		};

		const response = await query({
			ax: this._ax,
			query: `
				query tenant_settings($acct_id: String!, $productName: String) {
					crm(acct_id: $acct_id, productName: $productName) {
						tenant_settings {
							success
							message
							rec {
								timeZone
							}
						}
					}
				}
			`,
			token: context.token,
			type: "tenant_settings",
			url: this._graphUrl,
			variables
		});

		return response.crm.tenant_settings;
	}
}

export default AdminPrefix;
