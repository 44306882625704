const FREQS_TYPES = { NONE: "NONE", DAILY: "DAILY", WEEKLY: "WEEKLY", MONTHLY: "MONTHLY", YEARLY: "YEARLY" };
const WEEK_DAYS = { SU: "SU", MO: "MO", TU: "TU", WE: "WE", TH: "TH", FR: "FR", SA: "SA" };

const { SU, MO, TU, WE, TH, FR, SA } = WEEK_DAYS;
const WEEKDAY_OPTS = [
	{ day: SU, index: 0 },
	{ day: MO, index: 1 },
	{ day: TU, index: 2 },
	{ day: WE, index: 3 },
	{ day: TH, index: 4 },
	{ day: FR, index: 5 },
	{ day: SA, index: 6 }
];

const SUBSCRIPTION_TYPES = {
	ON_LOAD: "onLoad",
	ON_CHANGE: "onChange",
	ON_UPDATE: "onUpdate",
	ON_BLUR: "onBlur",
	ON_CLICK: "onClick",
	AFTER_SUBMIT: "afterSubmit",
	AFTER_CANCEL: "afterCancel",
	BEFORE_SUBMIT: "submit" // TODO: change this to beforeSubmit, and change all the form def
};

const DATE_PICKER_PROP_TYPES = [
	"adornmentPosition",
	"ampm",
	"autoOk",
	"cancelLabel",
	"classes",
	"className",
	"clearable",
	"clearLabel",
	"disablePast",
	"emptyLabel",
	"format",
	"initialFocusedDate",
	"InputAdornmentProps",
	"invalidDateMessage",
	"invalidLabel",
	"keepCharPositions",
	"keyboard",
	"KeyboardButtonProps",
	"keyboardIcon",
	"labelFunc",
	"mask",
	"maxDate",
	"maxDateMessage",
	"minDate",
	"minDateMessage",
	"okLabel",
	"onClose",
	"onMonthChange",
	"onOpen",
	"onYearChange",
	"openTo",
	"openToYearSelection",
	"renderDay",
	"rightArrowIcon",
	"shouldDisableDate",
	"showTodayButton",
	"TextFieldComponent",
	"todayLabel",
	"views",
	"required"
];

const ERROR_TYPES = {
	IS_VALID: "isValid",
	IS_REQUIRED: "isRequired",
	IS_EMAIL: "isEmail",
	IS_VALID_DATE: "isValidDate",
	IS_VALID_DATE_ORDER: "isValidDateOrder",
};

export { FREQS_TYPES, WEEK_DAYS, WEEKDAY_OPTS, SUBSCRIPTION_TYPES, DATE_PICKER_PROP_TYPES, ERROR_TYPES };
