/**
 * Determines if object is an empty array, empty object, undefined, null or an
 * empty string.
 */
const isEmpty = (obj, consideredEmpty) => {
	if (consideredEmpty && consideredEmpty.indexOf(obj) >= 0) {
		return true;
	}
	return obj === undefined ||
		obj === null ||
		(obj instanceof Array && obj.length === 0) ||
		(obj instanceof Object && Object.getPrototypeOf(obj) === Object.prototype && (Object.keys(obj).length === 0 || Object.keys(obj).reduce((accumulator, key) => {
			return accumulator && isEmpty(obj[key], consideredEmpty);
		}))) ||
		obj === "";
};

export default isEmpty;
