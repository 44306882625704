import React from "react";
import DropdownFilter from "./dropdownFilter";

const allDateOptions = [
	{ value: 1, label: "Yes" },
	{ value: 0, label: "No" }
];


const BooleanYesNoFilter = props => {
	return <DropdownFilter options={allDateOptions} {...props} />;
};

export default BooleanYesNoFilter;
