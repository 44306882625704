const connectionModules = crm => ({ fieldName, dispatchWrapper, getState, eventName }) => {
	const { data } = getState();

	if (data[fieldName]) {
		const connectionId = data[fieldName].value;
		const connectionModules = [1, 2, 5, 7, 12];
		const moduleFilterByConnection = {
			35: 5
		};

		const filter = moduleFilterByConnection[connectionId] ? `(recId: ${moduleFilterByConnection[connectionId]})` : "";

		crm.get_old({
			queryString: `get_modules${filter} {
				DisplayNm ModuleID
			}`
		}).then(res => {
			const modules = res.get_modules.filter(module => connectionModules.includes(module.ModuleID))
				.map(module => ({ label: module.DisplayNm, value: module.ModuleID }))
				.sort(function (a, b) {
					if (a.label >= b.label) {
						return 1;
					}
					return -1;
				});
			
			dispatchWrapper("updateField", ["Modules", { options: modules }]);
		});
	}
};

export default connectionModules;
