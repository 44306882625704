import React, { useEffect, useState, useCallback } from "react";
import { GridPrimaryFilter } from "@simpleview/sv-mosaic";
import { Menu, MenuItem } from "@material-ui/core";
import isEmpty from "../../../common/isEmpty";


const DropdownFilter = ({ label, type, onRemove, options, onChange, data }) => {
	const [selected, setSelected] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSelect = useCallback((i) => () => {
		setSelected(options[i]);
		onChange({
			...options[i],
			comparison: "equals"
		});
		handleClose();
	});

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	});

	useEffect(() => {
		data && setSelected(data);
	}, [data]);

	return <>
		<GridPrimaryFilter
			label={label}
			value={!isEmpty(selected?.value) ? selected?.label : "Any"}
			type={type}
			onRemove={onRemove}
			onClick={handleClick}
		/>
		<Menu
			open={Boolean(anchorEl)}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			{ options.map((option, i) => {
				return <MenuItem onClick={handleSelect(i)} key={i}>{option.label}</MenuItem>;
			}) }
		</Menu>
	</>;
};

export default DropdownFilter;
