class SelectHelper {
	constructor(
		{ 
			server, 
			parentType, 
			parentID, 
			parentColumn, 
			optionTable, 
			labelColumn, 
			valueColumn, 
			sortColumn, 
			isActiveColumn, 
			optionFunction, 
			functionPath, 
			functionArgs, 
			filter, 
			valueToString 
		}) {
		this.server = server;
		this.parentType = parentType;
		this.parentID = parentID;
		this.parentColumn = parentColumn;
		this.optionTable = optionTable;
		this.labelColumn = labelColumn;
		this.valueColumn = valueColumn;
		this.sortColumn = sortColumn;
		this.isActiveColumn = isActiveColumn;
		this.optionFunction = optionFunction;
		this.functionPath = functionPath;
		this.functionArgs = functionArgs;
		this.valueToString = valueToString;
		this.filter = filter;
		// because of the way these get called, they cannot be set as class methods and receive the proper "this"
		this.getOptions = getOptions.bind(this);
		this.getSelected = getSelected.bind(this);
	}
}

// we could cache this later
async function getOptions(filterFromGrid) {
	const query = {
		parentType: this.parentType,
		parentID: this.parentID,
		parentColumn: this.parentColumn,
		optionTable: this.optionTable,
		labelColumn: this.labelColumn,
		valueColumn: this.valueColumn,
		isActiveColumn: this.isActiveColumn,
		optionFunction: this.optionFunction,
		functionPath: this.functionPath,
		functionArgs: this.functionArgs,
		pagingOptions: {
			limit: filterFromGrid.limit,
			page: filterFromGrid.skip > 0 ? (filterFromGrid.skip / filterFromGrid.limit) + 1 : 1
		},
		valueToString: true,
		filter: this.filter
	};

	if (this.sortColumn !== undefined && this.sortColumn !== this.labelColumn) {
		query.pagingOptions.orderBy = [
			{
				field: this.sortColumn,
				order: "ASC"
			},
			{
				field: this.labelColumn,
				order: "ASC"
			}
		];
	} else {
		query.pagingOptions.orderBy = [
			{
				field: this.sortColumn || this.labelColumn,
				order: "ASC"
			}
		];
	}

	query.filter = [];

	if (this.filter && this.filter.length) {
		query.filter = this.filter;
	}

	if (filterFromGrid.keyword !== undefined && filterFromGrid.keyword.length) {
		query.filter = query.filter.concat([
			{
				column: this.labelColumn,
				string: filterFromGrid.keyword
			}
		]);
	}

	const result = await this.server.fetchFormOptions(query);

	return {
		docs: result.options,
		hasMore: result.nextPage !== null && result.nextPage !== undefined
	};
}

async function getSelected(ids) {
	const filterObj = {};
	if (this.valueToString) {
		filterObj.stringArray = ids;
	} else {
		filterObj.int = ids.map(id => Number(id));
	}
	const result = await this.server.fetchFormOptions({
		parentType: this.parentType,
		parentID: this.parentID,
		parentColumn: this.parentColumn,
		optionTable: this.optionTable,
		labelColumn: this.labelColumn,
		valueColumn: this.valueColumn,
		optionFunction: this.optionFunction,
		functionPath: this.functionPath,
		functionArgs: this.functionArgs,
		pagingOptions: {
			orderBy: [
				{
					field: this.sortColumn || this.labelColumn,
					order: "ASC"
				}
			]
		},
		filter: [{
			column: this.valueColumn,
			...filterObj
		}],
		valueToString: true
	});

	return result.options;
}

export default SelectHelper;
