import { createActions } from "reduxsauce";
import { ADD_NEW, GENERAL, SUMMARY, KB_LINK } from "../common/consts";
import alerts from "../components/Header/Alerts/alerts.json";
import { updateConfig, setConfig } from "@react-ui/common/format";
import { Creators as SummaryCreators } from "./summaryActions";

export const LEFT_NAV = "LeftNav";
export const LEFT_NAV_TOGGLE = "LeftNavToggle";

const { Types, Creators } = createActions({
	alertsFetchStart: null,
	alertsFetchSuccess: ["alerts"],
	fetchProfileSettingSuccess: ["settings"],
	alertsFullscreenOn: null,
	alertsFullscreenOff: null,
	alertsHide: null,
	alertsShow: null,
	alertsTypeSet: ["alertType"],
	leftNavFetchStart: null,
	leftNavFetchSuccess: ["leftNavLinks"],
	bookmarksFetchStart: null,
	bookmarksFetchSuccess: ["bookmarks"],
	setBookmarks: ["bookmarks"],
	setActiveBookmarkRVTab: ["tab"],
	recentlyViewedFetchStart: null,
	recentlyViewedFetchSuccess: ["recentlyViewed"],
	fetchAddNewOptionsSuccess: ["options"],
	toggleSection: ["section"],
	generalSettingsSuccess: ["settings"],
	updateGeneralSettingSuccess: ["name", "value"],
	fetchTenantSettingsSuccess: ["settings"],
	kbLinksFetchSuccess: ["kbLinks"]
});


const setGeneralSettings = ({ dispatch, recs }) => {
	if (!recs || recs.length === 0) {
		return;
	}

	const settings = recs.reduce((prev, curr) => {
		const parsedInt = parseInt(curr.SettingValue);
		if (!isNaN(parsedInt)) {
			return {
				...prev,
				[curr.SettingName]: parsedInt
			};
		} else {
			if (curr.SettingValue === "true" || curr.SettingValue === "false") {
				curr.SettingValue = curr.SettingValue === "true";
			}

			return {
				...prev,
				[curr.SettingName]: curr.SettingValue
			};
		}
	}, {});
	const { Locale, TimeZone, Language } = settings;

	const ClientTimeZone = recs.find(rec => rec.SettingName === "TimeZone")?.TenantSettingValue || TimeZone;

	setConfig({ Locale, TimeZone, ClientTimeZone, Language });
	dispatch(Creators.generalSettingsSuccess(settings));
};

const setAddNewOptions = ({ dispatch, recs }) => {
	const settings = recs.filter(rec => rec.SettingName === "modules");
	if (settings[0] && settings[0].SettingValue) {
		const options = JSON.parse(settings[0].SettingValue);
		dispatch(Creators.fetchAddNewOptionsSuccess(options));
	}
};

const setSummaryTabs = ({ dispatch, recs }) => {
	if (!recs || recs.length === 0) {
		return;
	}
	const tabObj = {};

	recs.forEach(setting => {
		tabObj[setting.SettingName] = JSON.parse(setting.SettingValue);
	});

	dispatch(SummaryCreators.tabsFetchSuccess(tabObj));
};

const setKBLinks = ({ dispatch, recs }) => {
	if (!recs || recs.length === 0) {
		return;
	}
	const linkObj = {};

	recs.forEach(setting => {
		linkObj[setting.SettingName] = setting.SettingValue;
	});

	dispatch(Creators.kbLinksFetchSuccess(linkObj));
};

Creators.fetchTenantSettings = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;

	crmGraphServer.crm_admin.fetchTenantSettings().then(res => {
		if (res.success) {
			dispatch(Creators.fetchTenantSettingsSuccess(res.rec));
		}
	});
};

Creators.getGeneralSettings = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "user_settings",
		filter: [GENERAL, SUMMARY, ADD_NEW, KB_LINK].map(SettingGroupName => ({
			SettingGroupName
		})),
		fields: "SettingName SettingValue TenantSettingValue SettingGroupName"
	}).then(res => {
		if (!res.success) {
			return;
		}
		const settings = {
			[GENERAL]: [],
			[SUMMARY]: [],
			[ADD_NEW]: [],
			[KB_LINK]: []
		};
		const summaryRecs = [];
		res.recs.forEach(rec => {
			settings[rec.SettingGroupName].push(rec);
			if ( rec.SettingGroupName === SUMMARY) {
				summaryRecs.push(rec.SettingName);
			}
		});

		setGeneralSettings({
			dispatch,
			recs: settings[GENERAL],
		});
		setAddNewOptions({
			dispatch,
			recs: settings[ADD_NEW]
		});

		setSummaryTabs({
			dispatch,
			recs: settings[SUMMARY]
		});

		setKBLinks({
			dispatch,
			recs: settings[KB_LINK]
		});
		dispatch(SummaryCreators.setHasSummary(summaryRecs));
	});
};

Creators.updateGeneralSettings = (name, value) => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.set_old({
		type: "set_user_settings",
		queryString: `
			set_user_settings(
				input: {
					ModuleNm: "user",
					ModuleSettingNm: "${name}",
					ModuleSettingGroupNm: "general",
					ModuleSettingValue: "${value}"
				}
			)`
	}).then(() => {
		// I don't think this is getting used
		updateConfig(name, value);
		dispatch(Creators.updateGeneralSettingSuccess(name, value));
	});
};

Creators.alertsFetch = () => dispatch => {
	Creators.alertsFetchSuccess(alerts);
};

Creators.alertsDismissToggle = (type, id) => dispatch => {
	// TODO: Create async action to toggle dismiss property on tip/update
};

Creators.alertsReadToggle = (type, id) => dispatch => {
	// TODO: Create async action to toggle read property on tip/update
};

Creators.leftNavFetch = () => (dispatch, getState) => {
	dispatch(Creators.leftNavFetchStart());
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get_old({
		type: "get_navigation",
		queryString: `
			get_navigation {
				JSONMenu
			}
		`
	}).then(res => {
		const navLinks = JSON.parse(res.JSONMenu);

		if (navLinks) {
			dispatch(Creators.leftNavFetchSuccess(navLinks));
		}
	});
};

Creators.bookmarksFetch = () => dispatch => {
	dispatch(Creators.bookmarksFetchStart());

	const data = fakeGraphQLGetBookmarks();
	dispatch(Creators.bookmarksFetchSuccess(data));
};

Creators.recentlyViewedFetch = () => (dispatch, getState) => {
	dispatch(Creators.recentlyViewedFetchStart());
	const { crmGraphServer } = getState().login;
	
	crmGraphServer.general.get({
		type: "recently_viewed",
		fields: "Module ViewedDt Title RecordID"
	}).then(res => {
		dispatch(Creators.recentlyViewedFetchSuccess(res.recs));
	});
};

Creators.fetchProfileSetting = () => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	crmGraphServer.general.get({
		type: "user_info",
		fields: "FirstName LastName products { label name href }"
	}).then(res => {
		if (res.success && res.count) {
			dispatch(Creators.fetchProfileSettingSuccess(res.recs[0]));
		}
	});
};

const fakeGraphQLGetBookmarks = () => {
	return {
		account: [
			{
				id: 1,
				type: "account",
				name: "The Schwartz Corporation"
			},
			{
				id: 2,
				type: "account",
				name: "Kingsmen Coach Lines"
			},
			{
				id: 3,
				type: "account",
				name: "Royal Excursion"
			},
			{
				id: 4,
				type: "account",
				name: "Blue Grass Tours"
			}
		],
		lead: [
			{
				id: 1,
				type: "lead",
				name: "League Retreat"
			},
			{
				id: 2,
				type: "lead",
				name: "Kansas City Life Insurance Meeting 2018"
			},
			{
				id: 3,
				type: "lead",
				name: "Neil INC. Annual Conference Meet 2019"
			}
		],
		contact: [
			{
				id: 1,
				type: "contact",
				name: "Beth Strelitz"
			},
			{
				id: 2,
				type: "contact",
				name: "Emma Reichenbach"
			},
			{
				id: 3,
				type: "contact",
				name: "Kelvin Bates"
			},
			{
				id: 4,
				type: "contact",
				name: "Michael Putman Estwanik"
			},
			{
				id: 5,
				type: "contact",
				name: "Pippi Stocking"
			}
		],
		profile: [
			{
				id: 1,
				type: "profile",
				name: "Board of Director Meeting"
			},
			{
				id: 2,
				type: "profile",
				name: "Burning Man Tucson"
			},
			{
				id: 3,
				type: "profile",
				name: "Dental Consortium"
			},
			{
				id: 4,
				type: "profile",
				name: "NewCastle Comic Con"
			}
		],
		request: [
			{
				id: 1,
				type: "request",
				name: "Transportation Service"
			},
			{
				id: 2,
				type: "request",
				name: "Entertainment for Reception"
			},
			{
				id: 3,
				type: "request",
				name: "Meeting Space for Pediatricians"
			},
			{
				id: 4,
				type: "request",
				name: "Need Catering"
			},
			{
				id: 5,
				type: "request",
				name: "Star of the Year Luncheon"
			}
		]
	};
};

Creators.fetchZendeskWebToken = (user) => (dispatch, getState) => {
	const { crmGraphServer } = getState().login;
	return crmGraphServer.general.get({
		type: "zendeskToken",
		fields: "token",
		filter: user
	});
};

export { Creators, Types };
