import React from "react";
import Grid from "@material-ui/core/Grid";
import Row from "./Row";
import Field from "./Field";

const Column = ({ classes, fields, rows, name, compField, compIndex }) => {
	return <>
		{fields && fields.map(field => {
			if (!field) {
				return undefined;
			}
			field = field.name || field;
			return <Field compField={compField} compIndex={compIndex} key={field} name={field} classes={classes} />;
		})}
		<Grid container>
			{rows && rows.map((row, i) => {
				const rowName = `${name}-row-${i}`;
				return <Row compField={compField} compIndex={compIndex} classes={classes} key={rowName} {...row} name={rowName} />;
			})}
		</Grid>
	</>;
};

export default Column;
