import { extractInfo } from "../form";
import store from "../../store";

/**
 * staticArgs:
 * 0 - fieldPropname: String
 *     prop name
 * 1 - isSVUser: Boolean
 *     Set the prop if user is SV or not
 * 2 - toSet: any
 *     value to set prop to
 */    
 
const setPropSVUser = crm => info => {
	const { updateField, staticArgs = [] } = extractInfo(info);
	const [attrName, isSVUser, toSet] = staticArgs;
	const loginState = store.getState().login;
	const update = (value) => {
		updateField({
			[attrName]: value
		});
	};

	if (loginState.isSVUser === (isSVUser == "true")) {
		update(toSet);
	}
};

export default setPropSVUser;
