import { handleSubmit } from "../form";

const saveDraftComm = crm => info => {
	const { data } = info.getState();
	const newData = {
		...data,
		EmailStatus: "Draft"
	};
	handleSubmit({ crm, ...info, data: newData });
};

export default saveDraftComm;
