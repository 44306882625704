import debounce from "lodash/debounce";

const reverseGeocode = crm => debounce(({ dispatchWrapper, getState, eventName }) => {
	const singleAddressTypes = ["eventCalendar", "listing"];
	const {
		formType,
		data: {
			ListingAddress_v2,
			Latitude,
			Longitude,
			AutoGeocode
		},
		data,
		fields
	} = getState();

	const typeArray = formType.split(".");
	const actualFormType = typeArray.length === 1 ? formType : typeArray[1];

	const addresses = ListingAddress_v2.address.filter(addr => {
		let rtn = false;
		Object.keys(addr).forEach(key => {
			if (addr[key]) {
				rtn = true;
			}
		});
		return rtn;
	});

	const findPhysical = singleAddressTypes.includes(actualFormType) ? 0 : addresses.findIndex(rec => rec.AddressTypeID && rec.AddressTypeID.label === "Physical");

	const address = addresses
		.filter(addr => (
			(addr.AddressTypeID && addr.AddressTypeID.label === "Physical") 
				|| singleAddressTypes.includes(actualFormType)
		) && addr.CountryCode && addr.PostalCode
		);

	//Logic for overwrite field (don't autopopulate listing/lat)
	const reverseListing = actualFormType === "listing" ? (!data.AccountLatitude && !data.AccountLongitude) || !AutoGeocode : true;

	// If the address did not exist, populate a new address for it
	if (!address[0] && Latitude && Longitude && reverseListing) {
		crm.get({
			type: "address_bygeolocation",
			filter: {
				Latitude,
				Longitude
			},
			fields: "PostalCode CountryCode Country State StateCode Latitude Longitude"
		}).then(res => {
			const [location] = res.recs;
			const { PostalCode, CountryCode, Country, StateCode, State, Latitude, Longitude } = location;

			if (findPhysical === -1 && addresses.length > 0) {
				dispatchWrapper("addField", ["Address"]);
			}

			const newAddress = {
				"AddressTypeID": { value: 1, label: "Physical" },
				"StateCode": { value: StateCode, label: State } || "",
				"CountryCode": { value: CountryCode, label: Country } || "",
				"PostalCode": PostalCode || "",
			};

			const newAddress_v2 = { 
				"address": [newAddress], 
				"map": {
					"AutoGeocode": AutoGeocode,
					"Latitude": Number(Latitude),
					"Longitude": Number(Longitude),
				}
			};

			dispatchWrapper("handleFormChange", ["ListingAddress_v2", "Address_v2", newAddress_v2]);
		});
	}
}, 1000);

export default reverseGeocode;
