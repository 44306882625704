import isEmpty from "./isEmpty";

/**
 * Remove all empty strings, empty objects and empty arrays from an object.
 */
const clean = (obj, nullToUndefined) => {
	if (!(obj instanceof Object)) {
		return obj;
	}

	if (obj instanceof Array) {
		for (let i = 0; i < obj.length; i++) {
			const item = obj[i];
			if (item instanceof Object || item instanceof Array) {
				clean(item, nullToUndefined);
			}

			if (isEmpty(item)) {
				// remove the item and increment backwards since the array now has a different length after the splice
				obj.splice(i, 1);
				i--;
			}
		}
	} else {
		const keys = Object.keys(obj);
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const value = obj[key];
			if (value instanceof Object || value instanceof Array) {
				clean(value, nullToUndefined);
			}

			if (isEmpty(value)) {
				if (nullToUndefined === true) {
					obj[key] = undefined;
				} else {
					delete obj[key];
				}
			}
		}
	}

	return obj;
};

export default clean;
