import { extractInfo } from "../form";

const setImageLimit = crm => info => {
	const { updateField } = extractInfo(info);
	const { data } = info.getState();
	updateField({
		limit: 0
	});

	let value = data["ListingTypeID"];

	if (value?.value) {
		value = value.value;
	}	

	if (value) {
		crm.get({
			type: "listingType",
			filter: {
				recId: value
			},
			fields: `
				ImageCnt
				AllowMedia
			`
		}).then(res => {
			if (res?.recs?.length > 0) {
				const limit = !res.recs[0].AllowMedia ? -1 : res.recs[0].ImageCnt || 0;
				updateField({
					limit
				});

				if (limit != 0) {
					const label = limit === -1 
						? "No Images allowed for this listing"
						: `Maximum of ${limit} image(s) allowed`;

					updateField({
						label
					});
				} else {
					updateField({
						label: "Please select your images for this listing"
					});
				}
			}		
		});
	}
};

export default setImageLimit;
