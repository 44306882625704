import { CrmFormCallback } from "./typeDef";
import { GLOBAL_TABBED_DRAWER, COMMUNICATION } from "../consts";
import { handleSubmit } from "../form";
import FormFrame from "../../components/Form/FormFrame";
import React from "react";
import store from "../../store";

const sendReferralComm: CrmFormCallback = (crm) => async (info): Promise<any> => {
	const { getState, setDrawerOpen, afterSubmit } = info;
	const { data, formType, refId } = getState();
	const headerState = store.getState().header;
	const response = await handleSubmit({ crm, ...info, data: data });
	const commType = formType.split(".");
	commType[1] = COMMUNICATION;
	setDrawerOpen(GLOBAL_TABBED_DRAWER, { isOpen: false });
	const tabs = [{ name: "" }];
	const activeTab = tabs[0].name;
	const defaultReferralTemplate = headerState.defaultReferralTemplateID || -1;

	const displayComponent = <FormFrame
		type={commType.join(".")}
		action={"add"}
		recId={0}
		refId={+refId}
		afterSubmit={afterSubmit}
		reMount={new Date()}
		defaultData={{ ReferralID: response.set_referral.recId, TemplateID: {label: "Default Referral", value: defaultReferralTemplate, sortOrder: null} }}
	/>;

	setDrawerOpen(GLOBAL_TABBED_DRAWER, {
		isOpen: true,
		type: commType.join("."),
		id: 0,
		action: "add",
		tabs,
		activeTab,
		displayComponent
	});

};

export default sendReferralComm;
