const setAccountContactRelationFields = crm => ({ fieldName, dispatchWrapper, getState, staticArgs }) => {
	const { data } = getState();
	//if the fieldName matches the tabgrid prefix then disable the field, as we know the value and don't need users to select it
	//e.g. "accountid".indexOf("account")

	if (data[fieldName].value) {
		dispatchWrapper("updateField", [fieldName, { readOnly: true }]);
	}
};

export default setAccountContactRelationFields;
