import { filtersToGridFilter, gridFilterToFilters } from "./filter";
import isEmpty from "../../common/isEmpty";
import difference from "lodash/difference";
export const DEFAULT_LIMIT = 50;

/**
 * Return number of records to skip given the settings object with page number and number of records per page.
 *
 * @param {Object} settings
 * @param {number} settings.page - 1 based page number
 * @param {number} [settings.limit=50] - number of records per page
 */
export const getSkip = settings => Math.max(settings?.page - 1 || 0, 0) * (settings?.limit || DEFAULT_LIMIT);

/**
 * Return the page number based on the skip and limiy
 *
 * @param {number} skip
 * @param {number} [limit=50]
 */
export const getPage = (skip, limit = DEFAULT_LIMIT) => {
	return Math.floor(skip / limit) + 1;
};

/**
 * get mosaic friendly view state with config and graph view.
 * @param {*} graphView
 * @param {*} config - optional for generating default sort and default activeColumns
 */
export const getViewState = (graphView, config) => {
	const { filters: viewFilters, sortedColumns, limit, page, visibleColumns, primaryFilters, id, activeFilters, type } = graphView;
	const defaultSort = config ? {
		name: config.columns[0].name,
		dir: "asc"
	} : undefined;
	const sortArray = sortedColumns.map(sort => ({
		name: sort.field,
		dir: sort.order.toLowerCase()
	}));

	return {
		filter: filtersToGridFilter(viewFilters),
		limit,
		skip: getSkip({ page, limit }),
		sort: sortArray[0] || defaultSort,
		activeFilters: activeFilters || (config && difference(
			viewFilters.filter(filter =>!isEmpty(filter.value))
				.map(filter => filter.name)
			, config.filters.filter(filter => filter.type === "primary").map(filter => filter.name))),
		activeColumns: visibleColumns,
		name: graphView.name,
		primaryFilters,
		id,
		type
	};
};

/**
 * parse viewState for graph
 * @param {*} viewState
 */
export const parseViewState = (viewState) => {
	const { sort, filter, limit, activeColumns, activeFilters, filters, primaryFilters, savedView } = viewState;
	return {
		limit,
		visibleColumns: activeColumns,
		filters: gridFilterToFilters({ gridFilter: filter, filters }),
		activeFilters,
		primaryFilters,
		name: savedView?.id === "unsaved" ? "Select a View" : savedView?.label,
		id: savedView?.id,
		type: savedView?.type,
		sortedColumns: [{
			field: sort.name,
			order: sort.dir.toUpperCase()
		}]
	};
};
