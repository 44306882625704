import { extractInfo } from "../form";	
import store from "../../store";	
import { Creators } from "../../actions/transientElementActions";	

const convertFileToFileObject = async function(file) {	
	if ( typeof file !== "object" ) {	
		return new Error("{File} is not an object.");	
	}	
	if ( file.size === undefined || file.name === undefined ) {	
		return new Error("{File} is not a valid file.");	
	}	

	const reader = new FileReader();	
	const returnObject = {	
		name: file.name,	
		length: file.size.toString(),	
		dataFormat: "base64",	
		data: []	
	};	

	return new Promise((resolve, reject) => {	
		reader.onerror = () => {	
			reader.abort();	
			reject(new Error("Unable to parse file data."));	
		};	

		reader.onload = () => {	
			returnObject.data[0] = reader.result;	
			resolve(returnObject);	
		};	

		reader.readAsDataURL(file);	
	});	
};	

const uploadAttachments = crm => async info => {	
	const { ownField, updateField, updateValue, isWebForm } = extractInfo(info);	
	let fileToDelete;	
	updateField({	
		disabled: true	
	});	
	info.dispatchWrapper("enableSubmit", [false]);
	const filesToAdd = [];	
	const updatedFiles = [...ownField.files];	
	for (const i in updatedFiles) {	
		const file = updatedFiles[i];	
		const key = file.name + new Date().toISOString();	
		if (!file.AttachmentPath && !file.fileName && !file.toBeDeleted && !file.id) {	
			const object = await convertFileToFileObject(file);	
			object.key = key;	
			filesToAdd.push(object);	
			updateField({	
				files: updatedFiles.map((f, j) => {	
					if (i == j) {	
						f.uploading = true;	
						f.key = key;	
						f.fileName = file.name;	
						updatedFiles[i] = f;	
					}	
					return f;	
				})	
			});	
		} else if (file.fileName && file.toBeDeleted && !file.id) {	
			fileToDelete = file.fileName;	
		} else if (file.id && file.toBeDeleted) {	
			const updates = updatedFiles.map((f, j) => {	
				if (i == j) {	
					f.toBeDeleted = true;	
					updatedFiles[i] = f;	
				}	
				return f;	
			});	

			updateValue([...updates].map(update => ({	
				path: update.path,	
				id: update.id,	
				url: update.url,	
				name: update.fileName || update.name,	
				toBeDeleted: update.toBeDeleted	
			})));	
		}	
	}	

	if (filesToAdd.length > 0) {	
		try {	
			const res = await crm.uploadAttachments({ files: filesToAdd });	
			res.forEach(rec => {	
				const updates = updatedFiles.map((f, j) => {	
					if (f.key === rec.key) {	
						f.uploading = false;	
						f.fileName = f.fileName;	
						f.path = rec.fileName;	
						f.url = rec.url;	
						f.id = f.id;	
						updatedFiles[j] = f;	
					}	
					return f;	
				});	

				updateField({	
					files: updates	
				});	
				updateValue([...updates.map(update => ({	
					name: update.fileName || update.name,	
					path: update.path,	
					url: update.url,	
					id: update.id	
				}))]);	
			});	
		} catch (e) {	
			console.error(e.toJSON());	
			if (isWebForm) {	
				alert(e.message);	
			} else {	
				store.dispatch(Creators.setMessage(e.message, "error"));	
			}	
			updateField({	
				files: updatedFiles.filter(f => f.path)	
			});	
		}	

	} else if (fileToDelete) {	
		// this is if a file was added and then removed without saving	
		await crm.deleteAttachment({ fileName: fileToDelete });	
		updateField({	
			files: updatedFiles.filter(f => f.fileName !== fileToDelete)	
		});	
	}	
	updateField({	
		disabled: false	
	}); 
	info.dispatchWrapper("enableSubmit", [true]);

};	

export default uploadAttachments;
