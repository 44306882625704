
const IS_LIMIT_EXCEEDED = "isLimitExceeded";

const isLimitExceeded = crm => ({ subscriber, compField, compIndex, dispatchWrapper, getState, fileCount, fileLimit }) => {
	const { data, fields } = getState();
	const fieldParts = [subscriber, compField, compIndex];
	const assetCount = fileCount || (data[subscriber] ? data[subscriber].filter(asset => asset.toBeDeleted !== true).length : 0);

	const handleMessaging = (limit, assetCount, fieldParts) => {
		if (limit === 0) {
			dispatchWrapper("clearValidation", [...fieldParts, IS_LIMIT_EXCEEDED]);
		} else {
			const valid = !(assetCount > limit) || (limit === -1 && assetCount === 0);

			if (!valid) {
				const message = limit === -1
					? "No Images allowed. Please remove all images."
					: `There is a maximum of ${limit} images. Please remove additional images.`;

				dispatchWrapper("setValidation", [...fieldParts, IS_LIMIT_EXCEEDED, message]);
			} else {
				dispatchWrapper("clearValidation", [...fieldParts, IS_LIMIT_EXCEEDED]);
			}
		}
	};

	if (fileCount && fileLimit) {
		handleMessaging(fileLimit, fileCount, fieldParts);
	} else {
		let listingTypeID = data["ListingTypeID"];

		if (listingTypeID?.value) {
			listingTypeID = listingTypeID.value;
		}

		if (listingTypeID) {
			crm.get({
				type: "listingType",
				filter: {
					recId: listingTypeID
				},
				fields: `
					ImageCnt
					AllowMedia
				`
			}).then(res => {
				if (res?.recs?.length > 0) {
					const limit = !res.recs[0].AllowMedia ? -1 : res.recs[0].ImageCnt || 0;
					handleMessaging(limit, assetCount, fieldParts);
				}
			});
		}
	}
};

export default isLimitExceeded;
