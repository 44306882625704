const browseFromAccountMedia = crm => async ({ field, getState, update }) => {
	const state = getState();
	
	let accountId = state.data.AccountID && state.data.AccountID.value;

	if (accountId === undefined || accountId === null) {
		const additionalFields = field?.fileAppendText?.dialog?.callWithValuesFromFields;
		
		if (additionalFields.length && state.data[additionalFields[0]] && state.data[additionalFields[0]].value) {
			accountId = state.data[additionalFields[0]].value;
		} else {
			update(field.name, { fileBrowserValues: [] });
			return;
		}
	}

	crm.get({
		type: "media",
		filter: {
			refId: accountId,
			type: "account"
		},
		fields: "Asset { recs { AssetID } } AssetPath Title MediaID MediaType AccountSortOrder"
	}).then(res => {
		update(field.name, { fileBrowserValues: res.recs.map(file => ({ ...file, AssetID: file.Asset.recs[0].AssetID })) });
	});
};


export default browseFromAccountMedia;
